import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Typography, Divider } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { useTranslation } from 'react-i18next';
import { EmailString, ProjectId } from '../../../models/Types';
import {
  CreateProjectDistributionList,
  ProjectDistributionContact,
  ProjectDistributionList,
} from '../../../models/ProjectDistributionList';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllContacts,
  getContactsByIdState,
} from '../../../apps/main/rootReducer';
import { createTemporaryId } from '../../../util';
import AddressSelect from '../../mail/components/AddressSelect';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import {
  updateProjectDistributionList,
  createProjectDistributionList,
} from '../actions';
import equals from 'deep-equal';
import FilterContextVirtualTable from '../../../components/Filter/FilterContextVirtualTable';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  addressSelect: {
    width: '100%',
    height: 'fit-content',
  },
  addContactsRow: {
    marginBottom: theme.old.spacing.unit(2),
    width: '100%',
  },
  errorTable: {
    color: theme.old.palette.chromaticPalette.red,
  },
  tableCell: {
    display: 'contents',
    '& > div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      marginLeft: '10px',
    },
  },
}));

interface ProjectDistributionListFormProps {
  initialValues?: ProjectDistributionList;
  openedDistributionList?: ProjectDistributionList;
  closeDrawer?: () => void;
  setIsSaveButtonDisabled?: (value: boolean) => void;
  disableForm?: boolean;
  projectId?: ProjectId;
  form?: any;
}

export const ProjectDistributionListForm: React.FC<
  ProjectDistributionListFormProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    initialValues,
    disableForm,
    projectId,
    form,
    openedDistributionList,
    closeDrawer,
    setIsSaveButtonDisabled,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [selectedTableItems, setSelectedTableItems] = useState<
    ProjectDistributionContact[]
  >([]);

  const [name, setName] = useState<string>(initialValues?.name ?? '');

  const [distributionContacts, setDistributionContacts] = useState<
    ProjectDistributionContact[]
  >(initialValues?.projectDistributionContacts ?? []);

  const emptyValues: CreateProjectDistributionList = useMemo(
    () => ({
      projectId: projectId,
      name: name,
      projectDistributionContacts: distributionContacts,
    }),
    [projectId, name, distributionContacts]
  );

  const [showTableError, setShowTableError] = useState<boolean>(false);

  const [isFilterEmailPickerShown, setIsFilterEmailPickerShown] =
    useState<boolean>(false);

  const [mailsToAdd, setMailsToAdd] = useState<EmailString[]>(null);

  const allContacts = useSelector(getAllContacts);
  const contactsById = useSelector(getContactsByIdState);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onFinish = (entry: ProjectDistributionList) => {
    if (openedDistributionList) {
      dispatch(updateProjectDistributionList(entry, openedDistributionList));
    } else {
      dispatch(
        createProjectDistributionList(entry, entry.projectDistributionListId)
      );
    }
  };

  const handleOnFinish = (value: CreateProjectDistributionList) => {
    if (!showTableError && onFinish) {
      onFinish({
        projectId: projectId,
        projectDistributionListId:
          initialValues?.projectDistributionListId ?? createTemporaryId(),
        projectDistributionContacts: distributionContacts,
        rowVersion: initialValues?.rowVersion ?? undefined,
        ...value,
      });
      closeDrawer();
    } else {
      setShowTableError(true);
    }
  };

  const addContactsToTable = () => {
    const addedContacts: ProjectDistributionContact[] = mailsToAdd?.map(
      (mail) => {
        return {
          contactId: allContacts.find((contact) => contact.eMail === mail)
            ?.contactId,
          eMail: mail,
        };
      }
    );
    setDistributionContacts(distributionContacts.concat(addedContacts));
    setShowTableError(false);

    setMailsToAdd(null);
    setIsFilterEmailPickerShown(false);
  };

  const cancelAddContactsToTable = () => {
    setMailsToAdd(null);
    setIsFilterEmailPickerShown(false);
  };

  const deleteContactFromList = (contacts: ProjectDistributionContact[]) => {
    setDistributionContacts(
      distributionContacts.filter(
        (contact) =>
          !contacts.some(
            (contactToDelete) => contactToDelete.eMail === contact.eMail
          )
      )
    );
    setSelectedTableItems([]);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setDistributionContacts(initialValues?.projectDistributionContacts ?? []);
    setName(initialValues?.name ?? '');
  }, [initialValues]);

  useEffect(() => {
    form.setFieldsValue(emptyValues);
  }, [emptyValues, form]);

  useEffect(() => {
    const hasNameChanged = name !== initialValues?.name;
    const isNamePropValid = name !== '' && hasNameChanged;

    const hasProjectDistributionContactsNotChanged = equals(
      initialValues?.projectDistributionContacts,
      distributionContacts
    );
    const isContactsPropValid =
      !!distributionContacts?.length &&
      !hasProjectDistributionContactsNotChanged;

    if (setIsSaveButtonDisabled) {
      setIsSaveButtonDisabled(!isNamePropValid && !isContactsPropValid);
    }
  }, [
    initialValues?.name,
    name,
    distributionContacts,
    initialValues?.projectDistributionContacts,
    setIsSaveButtonDisabled,
  ]);
  //#endregion

  //#region ------------------------------ Table Components
  const columns: Column<ProjectDistributionContact>[] = [
    {
      title: t('projects:projectDistributionList.form.contacts.contact'),
      id: 'contactId',
      accessor: 'contactId',
      width: 30,
      sortingFn: (a, b) => a.eMail.localeCompare(b.eMail),
      Cell: (CellProps) => {
        const name = `${
          contactsById[CellProps.value]?.firstName
            ? `${contactsById[CellProps.value]?.firstName} `
            : ''
        }${contactsById[CellProps.value]?.lastName ?? ''}`;
        return <div>{!name ? '-' : name}</div>;
      },
      className: classes.tableCell,
    },
    {
      title: t('projects:projectDistributionList.form.contacts.email'),
      id: 'eMail',
      accessor: 'eMail',
      width: 70,
      sortingFn: (a, b) => a.eMail.localeCompare(b.eMail),
      Cell: (CellProps) => <div>{CellProps.value}</div>,
      className: classes.tableCell,
    },
  ];
  //#endregion

  return (
    <Flex.Column className={classes.fullHeight}>
      <Form<CreateProjectDistributionList>
        className={classes.root}
        onFinish={handleOnFinish}
        initialValues={emptyValues}
        form={form}
        layout="vertical"
        onValuesChange={(changedValues: CreateProjectDistributionList) => {
          setName(changedValues.name);
        }}
      >
        <Flex.Row>
          <Form.Item
            name={'name'}
            label={t('projects:projectDistributionList.form.details.name')}
            className={classes.fullWidth}
            rules={[
              {
                required: true,
                message: t(
                  'projects:projectDistributionList.form.validation.missingName'
                ),
              },
            ]}
          >
            <Input
              placeholder={
                openedDistributionList
                  ? t(
                      'projects:projectDistributionList.form.placeholder.name',
                      { name: initialValues?.name }
                    )
                  : ''
              }
              disabled={disableForm}
              value={name}
            />
          </Form.Item>
        </Flex.Row>
      </Form>

      <Divider />
      <Flex.Row className={classes.addContactsRow}>
        <Flex.Column flex={1} childrenGap={theme.old.spacing.unit(2)}>
          <div style={{ fontWeight: '600' }}>
            {t('projects:projectDistributionList.form.contacts.title')}
          </div>
          {!isFilterEmailPickerShown ? (
            <Flex.Row>
              <Button
                onClick={() => setIsFilterEmailPickerShown(true)}
                iconProp={['fal', 'plus']}
              >
                {t('projects:projectDistributionList.form.contacts.addContact')}
              </Button>
            </Flex.Row>
          ) : (
            <Flex.Row
              childrenGap={theme.old.spacing.unit(2)}
              alignItems="center"
            >
              <Flex.Item flex={1}>
                <AddressSelect
                  changeHandler={setMailsToAdd}
                  value={mailsToAdd}
                  className={classes.addressSelect}
                  projectId={projectId}
                  excludedMails={distributionContacts.map(
                    (contact) => contact.eMail
                  )}
                  bordered
                  closeableTags
                />
              </Flex.Item>

              <Button
                onClick={cancelAddContactsToTable}
                type="default"
                iconProp={['fal', 'times']}
                tooltip={t(
                  'projects:projectDistributionList.form.contacts.cancel'
                )}
              />
              <Button
                type="primary"
                onClick={addContactsToTable}
                iconProp={['fal', 'check']}
                disabled={!mailsToAdd?.length}
                tooltip={t(
                  'projects:projectDistributionList.form.contacts.add'
                )}
              />
            </Flex.Row>
          )}
        </Flex.Column>
      </Flex.Row>
      <Flex.Row flex={1}>
        <FilterContextVirtualTable<ProjectDistributionContact>
          id="projectDistributionListForm"
          columns={columns}
          data={distributionContacts}
          resizable="relative"
          rowsAreSelectable
          selectedItems={selectedTableItems}
          onSelectionChange={setSelectedTableItems}
          checkBoxContainerWidth={50}
          actionBarButtons={[
            {
              children: t('common:actions.delete'),
              iconProp: ['fal', 'trash'],
              onClick: () => deleteContactFromList(selectedTableItems),
            },
          ]}
        />
        {showTableError && (
          <Typography.Text className={classes.errorTable}>
            {t('projects:projectDistributionList.form.validation.missingEmail')}
          </Typography.Text>
        )}
      </Flex.Row>
    </Flex.Column>
  );
};

export default ProjectDistributionListForm;
