import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, notification, InputNumber } from 'antd';
import { Button, Drawer } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { HolidayEntitlement, Employee } from '../../../../models/Employee';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import {
  apiAddHolidayEntitlementEntry,
  apiUpdateHolidayEntitlement,
} from '../../api';
import { EmployeeId, OfficeId } from '../../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AbsenceOverview } from '../../../../models/AbsenceProposal';
import FilterContextVirtualTable from '../../../../components/Filter/FilterContextVirtualTable';

const useStyles = makePrioStyles((theme) => ({
  root: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing.regular,
    '& .prio-vt-body-container': {
      overflowX: 'hidden',
    },
  },
  actionButtonRow: {
    marginTop: theme.spacing.regular,
  },
  customDrawerContent: {
    paddingBottom: 0,
  },
  table: {
    border: `1px solid ${theme.colors.application.border}`,
    borderRadius: theme.borderRadius.regular,
  },
  tableRow: {
    cursor: 'pointer',
  },
}));

interface HolidayEntitlementsTableEntry extends HolidayEntitlement {
  remainingEntitlementEndOfLastYear: number;
}

interface HolidayEntitlementsTableProps {
  employee: Employee;
  setEmployee: (employee: Employee) => void;
  employeeId: EmployeeId;
  officeId: OfficeId;
  fetchAbsenceOverview: () => void;
  absenceOverview: AbsenceOverview;
  fetchEmployee: (args) => void;
}

export const HolidayEntitlementsTable: React.FC<
  HolidayEntitlementsTableProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    employee,
    employeeId,
    officeId,
    setEmployee,
    fetchAbsenceOverview,
    absenceOverview,
    fetchEmployee,
  } = props;
  const { t } = useTranslation();
  const [holidayEntitlementForm] = Form.useForm<HolidayEntitlement>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const currentYear: number = new Date().getUTCFullYear();

  const entitlements: HolidayEntitlementsTableEntry[] = useMemo(() => {
    const _entitlements = employee?.employeeHolidayEntitlements ?? [];
    const _updatedEntitlements = _entitlements
      .sort((a, b) => a.year - b.year)
      .map((entitlement, i) => {
        return {
          ...entitlement,
          remainingEntitlement:
            entitlement.year === currentYear - 1
              ? absenceOverview?.remainingDaysPreviousYear
              : entitlement.year === currentYear
              ? absenceOverview?.remainingVacationDays
              : entitlement.remainingEntitlement,
          remainingEntitlementEndOfLastYear:
            i === 0 ? 0 : _entitlements[i - 1].remainingEntitlement,
        } as HolidayEntitlementsTableEntry;
      });
    return _updatedEntitlements;
  }, [employee.employeeHolidayEntitlements, absenceOverview, currentYear]);

  const maxYear: number | null = useMemo(
    () =>
      entitlements?.length > 0
        ? entitlements.reduce(
            (max, entitlement) =>
              entitlement.year > max ? entitlement.year : max,
            1900
          )
        : null,
    [entitlements]
  );

  const newHolidayEntitlement: HolidayEntitlement = useMemo(() => {
    return {
      year: maxYear ? maxYear + 1 : new Date().getFullYear(),
      holidayEntitlement: 24,
    };
  }, [maxYear]);

  const [addDrawerVisible, setAddDrawerVisible] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [initialValuesDrawerForm, setInitialValuesDrawerForm] =
    useState<HolidayEntitlement>(newHolidayEntitlement);

  const [formValueHolidayEntitlement, setFormValueHolidayEntitlement] =
    useState<number>(initialValuesDrawerForm?.holidayEntitlement);

  const [formValueRemainingEntitlement, setFormValueRemainingHoliday] =
    useState<number>(initialValuesDrawerForm?.remainingEntitlement);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const createNewHolidayEntitlement = async (
    dataToSubmit: HolidayEntitlement
  ) => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const { data } = await apiAddHolidayEntitlementEntry(
        employeeId,
        officeId,
        {
          ...dataToSubmit,
          employeeId,
        }
      );
      if (data) {
        const updatedEmployee = {
          ...employee,
          employeeHolidayEntitlements: [
            ...(employee.employeeHolidayEntitlements ?? []),
            data,
          ],
        };
        setEmployee(updatedEmployee);
        await fetchAbsenceOverview();
        fetchEmployee({ signal });
      } else {
        notification.open({
          message: t('common:error'),
          description: t('hr:personnelFile.errorMessages.patchEmployee'),
        });
      }
    } catch {
      notification.open({
        message: t('common:error'),
        description: t('hr:personnelFile.errorMessages.newEntitlement'),
      });
    }
    return () => controller.abort();
  };

  const updateHolidayEntitlement = async (
    updatedHolidayEntitlement: HolidayEntitlement
  ) => {
    const dataToSubmit = {
      employeeHolidayEntitlementId:
        initialValuesDrawerForm.employeeHolidayEntitlementId,
      holidayEntitlement: updatedHolidayEntitlement.holidayEntitlement,
      remainingEntitlement: initialValuesDrawerForm.remainingEntitlement,
      rowVersion: initialValuesDrawerForm.rowVersion,
    };

    const controller = new AbortController();
    const signal = controller.signal;

    const { data } = await apiUpdateHolidayEntitlement(
      employeeId,
      dataToSubmit,
      officeId
    );
    if (data) {
      const updatedEmployee = {
        ...employee,
        employeeHolidayEntitlements: [
          ...(employee.employeeHolidayEntitlements ?? []).map((entitlement) =>
            entitlement.employeeHolidayEntitlementId ===
            data.employeeHolidayEntitlementId
              ? data
              : entitlement
          ),
        ],
      };
      setEmployee(updatedEmployee);
      await fetchAbsenceOverview();
      fetchEmployee({ signal });
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:personnelFile.errorMessages.patchEmployee'),
      });
    }
    return () => controller.abort();
  };

  const submitSubmitAddHolidayEntitlementForm = async () => {
    setIsSaving(true);
    const values: HolidayEntitlement = holidayEntitlementForm.getFieldsValue();

    const { remainingEntitlement, ...dataToSubmit } = values;

    if (isEditMode) {
      await updateHolidayEntitlement(dataToSubmit).then(() => {
        setAddDrawerVisible(false);
        setIsSaving(false);
        setInitialValuesDrawerForm(newHolidayEntitlement);
      });
    } else {
      await createNewHolidayEntitlement(dataToSubmit).then(() => {
        setAddDrawerVisible(false);
        setIsSaving(false);
        setInitialValuesDrawerForm(newHolidayEntitlement);
      });
    }
  };

  const cancelAddHolidayEntitlementForm = () => {
    setAddDrawerVisible(false);
    setInitialValuesDrawerForm(newHolidayEntitlement);
    holidayEntitlementForm.setFieldsValue(newHolidayEntitlement);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setIsEditMode(
      initialValuesDrawerForm?.employeeHolidayEntitlementId ? true : false
    );
  }, [initialValuesDrawerForm]);

  useEffect(() => {
    setInitialValuesDrawerForm(newHolidayEntitlement);
    holidayEntitlementForm.setFieldsValue(newHolidayEntitlement);
  }, [newHolidayEntitlement, holidayEntitlementForm]);
  //#endregion

  //#region ------------------------------ Table
  const columns: Column<HolidayEntitlementsTableEntry>[] = useMemo(
    () => [
      {
        id: 'year',
        accessor: 'year',
        title: t('hr:holidayEntitlementsTable.columnTitles.year'),
        sortingFn: (a, b) => a.year - b.year,
        Cell: (cellProps: any) => cellProps.value,
        width: 9,
        alignSelf: true,
      },
      {
        id: 'holidayEntitlement',
        accessor: 'holidayEntitlement',
        title: t('hr:holidayEntitlementsTable.columnTitles.holidayEntitlement'),
        Cell: (cellProps: any) => cellProps.value,
        width: 30,
        alignSelf: true,
      },
      {
        id: 'remainingEntitlementEndOfLastYear',
        accessor: 'remainingEntitlementEndOfLastYear',
        title: t(
          'hr:holidayEntitlementsTable.columnTitles.remainingEntitlementEndOfLastYear'
        ),
        Cell: (cellProps: any) => {
          let value = cellProps.value ?? 0;
          return value;
        },
        width: 30,
        alignSelf: true,
      },
      {
        id: 'remainingEntitlement',
        accessor: 'remainingEntitlement',
        title: t(
          'hr:holidayEntitlementsTable.columnTitles.remainingEntitlement'
        ),
        Cell: (cellProps: any) => {
          let value =
            cellProps.value ??
            t(
              'hr:holidayEntitlementsTable.placeholder.remainingEntitlementCalculated'
            );
          return value;
        },
        width: 30,
        alignSelf: true,
      },
    ],
    [t]
  );

  const data: HolidayEntitlementsTableEntry[] = useMemo(
    () => entitlements.sort((a, b) => a.year - b.year),
    [entitlements]
  );
  //#endregion

  //#region ------------------------------ Drawer
  const addDrawerContent = () => {
    return (
      <Form<HolidayEntitlement>
        form={holidayEntitlementForm}
        layout="vertical"
        initialValues={initialValuesDrawerForm}
        onValuesChange={(_, allValues) => {
          setFormValueHolidayEntitlement(allValues?.holidayEntitlement ?? null);
          setFormValueRemainingHoliday(allValues?.remainingEntitlement ?? null);
        }}
      >
        <Flex.Column>
          <Form.Item
            name="year"
            label={t('hr:holidayEntitlementsTable.columnTitles.year')}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="holidayEntitlement"
            label={
              <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
                {!(initialValuesDrawerForm.year > currentYear - 2) && (
                  <div
                    title={t(
                      'hr:holidayEntitlementsTable.drawer.info.notChangeable'
                    )}
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'info-circle']}
                      color={theme.colors.application.typography.muted}
                    />
                  </div>
                )}
                <span>
                  {t(
                    'hr:holidayEntitlementsTable.columnTitles.holidayEntitlement'
                  )}
                </span>
              </Flex.Row>
            }
            rules={[
              {
                required: true,
                message: t(
                  'hr:holidayEntitlementsTable.validation.holidayEntitlement'
                ),
              },
            ]}
          >
            <InputNumber
              disabled={
                isSaving || !(initialValuesDrawerForm.year > currentYear - 2)
              }
              step={1}
              min={0}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            name="remainingEntitlement"
            label={t(
              'hr:holidayEntitlementsTable.columnTitles.remainingEntitlement'
            )}
            rules={[
              {
                required: true,
                message: t(
                  'hr:holidayEntitlementsTable.validation.remainingEntitlement'
                ),
              },
            ]}
          >
            <InputNumber
              placeholder={
                initialValuesDrawerForm.year === currentYear - 2
                  ? ''
                  : t(
                      'hr:holidayEntitlementsTable.placeholder.remainingEntitlementCalculated'
                    )
              }
              disabled={
                isSaving || initialValuesDrawerForm.year !== currentYear - 2
              }
              step={1}
              min={0}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Flex.Column>
      </Form>
    );
  };

  const addDrawerFooter = () => {
    const disabled =
      // when saving
      isSaving ||
      // when either of the fields are null
      formValueHolidayEntitlement === null ||
      // when in edit mode and the values havent changed
      (isEditMode &&
        formValueHolidayEntitlement ===
          initialValuesDrawerForm.holidayEntitlement &&
        formValueRemainingEntitlement ===
          initialValuesDrawerForm.remainingEntitlement);
    return (
      <Flex.Row justifyContent="flex-end" childrenGap={theme.spacing.small}>
        <Button type="link" onClick={cancelAddHolidayEntitlementForm}>
          {t('common:actions.cancel')}
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          disabled={disabled}
          onClick={submitSubmitAddHolidayEntitlementForm}
        >
          {t('common:save')}
        </Button>
      </Flex.Row>
    );
  };
  //#endregion

  return (
    <>
      <div className={classes.root}>
        <FilterContextVirtualTable<HolidayEntitlementsTableEntry>
          className={classes.table}
          id="holidayEntitlementsTable"
          resizable="relative"
          columns={columns}
          data={data}
          onRow={(record) => ({
            onClick: () => {
              setAddDrawerVisible(true);
              setInitialValuesDrawerForm(record);
              holidayEntitlementForm.setFieldsValue(record);
              setFormValueHolidayEntitlement(record?.holidayEntitlement);
              setFormValueRemainingHoliday(record?.remainingEntitlement);
            },
          })}
          checkBoxContainerWidth={50}
          classNameTableRow={classes.tableRow}
        />

        <Flex.Row justifyContent="flex-end" className={classes.actionButtonRow}>
          <Button type="primary" onClick={() => setAddDrawerVisible(true)}>
            {t('hr:holidayEntitlementsTable.actions.add')}
          </Button>
        </Flex.Row>
        <Drawer
          title={t(
            `hr:holidayEntitlementsTable.drawer.title${
              isEditMode ? 'Edit' : 'Add'
            }Mode`
          )}
          visible={addDrawerVisible}
          onClose={cancelAddHolidayEntitlementForm}
          children={addDrawerContent()}
          footer={addDrawerFooter()}
          contentClassName={classes.customDrawerContent}
        />
      </div>
    </>
  );
};

export default HolidayEntitlementsTable;
