import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import DocumentTemplateFormSelector from './DocumentTemplateFormSelector';
import { useTranslation } from 'react-i18next';
import { DriveItem } from '../../../../models/Drive';
import {
  CompanyId,
  ContactId,
  DocumentTemplateId,
  DriveItemId,
  GroupId,
  OfficeId,
  ProjectId,
} from '../../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Divider,
  Form,
  Input,
  notification,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import Flex from '../../../../components/Flex';
import { colon, rowGutter } from '../../../../util/forms';
import moment from 'moment';
import { fileNameRegex } from '../../util';
import {
  CreateDocumentFromTemplate,
  CreateDocumentReportRequest,
  CreateDocumentRequest,
  Placeholder,
  PlaceholderKeyValue,
} from '../../../../models/Document';
import ContactPicker from '../../../contacts/components/ContactPicker';
import CompanyPicker from '../../../companies/components/CompanyPicker';
import OfficePicker from '../../../companies/components/OfficePicker';
import { DocumentTemplate } from '../../../../models/DocumentTemplate';
import { distinct } from '../../../../util';
import {
  getCompaniesByIdState,
  getCurrentFolderItem,
  getCurrentRemoteItem,
  getProject,
  getProjectByIdState,
  getUserMeContactId,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { createSelector } from 'reselect';
import { CompaniesByIdState } from '../../../companies/reducers/companies';
import { ProjectByIdState } from '../../../projects/reducers/projects';
import DriveItemBreadcrumb from '../DriveItemBreadcrumb/DriveItemBreadcrumb';
import ProjectPicker from '../../../projects/components/ProjectPicker';
import { Project } from '../../../../models/Project';
import { TimeRecord, TimeRecordsFilter } from '../../../../models/TimeRecord';
import {
  apiCreateDocument,
  apiCreateDocumentReport,
  apiDownloadDocumentTemplatePreview,
  apiFetchDocumentPrefix,
  apiFetchDocumentTemplateSuggestionPath,
} from '../../api';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { makePrioStyles } from '../../../../theme/utils';
import {
  DriveItemsFetchContext,
  fetchDriveItemsSagaAction,
} from '../../actions';
import FolderSelectionDrawer from '../Drawers/FolderSelectionDrawer';
import { PathSuggestionRadioButton } from './PathSuggestionRadioButton';
import ProjectPhasePicker from '../../../projects/components/ProjectPhasePicker';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    flex: 1,
  },
  newDocument: {
    height: 'calc(100% - 294px)',
  },
  projectPickerWidth: {
    width: '100%',
  },
  prefixLabel: {
    whiteSpace: 'nowrap',
  },
  targetFolderBreadcrumb: {
    marginTop: theme.old.spacing.unit(1),
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
  },
  editTargetFolderIcon: {
    color: `${theme.old.palette.primaryColor}!important`,
  },
  noTemplateSelected: {
    margin: `${theme.old.spacing.unit(3)}px 0`,
  },
  spacing: {
    marginBottom: theme.old.spacing.defaultPadding,
  },
  buttonSpacing: {
    marginRight: theme.old.spacing.defaultPadding,
  },
  fullHeight: {
    height: '100%',
    overflow: 'hidden',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
  },
  radioGroup: {
    overflow: 'hidden',
    width: '100%',
    '& .ant-space-vertical': {
      width: '100%',
    },
    '& .ant-radio-wrapper': {
      overflow: 'hidden',
      width: '100%',
    },
    '& .ant-radio-wrapper > span:last-child': {
      display: 'flex',
      overflow: 'hidden',
      width: '100%',
    },
  },
  warning: {
    color: theme.colors.base.red[80],
  },
  formSelectorFullHeight: {
    height: '100%',
  },
  boldFont: {
    fontWeight: 600,
  },
  noData: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing.regular,
  },
  drawerButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));

const placeholderRegex = /@([A-Za-z.]+)\b/g;
const KNOWN_SELECTABLE_PLACEHOLDERS = [
  'project',
  'contact',
  'company',
  'office',
  'internaloffice',
  'creator',
  'date',
  'projectphase',
];

/*
#################### const static methods
*/
const mainOfficeSelector = (internalCompanyId: CompanyId) =>
  createSelector<[(state: RootReducerState) => CompaniesByIdState], OfficeId>(
    (state) => getCompaniesByIdState(state),
    (companyByIds) => {
      return (
        companyByIds[internalCompanyId]?.offices?.find(
          (office) => office.isMainOffice
        )?.officeId ?? null
      );
    }
  );

const internalCompanySelector = (selectedProjectId: ProjectId) =>
  createSelector<[(state: RootReducerState) => ProjectByIdState], CompanyId>(
    (state) => getProjectByIdState(state),
    (projectsById) =>
      projectsById ? projectsById[selectedProjectId]?.subsidiaryId : null
  );

export interface DocumentTemplateFormWrapperRef {
  onFinish: VoidFunction;
}

interface DocumentTemplateFormWrapperProps {
  className?: string;
  parentFolderDriveItem?: DriveItem;
  projectId?: ProjectId;
  groupId?: GroupId;
  showOnlyReports?: boolean;
  timeRecords?: TimeRecord[];
  filter?: TimeRecordsFilter;
  projectPickerFilter?: (project: Project) => boolean;
  onSuccess?: (targetFolder: DriveItem, projectId: ProjectId) => void;
  onStartCreateDocument?: () => void;
  onCancel?: () => void;
  onChange?: () => void;
  onThumbnailURLChange?: (thumbnailURL: String[]) => void;
  onSelectedTemplateChange?: (selectedTemplate: any) => void;
  setIsFormValid?: (isFormValid: boolean) => void;
}

export const DocumentTemplateFormWrapper = forwardRef(
  (
    props: DocumentTemplateFormWrapperProps,
    ref: React.Ref<DocumentTemplateFormWrapperRef>
  ) => {
    const {
      className,
      parentFolderDriveItem,
      projectId,
      showOnlyReports,
      timeRecords,
      projectPickerFilter,
      onStartCreateDocument,
      onSuccess,
      onCancel,
      onThumbnailURLChange,
      onSelectedTemplateChange = () => {},
      filter,
      setIsFormValid,
    } = props;

    const theme = useTheme<PrioTheme>();
    //#region ------------------------------------------------- Defaults
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    //#endregion

    //#region  ------------------------------------------------- States / Attributes / Selectors
    const currentRemoteItem = useSelector(getCurrentRemoteItem);

    const [selectedProjectId, setSelectedProjectId] =
      useState<ProjectId>(projectId);
    const [saveDestinationProjectId, setSaveDestinationProjectId] =
      useState<ProjectId>(projectId);
    const internalCompanyIdFromProjectId = useSelector(
      internalCompanySelector(selectedProjectId)
    );
    const mainOfficeId = useSelector(
      mainOfficeSelector(internalCompanyIdFromProjectId)
    );
    const [prefix, setPrefix] = useState<string>(
      moment().format('YYYY-MM-DD_')
    );
    const [templateId, setTemplateId] = useState<DocumentTemplateId>();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [targetFolderId, setTargetFolderId] = useState<DriveItemId>(
      parentFolderDriveItem?.id
    );

    const targetFolder = useSelector<RootReducerState, DriveItem>((state) =>
      getCurrentFolderItem(state, targetFolderId)
    );

    const [saveFolderDriveItemId, setSaveFolderDriveItemId] =
      useState<DriveItemId>(targetFolderId);

    const [saveLocationSuggestions, setSaveLocationSuggestions] =
      useState<DriveItem[]>();
    const [saveFolderChoice, setSaveFolderChoice] = useState<
      'customFolder' | string
    >('customFolder');
    const [
      selectTargetFolderDrawerVisible,
      setSelectTargetFolderDrawerVisible,
    ] = useState<boolean>(false);
    const [collapsed, setCollapsed] = useState<Boolean>(true);
    const [documentTemplates, setDocumentTemplates] = useState<
      DocumentTemplate[]
    >([]);
    const [isMounted, setIsMounted] = useState(false);
    const userId = useSelector(getUserMeContactId);

    const projectForSavePath = useSelector<RootReducerState, Project>((state) =>
      getProject(state, saveDestinationProjectId)
    );

    const [documentName, setDocumentName] = useState<string>('');
    const [sourceProjectId, setSourceProjectId] =
      useState<ProjectId>(projectId);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [placeholder, setPlaceholder] = useState(null);
    const [hasProjectPhases, setHasProjectPhases] = useState<boolean>(false);

    //#endregion

    //#region  ------------------------------------------------- Memos
    const [
      hasInternalOffice,
      hasCompany,
      hasOffice,
      hasContact,
      hasProjectPhase,
    ] = useMemo(() => mapToSinglePlaceholders(placeholder), [placeholder]);

    const initialValues = useMemo(
      () =>
        selectedTemplate
          ? {
              documentName: '',
              saveFolderDriveItemId: '',
              templateTags: [],
              projectId: selectedProjectId,
              documentTemplateId: selectedTemplate.documentTemplateId,
              contact: hasContact ? '' : undefined,
              office: hasOffice ? '' : undefined,
              company: hasCompany ? '' : undefined,
              internalOffice: hasInternalOffice ? mainOfficeId : undefined,
              projectPhase: hasProjectPhase ? '' : undefined,
            }
          : null,
      [
        selectedProjectId,
        selectedTemplate,
        hasInternalOffice,
        hasCompany,
        hasOffice,
        hasContact,
        mainOfficeId,
        hasProjectPhase,
      ]
    );
    //#endregion

    //#region  ------------------------------------------------- Effects
    useEffect(() => {
      if (templateId) {
        const newSelectedTemplate =
          templateId &&
          documentTemplates.find((t) => t.documentTemplateId === templateId);
        setSelectedTemplate(newSelectedTemplate);
        const newPlaceholder =
          newSelectedTemplate &&
          extractPlaceholders(
            newSelectedTemplate,
            selectedProjectId,
            mainOfficeId,
            userId
          );
        setPlaceholder(newPlaceholder);
      }
    }, [
      templateId,
      documentTemplates,
      mainOfficeId,
      selectedProjectId,
      userId,
    ]);

    useEffect(() => {
      if (selectedProjectId) {
        setSelectedProjectId(selectedProjectId);
      }
    }, [selectedProjectId]);
    useEffect(() => {
      onSelectedTemplateChange(selectedTemplate);
    }, [selectedTemplate, onSelectedTemplateChange]);

    useEffect(() => {
      async function fetchDocumentTemplateSuggestionPath(
        templateId: string,
        saveDestinationProjectId: ProjectId
      ) {
        const { data } = await apiFetchDocumentTemplateSuggestionPath(
          templateId,
          saveDestinationProjectId
        );
        if (data) {
          setSaveLocationSuggestions(data);
        }
      }

      async function fetchPrefix() {
        const { data } = await apiFetchDocumentPrefix(saveDestinationProjectId);
        if (data) {
          setPrefix(data.value);
        }
      }

      if (sourceProjectId) {
        fetchPrefix();
      }
      if (templateId && saveDestinationProjectId) {
        fetchDocumentTemplateSuggestionPath(
          templateId,
          saveDestinationProjectId
        );
      }
    }, [saveDestinationProjectId, templateId, sourceProjectId]);

    useEffect(() => {
      setIsMounted(true);
      return () => setIsMounted(false);
    }, []);

    useEffect(() => {
      if (projectId) {
        setSelectedProjectId(projectId);
      }
    }, [projectId]);

    useEffect(() => {
      if (targetFolder) {
        setTargetFolderId(targetFolder.id);
      }
    }, [targetFolder]);

    useEffect(() => {
      form.resetFields();
    }, [initialValues, placeholder, form]);

    useEffect(() => {
      async function getThumbnails() {
        if (onThumbnailURLChange) onThumbnailURLChange([]);
        const response = await apiDownloadDocumentTemplatePreview(
          selectedTemplate.documentTemplateId
        );
        if (response && response.data) {
          if (onThumbnailURLChange)
            onThumbnailURLChange([response?.data['url']]);
        }
      }

      if (selectedTemplate && selectedTemplate.documentTemplateId) {
        getThumbnails();
      } else {
      }
    }, [selectedTemplate, onThumbnailURLChange]);

    useEffect(() => {
      const validateForm = async () => {
        try {
          if (!saveFolderDriveItemId || !templateId) {
            setIsFormValid(false);
            return;
          }
          await form.validateFields();
          setIsFormValid(true);
        } catch (error) {
          setIsFormValid(false);
        }
      };
      validateForm();
    }, [form, templateId, setIsFormValid, saveFolderDriveItemId]);

    useEffect(() => {
      if (selectedProjectId) {
        form.setFieldsValue({ documentName: documentName });
      }
    }, [selectedProjectId, documentName, form]);

    useImperativeHandle(ref, () => ({
      onFinish: form.submit,
    }));
    //#endregion

    //#region  ------------------------------------------------- helper methods
    const onCallFinished = () => {
      onSuccess(targetFolder, selectedProjectId);
      if (isMounted) {
        setIsSaving(false);
        onCreateDocumentSuccess();
      }
    };

    const startCreateDocument = () => {
      notification.open({
        message: t('common:info'),
        description: t(
          'documents:newDocumentForm.successMessages.startCreateDocument'
        ),
      });
      if (onStartCreateDocument) {
        onStartCreateDocument();
      }
    };
    //#endregion

    //#region  ------------------------------------------------- onChangeEvents
    const onTemplateIdChange = (templateId: DocumentTemplateId) => {
      setTemplateId(templateId);
      if (setIsFormValid) {
        setIsFormValid(false);
      }
    };
    const updateCollapsed = () => {
      setCollapsed(!collapsed);
    };
    const handleFormValuesChange = async () => {
      if (setIsFormValid) {
        try {
          await form.validateFields();
          setIsFormValid(saveFolderDriveItemId ? true : false);
        } catch (error) {
          setIsFormValid(false);
        }
      }
    };

    const handleProjectPhaseChange = (projectPhaseId: string) => {
      form.setFieldsValue({ projectPhase: projectPhaseId });
    };
    //#endregion

    //#region  ------------------------------------------------- handleEvents
    const handleOnBreadcrumbItemClick = (driveItemId: DriveItemId) => {
      setTargetFolderId(driveItemId);
      setSaveFolderDriveItemId(driveItemId);
      if (!!projectForSavePath) {
        const { groupId, projectId } = projectForSavePath;
        dispatch(
          fetchDriveItemsSagaAction(
            projectId,
            groupId,
            driveItemId,
            driveItemId === null,
            DriveItemsFetchContext.Other
          )
        );
      }
    };

    const openTargetFolderDrawer = () => {
      setSelectTargetFolderDrawerVisible(true);
    };

    const handleTargetFolderDrawerOk = (targetFolder: DriveItem | null) => {
      setSelectTargetFolderDrawerVisible(false);
      setTargetFolderId(targetFolder?.id);
      setSaveFolderDriveItemId(targetFolder?.id);
    };
    const handleTargetFolderDrawerCancel = () => {
      setSelectTargetFolderDrawerVisible(false);
    };

    const handleProjectChange = (givenProjectId: ProjectId) => {
      setSelectedProjectId(givenProjectId);
      setSourceProjectId(givenProjectId);
      if (setIsFormValid) {
        setIsFormValid(false);
      }
      if (saveDestinationProjectId == null) {
        setSaveDestinationProjectId(givenProjectId);
      }
    };

    const onCreateDocumentSuccess = () => {};
    const handleSaveFolderChoice = (e: RadioChangeEvent) => {
      setSaveFolderChoice(e.target.value);
      if (e.target.value !== 'customFolder') {
        setSaveFolderDriveItemId(e.target.value);
      } else {
        setTargetFolderId(targetFolder !== null ? targetFolder?.id : null);
        setSaveFolderDriveItemId(
          targetFolder !== null ? targetFolder?.id : null
        );
      }
    };

    const handleOnFinish = async (createDocumentRequest) => {
      const placeholderRequest = placeholder?.map(({ name, values }) => ({
        name,
        values: values.map((value) => {
          switch (value.key.toLowerCase()) {
            case 'internaloffice': {
              return {
                key: value.key,
                id: createDocumentRequest.internalOffice,
              };
            }
            case 'company': {
              return {
                key: value.key,
                id: createDocumentRequest.company,
              };
            }
            case 'office': {
              return {
                key: value.key,
                id: createDocumentRequest.office,
              };
            }
            case 'contact': {
              return {
                key: value.key,
                id: createDocumentRequest.contact,
              };
            }
            case 'projectphase': {
              return {
                key: value.key,
                id: createDocumentRequest.projectPhase,
              };
            }
            default: {
              return value;
            }
          }
        }),
      }));

      const requestPayloadDocumentRequest: CreateDocumentFromTemplate = {
        ...createDocumentRequest,
        placeholder: placeholderRequest,
        documentName: `${prefix}${createDocumentRequest.documentName}`,
        saveFolderDriveItemId: saveFolderDriveItemId,
        saveDestinationProjectId: saveDestinationProjectId ?? selectedProjectId,
        projectId: selectedProjectId,
        documentTemplateId: templateId,
      };
      if (selectedTemplate?.isReport) {
        const requestPayload: CreateDocumentReportRequest = {
          createDocument: requestPayloadDocumentRequest,
          timeRecords: timeRecords,
          reportFilterDto: filter,
        };
        setIsSaving(true);
        startCreateDocument();
        await apiCreateDocumentReport(requestPayload);
        onCallFinished();
      } else {
        const requestPayload: CreateDocumentRequest =
          requestPayloadDocumentRequest;
        setIsSaving(true);
        startCreateDocument();
        await apiCreateDocument(requestPayload);
        onCallFinished();
      }
    };
    //#endregion

    //#region ------------------------------------------------- BODY
    const pathSuggestions = () => {
      return saveLocationSuggestions?.map((driveItem, i) => {
        const pathItems = driveItem.parentReference.path.split('/').slice(3);
        return (
          <PathSuggestionRadioButton
            driveItem={driveItem}
            pathItems={pathItems}
            project={projectForSavePath}
          />
        );
      });
    };
    //#endregion

    //#region ------------------------------------------------- BODY
    return (
      <>
        <Flex.Row className={classNames(classes.root, className)}>
          <Flex.Column flex={3}>
            <DocumentTemplateFormSelector
              className={classNames({
                [classes.formSelectorFullHeight]: !(
                  templateId && selectedProjectId
                ),
              })}
              isSaving={false}
              showOnlyReports={showOnlyReports}
              onTemplateIdChange={onTemplateIdChange}
              onTemplatesLoaded={setDocumentTemplates}
              onCancel={onCancel}
              onChange={updateCollapsed}
              cancelable={!(templateId && saveDestinationProjectId)}
            >
              {!projectId && (
                <>
                  <Form layout="vertical">
                    <Flex.Row>
                      <Flex.Item flex={1}>
                        <Row gutter={theme.old.spacing.unit(rowGutter)}>
                          <Col span={24}>
                            <Form.Item
                              label={t(
                                'documents:newDocumentForm.labels.project'
                              )}
                            >
                              <ProjectPicker
                                filter={projectPickerFilter}
                                onChange={handleProjectChange}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Flex.Item>
                    </Flex.Row>
                  </Form>
                </>
              )}
            </DocumentTemplateFormSelector>
            {templateId && saveDestinationProjectId && (
              <>
                <Divider style={{ marginTop: '8px' }} />
                <Typography.Title level={5}>
                  {t('documents:documentTemplates.generalHeadline')}
                </Typography.Title>
                <Form
                  form={form}
                  onFinish={handleOnFinish}
                  onValuesChange={handleFormValuesChange}
                  layout="vertical"
                  initialValues={initialValues}
                  id={'prio-project-new-documents-form'}
                >
                  <Flex.Row className={classes.fullHeight}>
                    <Flex.Item flex={1} className={classes.flex}>
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        <Row gutter={theme.old.spacing.unit(rowGutter)}>
                          <Col span={24}>
                            <Form.Item
                              name="documentName"
                              validateTrigger="onBlur"
                              colon={colon}
                              label={t(
                                'documents:newDocumentForm.labels.documentName'
                              )}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'documents:newDocumentForm.validation.missingDocumentName'
                                  ),
                                },
                                {
                                  message: t(
                                    'documents:newDocumentForm.validation.invalidDocumentName'
                                  ),
                                  pattern: fileNameRegex,
                                },
                              ]}
                            >
                              <Input
                                disabled={isSaving}
                                value={documentName}
                                onChange={(e) =>
                                  setDocumentName(e.target.value)
                                }
                                prefix={prefix}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider style={{ marginTop: '8px' }} />
                        <Typography.Title level={5}>
                          {t('documents:documentTemplates.placeholderHeadline')}
                        </Typography.Title>
                        {!hasInternalOffice &&
                          !hasCompany &&
                          !hasOffice &&
                          !hasContact &&
                          !hasProjectPhase && (
                            <div className={classes.noData}>
                              <div>
                                {t(
                                  'documents:documentTemplates.noPlaceholders'
                                )}
                              </div>
                            </div>
                          )}
                        {hasInternalOffice && (
                          <Row gutter={theme.old.spacing.unit(rowGutter)}>
                            <Col span={24}>
                              <Form.Item
                                name="internalOffice"
                                validateTrigger="onBlur"
                                colon={colon}
                                label={t(
                                  'documents:newDocumentForm.labels.internalOffice'
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'documents:newDocumentForm.validation.missingPlaceholderChoice'
                                    ),
                                  },
                                ]}
                              >
                                <OfficePicker
                                  onlyInternal
                                  disabled={isSaving}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        {hasCompany && (
                          <Row gutter={theme.old.spacing.unit(rowGutter)}>
                            <Col span={24}>
                              <Form.Item
                                name="company"
                                colon={colon}
                                validateTrigger="onBlur"
                                label={t(
                                  'documents:newDocumentForm.labels.company'
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'documents:newDocumentForm.validation.missingPlaceholderChoice'
                                    ),
                                  },
                                ]}
                              >
                                <CompanyPicker disabled={isSaving} />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        {hasOffice && (
                          <Row gutter={theme.old.spacing.unit(rowGutter)}>
                            <Col span={24}>
                              <Form.Item
                                name="office"
                                colon={colon}
                                validateTrigger="onBlur"
                                label={t(
                                  'documents:newDocumentForm.labels.office'
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'documents:newDocumentForm.validation.missingPlaceholderChoice'
                                    ),
                                  },
                                ]}
                              >
                                <OfficePicker disabled={isSaving} />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        {hasContact && (
                          <Row gutter={theme.old.spacing.unit(rowGutter)}>
                            <Col span={24}>
                              <Form.Item
                                name="contact"
                                colon={colon}
                                validateTrigger="onBlur"
                                label={t(
                                  'documents:newDocumentForm.labels.contact'
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'documents:newDocumentForm.validation.missingPlaceholderChoice'
                                    ),
                                  },
                                ]}
                              >
                                <ContactPicker disabled={isSaving} />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        {hasProjectPhase && (
                          <Row gutter={theme.old.spacing.unit(rowGutter)}>
                            <Col span={24}>
                              <Form.Item
                                name="projectPhase"
                                validateTrigger="onBlur"
                                colon={colon}
                                label={t(
                                  'documents:newDocumentForm.labels.projectPhase'
                                )}
                                rules={[
                                  {
                                    required: hasProjectPhases,
                                    message: t(
                                      'documents:newDocumentForm.validation.missingPlaceholderChoice'
                                    ),
                                  },
                                ]}
                              >
                                <ProjectPhasePicker
                                  projectId={selectedProjectId}
                                  onChange={handleProjectPhaseChange}
                                  disabled={isSaving || !hasProjectPhases}
                                  setHasProjectPhases={setHasProjectPhases}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        <Divider style={{ marginTop: '8px' }} />
                        <Typography.Title level={5}>
                          {t(
                            'documents:newDocumentForm.labels.saveFolderChoice'
                          )}
                        </Typography.Title>
                        <Row gutter={theme.old.spacing.unit(rowGutter)}>
                          <Col span={24}>
                            <Form.Item
                              label={
                                <div>
                                  {t(
                                    `documents:newDocumentForm.labels.targetFolder`
                                  )}
                                  <Button
                                    onClick={openTargetFolderDrawer}
                                    iconProp={['fal', 'pen']}
                                    type="link"
                                  ></Button>
                                </div>
                              }
                            >
                              {
                                <Radio.Group
                                  value={saveFolderChoice}
                                  onChange={handleSaveFolderChoice}
                                  disabled={isSaving}
                                  className={classes.radioGroup}
                                >
                                  <Space direction="vertical">
                                    <Radio
                                      value="customFolder"
                                      key="customFolder"
                                    >
                                      <DriveItemBreadcrumb
                                        driveItem={
                                          targetFolder ?? parentFolderDriveItem
                                        }
                                        remoteItem={currentRemoteItem}
                                        project={projectForSavePath}
                                        onItemClick={
                                          handleOnBreadcrumbItemClick
                                        }
                                        disableLinkNavigation
                                      />
                                    </Radio>
                                  </Space>
                                </Radio.Group>
                              }
                            </Form.Item>
                            {saveLocationSuggestions?.length > 0 && (
                              <Form.Item
                                label={t(
                                  'documents:newDocumentForm.labels.folderSuggestions'
                                )}
                              >
                                {
                                  <Radio.Group
                                    value={saveFolderChoice}
                                    onChange={handleSaveFolderChoice}
                                    disabled={isSaving}
                                    className={classes.radioGroup}
                                  >
                                    <Space direction="vertical">
                                      {pathSuggestions()}
                                    </Space>
                                  </Radio.Group>
                                }
                              </Form.Item>
                            )}
                          </Col>
                        </Row>
                        {!saveFolderDriveItemId && (
                          <p className={classes.warning}>
                            {t(
                              `documents:newDocumentForm.labels.noFolderSelected`
                            )}
                          </p>
                        )}
                      </div>
                    </Flex.Item>
                  </Flex.Row>
                </Form>
              </>
            )}
          </Flex.Column>
        </Flex.Row>
        {projectForSavePath && (
          <FolderSelectionDrawer
            visible={selectTargetFolderDrawerVisible}
            onOk={handleTargetFolderDrawerOk}
            onCancel={handleTargetFolderDrawerCancel}
            projectId={saveDestinationProjectId}
            selectedFolder={targetFolder?.id ?? parentFolderDriveItem?.id}
            allowProjectChange
            setProjectId={setSaveDestinationProjectId}
          />
        )}
      </>
    );
    //#endregion
  }
);

const extractPlaceholders: (
  documentTemplate: DocumentTemplate,
  selectedProjectId: ProjectId,
  mainOfficeId: OfficeId,
  userId: ContactId
) => Placeholder[] = (
  documentTemplate,
  selectedProjectId,
  mainOfficeId,
  userId
) => {
  var templatePlaceholders =
    documentTemplate.placeholders ??
    documentTemplate.documentTemplatePlaceholder;
  return templatePlaceholders?.map(({ name, value }) => ({
    name: name,
    values: distinct(
      Array.from(value.matchAll(placeholderRegex), (m) => m[1]).map(
        (placeholderString) => placeholderString.split('.')[0]
      )
    )
      .filter((placeholderString) =>
        KNOWN_SELECTABLE_PLACEHOLDERS.includes(placeholderString.toLowerCase())
      )
      .map((placeholderString) => {
        switch (placeholderString.toLowerCase()) {
          case 'project': {
            return { key: placeholderString, id: selectedProjectId };
          }
          case 'internaloffice': {
            return { key: placeholderString, id: mainOfficeId };
          }
          case 'creator': {
            return { key: placeholderString, id: userId };
          }
          case 'date': {
            return { key: placeholderString, id: '' };
          }
          case 'projectphase': {
            return { key: placeholderString, id: '' };
          }
          default: {
            return { key: placeholderString, id: '' };
          }
        }
      }),
  }));
};

const mapToSinglePlaceholders: (
  placeholders: Placeholder[]
) => [boolean, boolean, boolean, boolean, boolean] = (placeholders) => {
  if (!!placeholders) {
    const reducedKeys = distinct(
      placeholders
        .reduce<PlaceholderKeyValue[]>((previous, current) => {
          return previous.concat(current.values);
        }, [])
        .map((value) => value.key.toLowerCase())
    );
    return [
      reducedKeys.includes('internaloffice'),
      reducedKeys.includes('company'),
      reducedKeys.includes('office'),
      reducedKeys.includes('contact'),
      reducedKeys.includes('projectphase'),
    ];
  }
  return [false, false, false, false, false];
};
export default DocumentTemplateFormWrapper;
