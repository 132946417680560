import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { Button, Select } from '@prio365/prio365-react-library';

import {
  getContactsIsFetching,
  getInternalProjectContactsIsFetching,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Contact } from '../../../models/Contact';
import {
  ContactId,
  ProjectId,
  ContactType,
  OfficeId,
} from '../../../models/Types';
import { isTemporaryId } from '../../../util';
import Flex from '../../../components/Flex';
import { useTranslation } from 'react-i18next';
import { apiSearchContacts } from '../api';
import useDebounce from '../../../hooks/useDebounce';
import PrioSpinner from '../../../components/PrioSpinner';
import { fetchInternalProjectContacts } from '../../projects/actions';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import useContactsContext from '../hooks/useContactsProvider';
import useCompaniesContext from '../../companies/hooks/useCompaniesContext';
import useInternalProjectContactsContext from '../../projects/hooks/useInternalProjectContactsContext';
import { Size } from '@prio365/prio365-react-library/lib/Select/components/Select';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  dropdown: {
    '& .prio-select-item-option-content': {
      height: 'fit-content',
    },
  },
}));
const useContactPicker = (props: {
  projectId: ProjectId;
  officeId: OfficeId;
}) => {
  const { projectId, officeId } = props;

  const { contacts } = useContactsContext(true);

  const { getInternalProjectContactsContacts } =
    useInternalProjectContactsContext();

  return useMemo(() => {
    if (projectId) {
      return getInternalProjectContactsContacts(projectId);
    }

    if (officeId) {
      return contacts.filter(
        ({ officeId: _officeId, contactType }) =>
          officeId === _officeId && contactType === 'InternalContact'
      );
    }

    return contacts;
  }, [contacts, getInternalProjectContactsContacts, officeId, projectId]);
};

interface ContactPickerPrioCLProps {
  value?: string | string[];
  label?: string;
  onChange?: (value: string | string[], companyId?: string) => void;
  // onSelect?: (value: string, option: any) => void;
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  excludedContactIds?: ContactId[];
  includedContacts?: Contact[];
  onlyInternalProject?: boolean;
  contactType?: ContactType;
  projectId?: ProjectId;
  officeId?: OfficeId;
  multiple?: boolean;
  fetch?: boolean;
  loading?: boolean;
  allowClear?: boolean;
  filter?: (contact: Contact) => boolean;
  size?: Size;
}

export const ContactPickerPrioCL: React.FC<ContactPickerPrioCLProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const {
      className,
      style,
      value,
      label,
      onChange,
      onClick,
      disabled,
      excludedContactIds,
      onlyInternalProject: onlyInternal,
      contactType,
      projectId,
      officeId,
      multiple,
      loading,
      filter,
      includedContacts,
      allowClear,
      size = 'small',
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme<PrioTheme>();
    const classes = useStyles();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const _projectId = useMemo(() => {
      return onlyInternal ? projectId : undefined;
    }, [onlyInternal, projectId]);

    const _officeId = useMemo(() => {
      return contactType === 'InternalContact' ? officeId : undefined;
    }, [contactType, officeId]);

    const allContacts = useContactPicker({
      projectId: _projectId,
      officeId: _officeId,
    });

    const { getCompanyById } = useCompaniesContext();

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isOnline, setIsOnline] = useState<boolean>(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 1000);

    const [onlineData, setOnlineData] = useState<Contact[]>([]);
    const [onlineLoading, setOnlineLoading] = useState<boolean>(false);

    const isFetching = useSelector<RootReducerState, boolean>((state) =>
      onlyInternal
        ? getInternalProjectContactsIsFetching(state)
        : getContactsIsFetching(state)
    );

    const contacts: Contact[] = useMemo(() => {
      const list = !isOnline
        ? allContacts.filter((contact) => contact.isArchived === false)
        : onlineData;
      if (contactType) {
        return list.filter((c) => c.contactType === contactType);
      } else {
        return list;
      }
    }, [contactType, allContacts, onlineData, isOnline]);

    const contactsToShow = React.useMemo(
      () =>
        contacts
          .filter(
            (c) =>
              !isTemporaryId(c.contactId) &&
              (!excludedContactIds?.includes(c.contactId) ?? true) &&
              (!filter || filter(c)) &&
              !includedContacts?.find((iC) => iC.contactId === c.contactId)
          )
          .concat(includedContacts ?? [])
          .sort((a, b) => (a?.lastName ?? '').localeCompare(b?.lastName ?? ''))
          .reduce((unique, o) => {
            if (!unique.some((obj) => obj.contactId === o.contactId)) {
              unique.push(o);
            }
            return unique;
          }, []),
      [contacts, excludedContactIds, includedContacts, filter]
    );
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleOnChange: (value: string) => void = onChange
      ? (value: string) => {
          if (contactType === 'ExternalContact') {
            onChange(
              value ?? '',
              contactsToShow.find((contact) => contact.contactId === value)
                .companyId
            );
          } else {
            onChange(value ?? '');
          }
        }
      : null;
    //#endregion

    //#region ------------------------------ Effects

    useEffect(() => {
      if (
        isOnline &&
        debouncedSearchTerm !== '' &&
        debouncedSearchTerm.length > 2
      ) {
        const searchOnline = async () => {
          setOnlineLoading(true);
          const { result, data } = await apiSearchContacts(
            debouncedSearchTerm,
            contactType
          );
          if (result.status >= 200 && result.status < 300) {
            setOnlineData(data);
          } else {
            notification.open({
              message: t('common:error'),
              description: t('contacts:errorMessages.fetchError'),
            });
          }
          setOnlineLoading(false);
        };
        searchOnline();
      }
    }, [debouncedSearchTerm, isOnline, t, contactType]);

    useEffect(() => {
      if (projectId && onlyInternal) {
        dispatch(fetchInternalProjectContacts(projectId));
      }
    }, [projectId, onlyInternal, dispatch]);
    //#endregion

    return (
      <Select
        className={className}
        dropdownClassName={classes.dropdown}
        style={{
          ...(style ?? {}),
          ...(disabled || isFetching
            ? { backgroundColor: 'rgb(0,0,0,0.05)' }
            : {}),
        }}
        mode={multiple ? 'multiple' : null}
        size={size}
        showSearch
        value={value ?? (multiple ? [] : value)}
        placeholder={label}
        onChange={handleOnChange}
        onClick={onClick}
        filterOption={(input, option) => {
          return (
            option.title
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          );
        }}
        disabled={disabled || isFetching}
        loading={loading || isFetching}
        allowClear={allowClear}
        onSearch={(value: string) => {
          if (value === '') {
            setIsOnline(false);
            setOnlineData([]);
          }
          setSearchTerm(value);
        }}
        dropdownRender={(menu) => (
          <Flex.Column>
            {onlineLoading && (
              <div
                style={{
                  background: '#FFFFFFBF',
                  position: 'absolute',
                  top: 0,
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1,
                }}
              >
                <PrioSpinner />
              </div>
            )}
            {menu}
            {!isOnline && searchTerm !== '' && (
              <Button
                type="link"
                onClick={() => {
                  setIsOnline(true);
                }}
                iconProp={['fal', 'plus']}
                style={{
                  width: '100%',
                  borderTop: theme.old.borders.content,
                  paddingTop: 4,
                  height: 32,
                }}
              >
                {t('contacts:actions.showMore')}
              </Button>
            )}
          </Flex.Column>
        )}
        options={(contactsToShow ?? []).map((contact: Contact) => ({
          value: contact.contactId,
          title: `${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`,
          label: (
            <Flex.Column>
              <span>{`${contact.firstName} ${contact.lastName}`}</span>
              {!onlyInternal && (
                <span
                  style={
                    {
                      fontSize: theme.font.fontSize.extraSmall,
                      color: theme.colors.application.typography.muted,
                    } as CSSProperties
                  }
                >
                  {getCompanyById(contact.companyId)?.shortName}
                </span>
              )}
            </Flex.Column>
          ),
        }))}
      />
    );
  });

export default ContactPickerPrioCL;
