import { QueryClient, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  apiFetchEditableMonthlyClose,
  apiFetchMonthlyCloseMeById,
} from '../../timeKeeping/api';

/**
 * Custom hook to fetch the nextToClosableMonthlyClose and its isLoading state
 * @param userId The Id of the current user
 * @param queryClient The initialized queryClient
 * @returns Returns the nextToClosableMonthlyClose and isLoading state
 */
export const useMonthlyClosesToClose = (
  userId: string,
  queryClient: QueryClient
) => {
  const { data: editableResult, isLoading: isLoadingEditable } = useQuery({
    queryKey: ['editableMonthlyClose', userId],
    queryFn: () => apiFetchEditableMonthlyClose(userId),
  });

  const { data: nextMCToClose, isLoading: isLoadingNext } = useQuery({
    queryKey: [
      'nextMCToClose',
      userId,
      editableResult?.data?.nextToCloseMonthlyCloseId,
    ],
    queryFn: () =>
      apiFetchMonthlyCloseMeById(
        editableResult?.data?.nextToCloseMonthlyCloseId
      ),
    enabled: !!editableResult?.data?.nextToCloseMonthlyCloseId,
  });

  const nextToClosableMonthlyClose = nextMCToClose?.data;
  const isLoading = isLoadingEditable || isLoadingNext;

  // refetch the nextMCToClose query when the nextToCloseMonthlyCloseId changes
  useEffect(() => {
    if (editableResult?.data?.nextToCloseMonthlyCloseId) {
      queryClient.invalidateQueries({
        queryKey: [
          'nextMCToClose',
          userId,
          editableResult?.data?.nextToCloseMonthlyCloseId,
        ],
      });
    }
  }, [editableResult?.data?.nextToCloseMonthlyCloseId, queryClient, userId]);

  return {
    nextToClosableMonthlyClose,
    isLoading,
  };
};
