import React, { useMemo } from 'react';
import {} from 'antd';
import { Drawer } from '@prio365/prio365-react-library';
import {
  MonthlyClose,
  TimeKeepingDay,
  TimeKeepingDayCalculatedData,
  TimeKeepingDaySearchResultItem,
} from '../../../../models/TimeKeeping';
import { makePrioStyles } from '../../../../theme/utils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { apiFetchSearch } from '../../../dashboard/api';
import { useQuery } from '@tanstack/react-query';
import MonthlyCloseOverview from '../../../timeKeeping/components/MonthlyCloseOverview';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  drawerContent: {},
}));

interface HRTimekeepingPageDrawerProps {
  className?: string;
  timekeepingDay?: TimeKeepingDay;
  isDrawerOpen?: boolean;
  timekeepingDaySearchResultItem: TimeKeepingDaySearchResultItem;
  setTimekeepingDaySearchResultItem?: (
    timekeepingDaySearchResultItem: TimeKeepingDaySearchResultItem
  ) => void;
}

export const HRTimekeepingPageDrawer: React.FC<HRTimekeepingPageDrawerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { timekeepingDaySearchResultItem, setTimekeepingDaySearchResultItem } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { fetchSearch } = useFilterContext<
    TimeKeepingDay,
    TimeKeepingDayCalculatedData
  >();
  const monthValue = useMemo(
    () =>
      timekeepingDaySearchResultItem?.data?.timeKeepingEntries[0]?.startTime.slice(
        0,
        7
      ),
    [timekeepingDaySearchResultItem]
  );
  const month = moment(`${monthValue}-15`).utc(true).toISOString();

  const employeeId = timekeepingDaySearchResultItem?.data.employeeId;

  const { data } = useQuery({
    queryKey: ['monthlyClose', moment(month).format('YYYY-MM'), employeeId],
    queryFn: () =>
      apiFetchSearch(
        'monthlyCloses',
        `Data.Month eq '${month}' %26 Data.EmployeeId eq '${employeeId}'`,
        1000,
        false
      ),
    // staleTime: 1000 * 60 * 15, // 15 min
    enabled: !!month && !!employeeId,
  });

  const monthlyClose = data?.data?.items[0]?.data as MonthlyClose;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onDrawerClose = () => {
    setTimekeepingDaySearchResultItem(undefined);
    fetchSearch();
  };
  //#endregion

  return (
    <Drawer
      title={t(`timeKeeping:monthlyCloseConfirmationDrawer.details`, {
        month: moment(monthlyClose?.month).format('MMMM YYYY'),
      })}
      onClose={onDrawerClose}
      visible={!!timekeepingDaySearchResultItem}
      contentClassName={classes.drawerContent}
    >
      <MonthlyCloseOverview monthlyClose={monthlyClose} />
    </Drawer>
  );
};

export default HRTimekeepingPageDrawer;
