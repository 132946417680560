import { notification } from 'antd';
import {
  actionChannel,
  call,
  spawn,
  race,
  select,
  take,
  delay,
  takeLatest,
  cancelled,
  all,
  fork,
  put,
} from 'redux-saga/effects';
import {
  getCurrentFolderNextLink,
  getDocumentsCachingEnabled,
  isLoggedIn,
} from '../../../apps/main/rootReducer';
import i18n from '../../../i18n';
import { dispatchSaga, SAGA_REBUILD } from '../../../util/sagas';
import { LOGGED_IN, LOGGED_OUT } from '../../auth/actions';
import {
  DRIVE_ITEM_ABORT,
  DriveItemsFetchContext,
  FetchDriveItemsSagaAction,
  START_FETCH_DRIVE_ITEMS_SAGA,
  abortDriveItemFetch,
  fetchDriveItems,
  fetchDriveItemsWithCaching,
} from '../actions';
import { DriveItemId, GroupId } from '../../../models/Types';
import { distinct } from '../../../util';

/**
 * Manager class to manage the fetching of drive items
 * There are 3 contexts in which drive items are fetched
 * 1. DocumentsPage
 * 2. WidgetArea
 * 3. Other, e.g. CopyDocumentsDrawer, FolderSelectionDrawer, etc.
 * This class manages the fetching of drive items in these contexts, including in which drive item we are currently fetching
 * and whether we should abort the current fetch
 */
class FetchDriveItemsManager {
  /**
   * Abort controllers for the contexts
   */
  controllers: Record<
    DriveItemsFetchContext,
    Record<DriveItemId, AbortController>
  > = {
    DocumentsPage: {},
    WidgetArea: {},
    Other: {},
  };
  /**
   * Whether a fetch is currently in progress in the context
   * These should be cleared at the end of the fetch or the component unmount
   */
  isFetching: Record<DriveItemsFetchContext, boolean> = {
    DocumentsPage: false,
    WidgetArea: false,
    Other: false,
  };
  /**
   * The drive item id that is currently fetching in the context
   * These should be cleared at the end of the fetch or the component unmount
   */
  currentDriveItemId: Record<DriveItemsFetchContext, DriveItemId | null> = {
    DocumentsPage: null,
    WidgetArea: null,
    Other: null,
  };
  /**
   * Array of drive item ids that are currently fetching
   * This is used to avoid fetching the same drive item multiple times
   * Should be cleared at the end of the fetch
   * No duplicates in the array
   */
  fetchingDriveItemIds: Array<DriveItemId> = [];
  constructor() {
    this.controllers = {
      DocumentsPage: {},
      WidgetArea: {},
      Other: {},
    };
    this.isFetching = {
      DocumentsPage: false,
      WidgetArea: false,
      Other: false,
    };
    this.currentDriveItemId = {
      DocumentsPage: null,
      WidgetArea: null,
      Other: null,
    };
    this.fetchingDriveItemIds = [];
  }
  registerAbortController(
    context: DriveItemsFetchContext,
    driveItemId: DriveItemId
  ) {
    const abortController = new AbortController();
    this.controllers[context][driveItemId] = abortController;
  }
  abortAndUnregister(
    context: DriveItemsFetchContext,
    driveItemId: DriveItemId
  ) {
    this.controllers[context][driveItemId].abort();
    this.registerAbortController(context, driveItemId);
  }
  getController(context: DriveItemsFetchContext, driveItemId: DriveItemId) {
    return this.controllers[context][driveItemId];
  }
  getIsFetching(context: DriveItemsFetchContext) {
    return this.isFetching[context];
  }
  getCurrentDriveItemId(context: DriveItemsFetchContext) {
    return this.currentDriveItemId[context];
  }
  /**
   * Set fetching state for the context
   * Add the drive item id to the fetching drive item ids if fetching is true
   * Remove the drive item id from the fetching drive item ids if fetching is false
   * @param context The context in which the drive items are fetched
   * @param value Whether the drive items are fetching
   * @param driveItemId The drive item id that is fetching
   */
  setFetching(
    context: DriveItemsFetchContext,
    value: boolean,
    driveItemId: DriveItemId
  ) {
    this.isFetching[context] = value;
    if (value) {
      this.fetchingDriveItemIds = distinct([
        ...this.fetchingDriveItemIds,
        driveItemId,
      ]);
    } else {
      this.fetchingDriveItemIds = this.fetchingDriveItemIds.filter(
        (id) => id !== driveItemId
      );
    }
  }
  /**
   * Check if the drive item is fetching
   * @param driveItemId The drive item id to check
   * @returns Whether the drive item is fetching
   */
  isFetchingDriveItemId(driveItemId: DriveItemId) {
    return this.fetchingDriveItemIds.includes(driveItemId);
  }
  setDriveItemId(context: DriveItemsFetchContext, driveItemId: DriveItemId) {
    this.currentDriveItemId[context] = driveItemId;
  }
  /**
   * Check if we should abort the current fetch
   * If the context is fetching and the drive item id is different for the given context, we should abort the current fetch
   * We should not abort if the currentDriveItem of DocumentsPage or WidgetArea is the same as the driveItemId. Only concerns if the context is a different one than the one with the same driveItemId
   * @param context The context in which the drive items are fetched
   * @param driveItemId The drive item id to check
   * @returns Whether we should abort the current fetch
   */
  shouldAbort(context: DriveItemsFetchContext, driveItemId: DriveItemId) {
    const isFetching = this.getIsFetching(context);
    const currentDriveItemId = this.getCurrentDriveItemId(context);

    let currentDriveItemIdIsSameAsCurrentDriveItemId = false;
    switch (context) {
      case DriveItemsFetchContext.DocumentsPage: {
        currentDriveItemIdIsSameAsCurrentDriveItemId =
          this.getCurrentDriveItemId(DriveItemsFetchContext.WidgetArea) ===
          currentDriveItemId;
        break;
      }
      case DriveItemsFetchContext.WidgetArea: {
        currentDriveItemIdIsSameAsCurrentDriveItemId =
          this.getCurrentDriveItemId(DriveItemsFetchContext.DocumentsPage) ===
          currentDriveItemId;
        break;
      }
    }

    return (
      isFetching &&
      currentDriveItemId !== driveItemId &&
      !currentDriveItemIdIsSameAsCurrentDriveItemId
    );
  }
  /**
   * Abort the current fetch
   * Set fetching to false and remove the drive item id from the fetching drive item ids
   * @param context
   */
  abort(context: DriveItemsFetchContext, driveItemId?: DriveItemId) {
    if (driveItemId) {
      this.abortAndUnregister(context, driveItemId);
    } else {
      Object.keys(this.controllers[context]).forEach((driveItemId) => {
        this.abortAndUnregister(context, driveItemId);
      });
    }
    this.setFetching(context, false, this.currentDriveItemId[context]);
  }
  /**
   * Check if we should fetch drive items
   * If the drive item is already fetching, we shouldn't fetch it again
   * If the drive item is open in documents widget or documents page, we should not fetch it. Only concerns Other context
   * @param context The context in which the drive items are fetched
   * @param driveItemId The drive item id to fetch
   * @returns Whether we should fetch the drive item
   */
  shouldFetch(context: DriveItemsFetchContext, driveItemId: DriveItemId) {
    const documentsWidgetDriveItemId = this.getCurrentDriveItemId(
      DriveItemsFetchContext.WidgetArea
    );
    const documentsPageDriveItemId = this.getCurrentDriveItemId(
      DriveItemsFetchContext.DocumentsPage
    );
    const driveItemToFetchIsOpenInDocumentsWidgetOrDocumentsPage =
      context === DriveItemsFetchContext.Other &&
      (documentsWidgetDriveItemId === driveItemId ||
        documentsPageDriveItemId === driveItemId);

    return (
      !this.isFetchingDriveItemId(driveItemId) &&
      !driveItemToFetchIsOpenInDocumentsWidgetOrDocumentsPage
    );
  }
}

// abort the fetch if the action is dispatched
// if the resetContextInManager is true, reset the context in the manager class
// if the drive item folder id is the same as the one we are fetching, abort the fetch for the specific drive item folder id
// if the drive item folder id is not defined, abort the fetch for the context
function* abortFetch(
  driveItemFolderId: DriveItemId,
  context: DriveItemsFetchContext,
  manager: FetchDriveItemsManager
) {
  while (true) {
    // only abort the call if the drive item folder id is the same as the one we are fetching
    const action: any = yield take((action) => {
      if (action.type === DRIVE_ITEM_ABORT) {
        return (
          action.context === context &&
          (action.driveItemId === undefined ||
            action.driveItemId === driveItemFolderId)
        );
      }
      return false;
    });

    manager.abort(context, action.driveItemId ?? undefined);

    if (action.resetContextInManager) {
      manager.setDriveItemId(context, null);
      manager.setFetching(context, false, driveItemFolderId);
    }
    yield delay(300);
    return true;
  }
}

// fetch drive items till no next link
// if there is no next link, the fetch will stop and the task will finish
// if an abort action is dispatched, the fetch will be aborted and the task will finish
// the first time the fetch is called, the next link is null
// if there is a next link, the fetch will be called again with the next link
// cleanup fetching state at the end
function* fetchDriveItemsTillNoNextLink(
  groupId: GroupId,
  driveItemFolderId: DriveItemId,
  isRoot: boolean,
  context: DriveItemsFetchContext,
  manager: FetchDriveItemsManager
) {
  let nextLink = null;
  const abortController = manager.getController(
    context,
    isRoot ? `root-group-${groupId}` : driveItemFolderId
  );
  do {
    yield call(
      dispatchSaga,
      fetchDriveItems(groupId, driveItemFolderId, nextLink, isRoot),
      undefined,
      abortController
    );
    nextLink = yield select((state) =>
      getCurrentFolderNextLink(
        state,
        isRoot ? `root-group-${groupId}` : driveItemFolderId
      )
    );
  } while (nextLink && !abortController.signal.aborted);
  manager.setFetching(
    context,
    false,
    isRoot ? `root-group-${groupId}` : driveItemFolderId
  );
}

function* handleDriveItemsFetch(
  action: FetchDriveItemsSagaAction,
  context: DriveItemsFetchContext,
  manager: FetchDriveItemsManager
) {
  const {
    driveItemFolderId: _driveItemFolderId,
    groupId,
    isRoot,
    projectId,
  } = action;

  const driveItemFolderId = isRoot
    ? `root-group-${groupId}`
    : _driveItemFolderId;

  // keep this to avoid multiple fetches at initial browser load
  switch (context) {
    case DriveItemsFetchContext.Other: {
      yield delay(250);
      break;
    }
    case DriveItemsFetchContext.WidgetArea: {
      yield delay(225);
      break;
    }
    default: {
      yield delay(200);
    }
  }

  try {
    const isDocumentsCachingEnabled = yield select((state) =>
      getDocumentsCachingEnabled(state)
    );

    // set fetching to true for the context in manager class and set the drive item folder id
    manager.setFetching(context, true, driveItemFolderId);
    manager.setDriveItemId(context, driveItemFolderId);
    manager.registerAbortController(context, driveItemFolderId);
    if (isDocumentsCachingEnabled) {
      // spawn a new task to fetch drive items with caching
      // doesn't wait for the task to finish
      yield spawn(
        dispatchSaga,
        fetchDriveItemsWithCaching(
          projectId,
          groupId,
          _driveItemFolderId,
          isRoot
        )
      );
      // cleanup fetching state
      manager.setFetching(context, false, driveItemFolderId);
    } else {
      // spawn a new task to fetch drive items
      // doesn't wait for the task to finish
      yield spawn(function* () {
        // race between fetching drive items and aborting the fetch
        // the faster one wins and the other is cancelled
        // e.g. if the abort action is dispatched, manager will abort the fetch and the fetch task will be cancelled
        // if the fetch task finishes before the abort action is dispatched, the abort action will be cancelled
        yield race({
          // fetch drive items till no next link
          // if there is no next link, the fetch will stop and the task will finish
          fetch: call(
            fetchDriveItemsTillNoNextLink,
            groupId,
            _driveItemFolderId,
            isRoot,
            context,
            manager
          ),
          // listen to abort action
          // if the action is dispatched, abort the fetch
          // with a delay to avoid racing conditions with dispatchSaga abort (also 250ms)
          abort: call(abortFetch, driveItemFolderId, context, manager),
        });
      });
    }
    yield delay(10);
  } catch (error) {
    console.error('Error in handleDriveItemsFetch', error, action);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('documents:errorMessages.fetchDriveItemsError'),
    });

    // if sth. goes wrong, cleanup fetching state
    manager.setFetching(context, false, driveItemFolderId);
    manager.setDriveItemId(context, null);
    manager.abort(context, driveItemFolderId);
  }
}

function* handleContextFetch(
  context: DriveItemsFetchContext,
  manager: FetchDriveItemsManager
) {
  let lastAction: FetchDriveItemsSagaAction | null = null;
  try {
    const channel = yield actionChannel(
      (action: FetchDriveItemsSagaAction) =>
        action.type === START_FETCH_DRIVE_ITEMS_SAGA &&
        action.context &&
        action.context === context
    );

    while (true) {
      const payload: FetchDriveItemsSagaAction = (yield take(
        channel
      )) as FetchDriveItemsSagaAction;
      lastAction = payload;

      const driveItemId = payload.isRoot
        ? `root-group-${payload.groupId}`
        : payload.driveItemFolderId;

      // check if we should abort the fetch
      // the previous fetch should be aborted if the drive item folder id is different and the fetch is still in progress
      if (manager.shouldAbort(context, driveItemId)) {
        yield put(
          abortDriveItemFetch(context, manager.getCurrentDriveItemId(context))
        );
      }
      // call a new task to fetch drive items
      // check if we should fetch the drive item
      // if the drive item is already fetching, we shouldn't fetch it again
      // if the drive item is open in documents widget or documents page, we should not fetch it. Only concerns Other context
      if (manager.shouldFetch(context, driveItemId)) {
        yield call(handleDriveItemsFetch, payload, context, manager);
      }
      lastAction = null;
    }
  } finally {
    // if the task was cancelled, call a new task to fetch drive items with the last action
    // for error handling and to provide a way to continue fetching
    if (yield cancelled() && lastAction !== null) {
      yield call(handleDriveItemsFetch, lastAction, context, manager);
      lastAction = null;
    }
  }
}

// main task to manage the fetching of drive items
// manager class is defined to manage the fetching of drive items in different contexts
function* managePipelines() {
  const manager = new FetchDriveItemsManager();
  try {
    yield all([
      fork(handleContextFetch, DriveItemsFetchContext.DocumentsPage, manager),
      fork(handleContextFetch, DriveItemsFetchContext.WidgetArea, manager),
      fork(handleContextFetch, DriveItemsFetchContext.Other, manager),
    ]);
  } catch (error) {
    console.error('Error in managePipelines', error);
    if (yield cancelled()) {
      yield call(managePipelines);
    }
  }
}

function* mainTask() {
  try {
    const loggedIn = yield select(isLoggedIn);
    if (loggedIn) {
      yield race([call(managePipelines), take(LOGGED_OUT)]);
    }
  } catch (error) {
    console.error('Error in watchDriveItemsFetch - mainTask', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('documents:errorMessages.fetchDriveItemsError'),
    });
    yield mainTask();
  }
}

export default function* watchDriveItemsFetch() {
  yield takeLatest([LOGGED_IN, SAGA_REBUILD], mainTask);
}
