import { Company } from '../../../models/Company';
import { Contact } from '../../../models/Contact';
import { Project } from '../../../models/Project';
import { Office } from '../../../models/Office';
import {
  FilterConfig,
  GenericSearchResultItem,
  PickerStrings,
  SearchOperation,
} from '../types';
import moment from 'moment';
import {
  HistoryItem,
  generateDatePickerLabel,
  generateMonthRangeLabel,
  generateYearRangeLabel,
  generateBooleanLabel,
  getSingleLabelFromUTSN,
  DatePickerValue,
  DateRangeMoment,
} from '@prio365/prio365-react-library';
import { TFunction } from 'i18next';
import { FilterPickerType, FilterPickerTypeStrings } from '../FilterPicker';
import { Paths } from '../../../util/GenericHelper';
import { UTSNSuggestion } from '@prio365/prio365-react-library/lib/Date/utility/utsn';
import { convertUtsnToDynamicDate } from '@prio365/prio365-react-library/lib/Date/utils';

interface SearchCondition {
  field: string;
  operator: SearchOperation;
  value: string;
}

const isUuid = (value: string): boolean => {
  return !!value.match(
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
  );
};

const isBoolean = (value: string): boolean => {
  return value === 'true' || value === 'false';
};

const isNumber = (value: string): boolean => {
  return !isNaN(Number(value));
};

const isDateTimeString = (value: string): boolean => {
  return !isNaN(Date.parse(value));
};

const toCamelCase: (str: string) => string = (str) => {
  return str[0].toLowerCase() + str.slice(1);
};

const parseCondition: (
  conditionString: string,
  transformedMap?: Record<string, string[]>
) => SearchCondition[] = (conditionString, transformedMap = {}) => {
  const parts = conditionString.trim().split(/\s+/);
  const field = parts[0];
  const operator = parts[1] as SearchOperation;
  const value = parts.slice(2).join(' ');

  const transformedFields = transformedMap[field];
  if (field.includes('Transformed') && transformedFields) {
    return transformedFields.map((transformedField) => {
      return {
        field: transformedField,
        operator,
        value: value.substring(1, value.length - 1),
      };
    });
  }

  return [{ field, operator, value: value.substring(1, value.length - 1) }];
};

const getField: (item: GenericSearchResultItem, field: string) => any = (
  item,
  field
) => {
  if (field.startsWith('Data.')) {
    const fieldName = toCamelCase(field.substring(5));
    return item.data[fieldName];
  } else if (field.startsWith('Calculated.')) {
    const fieldName = toCamelCase(field.substring(11));
    return item.calculated[fieldName];
  } else {
    throw new Error(`Invalid field: ${field}`);
  }
};

const _equals: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  const valueIsArray = value.includes("','");
  if (isBoolean(value)) {
    return fieldValue === (value === 'true');
  } else if (isNumber(value)) {
    return fieldValue === Number(value);
  } else if (isDateTimeString(value)) {
    return (
      moment(fieldValue).seconds(0).milliseconds(0).toISOString(true) ===
      moment(value).seconds(0).milliseconds(0).toISOString(true)
    );
  } else if (isUuid(value)) {
    return fieldValue.toLowerCase() === value.toLowerCase();
  } else if (valueIsArray) {
    const array = value.split(',').map((v) => {
      if (v.startsWith("'") || v.endsWith("'")) {
        v = v.replace("'", '');
        v = v.replace("'", '');
      }
      return v.toLowerCase();
    });

    if (Array.isArray(fieldValue)) {
      return fieldValue.every((v) => array.includes(v.toLowerCase()));
    }
    return array.includes(fieldValue.toLowerCase());
  } else {
    return (
      fieldValue === value || fieldValue.toLowerCase() === value.toLowerCase()
    );
  }
};

const _greaterThan: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  if (isNumber(value)) {
    return fieldValue > Number(value);
  } else if (isDateTimeString(value)) {
    return moment(fieldValue).isAfter(moment(value));
  } else {
    throw new Error(`Invalid value: ${value}`);
  }
};

const _lessThan: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  if (isNumber(value)) {
    return fieldValue < Number(value);
  } else if (isDateTimeString(value)) {
    return moment(fieldValue).isBefore(moment(value));
  } else {
    throw new Error(`Invalid value: ${value}`);
  }
};

const _greaterThanEquals: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  if (isNumber(value)) {
    return fieldValue >= Number(value);
  } else if (isDateTimeString(value)) {
    return moment(fieldValue).isSameOrAfter(moment(value));
  } else {
    throw new Error(`Invalid value: ${value}`);
  }
};

const _lessThanEquals: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  if (isNumber(value)) {
    return fieldValue <= Number(value);
  } else if (isDateTimeString(value)) {
    return moment(fieldValue).isSameOrBefore(moment(value));
  } else {
    throw new Error(`Invalid value: ${value}`);
  }
};

const _isIn: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  const fieldValueIsArray = Array.isArray(fieldValue);
  const array = value.split(',').map((v) => {
    if (v.startsWith("'") || v.endsWith("'")) {
      v = v.replace("^'", '');
      v = v.replace("'$", '');
    }
    if (isUuid(v)) {
      return v.toLowerCase();
    }
    return v;
  });
  if (fieldValueIsArray) {
    return array.every((v) =>
      fieldValue
        .map((value) => {
          if (isUuid(value)) {
            return value.toLowerCase();
          }
          return value;
        })
        .includes(v)
    );
  }
  return array.includes(fieldValue);
};

const applyCondition: (
  item: GenericSearchResultItem,
  condition: SearchCondition
) => boolean = (item, condition) => {
  const { field, operator, value } = condition;
  const fieldValue = getField(item, field);

  switch (operator) {
    case 'eq':
      return _equals(value, fieldValue);
    case 'ne':
      return !_equals(value, fieldValue);
    case 'gt':
      return _greaterThan(value, fieldValue);
    case 'lt':
      return _lessThan(value, fieldValue);
    case 'ge':
      return _greaterThanEquals(value, fieldValue);
    case 'le':
      return _lessThanEquals(value, fieldValue);
    case 'in':
      return _isIn(value, fieldValue);
    case 'notin':
      return !_isIn(value, fieldValue);
    case 'like':
      return fieldValue.includes(value);
    case 'notlike':
      return !fieldValue.includes(value);
    case 'isnull':
      return fieldValue === null || fieldValue === undefined;
    case 'isnotnull':
      return fieldValue !== null && fieldValue !== undefined;
    default:
      throw new Error(`Invalid operator: ${operator}`);
  }
};

export const filterSearchDataBasedOnSearchString: <
  ResultData = unknown,
  Calculated = unknown,
>(
  data: GenericSearchResultItem<ResultData, Calculated>[],
  searchString: string,
  transformedMap?: Record<string, string[]>
) => GenericSearchResultItem<ResultData, Calculated>[] = (
  data,
  searchString,
  transformedMap
) => {
  const conditions = searchString
    .split('&')
    .map((conditionString) => parseCondition(conditionString, transformedMap))
    .flat();

  return data.filter((item) =>
    conditions.every((condition) => applyCondition(item, condition))
  );
};

export const generateSingleSearchStringCondition: <
  ResultData = unknown,
  CalculatedData = unknown,
>(
  field:
    | Paths<GenericSearchResultItem<ResultData, CalculatedData>>
    | 'transformed.timespan',
  operator: SearchOperation,
  value: string | Array<string>
) => string = (field, operator, value) => {
  const fieldToUppercase = field
    .split('.')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join('.');
  if (Array.isArray(value)) {
    return `${fieldToUppercase} ${operator} '${value.join("','")}'`;
  }
  return `${fieldToUppercase} ${operator} '${value}'`;
};

export const getRedux = (
  type: string,
  uuids: string[],
  getContactById: (id: string) => Contact,
  getCompanyById: (id: string) => Company,
  getProjectById: (id: string) => Project,
  getOfficeById: (id: string) => Office
): string[] => {
  switch (type) {
    case 'contact':
    case 'internalContact':
    case 'externalContact':
    case 'employee':
      const contacts =
        uuids?.map((uuid) => getContactById(uuid))?.filter((c) => c) || [];
      const contactNames = contacts.map(
        (contact) => contact?.firstName + ' ' + contact?.lastName
      );
      return contactNames;
    case 'company':
      const companies =
        uuids?.map((uuid) => getCompanyById(uuid))?.filter((c) => c) || [];
      const companyNames = companies.map(
        (company) =>
          company?.shortName || company?.fullName || company?.fullName2
      );
      return companyNames;
    case 'project':
      const projects =
        uuids?.map((uuid) => getProjectById(uuid))?.filter((c) => c) || [];
      const projectNames = projects.map(
        (project) => project?.shortName || project?.name
      );
      return projectNames;
    case 'office':
      const offices =
        uuids?.map((uuid) => getOfficeById(uuid))?.filter((c) => c) || [];
      const officeNames = offices.map((office) => office?.name);
      return officeNames;
    default:
      return null;
  }
};

export const generateFilterHistoryDrawerItems = (
  filterHistory: HistoryItem[],
  filters: FilterConfig[],
  getContactById: (id: string) => Contact,
  getCompanyById: (id: string) => Company,
  getProjectById: (id: string) => Project,
  getOfficeById: (id: string) => Office,
  t: TFunction
) => {
  const locale = moment.locale();
  const historyItems =
    filterHistory?.map((item) => {
      item.pills = [];

      if (!item?.searchString?.length) {
        return item;
      }

      const searchStringSplit = item?.searchString?.split('&') || [];
      const allFiltersInItem = searchStringSplit?.map((string: string) => {
        const [parameterName] = string?.trim()?.split(' ');
        return parameterName;
      });
      const allUniqueFiltersInItem = Array.from(new Set(allFiltersInItem));

      allUniqueFiltersInItem?.forEach((pickerName) => {
        const filter = filters?.find(
          (filter) => filter.parameterName === pickerName
        );
        if (!filter) {
          return;
        }
        const pickerStrings = calculatePickerStringsBasedOnSearchString(
          pickerName,
          item?.searchString
        );

        // handle picker value conversion

        if (
          FilterPickerTypeStrings?.includes(
            filter?.pickerType as FilterPickerType
          )
        ) {
          const uuids = [];
          pickerStrings?.forEach((pickerString) => {
            const { value } = pickerString;
            const valueArray =
              value.split(',')?.map((v) => v.replace(/'/g, '')) || [];
            uuids.push(...valueArray);
          });

          const values = getRedux(
            filter?.pickerType,
            uuids,
            getContactById,
            getCompanyById,
            getProjectById,
            getOfficeById
          );

          if (values?.length > 0) {
            const label = values?.join(', ');
            item.pills.push({
              title: filter.parameterNameTranslated,
              text: label,
            });
            return;
          }
        }

        if (filter?.selectOptions?.[0]) {
          const values = [];
          pickerStrings?.forEach((pickerString) => {
            const { value } = pickerString;
            const valueArray =
              value.split(',')?.map((v) => v.replace(/'/g, '')) || [];
            valueArray.forEach((v) => {
              const option = filter?.selectOptions?.find(
                (option) => option.selectValue === v
              );
              if (option) {
                values.push(option.selectValueTranslated);
              }
            });
          });

          if (values?.length > 0) {
            const label = values?.join(', ');
            item.pills.push({
              title: filter.parameterNameTranslated,
              text: label,
            });
          }

          return;
        }

        switch (filter?.parameterType) {
          case 'date':
          case 'dateTime':
            const pickerStringsWithoutRel = pickerStrings?.filter(
              (S) => S.method !== 'rel'
            );

            const relPickerString = pickerStrings?.find(
              (S) => S.method === 'rel'
            );

            // es-lint-disable-next-line
            var [startISOString, endISOString] =
              pickerStringsWithoutRel
                ?.map((S) => S.value)
                .sort((a, b) => a.localeCompare(b)) || [];

            if (startISOString && endISOString) {
              const label = generateDatePickerLabel(
                relPickerString?.value
                  ? (getSingleLabelFromUTSN(
                      relPickerString?.value,
                      'range',
                      locale as any
                    ) as any)
                  : null,
                [moment(startISOString), moment(endISOString)],
                t
              );
              item.pills.push({
                title: filter.parameterNameTranslated,
                text: label,
              });
            }

            return;
          case 'month':
            // es-lint-disable-next-line
            [startISOString, endISOString] =
              pickerStrings
                ?.map((S) => S.value)
                .sort((a, b) => a.localeCompare(b)) || [];

            if (startISOString && endISOString) {
              const label = generateMonthRangeLabel([
                moment(startISOString),
                moment(endISOString),
              ]);

              item.pills.push({
                title: filter.parameterNameTranslated,
                text: label,
              });
            }
            return;
          case 'year':
            // es-lint-disable-next-line
            [startISOString, endISOString] =
              pickerStrings
                ?.map((S) => S.value)
                .sort((a, b) => a.localeCompare(b)) || [];

            if (startISOString && endISOString) {
              const label = generateYearRangeLabel([
                moment(startISOString),
                moment(endISOString),
              ]);

              item.pills.push({
                title: filter.parameterNameTranslated,
                text: label,
              });
            }
            return;
          case 'boolean':
            const value =
              pickerStrings?.[0]?.value === 'true'
                ? true
                : pickerStrings?.[0]?.value === 'false'
                ? false
                : undefined;

            const label = generateBooleanLabel(value);
            item.pills.push({
              title: filter.parameterNameTranslated,
              text: label,
            });

            return;
          case 'string':
            item.pills.push({
              title: filter.parameterNameTranslated,
              text: pickerStrings?.[0]?.value,
            });
            return;

          default:
            if (pickerStrings?.length > 0) {
              const labelLogik = `${pickerStrings?.length} Filter`;
              item.pills.push({
                title: filter.parameterNameTranslated,
                text: labelLogik,
              });
            }
            return;
        }
      });

      return item;
    }) || [];

  return historyItems;
};

export function calculatePickerStringsBasedOnSearchString(
  parameterName: string,
  searchString: string
): PickerStrings[] {
  const searchStringSplit = (searchString?.split('&') || []).map((s) =>
    s.trim()
  );
  const strings = searchStringSplit.filter(
    (string) => string.split(' ')?.[0]?.includes(parameterName)
  );
  return strings.map((string) => {
    //eslint-disable-next-line
    var method = '';
    var value = '';

    var atPartIndex = 0;

    for (let i = 0; i < string.length; i++) {
      const char = string[i];
      if (char === ' ' && atPartIndex < 2) {
        atPartIndex++;
        continue;
      }
      if (atPartIndex === 1) {
        method += char;
      } else if (atPartIndex === 2) {
        value += char;
      }
    }
    return { method, value: value?.replace(/'/g, '') };
  });
}

export const formatDateRangePickerValue = (
  valueArray: DateRangeMoment,
  utsn: UTSNSuggestion | null
): DatePickerValue<DateRangeMoment> => {
  const clonedValueArray = valueArray.map((date) => date?.clone()); // clone to prevent mutation
  const correctValueArray: DateRangeMoment = [
    clonedValueArray[0]?.startOf('day') || null,
    clonedValueArray[1]?.endOf('day') || null,
  ];

  if (utsn) {
    return {
      dateValue: convertUtsnToDynamicDate(utsn, 'range') as DateRangeMoment,
      utsn,
    };
  }
  return {
    dateValue: correctValueArray,
    utsn,
  };
};
