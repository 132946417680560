import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Typography,
} from 'antd';
import { Button, Modal } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import {
  MonthlyClose,
  MonthlyCloseCalculatedData,
  MonthlyCloseUpdate,
} from '../../../../models/TimeKeeping';
import { MonthlyCloseId, OfficeId } from '../../../../models/Types';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import Flex from '../../../../components/Flex';
import { colon, rowGutter } from '../../../../util/forms';
import { apiReopenMonthlyClose, apiUpdateMonthlyClose } from '../../api';
import equals from 'deep-equal';
import { compactDateWithWeekDayFormatString } from '../../../../util';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    '& .ant-typography.ant-typography-secondary': {
      fontSize: theme.old.typography.fontSize.label,
      paddingBottom: theme.old.spacing.unit(0.5),
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    '& > div:not(:last-child)': {
      marginBottom: theme.old.spacing.defaultPadding,
    },
    '& $overtimeCorrection': {},
  },
  marginBottomWarningPanel: {
    marginBottom: theme.old.spacing.unit(2),
  },
  horizontalDivider: {
    margin: `0 ${theme.old.spacing.defaultPadding}px`,
    borderLeft: theme.old.borders.content,
  },
  overtimeCorrection: {
    '& .ant-form-item-control-input-content': {
      width: 100,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
    },
    '& .ant-form-item-control-input-content::after': {
      content: '"h"',
      marginLeft: 6,
    },
  },
  undoButton: {
    marginLeft: theme.old.spacing.unit(1),
  },
}));

interface HRMonthlyCloseDetailsDrawerProps {
  monthlyClose?: MonthlyClose;
  setOpen: (open: boolean) => void;
  setSelectedMonthlyClose: (value: MonthlyClose) => void;
  officeId?: OfficeId;
  updateMonthlyClose?: (monthlyClose: MonthlyClose) => void;
  editableMonthlyCloseId: MonthlyCloseId;
}

export const HRMonthlyCloseDetailsDrawer: React.FC<
  HRMonthlyCloseDetailsDrawerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    monthlyClose,
    setOpen,
    officeId,
    setSelectedMonthlyClose,
    editableMonthlyCloseId,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [updateForm] = Form.useForm<MonthlyCloseUpdate>();

  const theme = useTheme<PrioTheme>();
  const queryClient = useQueryClient();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { optimisticWrite, getSearchResultItem } = useFilterContext<
    MonthlyClose,
    MonthlyCloseCalculatedData
  >();

  const monthlyCloseDetails: MonthlyCloseUpdate = useMemo(
    () => ({
      overtimeCorrection: monthlyClose?.overtimeCorrection ?? 0,
      notes: monthlyClose?.notes,
    }),
    [monthlyClose]
  );

  const [isEqual, setIsEqual] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const showWarningPanelNotClosed =
    monthlyClose && !monthlyClose?.employeeConfirmation;

  const [overviewCorrectionDelta, setOverviewCorrectionDelta] =
    useState<number>(0);
  const isThisMonthlyCloseEditable =
    monthlyClose?.monthlyCloseId === editableMonthlyCloseId;
  const [isModalOpen, setIsModalOpen] = useState(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onFinish = async (currentValues: MonthlyCloseUpdate) => {
    setIsSaving(true);
    const searchItem = getSearchResultItem(
      ({ data: { monthlyCloseId } }) =>
        monthlyClose.monthlyCloseId === monthlyCloseId
    );

    optimisticWrite([
      {
        data: {
          ...monthlyClose,
          notes: currentValues.notes,
          overtimeCorrection: currentValues.overtimeCorrection,
        },
        calculated: searchItem?.calculated,
        method: 'update',
        callback: async () => {
          const { result, data } = await apiUpdateMonthlyClose(
            officeId,
            currentValues,
            monthlyClose.monthlyCloseId
          );
          if (result.status >= 400) {
            notification.open({
              message: t('common:error'),
              description: t('common:errorMessages.saveChangeError'),
            });
          }
          queryClient.invalidateQueries({
            queryKey: [
              'monthlyClose',
              moment(monthlyClose.month).format('YYYY-MM'),
              monthlyClose?.employeeId,
            ],
          });

          return {
            result,
            data: data
              ? {
                  data: data,
                  calculated: searchItem?.calculated,
                }
              : null,
          };
        },
      },
    ]);

    setIsSaving(false);
    setIsEqual(true);
  };

  const handleOnCancel = () => {
    setOpen(false);
    setSelectedMonthlyClose(null);
    setIsModalOpen(false);
  };

  const handleUndoMonthlyClose = async () => {
    const searchItem = getSearchResultItem(
      ({ data: { monthlyCloseId } }) =>
        monthlyClose.monthlyCloseId === monthlyCloseId
    );
    setIsModalOpen(false);
    optimisticWrite([
      {
        data: {
          ...monthlyClose,
          employeeConfirmation: false,
          employeeConfirmationDate: null,
        },
        calculated: searchItem?.calculated,
        method: 'update',
        callback: async () => {
          const { result, data } = await apiReopenMonthlyClose(
            monthlyClose.monthlyCloseId,
            officeId
          );
          if (result.status >= 400) {
            notification.open({
              message: t('common:error'),
              description: t(
                'hr:timeAndLeaveManagement.monthlyCloseForm.undoModal.errorMessage'
              ),
            });
          }
          queryClient.invalidateQueries({
            queryKey: [
              'editableAndClosableMonthlyCloseIds',
              monthlyClose?.employeeId,
            ],
          });
          return {
            result,
            data: data
              ? {
                  data: data,
                  calculated: searchItem?.calculated,
                }
              : null,
          };
        },
      },
    ]);
  };

  const showUndoModal = () => {
    setIsModalOpen(true);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (monthlyCloseDetails) {
      updateForm.setFieldsValue(monthlyCloseDetails);
    } else {
      updateForm.resetFields();
    }
    setIsEqual(true);
  }, [monthlyCloseDetails, updateForm]);
  //#endregion

  return (
    <div className={classes.root}>
      <Form<MonthlyCloseUpdate>
        initialValues={monthlyCloseDetails}
        form={updateForm}
        onFinish={onFinish}
        layout="vertical"
        className={classes.form}
        onValuesChange={(changedValues, values: MonthlyCloseUpdate) => {
          if (
            changedValues.overtimeCorrection !== undefined &&
            changedValues.overtimeCorrection !== null
          ) {
            setOverviewCorrectionDelta(
              changedValues.overtimeCorrection -
                (monthlyClose?.overtimeCorrection ?? 0)
            );
          }
          if (equals(values, monthlyCloseDetails)) {
            setIsEqual(true);
          } else {
            setIsEqual(false);
          }
        }}
      >
        <Flex.Column flex={1} overflowY="auto" overflowX="hidden">
          <Flex.Row>
            <Flex.Column flex={1}>
              <Typography.Title level={4}>
                {t('hr:timeAndLeaveManagement.monthlyCloseForm.hoursOverview')}
              </Typography.Title>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.actualWorkHours'
                    )}
                    colon={colon}
                  >
                    <span>{`${
                      monthlyClose?.actualWorkHours.toLocaleString() ?? 0
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.expectedWorkHours'
                    )}
                    colon={colon}
                  >
                    <span>{`${
                      monthlyClose?.expectedWorkHours.toLocaleString() ?? 0
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.overtimeCompensationHours'
                    )}
                    colon={colon}
                  >
                    <span>{`${
                      monthlyClose?.overtimeCompensationHours.toLocaleString() ??
                      0
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.compensationPaymentHours'
                    )}
                    colon={colon}
                  >
                    <span>{`${
                      monthlyClose?.compensationPaymentHours.toLocaleString() ??
                      0
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
            </Flex.Column>
            <div className={classes.horizontalDivider} />
            <Flex.Column flex={1}>
              <Typography.Title level={4}>
                {t(
                  'hr:timeAndLeaveManagement.monthlyCloseForm.accumulatedOvertimeHours'
                )}
              </Typography.Title>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.accumulatedOvertimeHoursWithoutCorrection'
                    )}
                    colon={colon}
                  >
                    <span>{`${(
                      (monthlyClose?.accumulatedOvertimeHours ?? 0) -
                      (monthlyClose?.overtimeCorrection ?? 0)
                    ).toLocaleString()} h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.accumulatedOvertimeHoursChangeWithoutCorrection'
                    )}
                    colon={colon}
                  >
                    <span>{`${(
                      (monthlyClose?.overtimeHoursChange ?? 0) -
                      (monthlyClose?.overtimeCorrection ?? 0)
                    ).toLocaleString()} h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    className={classes.overtimeCorrection}
                    name="overtimeCorrection"
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.overtimeCorrection'
                    )}
                    colon={colon}
                    rules={[
                      () => ({
                        async validator(rule, value) {
                          if (value === null) {
                            return Promise.reject(
                              t(
                                'hr:timeAndLeaveManagement.monthlyCloseForm.warning.invalidValue'
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      step={0.25}
                      parser={(value) =>
                        parseFloat(value.replace(',', '.')) as 0.25
                      }
                      disabled={!isThisMonthlyCloseEditable || isSaving}
                      defaultValue={monthlyClose?.overtimeCorrection ?? 0}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.accumulatedOvertimeHoursWithCorrection'
                    )}
                    colon={colon}
                  >
                    <span>{`${(
                      (monthlyClose?.accumulatedOvertimeHours ?? 0) +
                      overviewCorrectionDelta
                    ).toLocaleString()} h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.accumulatedOvertimeHoursChangeWithCorrection'
                    )}
                    colon={colon}
                  >
                    <span>{`${(
                      (monthlyClose?.overtimeHoursChange ?? 0) +
                      overviewCorrectionDelta
                    ).toLocaleString()} h`}</span>
                  </Form.Item>
                </Col>
              </Row>
            </Flex.Column>
          </Flex.Row>
          {showWarningPanelNotClosed && (
            <Flex.Column
              childrenGap={theme.old.spacing.unit(2)}
              className={classes.marginBottomWarningPanel}
            >
              <Alert
                message={t(
                  'hr:timeAndLeaveManagement.monthlyCloseDrawer.detailsTab.noEmployeeConfirmation'
                )}
                type="warning"
              />
            </Flex.Column>
          )}
          {!isThisMonthlyCloseEditable && !showWarningPanelNotClosed && (
            <Flex.Column
              childrenGap={theme.old.spacing.unit(2)}
              className={classes.marginBottomWarningPanel}
            >
              <Alert
                message={t(
                  'hr:timeAndLeaveManagement.monthlyCloseDrawer.detailsTab.notLastClosedMonth'
                )}
                type="warning"
              />
            </Flex.Column>
          )}
          <Divider />
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name="notes"
                label={t('hr:timeAndLeaveManagement.monthlyCloseForm.notes')}
                colon={colon}
              >
                <Input.TextArea
                  style={{ height: '75px' }}
                  disabled={isSaving}
                  defaultValue={monthlyClose?.notes}
                />
              </Form.Item>
            </Col>
          </Row>
          {monthlyClose?.employeeConfirmation && (
            <Row>
              <Col span={24}>
                <Typography.Text type="secondary">
                  {`${t(
                    'hr:timeAndLeaveManagement.monthlyCloseForm.closedAt'
                  )} ${compactDateWithWeekDayFormatString(
                    monthlyClose?.employeeConfirmationDate
                  )}`}
                </Typography.Text>
                {isThisMonthlyCloseEditable && (
                  <Button
                    onClick={showUndoModal}
                    className={classes.undoButton}
                    suffixIconProp={['fal', 'arrow-turn-down-right']}
                    type="link"
                    style={{ transform: 'rotate(180deg)' }}
                    tooltip={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.undoModal.title'
                    )}
                  ></Button>
                )}
              </Col>
            </Row>
          )}
        </Flex.Column>
        <Flex.Row justifyContent="flex-end">
          <Button
            disabled={isSaving}
            onClick={handleOnCancel}
            type="default"
            style={{ marginRight: '8px' }}
          >
            {t('hr:timeAndLeaveManagement.monthlyCloseForm.cancel')}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            disabled={isEqual || isSaving}
          >
            {t('hr:timeAndLeaveManagement.monthlyCloseForm.save')}
          </Button>
        </Flex.Row>
      </Form>
      <Modal
        title={t('hr:timeAndLeaveManagement.monthlyCloseForm.undoModal.title')}
        visible={isModalOpen}
        onClose={handleOnCancel}
        onOk={handleUndoMonthlyClose}
        okText={t(
          'hr:timeAndLeaveManagement.monthlyCloseForm.undoModal.okText'
        )}
        cancelText={t(
          'hr:timeAndLeaveManagement.monthlyCloseForm.undoModal.cancelText'
        )}
      >
        {t('hr:timeAndLeaveManagement.monthlyCloseForm.undoModal.content')}
      </Modal>
    </div>
  );
};

export default HRMonthlyCloseDetailsDrawer;
