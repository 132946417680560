import React, { useCallback, useState } from 'react';
import { Popover } from 'antd';
import { ProjectDistributionList } from '../../../models/ProjectDistributionList';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import FilterContextVirtualTable from '../../../components/Filter/FilterContextVirtualTable';
import { Button, Dropdown, Menu } from '@prio365/prio365-react-library';
import MenuItem from '@prio365/prio365-react-library/lib/Menu/components/MenuItem';
import useContactsContext from '../../contacts/hooks/useContactsProvider';
import ProjectDistributionListDuplicateModal from './ProjectDistributionListDuplicateModal';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    overflow: 'hidden',
    flex: 1,
  },
  row: {
    cursor: 'pointer',
    '& button': {
      display: 'none',
    },
    '&:hover button': {
      display: 'block',
    },
  },
  table: {
    borderRadius: '4px',
  },
  tableCell: {
    display: 'contents',
    '& > div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      marginLeft: '10px',
    },
  },
  mailPopover: {
    color: theme.colors.application.typography.default,
    maxHeight: '150px',
    overflowY: 'auto',
  },
  contextMenu: {
    background: theme.colors.application.background.default,
    padding: 0,
    fontSize: theme.font.fontSize.regular,
    '& li': {
      marginLeft: 0,
    },
  },
  dropdown: {
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
  },
}));

interface ProjectDistributionListTableProps {
  className?: string;
  distributionLists: ProjectDistributionList[];
  onRowClick?: (
    item: ProjectDistributionList,
    index: number,
    selectedItems: ProjectDistributionList[]
  ) => VirtualListItemOnRowProps<null, null>;
  onDelete?: (records: ProjectDistributionList[]) => void;
  selectedTableItems?: ProjectDistributionList[];
  setSelectedTableItems?: (items: ProjectDistributionList[]) => void;
  showBulkActions?: boolean;
  handleDeleteDistributionList?: (
    distributionList: ProjectDistributionList[]
  ) => void;
}

export const ProjectDistributionListTable: React.FC<
  ProjectDistributionListTableProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    distributionLists,
    onRowClick,
    onDelete,
    selectedTableItems,
    setSelectedTableItems,
    showBulkActions = true,
    handleDeleteDistributionList,
  } = props;

  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getContactById } = useContactsContext();

  const existingNames = distributionLists.map((item) => item.name);

  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);

  const [selectedDistributionList, setSelectedDistributionList] =
    useState<ProjectDistributionList | null>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  const menu = useCallback(
    (item: ProjectDistributionList) => {
      return (
        <Menu className={classes.contextMenu} selectedItemId="">
          <MenuItem
            id="1"
            onClick={(e) => {
              setIsDuplicateModalVisible(true);
              setSelectedDistributionList(item);
            }}
            iconProp={['fal', 'copy']}
            label={t('projects:projectDistributionList.table.contextMenu.copy')}
          ></MenuItem>
          <MenuItem
            id="2"
            onClick={(e) => {
              handleDeleteDistributionList([item]);
            }}
            iconProp={['fal', 'trash']}
            label={t(
              'projects:projectDistributionList.table.contextMenu.delete'
            )}
          ></MenuItem>
        </Menu>
      );
    },
    [t, classes.contextMenu, handleDeleteDistributionList]
  );

  const columns: Column<ProjectDistributionList>[] = [
    {
      title: t('projects:projectDistributionList.table.name'),
      id: 'name',
      accessor: 'name',
      sortingFn: (a, b) => a.name.localeCompare(b.name),
      Cell: (CellProps) => <div> {CellProps.value}</div>,
      width: 20,
      className: classes.tableCell,
    },
    {
      title: t(
        'projects:projectDistributionList.table.projectDistributionContacts'
      ),
      id: 'projectDistributionContacts',
      accessor: 'projectDistributionContacts',
      Cell: (CellProps) => {
        const joinedValue = CellProps.value
          .map((item) =>
            getContactById(item?.contactId)
              ? `${getContactById(item?.contactId).firstName} ${
                  getContactById(item?.contactId).lastName
                }`
              : item.eMail
          )
          .join('; ');

        return (
          <Popover
            content={
              <div className={classes.mailPopover}>
                <div
                  style={{
                    fontWeight: 600,
                    marginBottom: theme.old.spacing.unit(0.5),
                  }}
                >
                  {t('projects:projectDistributionList.form.contacts.contacts')}
                </div>
                <ul>
                  {CellProps.value.map((item) => (
                    <li key={item.eMail}>
                      {getContactById(item?.contactId)
                        ? `${getContactById(item?.contactId).firstName} ${
                            getContactById(item?.contactId).lastName
                          }  (${item.eMail})`
                        : item.eMail}
                    </li>
                  ))}
                </ul>
              </div>
            }
          >
            <div>{joinedValue}</div>
          </Popover>
        );
      },
      width: 72,
      className: classes.tableCell,
    },
    {
      title: '',
      id: 'contextMenu',
      accessor: 'projectDistributionContacts',
      alignSelf: true,
      Cell: ({ originalData }) => {
        return (
          <Dropdown
            overlay={menu(originalData)}
            trigger={['click']}
            placement="bottomRight"
            overlayClassName={classes.dropdown}
            onOverlayClick={(e) => e.stopPropagation()}
          >
            <Button
              iconProp={['fal', 'ellipsis-v']}
              type="default"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Dropdown>
        );
      },
      width: 8,
    },
  ];

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <FilterContextVirtualTable
        id="projectDistributionListTable"
        className={classes.table}
        classNameTableRow={classes.row}
        columns={columns}
        data={distributionLists.sort((a, b) => a.name.localeCompare(b.name))}
        rowsAreSelectable
        selectedItems={selectedTableItems}
        onSelectionChange={setSelectedTableItems}
        resizable="relative"
        checkBoxContainerWidth={50}
        onRow={onRowClick}
        actionBarButtons={
          showBulkActions
            ? [
                {
                  children: t('common:actions.delete'),
                  iconProp: ['fal', 'trash'],
                  onClick: () => onDelete(selectedTableItems),
                },
              ]
            : null
        }
      />
      <ProjectDistributionListDuplicateModal
        isModalVisible={isDuplicateModalVisible}
        setIsModalVisible={setIsDuplicateModalVisible}
        selectedDistributionList={selectedDistributionList}
        existingNames={existingNames}
      />
    </Flex.Column>
  );
};

export default ProjectDistributionListTable;
