import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Empty, Menu, Typography, notification } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import {
  MonthlyClose,
  MonthlyCloseAbsence,
  TimeKeepingDay,
} from '../../../models/TimeKeeping';
import moment from 'moment';
import classNames from 'classnames';
import { DateTimeString, TimeKeepingDayState } from '../../../models/Types';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getOfficeHolidaysOfMonth,
} from '../../../apps/main/rootReducer';
import { OfficeHoliday } from '../../../models/AbsenceProposal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/Flex';
import VirtualTable, {
  Column,
} from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Button, Modal } from '@prio365/prio365-react-library';
import useContactsContext from '../../contacts/hooks/useContactsProvider';
import { apiHandleTimekeepingBulk } from '../../hr/api';
import { useLocation } from 'react-router-dom';
const useStyles = makePrioStyles((theme) => ({
  root: {},
  cell: {
    padding: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  centered: {
    justifyContent: 'center',
  },
  noItemsScreen: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  noItemsIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '200px',
    height: '200px',
    position: 'absolute',
    left: 'calc(50% - 100px)',
    top: 'calc(50% - 100px)',
  },
  noItemsText: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  row: {
    position: 'relative',
  },
  rowWithLeftMarker: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 5,
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  weekend: {
    backgroundColor: `${theme.old.palette.chromaticPalette.grey}20`,
  },
  stateLightBlue: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.lightBlue,
    },
  },
  stateBlue: {
    '&::before': {
      backgroundColor: theme.old.palette.primaryColor,
    },
    '& > div > div:last-child': {
      color: theme.old.palette.primaryColor,
    },
  },
  stateYellow: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.yellow,
    },
    '& > div > div:last-child': {
      color: theme.old.palette.chromaticPalette.yellow,
    },
  },
  stateRed: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
    },
    '& > div > div:last-child': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  holidayOrAbsence: {
    backgroundColor: `${theme.old.palette.chromaticPalette.green}20`,
    '&:not($stateBlue):not($stateYellow):not($stateRed) > div > div:nth-child(2)':
      {
        color: theme.old.palette.chromaticPalette.green,
      },
  },
  popover: {
    width: '24px',
    height: '15px',
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.application.typography.default,
    '&:hover': {
      cursor: 'pointer',
    },
  },

  hideCursor: {
    cursor: 'default',
  },
  contextMenuButton: {
    '&:not(:hover)': {
      backgroundColor: 'transparent',
    },
  },
  noteModal: {
    '& .prio-modal-footer': {
      display: 'none',
    },
  },
}));

type MonthlyCloseTimekeepingDaysTableEntryState = TimeKeepingDayState | 'none';

export interface MonthlyCloseTimekeepingDaysTableEntry {
  timeKeepingDay: TimeKeepingDay;
  date: DateTimeString;
  actualWorkingHours: number;
  sumOfBreaks: number;
  fromTo: string;
  isWeekend: boolean;
  absenceProposal?: MonthlyCloseAbsence;
  officeHoliday?: string;
  type: string;
  state: MonthlyCloseTimekeepingDaysTableEntryState;
}

export interface MonthlyCloseTimekeepingDaysTableRefProps {
  handleUpdateTableData: (
    data: MonthlyCloseTimekeepingDaysTableEntry[]
  ) => void;
}
interface MonthlyCloseTimekeepingDaysTableProps {
  monthlyClose?: MonthlyClose;
  onRowClick?: (date: DateTimeString) => void;
}

export const MonthlyCloseTimekeepingDaysTable = forwardRef<
  MonthlyCloseTimekeepingDaysTableRefProps,
  MonthlyCloseTimekeepingDaysTableProps
>((props, ref) => {
  //#region ------------------------------ Defaults
  const { monthlyClose, onRowClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const location = useLocation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isHRModule =
    location.pathname.includes('hr') &&
    location.pathname.includes('timekeeping');

  const [showModal, setShowModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [selectedTimeKeepingDay, setSelectedTimeKeepingDay] =
    useState<TimeKeepingDay>();
  const [updatedTableData, setUpdatedTableData] =
    useState<MonthlyCloseTimekeepingDaysTableEntry[]>();

  const officeHolidays = useSelector<RootReducerState, OfficeHoliday[]>(
    (state) => getOfficeHolidaysOfMonth(state, monthlyClose?.month)
  );

  const data: MonthlyCloseTimekeepingDaysTableEntry[] = useMemo(() => {
    if (!monthlyClose) {
      return [];
    }
    const days = moment(monthlyClose.month).daysInMonth();
    return new Array(days).fill(0).map((_, index) => {
      const day = index + 1;
      const date = moment(monthlyClose.month).date(day);
      const timeKeepingDay = monthlyClose.timeKeepingDays.find((t) =>
        moment(t.timeKeepingEntries[0].startTime).isSame(date, 'day')
      );
      const absenceProposal = monthlyClose.monthlyCloseAbsences.find(
        ({ from, to, type }) =>
          date.isSameOrBefore(moment(to)) &&
          date.isSameOrAfter(moment(from)) &&
          type !== 'publicHoliday'
      );
      let actualWorkingHours: number = null;
      let sumOfBreaks: number = null;
      let fromTo: string = null;
      let state: MonthlyCloseTimekeepingDaysTableEntryState = 'none';
      if (timeKeepingDay) {
        const sortedEntries = timeKeepingDay.timeKeepingEntries.sort((a, b) =>
          moment.utc(a.startTime).diff(moment.utc(b.startTime))
        );
        sortedEntries.forEach(({ startTime, endTime }) => {
          actualWorkingHours =
            (actualWorkingHours ?? 0) +
            moment.utc(endTime).diff(moment.utc(startTime), 'milliseconds');
        });
        const firstToLastEntryDuration = moment
          .utc(sortedEntries[sortedEntries.length - 1].endTime)
          .diff(moment.utc(sortedEntries[0].startTime), 'milliseconds');
        sumOfBreaks = firstToLastEntryDuration - actualWorkingHours;
        fromTo = `${moment
          .utc(sortedEntries[0].startTime)
          .format('H:mm')} - ${moment
          .utc(sortedEntries[sortedEntries.length - 1].endTime)
          .format('H:mm')}`;
        state = timeKeepingDay.state;
      }
      const entry: MonthlyCloseTimekeepingDaysTableEntry = {
        timeKeepingDay,
        date: date.toISOString(true).split('T')[0],
        sumOfBreaks,
        fromTo,
        actualWorkingHours,
        isWeekend: date.day() === 0 || date.day() === 6,
        absenceProposal: absenceProposal,
        officeHoliday: officeHolidays.find(({ date: _date }) =>
          moment(_date).isSame(date, 'day')
        )?.name,
        type: timeKeepingDay?.type ?? null,
        state,
      };
      return entry;
    });
  }, [monthlyClose, officeHolidays]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnRow: (
    item: MonthlyCloseTimekeepingDaysTableEntry
  ) => VirtualListItemOnRowProps<MonthlyCloseTimekeepingDaysTableEntry> =
    useCallback(
      (item) => {
        const { isWeekend, absenceProposal, officeHoliday, state } = item;
        return {
          onClick: (e) => {
            if (onRowClick) {
              onRowClick(item.date);
            }
          },
          className: classNames(classes.row, {
            [classes.hideCursor]: !onRowClick,
            [classes.weekend]: isWeekend,
            [classes.rowWithLeftMarker]: state !== 'none',
            [classes.holidayOrAbsence]: !!absenceProposal || !!officeHoliday,
            [classes.stateBlue]: state === 'ok' || state === 'approved',
            [classes.stateLightBlue]: state === 'locked',
            [classes.stateYellow]:
              state === 'approvalRequested' || state === 'recording',
            [classes.stateRed]: state === 'notOk' || state === 'notApproved',
          }),
        };
      },
      [classes, onRowClick]
    );

  const handleUpdateTableData = (
    tableData: MonthlyCloseTimekeepingDaysTableEntry[]
  ) => {
    setUpdatedTableData(tableData);
  };
  //#endregion

  //#region ------------------------------ Components
  const noItemsScreen = useCallback(
    () => (
      <div className={classes.noItemsScreen}>
        <div className={classes.noItemsIconContainer}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className={classes.noItemsText}>
                {t('documents:table.noItems')}
              </span>
            }
          />
        </div>
      </div>
    ),
    [classes, t]
  );

  const lastTableCell = useCallback(
    (timeKeepingDay: TimeKeepingDay, state: string) => {
      const requestState = state === 'approvalRequested';

      if (state === 'none') {
        return '-';
      }

      return (
        <Flex.Row alignItems="center" childrenGap={theme.spacing.regular}>
          <div
            style={{ flex: 1 }}
            title={
              state === 'none'
                ? null
                : t(
                    `timeKeeping:monthlyCloseTimeKeepingDaysTable.state.${state}`
                  )
            }
          >
            {t(`timeKeeping:monthlyCloseTimeKeepingDaysTable.state.${state}`)}
          </div>
          {(timeKeepingDay?.notes || (requestState && isHRModule)) && (
            <Flex.Item>
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    {timeKeepingDay.notes && (
                      <Menu.Item
                        key="1"
                        onClick={() => {
                          setShowModal(true);
                          setSelectedTimeKeepingDay(timeKeepingDay);
                        }}
                      >
                        {t(
                          'timeKeeping:monthlyCloseTimeKeepingDaysTable.openNotes'
                        )}
                      </Menu.Item>
                    )}
                    {isHRModule && requestState && (
                      <Menu.Item
                        key="2"
                        onClick={() => {
                          setShowApprovalModal(true);
                          setSelectedTimeKeepingDay(timeKeepingDay);
                        }}
                      >
                        {t(
                          'timeKeeping:monthlyCloseTimeKeepingDaysTable.approveReject'
                        )}
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button
                  type="default"
                  iconProp={['fal', 'ellipsis-v']}
                  onClick={(e) => e.stopPropagation()}
                  className={classes.contextMenuButton}
                />
              </Dropdown>
            </Flex.Item>
          )}
        </Flex.Row>
      );
    },
    [classes, isHRModule, t, theme]
  );

  //#endregion

  //#region ------------------------------ Columns
  const columns: Column<MonthlyCloseTimekeepingDaysTableEntry>[] = [
    {
      Cell: ({ originalData: { date } }) => moment(date).format('dd., DD. MMM'),
      cellTitle: ({ date }) => moment(date).format('dd., DD. MMMM'),
      title: t('timeKeeping:monthlyCloseTimeKeepingDaysTable.header.date'),
      width: 15,
      id: 'date',
      accessor: 'date',
      className: classes.cell,
      alignSelf: true,
    },
    {
      Cell: ({ originalData: { fromTo, officeHoliday, absenceProposal } }) => {
        if (fromTo) {
          if (officeHoliday || absenceProposal) {
            return (
              <span>
                <span>{fromTo}</span>
                <Dropdown
                  overlay={
                    <Menu
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        domEvent.preventDefault();
                      }}
                    >
                      {officeHoliday && (
                        <Menu.Item key="1">{officeHoliday}</Menu.Item>
                      )}
                      {absenceProposal && (
                        <Menu.Item key="2">
                          {t(
                            `absences:form.absenceTypes.${absenceProposal.type}`
                          )}
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                  placement="bottomRight"
                >
                  <FontAwesomeIcon
                    icon={['fal', 'chevron-down']}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    style={{ marginLeft: 5 }}
                  />
                </Dropdown>
              </span>
            );
          }
          return fromTo;
        }
        if (officeHoliday) {
          if (absenceProposal) {
            return (
              <span>
                <span>{officeHoliday}</span>
                <Dropdown
                  overlay={
                    <Menu
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        domEvent.preventDefault();
                      }}
                    >
                      <Menu.Item key="1">
                        {t(
                          `absences:form.absenceTypes.${absenceProposal.type}`
                        )}
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomRight"
                >
                  <FontAwesomeIcon
                    icon={['fal', 'chevron-down']}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    style={{ marginLeft: 5 }}
                  />
                </Dropdown>
              </span>
            );
          }
          return officeHoliday;
        }
        if (absenceProposal) {
          return t(`absences:form.absenceTypes.${absenceProposal.type}`);
        }
        return '-';
      },
      cellTitle: ({ fromTo, officeHoliday, absenceProposal }) => {
        if (fromTo) {
          return fromTo;
        }
        if (officeHoliday) {
          return officeHoliday;
        }
        if (absenceProposal) {
          return t(`absences:form.absenceTypes.${absenceProposal.type}`);
        }
        return null;
      },
      width: 20,
      id: 'fromTo',
      className: classes.cell,
      accessor: 'fromTo',
      title: t('timeKeeping:monthlyCloseTimeKeepingDaysTable.header.fromTo'),
      alignSelf: true,
    },
    {
      Cell: ({ originalData: { actualWorkingHours } }) =>
        actualWorkingHours
          ? `${(actualWorkingHours / (1000 * 60 * 60)).toLocaleString()} h`
          : '-',
      cellTitle: ({ actualWorkingHours }) =>
        actualWorkingHours
          ? `${(actualWorkingHours / (1000 * 60 * 60)).toLocaleString()} h`
          : null,
      title: t(
        'timeKeeping:monthlyCloseTimeKeepingDaysTable.header.actualWorkingHours'
      ),
      width: 12,
      id: 'actualWorkingHours',
      className: classNames(classes.cell, classes.centered),
      accessor: 'actualWorkingHours',
      alignSelf: true,
    },
    {
      Cell: ({ originalData: { sumOfBreaks } }) =>
        sumOfBreaks
          ? `${(sumOfBreaks / (1000 * 60 * 60)).toLocaleString()} h`
          : '-',
      cellTitle: ({ sumOfBreaks }) =>
        sumOfBreaks
          ? `${(sumOfBreaks / (1000 * 60 * 60)).toLocaleString()} h`
          : null,
      title: t(
        'timeKeeping:monthlyCloseTimeKeepingDaysTable.header.sumOfBreaks'
      ),
      width: 12,
      id: 'sumOfBreaks',
      className: classNames(classes.cell, classes.centered),
      accessor: 'sumOfBreaks',
      alignSelf: true,
    },
    {
      Cell: ({ originalData: { type } }) => {
        if (type) {
          return t(
            `hr:timeAndLeaveManagement.monthlyCloseDrawer.timekeepingDaysTable.type.${type}`
          );
        }
        return '-';
      },
      cellTitle: ({ type }) => {
        if (type) {
          return t(
            `hr:timeAndLeaveManagement.monthlyCloseDrawer.timekeepingDaysTable.type.${type}`
          );
        }
        return null;
      },
      title: t('timeKeeping:monthlyCloseTimeKeepingDaysTable.header.type'),
      width: 20,
      id: 'type',
      accessor: 'type',
      className: classes.cell,
      alignSelf: true,
    },
    {
      Cell: ({ originalData: { state, timeKeepingDay } }) => {
        return lastTableCell(timeKeepingDay, state);
      },
      title: t('timeKeeping:monthlyCloseTimeKeepingDaysTable.header.state'),
      width: 20,
      id: 'state',
      accessor: 'state',
      className: classes.cell,
      alignSelf: true,
    },
  ];
  //#endregion

  //#region ------------------------------ Effects
  useImperativeHandle(ref, () => ({
    handleUpdateTableData: (data: MonthlyCloseTimekeepingDaysTableEntry[]) =>
      handleUpdateTableData(data),
  }));
  //#endregion

  return (
    <div style={{ height: '100%' }}>
      <VirtualTable<MonthlyCloseTimekeepingDaysTableEntry>
        id={'prio-monthlyClose-timekeepingDaysTable'}
        className={classNames(classes.root)}
        resizable="relative"
        columns={columns}
        data={updatedTableData ?? data}
        noItemsScreen={noItemsScreen()}
        onRow={handleOnRow}
        checkBoxContainerWidth={50}
      />
      <Modal
        visible={showModal}
        onClose={() => setShowModal(false)}
        className={classes.noteModal}
      >
        <div>
          <Typography.Text type={'secondary'} style={{ fontSize: 14 }}>
            {t(`timeKeeping:monthlyCloseTimeKeepingDaysTable.notesHeader`)}
          </Typography.Text>
          <br />
          <Typography.Text>{selectedTimeKeepingDay?.notes}</Typography.Text>
        </div>
      </Modal>
      <ApprovalModal
        showApprovalModal={showApprovalModal}
        setShowApprovalModal={setShowApprovalModal}
        timeKeepingDay={selectedTimeKeepingDay}
        tableData={data}
        handleUpdateTableData={handleUpdateTableData}
      />
    </div>
  );
});

export default MonthlyCloseTimekeepingDaysTable;

interface ApprovalModalProps {
  showApprovalModal: boolean;
  setShowApprovalModal: (value: boolean) => void;
  timeKeepingDay: TimeKeepingDay;
  tableData: MonthlyCloseTimekeepingDaysTableEntry[];
  handleUpdateTableData: (
    tableData: MonthlyCloseTimekeepingDaysTableEntry[]
  ) => void;
}

export const ApprovalModal: React.FC<ApprovalModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    showApprovalModal,
    setShowApprovalModal,
    timeKeepingDay,
    tableData,
    handleUpdateTableData,
  } = props;
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getContactById } = useContactsContext();
  const [updatedTableData, setUpdatedTableData] =
    useState<MonthlyCloseTimekeepingDaysTableEntry[]>(tableData);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onModalClose = async () => {
    setShowApprovalModal(false);
  };

  const handleTimekeepingRequest = async (
    type: 'approve' | 'reject',
    timekeepingDay: TimeKeepingDay
  ) => {
    const { data, result } = await apiHandleTimekeepingBulk(
      getContactById(timekeepingDay.employeeId).officeId,
      [timekeepingDay.timeKeepingDayId],
      type
    );

    if (result.status >= 400) {
      notification.open({
        message: t('common:error'),
        description: `${
          type === 'reject'
            ? t(
                'hr:timeAndLeaveManagement.timekeepingDaysTable.approvalModal.errorRejectMessage'
              )
            : t(
                'hr:timeAndLeaveManagement.timekeepingDaysTable.approvalModal.errorApproveMessage'
              )
        } ${data?.['TranslatedMessage']}`,
      });
    } else {
      const _tableData =
        updatedTableData?.length < 1 ? tableData : updatedTableData;

      const _updatedTableData = _tableData.map((t) =>
        t?.timeKeepingDay?.timeKeepingDayId === timekeepingDay?.timeKeepingDayId
          ? {
              ...t,
              state: type === 'reject' ? 'notApproved' : 'approved',
            }
          : t
      );
      setUpdatedTableData(
        _updatedTableData as MonthlyCloseTimekeepingDaysTableEntry[]
      );
      handleUpdateTableData(
        _updatedTableData as MonthlyCloseTimekeepingDaysTableEntry[]
      );
    }

    setShowApprovalModal(false);
  };
  //#endregion

  return (
    <Modal
      visible={showApprovalModal}
      onClose={onModalClose}
      title={t(
        `hr:timeAndLeaveManagement.timekeepingDaysTable.approvalModal.titleSingular`
      )}
      footer={
        <Flex.Row
          justifyContent="flex-end"
          childrenGap={theme.spacing.small}
          width="100%"
        >
          <Button
            onClick={() => setShowApprovalModal(false)}
            type="default"
            style={{ marginRight: 'auto' }}
          >
            {t(
              'hr:timeAndLeaveManagement.timekeepingDaysTable.approvalModal.cancelText'
            )}
          </Button>
          <Button
            onClick={() => handleTimekeepingRequest('reject', timeKeepingDay)}
            type="default"
          >
            {t(
              'hr:timeAndLeaveManagement.timekeepingDaysTable.approvalModal.declineText'
            )}
          </Button>
          <Button
            type="primary"
            onClick={() => handleTimekeepingRequest('approve', timeKeepingDay)}
          >
            {t(
              'hr:timeAndLeaveManagement.timekeepingDaysTable.approvalModal.okText'
            )}
          </Button>
        </Flex.Row>
      }
    >
      <Flex.Column childrenGap={theme.old.spacing.unit(1)}>
        <Flex.Item>
          <span>
            {`${t(
              'hr:timeAndLeaveManagement.timekeepingDaysTable.approvalModal.content1',
              {
                name: `${getContactById(timeKeepingDay?.employeeId)
                  ?.firstName} ${getContactById(timeKeepingDay?.employeeId)
                  ?.lastName}`,
              }
            )} ${moment
              .utc(timeKeepingDay?.timeKeepingEntries[0]?.startTime)
              .format('dddd')}, `}
          </span>
          <strong>
            {moment
              .utc(timeKeepingDay?.timeKeepingEntries[0]?.startTime)
              .format('DD. MMMM YYYY')}
          </strong>
          <span>
            {`, ${t(
              'hr:timeAndLeaveManagement.timekeepingDaysTable.approvalModal.content2'
            )} `}
          </span>
          <strong>
            {moment
              .utc(
                timeKeepingDay?.timeKeepingEntries?.sort((a, b) =>
                  moment.utc(a.startTime).isBefore(moment.utc(b.startTime))
                    ? -1
                    : 1
                )[0]?.startTime
              )
              .format('H:mm')}
          </strong>
          <span>
            {` ${t(
              'hr:timeAndLeaveManagement.timekeepingDaysTable.approvalModal.content3'
            )} `}
          </span>
          <strong>
            {moment
              .utc(
                timeKeepingDay?.timeKeepingEntries?.sort((a, b) =>
                  moment.utc(a.startTime).isBefore(moment.utc(b.startTime))
                    ? -1
                    : 1
                )[timeKeepingDay?.timeKeepingEntries?.length - 1]?.endTime
              )
              .format('H:mm')}
          </strong>
          <span>{` ${t(
            'hr:timeAndLeaveManagement.timekeepingDaysTable.approvalModal.content4'
          )}`}</span>
        </Flex.Item>
        {timeKeepingDay?.notes && (
          <Flex.Item>
            <Flex.Column>
              <div>
                {`${t('dashboard:hrTimeKeepingOverview.modal.content5')}`}
              </div>
              <div>{timeKeepingDay?.notes}</div>
            </Flex.Column>
          </Flex.Item>
        )}

        <Flex.Item>
          <span>
            {t(
              `hr:timeAndLeaveManagement.timekeepingDaysTable.approvalModal.content6Singular`
            )}
          </span>
        </Flex.Item>
      </Flex.Column>
    </Modal>
  );
};
