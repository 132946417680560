import React, { useCallback, useState } from 'react';
import { DriveItem } from '../../../../models/Drive';
import { notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Project } from '../../../../models/Project';
import {
  PRIO_DEFAULT_SPACING,
  PRIO_DEFAULT_TYPOGRAPHY,
} from '../../../../theme/types/default';
import { apiFetchDocument, apiFetchDocumentWithCaching } from '../../api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'inline-flex',
  },
  iconSize: {
    fontSize: '24px',
    marginLeft: PRIO_DEFAULT_SPACING.defaultPadding,
    marginRight: PRIO_DEFAULT_SPACING.defaultPadding,
  },
  secondary: {
    color: PRIO_DEFAULT_TYPOGRAPHY.colors.muted,
    fontSize: PRIO_DEFAULT_TYPOGRAPHY.fontSize.small,
  },
  spacing: {
    marginTop: PRIO_DEFAULT_SPACING.baseSpacing,
  },
}));

interface UnzipSuccessNotificationProps {
  driveItem: DriveItem;
  project: Project;
  notificationKey: string;
  isDocumentsCachingEnabled?: boolean;
}

export const UnzipSuccessNotification: React.FC<
  UnzipSuccessNotificationProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { driveItem, project, notificationKey, isDocumentsCachingEnabled } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isFetchingUrl, setIsFetchingUrl] = useState<boolean>(false);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const openInBrowser = useCallback(async () => {
    if (!isFetchingUrl) {
      const a = document.createElement('a');
      let item: DriveItem = driveItem;
      if (!driveItem.parentReference?.path) {
        setIsFetchingUrl(true);
        const { data } =
          isDocumentsCachingEnabled === true
            ? await apiFetchDocumentWithCaching(project.projectId, driveItem.id)
            : await apiFetchDocument(project.groupId, driveItem.id);
        setIsFetchingUrl(false);
        if (data) {
          item = data;
        } else {
          notification.open({
            message: t('common:error'),
            description: t('documents:errorMessages.fetchDriveItemError'),
          });
          document.body.removeChild(a);
          return;
        }
      }
      window.open(
        `${window.location.protocol}//${window.location.hostname}/module/prio/projects/${project.projectId}/documents/folder/${item.id}`,
        '_self'
      );
    }

    notification.close(notificationKey);
  }, [
    driveItem,
    isFetchingUrl,
    notificationKey,
    project.groupId,
    t,
    project.projectId,
    isDocumentsCachingEnabled,
  ]);
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      <div className={classes.spacing}>
        <Flex.Row>
          <Flex.Column flex={0} childrenGap={PRIO_DEFAULT_SPACING.baseSpacing}>
            <div className={classes.iconSize}>
              <FontAwesomeIcon icon={['fas', 'folder']} color={'#ECC233'} />
            </div>
          </Flex.Column>
          <Flex.Row>
            <Flex.Column
              flex={1}
              childrenGap={PRIO_DEFAULT_SPACING.baseSpacing}
            >
              <p>
                {project.number} - {project.shortName ?? project.name}
                <br />
                <span className={classes.secondary}>{driveItem.name}</span>
              </p>
            </Flex.Column>
          </Flex.Row>
        </Flex.Row>
        <Flex.Row>
          <Flex.Row>
            <Button
              type="default"
              size="small"
              onClick={() => {
                openInBrowser();
              }}
              iconProp={['fal', 'globe']}
            >
              {t('documents:newDocumentForm.open.web')}
            </Button>
          </Flex.Row>
        </Flex.Row>
      </div>
    </>
  );
};

export default UnzipSuccessNotification;
