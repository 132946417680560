import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import Flex from '../../../components/Flex';
import { MonthlyClose } from '../../../models/TimeKeeping';
import MonthlyCloseTimekeepingDaysTable, {
  MonthlyCloseTimekeepingDaysTableEntry,
  MonthlyCloseTimekeepingDaysTableRefProps,
} from './MonthlyCloseTimekeepingDaysTable';

import StatBox from '../../../components/StatBox';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { timeRecordSumSelector } from '../../timeAndLeaveManagement/components/Calendar/ToolbarContent';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  label: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.extraSmall,
  },
}));

export interface MonthlyCloseOverviewRefProps {
  handleUpdateTableData: (
    data: MonthlyCloseTimekeepingDaysTableEntry[]
  ) => void;
}
interface MonthlyCloseOverviewProps {
  monthlyClose: MonthlyClose;
  enableEditableTimeKeepingDays?: boolean;
  handleOnRowClick?: (timeKeepingDayId: string) => void;
  timeRecordSum?: number;
  isFetchingTimeRecordSum?: boolean;
}

export const MonthlyCloseOverview = forwardRef<
  MonthlyCloseOverviewRefProps,
  MonthlyCloseOverviewProps
>((props, ref) => {
  //#region ------------------------------ Defaults
  const {
    monthlyClose,
    enableEditableTimeKeepingDays,
    timeRecordSum: _timeRecordSum,
    isFetchingTimeRecordSum,
    handleOnRowClick,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const monthlyCloseTimekeepingDaysTableRef =
    useRef<MonthlyCloseTimekeepingDaysTableRefProps>(null);
  const timeRecordSumRedux = useSelector(
    timeRecordSumSelector(monthlyClose?.month?.substring(0, 7) ?? '')
  );

  const timeRecordSum = useMemo(() => {
    if (_timeRecordSum) {
      return _timeRecordSum;
    }
    return timeRecordSumRedux;
  }, [_timeRecordSum, timeRecordSumRedux]);

  const isCurrentMonth: boolean = useMemo(() => {
    return monthlyClose?.month.substring(0, 7) === moment().format('YYYY-MM');
  }, [monthlyClose]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useImperativeHandle(ref, () => ({
    handleUpdateTableData: (data: MonthlyCloseTimekeepingDaysTableEntry[]) =>
      monthlyCloseTimekeepingDaysTableRef.current?.handleUpdateTableData(data),
  }));
  //#endregion

  return (
    <Flex.Column
      className={classes.root}
      childrenGap={theme.spacing.large}
      height="100%"
    >
      <Flex.Row
        justifyContent="space-around"
        childrenGap={theme.spacing.extraSmall}
      >
        <Flex.Item flex={1}>
          <StatBox
            title={t(
              'timeAndLeaveManagement:calendar.toolbar.recordedTimeKeeping'
            )}
            value={`${(monthlyClose?.actualWorkHours ?? 0).toLocaleString()} h`}
            valueInParanthesis={`${t('common:of')} ${
              isCurrentMonth
                ? (monthlyClose?.expectedWorkHoursToDate ?? 0).toLocaleString()
                : (monthlyClose?.expectedWorkHours ?? 0).toLocaleString()
            } h`}
            infoIconContent={
              <div>
                {t(
                  `timeAndLeaveManagement:calendar.toolbar.popover1_${
                    isCurrentMonth ? 'current' : 'past'
                  }`,
                  {
                    expectedToDate: monthlyClose?.expectedWorkHoursToDate,
                    expected: monthlyClose?.expectedWorkHours,
                  }
                )}
              </div>
            }
          />
        </Flex.Item>
        <Flex.Item flex={1}>
          <StatBox
            title={t(
              'timeAndLeaveManagement:calendar.toolbar.proportionTimerecording'
            )}
            value={`${
              timeRecordSum === 0 ? 0 : (timeRecordSum / 60)?.toLocaleString()
            } h`}
            valueInParanthesis={`${t('common:of')} ${(
              monthlyClose?.actualWorkHours ?? 0
            ).toLocaleString()} h`}
            infoIconContent={
              <div style={{ marginBottom: '0px' }}>
                {t('timeAndLeaveManagement:calendar.toolbar.popover2')}
              </div>
            }
            loading={isFetchingTimeRecordSum}
          />
        </Flex.Item>
      </Flex.Row>
      <Flex.Row childrenGap={theme.spacing.regular}>
        <Flex.Column flex={1}>
          <div className={classes.label}>
            {t(
              'timeKeeping:monthlyCloseConfirmationDrawer.labels.overtimeHoursChange'
            )}
          </div>
          <div>{`${monthlyClose?.overtimeHoursChange?.toLocaleString()} h`}</div>
        </Flex.Column>
        <Flex.Column flex={1}>
          <div className={classes.label}>
            {t(
              'timeKeeping:monthlyCloseConfirmationDrawer.labels.timeKeepingDays'
            )}
          </div>
          <div>{monthlyClose?.timeKeepingDays?.length?.toLocaleString()}</div>
        </Flex.Column>
        <Flex.Column flex={1}>
          <div className={classes.label}>
            {t(
              'timeKeeping:monthlyCloseConfirmationDrawer.labels.shouldBeWorkingDays'
            )}
          </div>
          <div>{monthlyClose?.totalWorkingDays?.toLocaleString()}</div>
        </Flex.Column>
        <Flex.Column flex={1}>
          <div className={classes.label}>
            {t('timeKeeping:monthlyCloseConfirmationDrawer.labels.absenceDays')}
          </div>
          <div>{monthlyClose?.absenceDays?.toLocaleString()}</div>
        </Flex.Column>
        <Flex.Column flex={1}>
          <div className={classes.label}>
            {t('timeKeeping:monthlyCloseConfirmationDrawer.labels.holidays')}
          </div>
          <div>{monthlyClose?.holidays?.toLocaleString()}</div>
        </Flex.Column>
      </Flex.Row>
      <Flex.Item flex={1}>
        <MonthlyCloseTimekeepingDaysTable
          ref={monthlyCloseTimekeepingDaysTableRef}
          monthlyClose={monthlyClose}
          onRowClick={
            enableEditableTimeKeepingDays ? handleOnRowClick : undefined
          }
        />
      </Flex.Item>
    </Flex.Column>
  );
});

export default MonthlyCloseOverview;
