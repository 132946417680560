import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Drawer, Modal } from '@prio365/prio365-react-library';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { makePrioStyles } from '../../../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MonthlyClose } from '../../../models/TimeKeeping';
import moment from 'moment';
import Flex from '../../../components/Flex';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getContact,
  getUserMe,
} from '../../../apps/main/rootReducer';
import { apiConfirmMonthlyCloseMe } from '../api';
import TimeKeepingDrawerContent from './TimeKeepingDrawerContent';
import {
  DateTimeString,
  MonthlyCloseId,
  TimeKeepingDayState,
} from '../../../models/Types';
import { fetchSingleMonthlyCloseMe } from '../actions';
import { distinct } from '../../../util';
import { Contact } from '../../../models/Contact';
import { debouncedFetchOfficeHolidaysMe } from '../../absences/actions';
import { fetchUserMe } from '../../users/actions';
import { useQueryClient } from '@tanstack/react-query';
import { useMonthlyClosesToClose } from '../../projects/hooks/useMonthlyClosesToClose';
import MonthlyCloseOverview from './MonthlyCloseOverview';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    '& .prio-drawer-content': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing.regular,
    },
  },
  label: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.extraSmall,
  },
  warningInfo: {
    backgroundColor: `${theme.colors.base.yellow[50]}`,
    padding: theme.spacing.regular,
    margin: `${theme.spacing.regular}px 0px`,
  },
  timeKeepingDrawerContent: {
    height: '100%',
    overflow: 'hidden',
  },
}));

interface MonthlyCloseConfirmDrawerProps {
  className?: string;
  monthlyClose: MonthlyClose;
  visible: boolean;
  onClose?: () => void;
  enableEditableTimeKeepingDays?: boolean;
  isClosedMonth?: boolean;
  isMonthClosable?: boolean;
}

export const MonthlyCloseConfirmDrawer: React.FC<MonthlyCloseConfirmDrawerProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const {
      className,
      visible,
      monthlyClose,
      enableEditableTimeKeepingDays,
      onClose,
      isClosedMonth,
      isMonthClosable,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme<PrioTheme>();

    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const userMe = useSelector(getUserMe);
    const contact = useSelector<RootReducerState, Contact>((state) =>
      getContact(state, userMe?.id)
    );

    const hasOpenTimeKeepingDays = monthlyClose?.timeKeepingDays?.some(
      (timeKeepingDay) =>
        (
          [
            'approvalRequested',
            'notApproved',
            'notOk',
            'recording',
          ] as TimeKeepingDayState[]
        ).includes(timeKeepingDay.state)
    );

    const {
      nextToClosableMonthlyClose,
      isLoading: isFetchingNextToCloseMonthlyClose,
    } = useMonthlyClosesToClose(userMe.id, queryClient);

    const isClosable = useMemo(() => {
      if (monthlyClose && nextToClosableMonthlyClose) {
        const { month: currentMonth } = monthlyClose;
        const { month: nextToCloseMonth } = nextToClosableMonthlyClose;
        return moment(currentMonth).isSame(nextToCloseMonth, 'month');
      }
      return true;
    }, [nextToClosableMonthlyClose, monthlyClose]);

    const [subDrawerVisible, setSubDrawerVisible] = useState<boolean>(false);

    const [updatedMonthlyCloseIds, setUpdatedMonthlyCloseIds] = useState<
      MonthlyCloseId[]
    >([]);

    const [subDrawerInitialDay, setSubDrawerInitialDay] =
      useState<DateTimeString>(null);

    const [isConfirmModalVisible, setIsConfirmModalVisible] =
      useState<boolean>(false);

    const [isModalActionRunning, setIsModalActionRunning] =
      useState<boolean>(false);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleOnConfirmMonthlyClose = () => {
      setIsConfirmModalVisible(true);
    };

    const onModalOk = async () => {
      setIsModalActionRunning(true);
      const { data } = await apiConfirmMonthlyCloseMe({
        monthlyCloseId: monthlyClose.monthlyCloseId,
        userTime: moment().toISOString(true).split('.')[0],
      });
      if (data) {
        dispatch(fetchSingleMonthlyCloseMe(monthlyClose.monthlyCloseId));
        dispatch(fetchUserMe());
        onClose();
        queryClient.invalidateQueries({
          queryKey: ['editableMonthlyClose', userMe.id],
        });
      }
      setIsConfirmModalVisible(false);
      setIsModalActionRunning(false);
    };

    const handleOnCloseSubDrawer = () => {
      updatedMonthlyCloseIds.forEach((id) => {
        dispatch(fetchSingleMonthlyCloseMe(id));
      });
      setUpdatedMonthlyCloseIds([]);
      setSubDrawerVisible(false);
    };

    const addMonthlyCloseId = (id: MonthlyCloseId) => {
      setUpdatedMonthlyCloseIds(distinct([...updatedMonthlyCloseIds, id]));
    };

    const handleOnRowClick = (day: DateTimeString) => {
      setSubDrawerInitialDay(day);
      setSubDrawerVisible(true);
    };
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      if (contact?.officeId && monthlyClose?.month) {
        const start = moment(monthlyClose?.month)
          .subtract(1, 'month')
          .startOf('month')
          .toISOString(true)
          .split('T')[0];
        const end = moment(monthlyClose?.month)
          .add(1, 'month')
          .endOf('month')
          .toISOString(true)
          .split('T')[0];
        dispatch(debouncedFetchOfficeHolidaysMe(contact?.officeId, start, end));
      }
    }, [contact, monthlyClose?.month, dispatch]);
    //#endregion

    return (
      <Drawer
        className={classNames(classes.root, className)}
        visible={visible}
        onClose={onClose}
        closable
        title={t(
          `timeKeeping:monthlyCloseConfirmationDrawer.${
            isMonthClosable ? 'title' : 'details'
          }`,
          {
            month: moment(monthlyClose?.month).format('MMMM YYYY'),
          }
        )}
      >
        <MonthlyCloseOverview
          monthlyClose={monthlyClose}
          enableEditableTimeKeepingDays={enableEditableTimeKeepingDays}
          handleOnRowClick={handleOnRowClick}
        />
        {(hasOpenTimeKeepingDays || !isClosable) && !isClosedMonth && (
          <Flex.Column className={classes.warningInfo}>
            {hasOpenTimeKeepingDays && (
              <Flex.Row alignItems="center" childrenGap={theme.spacing.regular}>
                <Flex.Item>
                  <FontAwesomeIcon icon={['fal', 'exclamation-circle']} />
                </Flex.Item>
                <Flex.Item>
                  {t(
                    'timeKeeping:monthlyCloseConfirmationDrawer.warningInfo.openTimeKeepingDays',
                    {
                      month: moment(monthlyClose?.month).format('MMMM YYYY'),
                    }
                  )}
                </Flex.Item>
              </Flex.Row>
            )}
            {!isClosable && (
              <Flex.Row alignItems="center" childrenGap={theme.spacing.regular}>
                <Flex.Item>
                  <FontAwesomeIcon icon={['fal', 'exclamation-circle']} />
                </Flex.Item>
                <Flex.Item>
                  {moment(monthlyClose?.month)
                    .subtract(1, 'months')
                    .isSame(moment(nextToClosableMonthlyClose?.month), 'month')
                    ? t(
                        'timeKeeping:monthlyCloseConfirmationDrawer.warningInfo.previousMonth',
                        {
                          month: moment(monthlyClose?.month)
                            .subtract(1, 'months')
                            .format('MMMM YYYY'),
                        }
                      )
                    : t(
                        'timeKeeping:monthlyCloseConfirmationDrawer.warningInfo.previousMonthTillNextMonthToClose',
                        {
                          month: moment(monthlyClose?.month)
                            .subtract(1, 'months')
                            .format('MMMM YYYY'),
                          nextMonthToClose: moment(
                            nextToClosableMonthlyClose?.month
                          ).format('MMMM YYYY'),
                        }
                      )}
                </Flex.Item>
              </Flex.Row>
            )}
          </Flex.Column>
        )}
        <Flex.Row justifyContent="flex-end">
          <Button
            onClick={handleOnConfirmMonthlyClose}
            loading={isFetchingNextToCloseMonthlyClose}
            disabled={
              !monthlyClose ||
              monthlyClose?.employeeConfirmation ||
              hasOpenTimeKeepingDays ||
              isFetchingNextToCloseMonthlyClose ||
              !isClosable
            }
          >
            {t('timeKeeping:monthlyCloseConfirmationDrawer.confirmButton')}
          </Button>
        </Flex.Row>
        {enableEditableTimeKeepingDays && (
          <Drawer
            title={t('timeKeeping:timeKeeping')}
            visible={subDrawerVisible}
            onClose={handleOnCloseSubDrawer}
            closable
          >
            <TimeKeepingDrawerContent
              className={classes.timeKeepingDrawerContent}
              monthlyCloseChange={addMonthlyCloseId}
              initialDay={subDrawerInitialDay}
              isVisible={subDrawerVisible}
            />
          </Drawer>
        )}
        <Modal
          title={t('timeKeeping:monthlyCloseConfirmationDrawer.modal.title', {
            month: moment(monthlyClose?.month).format('MMMM YYYY'),
          })}
          visible={isConfirmModalVisible}
          okText={t('timeKeeping:monthlyCloseConfirmationDrawer.modal.okText')}
          cancelText={t(
            'timeKeeping:monthlyCloseConfirmationDrawer.modal.cancelText'
          )}
          onOk={onModalOk}
          onClose={() => setIsConfirmModalVisible(false)}
          okButtonProps={{
            loading: isModalActionRunning,
          }}
          cancelButtonProps={{
            disabled: isModalActionRunning,
          }}
        >
          {t('timeKeeping:monthlyCloseConfirmationDrawer.modal.content')}
        </Modal>
      </Drawer>
    );
  });

export default MonthlyCloseConfirmDrawer;
