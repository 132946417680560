import React, { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  CreateProjectDraftDto,
  emptyCreateProjectDraftDto,
  emptyProject,
  Project,
} from '../../../../models/Project';
import {
  apiFetchConfigurations,
  apiFetchSettings as apiFetchProjectVisibilityDefaultSetting,
} from '../../../settings/api';
import { ConfigurationKeys } from '../../../../models/Types';
import { colon } from '../../../../util/forms';
import { defaultProjectNrPlaceholder } from '../../../../util';
import { useNavigate } from 'react-router-dom';

import {
  apiCreateProjectDraft,
  apiGetProjectById,
  apiUpdateProjectDraft,
} from '../../api';
import { useProjectDraftValidate } from '../../hooks/useProjectDraftValidate';
import { Button, Card, Toggle, Tooltip } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import OfficePickerPrioCL from '../../../companies/components/OfficePickerPrioCL';
import CompanyPickerPrioCL from '../../../companies/components/CompanyPickerPrioCL';
import ProjectPickerPrioCL from '../ProjectPickerPrioCL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { SettingKey } from '../../../../models/Settings';
import { getUserMe } from '../../../../apps/main/rootReducer';
import { useQuery } from '@tanstack/react-query';
import useProjectsContext from '../../hooks/useProjectsContext';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.old.spacing.baseSpacing * 3,
  },
  submitButtonFormItem: {
    textAlign: 'right',
  },
  background: {
    boxShadow: theme.old.palette.boxShadow.regular,
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
  projectCreationInProgress: {
    width: '100%',
    height: '100%',
    display: 'absolute',
    zIndex: 1000,
    backgroundColor: theme.old.palette.chromaticPalette.white,
    opacity: 0.9,
  },
  steps: {
    width: '100%',
    marginBottom: `${theme.old.spacing.baseSpacing * 4}px`,
  },
  wrapContainerWhite: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(2)}px`,
    marginBottom: `${theme.old.spacing.unit(2)}px`,
    overflow: 'hidden',
    minHeight: `${theme.old.spacing.unit(8)}px`,
  },
  wrapContainerWhiteMain: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(2)}px`,
    overflow: 'hidden',
    height: '100%',
  },
  contentContainerFloat: {
    gap: `${theme.old.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    '& .ant-form-item': {
      width: '100%',
    },
    '& .ant-row': {
      width: '100%',
    },
  },
  contentContainer: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    gap: `${theme.old.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  spacing: {
    marginLeft: theme.old.spacing.unit(2),
  },
  floatRight: {
    float: 'right',
  },
  visibilityInfo: {
    marginLeft: theme.spacing.small,
  },
  customStyling: {
    '& .prio-toggle-container': {
      '& > input': {
        display: 'none',
      },
    },
  },
  visibilityWarning: {
    fontSize: theme.font.fontSize.extraSmall,
    color: theme.colors.application.typography.muted,
    marginTop: `-${theme.spacing.regular}px`,
  },
}));

interface CreateProjectDataFormProps {
  className?: string;
  editMode?: boolean;
  disableActionButton?: boolean;
  disableForm?: boolean;
  projectId?: string;
  nextStep?: (projectId: string, step: string) => void;
}

export const CreateProjectDataForm: React.FC<CreateProjectDataFormProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { editMode, disableForm, nextStep, projectId } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getProjectById } = useProjectsContext();
  const [projectNrPlaceholder, setProjectNumberPlaceholder] =
    useState<string>(null);
  const [project, setProject] = useState<Project>(null);
  const [disabled, setDisabled] = useState<boolean>(disableForm);
  const [dataDirty, setDataDirty] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState(true);
  const [showVisibleWarning, setShowVisibleWarning] = useState(false);
  const userMe = useSelector(getUserMe);
  var {
    validationResult,
    validateProjectCreationData,
    projectToCreateIsValid,
  } = useProjectDraftValidate(form);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const { data: visibilityData } = useQuery({
    queryKey: ['projectVisibilitySetting', userMe?.myOfficeId],
    queryFn: async () => {
      const settingKey: SettingKey[] = [
        {
          scopeId: userMe?.myOfficeId,
          eSettingScope: 'office',
          keys: ['Project_Visibility'],
        },
      ];
      const { data } =
        await apiFetchProjectVisibilityDefaultSetting(settingKey);
      return data;
    },
    enabled: !!userMe?.myOfficeId,
    staleTime: 1000 * 60 * 60 * 1,
  });

  const saveAndContinue = async () => {
    const values = await form.validateFields();
    handleFinish(values, true);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  useEffect(() => {
    if (!disableForm) {
      const loadConfigs = async () => {
        const { data } = await apiFetchConfigurations();
        if (data) {
          const config = data.find(
            (configuration) =>
              configuration.key === ConfigurationKeys.PLACEHOLDER_PROJECTNR
          );
          setProjectNumberPlaceholder(config?.value ?? null);
        }
      };
      loadConfigs();
    }
  }, [disableForm]);

  useEffect(() => {
    if (validationResult) {
      if (
        !validationResult?.projectName?.isValid &&
        form.isFieldTouched('name') &&
        project.name !== form.getFieldValue('name')
      ) {
        form.setFields([
          {
            name: 'name',
            errors: [validationResult?.projectName?.description],
          },
        ]);
      } else {
        form.setFields([
          {
            name: 'name',
            errors: [],
          },
        ]);
      }
      if (
        !validationResult?.projectNumber?.isValid &&
        form.isFieldTouched('number') &&
        project.number !== form.getFieldValue('number')
      ) {
        form.setFields([
          {
            name: 'number',
            errors: [validationResult?.projectNumber?.description],
          },
        ]);
      } else {
        form.setFields([
          {
            name: 'number',
            errors: [],
          },
        ]);
      }
    }
    setDataDirty(false);
  }, [validationResult, form, t, project, projectToCreateIsValid]);

  useEffect(() => {
    if (projectId) {
      setDisabled(true);
      const loadProject = async () => {
        const { data } = await apiGetProjectById(projectId);
        if (
          (data && data?.projectStatus && data?.projectStatus === 'draft') ||
          data?.projectStatus === 10
        ) {
          setProject(data);
          form.resetFields();
        } else {
          navigateRef.current(`../`);
        }
        setDisabled(false);
      };
      loadProject();
    } else {
      setProject(emptyProject);
      form.resetFields();
    }
  }, [projectId, form]);

  useEffect(() => {
    if (visibilityData) {
      const jsonValue = visibilityData[0]?.jsonValue;
      if (!jsonValue) return;
      const parsedValue = JSON.parse(jsonValue);
      setIsVisible(parsedValue.IsVisible);
    }
  }, [visibilityData]);

  const handleFinish = async (
    values: CreateProjectDraftDto,
    forceUpdate: boolean = true
  ) => {
    if (projectId) {
      if (dataDirty) {
        const { data } = await apiUpdateProjectDraft(values, projectId);
        if (data) {
          nextStep(data.projectId, `settings`);
        }
      } else {
        if (forceUpdate) {
          await apiUpdateProjectDraft(values, projectId);
        }
        nextStep(projectId, `settings`);
      }
    } else {
      const { data } = await apiCreateProjectDraft(values);
      if (data) {
        nextStep(data.projectId, `settings`);
      }
    }
  };
  //#endregion

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.wrapContainerWhiteMain}>
          <Card
            secondaryCard={true}
            content={t('projects:form.labels.general')}
          ></Card>
          <p></p>
          <Form
            form={form}
            initialValues={{
              ...(project ?? emptyCreateProjectDraftDto),
              isVisible: isVisible,
            }}
            onFinish={handleFinish}
            layout="vertical"
            onValuesChange={(changedValue) => {
              if (Object.keys(changedValue)[0] === 'parentProject') {
                const project = getProjectById(changedValue['parentProject']);

                setIsVisible((prev) => {
                  if (project.isVisible !== prev) {
                    setShowVisibleWarning(true);
                  } else {
                    setShowVisibleWarning(false);
                  }
                  return project.isVisible;
                });
              }
            }}
          >
            <div className={classes.contentContainerFloat}>
              <Form.Item
                name="number"
                label={t('projects:form.labels.number')}
                labelAlign="left"
                validateTrigger="onBlur"
                colon={colon}
                rules={[
                  () => ({
                    async validator(rule, value) {
                      await validateProjectCreationData();
                    },
                  }),
                  {
                    required: true,
                    message: t('projects:form.validation.missingNumber'),
                  },
                ]}
              >
                <Input
                  placeholder={
                    projectNrPlaceholder ?? defaultProjectNrPlaceholder
                  }
                  disabled={editMode || disableForm || disabled}
                  onChange={() => setDataDirty(true)}
                />
              </Form.Item>
              <Form.Item
                name="shortName"
                label={t('projects:form.labels.shortName')}
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t('projects:form.validation.missingShortName'),
                  },
                ]}
              >
                <Input
                  disabled={disableForm || disabled}
                  onChange={() => setDataDirty(true)}
                />
              </Form.Item>
            </div>
            <div className={classes.contentContainerFloat}>
              <Form.Item
                name="name"
                label={t('projects:form.labels.name')}
                validateTrigger="onBlur"
                colon={colon}
                rules={[
                  () => ({
                    async validator(rule, value) {
                      await validateProjectCreationData();
                    },
                  }),
                  {
                    required: true,
                    message: t('projects:form.validation.missingName'),
                  },
                ]}
              >
                <Input
                  disabled={disableForm || disabled}
                  onChange={() => setDataDirty(true)}
                />
              </Form.Item>
              <Form.Item
                name="parentProject"
                label={t('projects:form.labels.parentProject')}
              >
                <ProjectPickerPrioCL
                  disabled={disableForm || disabled}
                  onChange={() => setDataDirty(true)}
                />
              </Form.Item>
            </div>
            <div className={classes.contentContainerFloat}>
              <Form.Item
                name="internalOfficeId"
                label={t('projects:form.labels.internalOffice')}
                rules={[
                  () => ({
                    async validator(rule, value) {
                      await validateProjectCreationData();
                    },
                  }),
                  {
                    required: true,
                    message: t('projects:form.validation.missingSubsidiary'),
                  },
                ]}
              >
                <OfficePickerPrioCL
                  onChange={() => setDataDirty(true)}
                  disabled={disableForm || disabled}
                />
              </Form.Item>
              <Form.Item
                name="companyId"
                label={t('projects:form.labels.companyId')}
                rules={[
                  () => ({
                    async validator(rule, value) {
                      await validateProjectCreationData();
                    },
                  }),
                  {
                    required: true,
                    message: t('projects:form.validation.missingCompanyId'),
                  },
                ]}
              >
                <CompanyPickerPrioCL
                  disabled={disableForm || disabled}
                  onChange={() => setDataDirty(true)}
                />
              </Form.Item>
            </div>
            <div className={classes.customStyling}>
              <Form.Item
                name="isVisible"
                label={
                  <span>
                    {t('projects:form.labels.visible')}
                    <Tooltip
                      overlay={t('projects:form.labels.visibilityInfo')}
                      overlayInnerStyle={{ textAlign: 'center' }}
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'info-circle']}
                        className={classes.visibilityInfo}
                      />
                    </Tooltip>
                  </span>
                }
                valuePropName="checked"
              >
                <div>
                  <Toggle
                    checked={isVisible}
                    onChange={(value) => setIsVisible(value)}
                  />
                </div>
              </Form.Item>
              {showVisibleWarning && (
                <div className={classes.visibilityWarning}>
                  {t('projects:form.labels.visibilityWarning')}
                </div>
              )}
            </div>
          </Form>
        </div>
        <div className={classes.wrapContainerWhite}>
          <Button
            className={classes.floatRight}
            disabled={
              projectId !== undefined && !disableForm && !disabled
                ? false
                : disableForm ||
                  disabled ||
                  dataDirty ||
                  !projectToCreateIsValid
            }
            onClick={() => {
              saveAndContinue();
            }}
          >
            {t('projects:createProject.buttons.save')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateProjectDataForm;
