import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { Menu, useTheme } from '@prio365/prio365-react-library';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  DocumentsSearchResultItem,
  DriveItem,
  DriveItemCalculatedData,
} from '../../../../models/Drive';
import {
  DefaultDateTimeFormatString,
  formatHumanFileSize,
} from '../../../../util';
import {
  checkIfLink,
  checkIfOneNote,
  colorForIcon,
  iconForFile,
  isDriveItemFolder,
} from '../../util';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, notification } from 'antd';
import MenuItem from '@prio365/prio365-react-library/lib/Menu/components/MenuItem';
import { useDriveItemActions } from '../../hooks/useDriveItemActions';
import { DriveItemId, GroupId, ProjectId } from '../../../../models/Types';
import { useNavigate } from 'react-router-dom';
import FilterContextVirtualTable from '../../../../components/Filter/FilterContextVirtualTable';
import { closeGlobalDocumentsSearchModal } from '../../actions/globalDocumentsSearchModal';
import { useDispatch, useSelector } from 'react-redux';
import useProjectsContext from '../../../projects/hooks/useProjectsContext';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import { setCurrentPreviewModalVisibility } from '../../actions/previewModal';
import { apiOpenDriveItemUrlFile } from '../../api';
import { getDocumentSettings } from '../../../../apps/main/rootReducer';
import { useGetLocalUrlByDriveId } from '../../hooks/useGetLocalUrlByDriveId';

const pathRegex = /(Shared Documents|Freigegebene Dokumente)\//;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '480px',
    width: '100%',
    overflowY: 'auto',
  },
  documentsRow: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      background: theme.colors.application.background.hover,
      cursor: 'pointer',
    },
  },
  documentsRowHovered: {
    background: theme.colors.application.background.hover,
    cursor: 'pointer',
  },
  documentsTextSpan: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
  },
  fileColumn: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerTypeColumn: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'center',
    '& > span': {
      flex: 0,
    },
  },
  menuButton: {
    cursor: 'pointer',
    padding: theme.spacing.small,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '&:hover': {
      background: theme.colors.base.blue[50],
    },
  },
  menu: {
    boxShadow: theme.boxShadow.regular,
    padding: 0,
    background: theme.colors.application.background.default,
    fontSize: theme.font.fontSize.small,
    '& .prio-menu-item': {
      marginLeft: 0,
    },
  },
  contextMenuButton: {
    height: '100%',
    width: '20px',
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      background: theme.colors.base.blue[50],
    },
  },
  onHoverPreviewIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },

  iconSize: {
    fontSize: theme.font.fontSize.small,
    lineHeight: theme.controlHeight.xs,
    maxWidth: '1em',
    width: '100%',
    '& .svg-inline--fa': {
      maxWidth: '1em',
      width: '100%',
    },
  },
  previewButton: {
    '& .fa-layers': {
      maxWidth: '1em',
      width: '100%',
    },
    '& > span > svg:nth-child(1)': {
      visibility: 'visible',
    },
    '& > span > svg:nth-child(2)': {
      visibility: 'hidden',
    },
    '&:hover > span > svg:nth-child(1)': {
      visibility: 'hidden',
    },
    '&:hover > span > svg:nth-child(2)': {
      visibility: 'visible',
    },
  },
}));

interface GlobalDocumentsSearchTableProps {
  className?: string;
}

export const GlobalDocumentsSearchTable: React.FC<
  GlobalDocumentsSearchTableProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getProjectById } = useProjectsContext();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [fileId, setFileId] = useState<string | null>(null);

  const { data: _data } = useFilterContext<
    DriveItem,
    DriveItemCalculatedData
  >();

  const data = useMemo(
    () =>
      _data.items
        .filter((item) => !isDriveItemFolder(item.data))
        .sort((a, b) => {
          return a.data.name.localeCompare(b.data.name);
        }),
    [_data]
  );

  const documentsSettings = useSelector(getDocumentSettings);
  const { getLocalUrlByDriveItem } = useGetLocalUrlByDriveId();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const openDocumentPreview = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>, driveItem: DriveItem) => {
      e.stopPropagation();
      e.preventDefault();
      const index = data.findIndex((item) => item.data.id === driveItem.id);
      const groupId = data[index].calculated.groupId;
      const projectId = data[index].calculated.projectIds[0];
      const itemList = data;

      dispatch(
        setCurrentPreviewModalVisibility(
          true,
          driveItem?.id,
          groupId,
          driveItem,
          itemList,
          index,
          '',
          projectId,
          false,
          null,
          'globalDocumentsSearch'
        )
      );
    },
    [dispatch, data]
  );

  const generateIcon = useCallback(
    (driveItem: DriveItem) => {
      const isOneNoteFile = (driveItem: DriveItem) => checkIfOneNote(driveItem);
      const iconColor = (driveItem: DriveItem) =>
        isOneNoteFile(driveItem)
          ? theme.colors.mimeType.onenote
          : colorForIcon(driveItem?.file?.mimeType);
      const iconType = (driveItem: DriveItem) => {
        return iconForFile(driveItem);
      };

      return (
        <div className={classNames(classes.iconSize, classes.previewButton)}>
          <span className="fa-layers">
            <FontAwesomeIcon
              icon={['fas', iconType(driveItem)]}
              color={iconColor(driveItem)}
            />
            <FontAwesomeIcon
              icon={['fal', 'eye']}
              color={'rgba(0, 0, 0, 0.6)'}
              className={classes.onHoverPreviewIcon}
              onClick={(e) => openDocumentPreview(e, driveItem)}
            />
          </span>
        </div>
      );
    },
    [
      theme,
      openDocumentPreview,
      classes.iconSize,
      classes.previewButton,
      classes.onHoverPreviewIcon,
    ]
  );

  const { openInBrowser, openLocally } = useDriveItemActions();

  const onOpenParentFolder = useCallback(
    (projectId: ProjectId, parentDriveItemId: DriveItemId) => {
      let url;
      if (!!!parentDriveItemId) {
        url = `/module/prio/projects/${projectId}/documents/all`;
      } else {
        url = `/module/prio/projects/${projectId}/documents/folder/${parentDriveItemId}`;
      }
      navigate(url);
      dispatch(closeGlobalDocumentsSearchModal());
    },
    [navigate, dispatch]
  );

  const onOpenInBrowser = useCallback(
    (driveItem: DriveItem, groupId: GroupId, projectId: ProjectId) => {
      openInBrowser(driveItem, groupId, projectId);
    },
    [openInBrowser]
  );

  const onOpenLocally = useCallback(
    (driveItem: DriveItem, groupId: GroupId, projectId: ProjectId) => {
      openLocally(driveItem, undefined, groupId, projectId);
    },
    [openLocally]
  );

  const onDriveItemDoubleClick = useCallback(
    async (clickedDocument: DocumentsSearchResultItem, e: React.MouseEvent) => {
      const webUrl = clickedDocument.data.webUrl;
      const localUrl = await getLocalUrlByDriveItem(
        clickedDocument.calculated.groupId,
        clickedDocument.calculated.projectIds[0],
        clickedDocument.data,
        null,
        true
      );

      const supportedFileTypes = [
        'pdf',
        'docx',
        'doc',
        'xlsx',
        'xls',
        'pptx',
        'ppt',
      ];

      const isSupported = supportedFileTypes.some(
        (ext) => webUrl?.includes(ext) || localUrl?.includes(ext)
      );

      if (!isSupported) {
        notification.open({
          message: t('common:info'),
          description: t('documents:infoMessages.unsupportedFileType'),
        });

        return;
      }

      if (checkIfLink(clickedDocument?.data.name)) {
        apiOpenDriveItemUrlFile(
          clickedDocument.calculated.groupId,
          clickedDocument.data
        );
        return;
      }
      if (
        documentsSettings &&
        documentsSettings.doubleClickOpenBehaviour === 'local'
      ) {
        window.open(localUrl);
      }
      if (
        documentsSettings &&
        documentsSettings.doubleClickOpenBehaviour === 'browser'
      ) {
        window.open(webUrl + '?web=1', '_blank');
      }
    },
    [documentsSettings, getLocalUrlByDriveItem, t]
  );

  const handleOnRowClick: (
    item: DocumentsSearchResultItem
  ) => VirtualListItemOnRowProps = (row) => {
    return {
      onDoubleClick: (e) => {
        onDriveItemDoubleClick(row, e);
      },
      className: classNames(classes.documentsRow, {
        [classes.documentsRowHovered]: fileId && fileId === row.data.id,
      }),
    };
  };

  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Columns
  const columns: Column<DocumentsSearchResultItem>[] = useMemo(
    () => [
      {
        id: 'file',
        alignSelf: true,
        width: 50,
        title: '',
        accessor: 'data.file',
        sortingFn: (a, b) => {
          const _a = a.data;
          const _b = b.data;
          return iconForFile(_a).localeCompare(iconForFile(_b));
        },
        className: classes.fileColumn,
        classNameHeaderCell: classes.headerTypeColumn,
        Cell: (cellProps) => generateIcon(cellProps.originalData.data),
      },
      {
        id: 'name',
        alignSelf: true,
        title: t('components:filter.globalDocumentsSearch.table.name'),
        width: 250,
        innerStyle: {
          height: '100%',
        },
        accessor: 'data.name',
        sortingFn: (rowA, rowB) => {
          return rowA.data.name.localeCompare(rowB.data.name);
        },
        Cell: (cellProps) => {
          return (
            <div
              className={classes.documentsTextSpan}
              title={cellProps.originalData.data.name}
            >
              {cellProps.originalData.data.name}
            </div>
          );
        },
        cellTitle: (item) => item.data.name,
      },
      {
        id: 'project',
        alignSelf: true,
        title: t('components:filter.globalDocumentsSearch.table.project'),
        width: 250,
        innerStyle: {
          height: '100%',
        },
        accessor: 'calculated.projectIds',
        sortingFn: (rowA, rowB) => {
          return rowA.calculated.projectIds[0].localeCompare(
            rowB.calculated.projectIds[0]
          );
        },
        Cell: (cellProps) => {
          const projectIds: ProjectId[] = cellProps.value;
          if (projectIds.length === 0) {
            return '';
          }
          const projects = projectIds
            .map((projectId) => {
              const project = getProjectById(projectId);
              return project?.number + ' - ' + project?.name;
            })
            .join(', ');
          return (
            <div className={classes.documentsTextSpan} title={projects}>
              {projects}
            </div>
          );
        },
      },
      {
        id: 'path',
        alignSelf: true,
        title: t('components:filter.globalDocumentsSearch.table.path'),
        width: 250,
        innerStyle: {
          height: '100%',
        },
        accessor: 'calculated.path',
        sortingFn: (rowA, rowB) => {
          const pathA = rowA.calculated.path.split(pathRegex)?.[2] ?? '';
          const pathB = rowB.calculated.path.split(pathRegex)?.[2] ?? '';
          return pathA.localeCompare(pathB);
        },
        Cell: (cellProps) => {
          const _path = (cellProps.value.split(pathRegex)?.[2] ?? '')
            ?.split('/')
            ?.slice(0, -1)
            ?.join(' / ');
          const path = _path.length === 0 ? '-' : _path;
          return (
            <div className={classes.documentsTextSpan} title={path}>
              {path}
            </div>
          );
        },
      },
      {
        id: 'lastModifiedAt',
        alignSelf: true,
        title: t(
          'components:filter.globalDocumentsSearch.table.lastModifiedAt'
        ),
        accessor: 'data.lastModifiedDateTime',
        width: 140,
        sortingFn: (a, b) => {
          return moment(a.data.lastModifiedDateTime).diff(
            moment(b.data.lastModifiedDateTime),
            'minutes'
          );
        },
        Cell: (cellProps) => DefaultDateTimeFormatString(cellProps.value),
        cellTitle: (item) =>
          DefaultDateTimeFormatString(item.data.lastModifiedDateTime),
      },
      {
        id: 'lastModifiedBy',
        alignSelf: true,
        title: t(
          'components:filter.globalDocumentsSearch.table.lastModifiedBy'
        ),
        accessor: 'data.lastModifiedBy.user.displayName',
        width: 170,
        sortingFn: (a, b) => {
          return a.data.lastModifiedBy?.user?.displayName?.localeCompare(
            b.data.lastModifiedBy?.user?.displayName
          );
        },
        Cell: (cellProps) => (
          <div className={classes.documentsRow}>
            <div className={classes.documentsTextSpan}>{cellProps.value}</div>
          </div>
        ),
        cellTitle: (driveItem) =>
          driveItem.data.lastModifiedBy?.user?.displayName,
      },
      {
        id: 'fileSize',
        alignSelf: true,
        title: t('components:filter.globalDocumentsSearch.table.size'),
        accessor: 'data.size',
        width: 100,
        sortingFn: (a, b) => a.data.size - b.data.size,
        Cell: (cellProps) => formatHumanFileSize(cellProps.value),
        cellTitle: (item) => formatHumanFileSize(item.data.size),
      },
      {
        id: 'menu',
        title: '',
        accessor: 'data.size',
        width: 40,

        Cell: ({ originalData: entry }) => {
          const projectIds = entry.calculated.projectIds;
          const groupId = entry.calculated.groupId;
          const parentDriveItemId = entry.data.parentReference?.id;
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setFileId(entry.data.id);
              }}
              style={{ height: '100%' }}
            >
              <Dropdown
                placement="bottomRight"
                trigger={['click']}
                onVisibleChange={(visible) => {
                  if (!visible)
                    setTimeout(() => {
                      setFileId(null);
                    }, 500);
                }}
                overlay={
                  <Menu className={classes.menu}>
                    <MenuItem
                      id="openInBrowser"
                      label={t(
                        'components:filter.globalDocumentsSearch.table.contextMenu.openInBrowser'
                      )}
                      onClick={() =>
                        onOpenInBrowser(entry.data, groupId, projectIds[0])
                      }
                    />
                    <MenuItem
                      id="openLocally"
                      label={t(
                        'components:filter.globalDocumentsSearch.table.contextMenu.openLocally'
                      )}
                      onClick={() =>
                        onOpenLocally(entry.data, groupId, projectIds[0])
                      }
                    />
                    <MenuItem
                      id="openParentFolder"
                      label={t(
                        'components:filter.globalDocumentsSearch.table.contextMenu.openParentFolder'
                      )}
                      onClick={() =>
                        onOpenParentFolder(projectIds[0], parentDriveItemId)
                      }
                    />
                  </Menu>
                }
              >
                <div className={classes.contextMenuButton}>
                  <FontAwesomeIcon icon={['fal', 'ellipsis-v']} size="lg" />
                </div>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [
      classes,
      t,
      generateIcon,
      onOpenInBrowser,
      onOpenLocally,
      onOpenParentFolder,
      getProjectById,
    ]
  );
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <FilterContextVirtualTable<DocumentsSearchResultItem>
        id={'global-documents-search-table'}
        data={data}
        columns={columns}
        resizable="absolute"
        noItemsScreen={t('components:filter.globalDocumentsSearch.noItems')}
        onRow={handleOnRowClick}
        checkBoxContainerWidth={50}
      />
    </div>
  );
};

export default GlobalDocumentsSearchTable;
