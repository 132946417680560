import { combineReducers, Reducer } from 'redux';
import {
  FETCH_PROJECT_DISTRIBUTION_LIST_COMMIT,
  FETCH_PROJECT_DISTRIBUTION_LIST_REQUEST,
  FETCH_PROJECT_DISTRIBUTION_LIST_ROLLBACK,
  CREATE_PROJECT_DISTRIBUTION_LIST_COMMIT,
  CREATE_PROJECT_DISTRIBUTION_LIST_REQUEST,
  CREATE_PROJECT_DISTRIBUTION_LIST_ROLLBACK,
  UPDATE_PROJECT_DISTRIBUTION_LIST_COMMIT,
  UPDATE_PROJECT_DISTRIBUTION_LIST_REQUEST,
  UPDATE_PROJECT_DISTRIBUTION_LIST_ROLLBACK,
  ARCHIVE_PROJECT_DISTRIBUTION_LIST_COMMIT,
  ARCHIVE_PROJECT_DISTRIBUTION_LIST_REQUEST,
  ARCHIVE_PROJECT_DISTRIBUTION_LIST_ROLLBACK,
  SET_PROJECT_DISTRIBUTION_LIST_DRAWER_STATE,
} from '../actions';
import { ProjectDistributionList } from '../../../models/ProjectDistributionList';
import { ProjectId } from '../../../models/Types';
import { ProjectDistributionListDrawerStateAction } from '../actions/index';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface ProjectDistributionListState {
  data: DataState;
  meta: ProjectDistributionListMeta;
  drawer: ProjectDistributionListDrawerState;
}

export interface DataState {
  [projectId: string]: ProjectDistributionList[];
}

const data: Reducer<DataState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROJECT_DISTRIBUTION_LIST_COMMIT: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: payload,
      };
    }

    /** CREATE */

    case CREATE_PROJECT_DISTRIBUTION_LIST_REQUEST: {
      const {
        payload,
        meta: { projectId, temporaryId },
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []),
          {
            ...payload,
            projectDistributionListId: temporaryId,
          },
        ],
      };
    }

    case CREATE_PROJECT_DISTRIBUTION_LIST_ROLLBACK: {
      const {
        meta: { projectId, temporaryId },
      } = action;
      const projectDistributionLists = state[projectId];
      if (!projectDistributionLists) return state;
      return {
        ...state,
        [projectId]: projectDistributionLists.filter(
          (list: ProjectDistributionList) =>
            list.projectDistributionListId !== temporaryId
        ),
      };
    }

    case CREATE_PROJECT_DISTRIBUTION_LIST_COMMIT: {
      const {
        meta: { temporaryId },
        payload: { rowVersion, projectDistributionListId, projectId },
      } = action;
      const projectDistributionList = state[projectId];
      if (!projectDistributionList) return state;

      return {
        ...state,
        [projectId]: projectDistributionList.map(
          (item: ProjectDistributionList) =>
            item.projectDistributionListId === temporaryId
              ? { ...item, rowVersion, projectDistributionListId }
              : item
        ),
      };
    }

    /** UPDATE */

    case UPDATE_PROJECT_DISTRIBUTION_LIST_REQUEST: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (list) =>
              (payload as ProjectDistributionList).projectDistributionListId !==
              list.projectDistributionListId
          ),
          payload,
        ],
      };
    }

    case UPDATE_PROJECT_DISTRIBUTION_LIST_ROLLBACK: {
      const {
        meta: { projectId },
        rollbackState,
      } = action;
      if (!rollbackState) return state;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (list) =>
              (rollbackState as ProjectDistributionList)
                .projectDistributionListId !== list.projectDistributionListId
          ),
          rollbackState,
        ],
      };
    }

    case UPDATE_PROJECT_DISTRIBUTION_LIST_COMMIT: {
      const {
        meta: { projectId },
        payload: updatedProjectDistributionList,
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (list) =>
              (updatedProjectDistributionList as ProjectDistributionList)
                .projectDistributionListId !== list.projectDistributionListId
          ),
          updatedProjectDistributionList,
        ],
      };
    }

    case ARCHIVE_PROJECT_DISTRIBUTION_LIST_REQUEST: {
      const {
        meta: { projectId, projectDistributionListId },
        payload,
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (list) =>
              projectDistributionListId !== list.projectDistributionListId
          ),
          {
            ...payload,
            isArchived: true,
          },
        ],
      };
    }

    case ARCHIVE_PROJECT_DISTRIBUTION_LIST_ROLLBACK: {
      const {
        meta: { projectId, projectDistributionListId },
        rollBackState,
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (list) =>
              projectDistributionListId !== list.projectDistributionListId
          ),
          {
            ...rollBackState,
            isArchived: false,
          },
        ],
      };
    }

    case ARCHIVE_PROJECT_DISTRIBUTION_LIST_COMMIT: {
      const {
        meta: { projectId, projectDistributionListId },
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (list) =>
              projectDistributionListId !== list.projectDistributionListId
          ),
        ],
      };
    }
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

interface ProjectDistributionListMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<ProjectDistributionListMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_PROJECT_DISTRIBUTION_LIST_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_PROJECT_DISTRIBUTION_LIST_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_PROJECT_DISTRIBUTION_LIST_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage:
          'projects:errorMessages.fetchProjectDistributionListError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export interface ProjectDistributionListDrawerState {
  isOpen: boolean;
  selectedEntry: ProjectDistributionList;
}

const drawer: Reducer<
  ProjectDistributionListDrawerState,
  ProjectDistributionListDrawerStateAction
> = (state = { isOpen: false, selectedEntry: null }, action) => {
  switch (action.type) {
    case SET_PROJECT_DISTRIBUTION_LIST_DRAWER_STATE: {
      return {
        isOpen: action.drawerState.isOpen,
        selectedEntry: action.drawerState.selectedEntry,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isOpen: false, selectedEntry: null };
    }
    default:
      return state;
  }
};

export default combineReducers<ProjectDistributionListState>({
  data,
  meta,
  drawer,
});

export const getData: (state: any) => DataState = (state: any) => state.data;
export const getProjectDistributionLists: (
  state: any,
  projectId: ProjectId
) => ProjectDistributionList[] = (state, projectId) => {
  return state.data[projectId] ?? [];
};

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;

export const getDrawerState: (
  state: any
) => ProjectDistributionListDrawerState = (state) => state.drawer;
