import {
  ButtonProps,
  createForm,
  Input,
  Toggle,
  Tooltip,
} from '@prio365/prio365-react-library';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Project, ProjectMetaData } from '../../../models/Project';
import {
  getProject,
  getProjectRedirect,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Navigate, useParams } from 'react-router-dom';
import {
  apiCreateProjectMetaData,
  apiUpdateAdditionalProjectInformation,
  apiUpdateProjectMetaData,
} from '../api';
import { updateProject } from '../actions';
import CompanyPicker from '../../companies/components/CompanyPicker';
import ContactPicker from '../../contacts/components/ContactPicker';
import { useEffect, useMemo, useRef, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { GlobalRole, OfficeRole, ProjectRole } from '../../../models/Types';
import useCompaniesContext from '../../companies/hooks/useCompaniesContext';
import { notification, Result } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from '@prio365/prio365-react-library/lib/Date/components/DatePicker/DatePicker';
import OfficePicker from '../../companies/components/OfficePicker';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: theme.spacing.regular,
    overflow: 'auto',
  },
  form: {
    padding: theme.spacing.regular,
    height: '100%',
  },
  formSection: {
    backgroundColor: theme.colors.application.background.default,
    width: '100%',
    padding: theme.spacing.regular,
    borderRadius: theme.borderRadius.small,
  },
  title: {
    marginBottom: '24px',
  },
  formRow: {
    display: 'flex',
    gap: theme.spacing.regular,
    flexWrap: 'wrap',
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.large,
  },
  description: {
    resize: 'none',
    color: theme.colors.application.typography.default,
  },
  textColor: {
    color: theme.colors.application.typography.default + ' !important',
  },
  readOnly: {
    cursor: 'not-allowed',
    '&:hover, &:focus': {
      border: `1px solid ${theme.colors.application.border}`,
      boxShadow: 'none',
    },
  },
  visibilityInfo: {
    marginLeft: theme.spacing.small,
  },
}));

type FormValuesProject = {
  name: string;
  shortName: string;
  internalOfficeId: string;
  companyId: string;
  masterPlanId: string;
  parentProject: string;
  projectId: string;
  rowVersion: string;
  isVisible: boolean;
};

type FormValuesAddedInfo = {
  content1: string;
  content2: string;
  content3: string;
  content4: string;
  content5: string;
};

type FormValues = ProjectMetaData & FormValuesProject & FormValuesAddedInfo;

export interface ProjectInformationFormProps {
  /**
   * The class name of the form.
   */
  className?: string;
  /**
   * Initial field values of the form, Formik will make these values available to render methods component as values.
   * Even if your form is empty by default, you must initialize all fields with initial values otherwise React will throw an error saying that you have changed an input from uncontrolled to controlled.
   */
  initialValues?: FormValues;
  /**
   * The function to call when the form is submitted.
   * @param values The values of the form.
   * @param formikHelpers Formik's helpers.
   * @returns
   */
  onSubmit?: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => Promise<any>;
  /**
   * The function to call when the form is changed.
   * @param values The values of the form.
   * @param changedValue The changed value.
   * @returns
   */
  onChange?: (
    values: FormValues,
    changedValue: [keyof FormValues, any]
  ) => void;
  /**
   * The function to call when the form is reset.
   * @returns
   */
  onReset?: () => void;
  /**
   * The actions of the form. Pass Button components here.
   */
  actions?: Record<string, ButtonProps> & {
    submit: ButtonProps;
    otherCancel: ButtonProps;
  };

  selectedItem: string;
  initialMetaData: ProjectMetaData;
  initialAdditionalData: FormValuesAddedInfo;
}

const Form = createForm<FormValues>();

const ProjectInformationForm = (props: ProjectInformationFormProps) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    onSubmit,
    selectedItem,
    initialMetaData,
    initialAdditionalData,
    ...rest
  } = props;
  const { t } = useTranslation();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const formRef = useRef<FormikProps<FormValues>>(null);
  const userMe = useSelector(getUserMe);
  const { getCompanyById } = useCompaniesContext();

  const { project, redirect } = useSelector<
    RootReducerState,
    { project: Project; redirect: string }
  >((state) => ({
    project: getProject(state, projectId),
    redirect: getProjectRedirect(state, projectId),
  }));
  const projectOfficeId = getCompanyById(project?.subsidiaryId)?.offices[0]
    ?.officeId;
  const myProjectRoles: ProjectRole[] = useMemo(() => {
    return userMe?.prioData?.projectRoles[projectId];
  }, [userMe, projectId]);

  const myOfficeRoles: OfficeRole[] = useMemo(() => {
    return userMe?.prioData?.officeRoles[projectOfficeId];
  }, [userMe, projectOfficeId]);

  const myGlobalRoles: GlobalRole[] = useMemo(() => {
    return userMe?.prioData?.globalRoles;
  }, [userMe]);

  const isReadOnly =
    !(
      myProjectRoles?.includes('projectAdmin') ||
      myGlobalRoles?.includes('globalAdmin') ||
      myOfficeRoles?.includes('officeAdmin')
    ) &&
    !(
      myGlobalRoles?.includes('globalAssistance') ||
      myOfficeRoles?.includes('officeAssistance')
    );
  const shouldShowActions =
    selectedItem !== 'general' || (selectedItem === 'general' && !isReadOnly);

  const [metaDataFormValues, setMetaDataFormValues] =
    useState<ProjectMetaData>(initialMetaData);
  const [additionalFormValues, setAdditionalFormValues] =
    useState<FormValuesAddedInfo>(initialAdditionalData);
  const [projectMetaDataId, setProjectMetaDataId] = useState<string | null>(
    initialMetaData?.projectMetaDataId
  );

  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...(project as FormValuesProject),
    ...metaDataFormValues,
    ...additionalFormValues,
  });

  const [isProjektUpdated, setIsProjektUpdated] = useState(false);
  const [checked, setChecked] = useState(initialFormValues?.isVisible);
  const [isFormDirty, setIsFormDirty] = useState(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    if (selectedItem === 'general') {
      const projectData: Project = values;

      dispatch(
        updateProject(
          { ...projectData, rowVersion: project.rowVersion },
          project
        )
      );
      setIsProjektUpdated(true);
    } else if (selectedItem === 'additional') {
      const { result } = await apiUpdateAdditionalProjectInformation(
        projectId,
        {
          newContent1: values.content1,
          newContent2: values.content2,
          newContent3: values.content3,
          newContent4: values.content4,
          newContent5: values.content5,
        }
      );
      if (result.status >= 200 && result.status < 400) {
        setAdditionalFormValues(values);
        formikHelpers.resetForm({
          values: values,
        });
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'projects:errorMessages.fetchAdditionalProjectInformationError'
          ),
        });

        formikHelpers.resetForm({
          values: {
            ...values,
            ...initialAdditionalData,
          },
        });
        setAdditionalFormValues(initialAdditionalData);
      }
    } else {
      const metaData: ProjectMetaData = values;
      if (values.startDate === '') {
        delete metaData.startDate;
      }

      if (values.endDate === '') {
        delete metaData.endDate;
      }
      let response;
      let errorMessage = '';
      if (!projectMetaDataId) {
        response = await apiCreateProjectMetaData(projectId, metaData);
        errorMessage = t('projects:settings.error.createMetaData');
        setProjectMetaDataId(response.data?.projectMetaDataId);
      } else {
        response = await apiUpdateProjectMetaData(
          projectId,
          projectMetaDataId,
          metaData
        );
        errorMessage = t('projects:settings.error.updateMetaData');
      }

      if (response.result.status >= 200 && response.result.status < 300) {
        setMetaDataFormValues(values);
        formikHelpers.resetForm({
          values: values,
        });
      } else {
        notification.open({
          message: t('common:error'),
          description: errorMessage,
        });
        formikHelpers.resetForm({
          values: {
            ...values,
            ...metaDataFormValues,
          },
        });
        setMetaDataFormValues(metaDataFormValues);
      }
    }
    setIsFormDirty(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (selectedItem === 'general') {
      formRef.current?.resetForm({
        values: project as FormValues,
      });
      setInitialFormValues(project as FormValues);
    } else if (selectedItem === 'additional') {
      formRef.current?.resetForm({
        values: additionalFormValues as FormValues,
      });
      setInitialFormValues(additionalFormValues as FormValues);
    } else {
      formRef.current?.resetForm({
        values: metaDataFormValues as FormValues,
      });
      setInitialFormValues(metaDataFormValues as FormValues);
    }
  }, [selectedItem, project, metaDataFormValues, additionalFormValues]);

  useEffect(() => {
    if (isProjektUpdated && selectedItem === 'general') {
      formRef.current?.setValues(project as FormValues);
      formRef.current?.resetForm({
        values: project as FormValues,
      });
      setIsProjektUpdated(false);
    }
    setChecked(project?.isVisible);
  }, [project, isProjektUpdated, selectedItem]);
  //#endregion

  if (redirect) {
    return <Navigate to={`../../../${redirect}/settings/edit`} />;
  }

  if (!project)
    return (
      <Result
        status="404"
        title="404"
        subTitle={t('projects:errorMessages.notFound')}
      />
    );

  return (
    <Form
      {...rest}
      initialValues={initialFormValues}
      onSubmit={async (values, helpers) => {
        handleSubmit && handleSubmit(values, helpers);
      }}
      ref={formRef}
      onChange={(values, changedValue) => {
        if (
          (Object.keys(changedValue)[0] === 'name' && !changedValue.name) ||
          (Object.keys(changedValue)[0] === 'shortName' &&
            !changedValue.shortName)
        ) {
          setIsFormDirty(false);
        } else {
          setIsFormDirty(true);
        }
      }}
      onReset={() => {
        setIsFormDirty(false);
      }}
      className={classes.form}
      actions={
        shouldShowActions && {
          submit: {
            children: t('projects:settings.save'),
            type: 'primary',
            disabled: !isFormDirty,
          },
          cancel: {
            children: t('projects:settings.cancel'),
            type: 'default',
          },
        }
      }
    >
      {selectedItem === 'general' && (
        <>
          <div className={classes.formSection}>
            <h2 className={classes.title}>
              {t('projects:settings.headline.project')}
            </h2>
            <div className={classes.items}>
              <div className={classes.formRow}>
                <Form.Item
                  label={t('projects:settings.labels.name')}
                  name="name"
                  validationSchema={Yup.string().required('Required')}
                >
                  <Input
                    readOnly={isReadOnly}
                    className={isReadOnly ? classes.readOnly : undefined}
                  />
                </Form.Item>
                <Form.Item
                  label={t('projects:settings.labels.shortName')}
                  name="shortName"
                  validationSchema={Yup.string().required('Required')}
                >
                  <Input
                    readOnly={isReadOnly}
                    className={isReadOnly ? classes.readOnly : undefined}
                  />
                </Form.Item>
              </div>
              <div className={classes.formRow}>
                <Form.Item
                  name="isVisible"
                  validationSchema={Yup.string().notRequired()}
                  disabled={isReadOnly}
                  label={
                    <span>
                      {t('projects:settings.labels.visibility')}
                      <Tooltip
                        overlay={t('projects:form.labels.visibilityInfo')}
                        overlayInnerStyle={{ textAlign: 'center' }}
                      >
                        <FontAwesomeIcon
                          icon={['fal', 'info-circle']}
                          className={classes.visibilityInfo}
                        />
                      </Tooltip>
                    </span>
                  }
                >
                  <Toggle
                    checked={checked}
                    onChange={(value) => setChecked(value)}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className={classes.formSection}>
            <h2 className={classes.title}>
              {t('projects:settings.headline.internalOffice')}
            </h2>
            <div className={classes.formRow}>
              <Form.Item
                label={t('projects:settings.labels.internalOffice')}
                name="internalOfficeId"
                validationSchema={Yup.string().required('Required')}
              >
                <OfficePicker
                  className={classes.textColor}
                  onlyInternal={true}
                  readonly={isReadOnly}
                />
              </Form.Item>
            </div>
          </div>
          <div className={classes.formSection}>
            <h2 className={classes.title}>
              {t('projects:settings.headline.company')}
            </h2>
            <div className={classes.formRow}>
              <Form.Item
                label={t('projects:settings.labels.company')}
                name="companyId"
                validationSchema={Yup.string().required('Required')}
              >
                <CompanyPicker
                  className={classes.textColor}
                  readonly={isReadOnly}
                />
              </Form.Item>
            </div>
          </div>
        </>
      )}

      {selectedItem === 'information' && (
        <>
          <div className={classes.formSection}>
            <h2 className={classes.title}>
              {t('projects:settings.headline.projectInfo')}
            </h2>
            <div className={classes.items}>
              <div className={classes.formRow}>
                <Form.Item
                  label={t('projects:settings.labels.projectType')}
                  name="projectType"
                  validationSchema={Yup.string().notRequired()}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('projects:settings.labels.sector')}
                  name="sector"
                  validationSchema={Yup.string().notRequired()}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className={classes.formRow}>
                <Form.Item
                  label={t('projects:settings.labels.address')}
                  name="address"
                  validationSchema={Yup.string().notRequired()}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('projects:settings.labels.coordinates')}
                  name="coordinates"
                  validationSchema={Yup.string().notRequired()}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className={classes.formSection}>
            <h2 className={classes.title}>
              {t('projects:settings.headline.internalOffice')}
            </h2>
            <div className={classes.formRow}>
              <Form.Item
                label={t('projects:settings.labels.internalContactPerson')}
                name="internalContactPersonId"
                validationSchema={Yup.string().notRequired()}
              >
                <ContactPicker
                  className={classes.textColor}
                  contactType="InternalContact"
                />
              </Form.Item>
            </div>
          </div>
          <div className={classes.formSection}>
            <h2 className={classes.title}>
              {t('projects:settings.headline.company')}
            </h2>
            <div className={classes.formRow}>
              <Form.Item
                label={t('projects:settings.labels.externalContactPerson')}
                name="externalContactPersonId"
                validationSchema={Yup.string().notRequired()}
              >
                <ContactPicker className={classes.textColor} />
              </Form.Item>
            </div>
          </div>
        </>
      )}

      {selectedItem === 'description' && (
        <>
          <div className={classes.formSection}>
            <h2 style={{ marginBottom: '8px' }}>
              {t('projects:settings.headline.shortDescription')}
            </h2>
            <Form.Item
              name="summary"
              validationSchema={Yup.string().notRequired()}
            >
              {({ value, onChange }) => {
                return (
                  <TextArea
                    className={classes.description}
                    value={value}
                    placeholder={t(
                      'projects:settings.placeholder.shortDescription'
                    )}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    rows={6}
                  />
                );
              }}
            </Form.Item>
          </div>
          <div className={classes.formSection}>
            <h2 style={{ marginBottom: '8px' }}>
              {t('projects:settings.headline.detailedDescription')}
            </h2>
            <Form.Item
              name="detailedDescription"
              validationSchema={Yup.string().notRequired()}
            >
              {({ value, onChange }) => {
                return (
                  <TextArea
                    className={classes.description}
                    value={value}
                    placeholder={t(
                      'projects:settings.placeholder.detailedDescription'
                    )}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    rows={20}
                  />
                );
              }}
            </Form.Item>
          </div>
        </>
      )}
      {selectedItem === 'times' && (
        <>
          <div className={classes.formSection}>
            <h2 className={classes.title}>
              {t('projects:settings.headline.date')}
            </h2>
            <div className={classes.formRow}>
              <Form.Item
                label={t('projects:settings.labels.startDate')}
                name="startDate"
                className={classes.textColor}
                validationSchema={Yup.string().notRequired()}
              >
                <DatePicker mode="single" onChange={() => {}} />
              </Form.Item>
              <Form.Item
                label={t('projects:settings.labels.endDate')}
                name="endDate"
                className={classes.textColor}
                validationSchema={Yup.string().notRequired()}
              >
                <DatePicker mode="single" onChange={() => {}} />
              </Form.Item>
            </div>
          </div>
        </>
      )}
      {selectedItem === 'additional' && (
        <>
          <div className={classes.formSection}>
            <h2 className={classes.title}>
              {t('projects:settings.headline.additional')}
            </h2>
            <div className={classes.items}>
              <Form.Item
                label={t('projects:settings.labels.additionalInfo') + ' 1'}
                name="content1"
                validationSchema={Yup.string().notRequired()}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('projects:settings.labels.additionalInfo') + ' 2'}
                name="content2"
                validationSchema={Yup.string().notRequired()}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t('projects:settings.labels.additionalInfo') + ' 3'}
                name="content3"
                validationSchema={Yup.string().notRequired()}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('projects:settings.labels.additionalInfo') + ' 4'}
                name="content4"
                validationSchema={Yup.string().notRequired()}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t('projects:settings.labels.additionalInfo') + ' 5'}
                name="content5"
                validationSchema={Yup.string().notRequired()}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

export default ProjectInformationForm;
