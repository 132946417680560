import { Contact } from '../../../models/Contact';
import { ContactId, PhoneLinePreference } from '../../../models/Types';

export const sortContactsHelper = (
  a: Contact | undefined,
  b: Contact | undefined
) => {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return 1;
  }
  if (b === undefined) {
    return -1;
  }
  const lastNameCompare = (a?.lastName ?? '').localeCompare(b?.lastName ?? '');
  if (lastNameCompare !== 0) {
    return lastNameCompare;
  }
  return (a?.firstName ?? '').localeCompare(b?.firstName ?? '');
};

/**
 * Function to get the calling href based on phoneLinePreference, contactId and additionalContactInformation
 * @param phoneLinePreference required; whether to use ms teams or phone
 * @param getContactById useContactsContext to get a contact to contactId
 * @param contactId contactId of the contact to call
 * @param additionalContactInformation string; additional prop to use for calling (if no contact available or contactId === null)
 * @returns
 */
export const getCallingHref = (
  phoneLinePreference: PhoneLinePreference,
  getContactById?: (contactId) => Contact,
  contactId?: ContactId,
  additionalContactInformation?: string
) => {
  let contact = {} as Contact;
  if (contactId && getContactById) {
    contact = getContactById(contactId);
  }

  let mail = '';
  let phone = '';

  if (!contactId || !getContactById) {
    phone = additionalContactInformation;
  }

  if (contact?.contactType === 'InternalContact') {
    mail = contact.eMail;
    phone = contact.cellphone ?? contact.phone ?? additionalContactInformation;
  }

  if (contact?.contactType === 'ExternalContact') {
    phone =
      contact.phone?.replace('+', '00') ??
      contact.phone2?.replace('+', '00') ??
      contact.cellphone?.replace('+', '00') ??
      contact.cellphone2?.replace('+', '00');
  }

  if (phoneLinePreference === 'PreferPhone') {
    return `tel:${phone}`;
  }
  if (
    phoneLinePreference === 'PreferTeams' &&
    contact?.contactType === 'InternalContact' &&
    mail
  ) {
    return `msteams:/l/call/0/0?users=${mail}`;
  }
  if (phoneLinePreference === 'PreferTeams' && phone) {
    return `msteams:/l/call/0/0?users=4:=${phone}`;
  }

  return undefined;
};
