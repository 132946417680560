import { ApiResult, apiUrl } from '../../../api';
import { getAccessToken } from '../../../store/authEffect';
import {
  EMailValidationResult,
  ProjectNumberValidationResult,
} from '../../../models/ValidationResults';
import {
  AdditionalProjectInformation,
  AttributeValidations,
  CreateProjectDraftByProjectDto,
  CreateProjectDraftDto,
  CreateProjectDraftValidationDto,
  Project,
  ProjectDocumentSetting,
  ProjectMetaData,
  ProjectNews,
  ProjectsWithNewsCounts,
  ProjectStatus,
  ProjectToCreatePreview,
  UpdateProjectDraftDto,
  ValidateProjectForCreationModel,
} from '../../../models/Project';
import {
  GlobalProjectSettingType,
  HourlyRateId,
  ProjectExtensionId,
  ProjectId,
} from '../../../models/Types';
import { Planner } from '../../../models/Planner';
import { ProjectSetting, ProjectMailSetting } from '../../../models/Project';
import { InternalProjectContact } from '../../../models/ProjectContacts';
import fetchWithRetry from '../../../util/fetchWithRetry';
import {
  CreateHourlyRateRequest,
  HourlyRate,
  HourlyRateSuggestion,
} from '../../../models/HourlyRate';
import {
  AvailableProjectExtension,
  CreateProjectExtension,
  CreateProjectExtensionConfiguration,
  ProjectExtension,
  ProjectExtensionAccess,
  ProjectExtensionConfiguration,
  TriggerTypes,
  UpdateProjectExtension,
  UpdateUserExtensionAccess,
  UpdateProjectExtensionConfiguration,
  ProjectExtensionUserConfiguration,
  UpdateProjectExtensionUserConfiguration,
  CreateProjectExtensionUserConfiguration,
  ProjectExtensionSuggestion,
  GetProjectExtensionSuggestion,
} from '../../../models/ProjectExtension';
import { ProjectMembership } from '../../../models/Contact';
import {
  CreateProjectPhase,
  ProjectPhase,
  UpdateProjectPhase,
} from '../../../models/ProjectPhase';
import { JiraBoard, JiraProject } from '../../../models/Atlassian/jira';

export const apiCreateProjectPreview: (
  validateProjectForCreation: ValidateProjectForCreationModel
) => Promise<ApiResult<ProjectToCreatePreview>> = async (
  validateProjectForCreation
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/createProjectPreview`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(validateProjectForCreation),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectToCreatePreview)
      : null;
  return {
    result,
    data,
  };
};

export const apiValidateProjectEmail: (
  email: string
) => Promise<ApiResult<EMailValidationResult>> = async (email) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/validateNewGroupEmail?email=${encodeURI(email)}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiValidateProjectNumber: (
  projectNumber: string
) => Promise<ApiResult<ProjectNumberValidationResult>> = async (
  projectNumber
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/validateProjectNumber?projectNumber=${encodeURI(
      projectNumber
    )}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiFetchProjectStatus: (
  projectId: ProjectId
) => Promise<ApiResult<ProjectStatus>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/status`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectStatus)
      : null;
  return {
    result,
    data,
  };
};

export const apiFixProject: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectFix/${projectId}/fix`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );
  return result;
};

export const apiDeleteHourlyRate: (
  projectId: ProjectId,
  hourlyRateId: HourlyRateId
) => Promise<ApiResult<void>> = async (projectId, hourlyRateId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/HourlyRate/${hourlyRateId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiCreateProjectFolder: (
  projectId: ProjectId,
  folderStructureId: string
) => Promise<Response> = async (projectId, folderStructureId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/createFolderStructure/${folderStructureId}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );

  return result;
};

export const apiPrecacheResync: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/emailsync/EmailSync/project/${projectId}/precacheResync`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );

  return result;
};

export const apiStartProjectSubscription: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectFix/Project/${projectId}/startSubscription`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );

  return result;
};

export const apiFixProjectSubscription: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectFix/Project/${projectId}/fixSubscription`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );

  return result;
};

export const apiFetchPlanners: (
  projectId: ProjectId
) => Promise<ApiResult<Planner[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/planner`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchTeams: (projectId: ProjectId) => Promise<number> = async (
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Team/${projectId}/teams`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  return result.status;
};

export const apiCreateTeams: (projectId: ProjectId) => Promise<number> = async (
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Team/${projectId}/createTeam`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify({}),
    }
  );

  return result.status;
};

export const apiFinishProjectCreation: (
  projectId: ProjectId
) => Promise<ApiResult<Project>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectCreation/${projectId}/finish`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    data,
    result,
  };
};

export const apiCreateProjectDraft: (
  createProjectDraftDto: CreateProjectDraftDto
) => Promise<ApiResult<Project>> = async (createProjectDraftDto) => {
  const result = await fetchWithRetry(`${apiUrl}/project/projectCreation`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(createProjectDraftDto),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectDraft: (
  updateProjectDraftDto: UpdateProjectDraftDto,
  projectId: ProjectId
) => Promise<ApiResult<Project>> = async (updateProjectDraftDto, projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/projectCreation/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updateProjectDraftDto),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiGetProjectDraftsById: () => Promise<
  ApiResult<Project[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/project/projectCreation`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiValidateProjectDraft: (
  createProjectDraftDto: CreateProjectDraftDto
) => Promise<ApiResult<CreateProjectDraftValidationDto>> = async (
  createProjectDraftDto
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectCreation/validateProjectDraft`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectDraftDto),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as CreateProjectDraftValidationDto)
      : null;
  return {
    result,
    data,
  };
};
export const apiCreateProjectDraftByProject: (
  createProjectDraftByProjectDto: CreateProjectDraftByProjectDto
) => Promise<ApiResult<Project>> = async (createProjectDraftByProjectDto) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/projectCreation/${createProjectDraftByProjectDto.draftProjectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectDraftByProjectDto),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiGetProjectById: (
  projectId: ProjectId
) => Promise<ApiResult<Project>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/project/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

///api/project/{projectId}/ProjectExtension/suggestion POST
/*
GetProjectExtensionSuggestion
{
  "key": "string",
  "value": "string",
  "projectExtensionType": "sharedMailbox"
}

return 
ProjectExtensionSuggestion
*/

export const apiGetProjectExtensionSuggestion: (
  projectId: ProjectId,
  getProjectExtensionSuggestion: GetProjectExtensionSuggestion
) => Promise<ApiResult<ProjectExtensionSuggestion>> = async (
  projectId,
  getProjectExtensionSuggestion
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension/suggestion`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        key: getProjectExtensionSuggestion.key,
        value: getProjectExtensionSuggestion.value,
        projectExtensionType:
          getProjectExtensionSuggestion.projectExtensionType,
      }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtensionSuggestion)
      : null;
  return {
    result,
    data,
  };
};

export const apiProjectExtensionTrigger: (
  projectId: ProjectId,
  projectExtensionId: string,
  triggerType: TriggerTypes
) => Promise<Response> = async (projectId, projectExtensionId, triggerType) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectExtension/${projectExtensionId}/trigger`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        triggerType: triggerType,
      }),
    }
  );

  return result;
};

export const apiUpdateProjectExtensionDefaultAccess: (
  projectId: ProjectId,
  projectExtensionId: ProjectExtensionId,
  hasDefaultAccess: boolean
) => Promise<Response> = async (
  projectId,
  projectExtensionId,
  hasDefaultAccess
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension/${projectExtensionId}/defaultAccess`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify({
        hasDefaultAccess: hasDefaultAccess,
      }),
    }
  );

  return result;
};

export const apiValidateProjectExtensionForProject: (
  projectId: ProjectId,
  createProjectExtension: CreateProjectExtension
) => Promise<ApiResult<AttributeValidations>> = async (
  projectId,
  createProjectExtension
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension/validate`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectExtension),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as AttributeValidations)
      : null;
  return {
    result,
    data,
  };
};
export const apiValidateProjectCreation: (
  createProjectDraftDto: CreateProjectDraftDto
) => Promise<ApiResult<CreateProjectDraftValidationDto>> = async (
  createProjectDraftDto
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/projectCreation/validateProjectDraft`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectDraftDto),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchInternalProjectContacts: (
  projectId: ProjectId
) => Promise<ApiResult<InternalProjectContact[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/InternalProjectContact`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchAllProjectSettings: (
  projectId: ProjectId,
  signal?: AbortSignal
) => Promise<ApiResult<ProjectSetting[]>> = async (projectId, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectMailSetting: (
  projectId: ProjectId,
  type: GlobalProjectSettingType,
  signal?: AbortSignal
) => Promise<ApiResult<ProjectMailSetting>> = async (
  projectId,
  type,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${type}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateProjectMailSetting: (
  type: GlobalProjectSettingType,
  projectId: ProjectId,
  setting: ProjectMailSetting
) => Promise<ApiResult<ProjectMailSetting>> = async (
  type,
  projectId,
  setting
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${type}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectMailSetting: (
  type: GlobalProjectSettingType,
  projectId: ProjectId,
  setting: ProjectMailSetting
) => Promise<ApiResult<ProjectMailSetting>> = async (
  type,
  projectId,
  setting
) => {
  setting.projectId = projectId;
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${setting.projectSettingId}/${type}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectDocumentSetting: (
  projectId: ProjectId,
  type: GlobalProjectSettingType,
  signal?: AbortSignal
) => Promise<ApiResult<ProjectDocumentSetting>> = async (
  projectId,
  type,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${type}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateProjectDocumentSetting: (
  type: GlobalProjectSettingType,
  projectId: ProjectId,
  setting: ProjectDocumentSetting
) => Promise<ApiResult<ProjectDocumentSetting>> = async (
  type,
  projectId,
  setting
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${type}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectDocumentSetting: (
  type: GlobalProjectSettingType,
  projectId: ProjectId,
  setting: ProjectDocumentSetting
) => Promise<ApiResult<ProjectDocumentSetting>> = async (
  type,
  projectId,
  setting
) => {
  setting.projectId = projectId;
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${setting.projectSettingId}/${type}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectNews: () => Promise<
  ApiResult<ProjectNews>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/project/ProjectNews`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectNewsCount: (
  projectIds?: string[]
) => Promise<ApiResult<ProjectsWithNewsCounts>> = async (projectIds) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectNews/counts${
      projectIds.length > 0
        ? `?projectIds=${encodeURIComponent(projectIds?.join(',') || '')}`
        : 0
    }`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectNewsByReferenceObjectId: (
  projectId: ProjectId,
  referenceObjectId: string
) => Promise<ApiResult<ProjectNews>> = async (projectId, referenceObjectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectNews?projectIds=${projectId}&referenceObjectIds=${referenceObjectId}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectNews)
      : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectMeNotification: (
  projectId: ProjectId
) => Promise<ApiResult<boolean>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/internalProjectContact/notification/me`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectMeNotification: (
  projectId: ProjectId,
  isNotificationEnabled: boolean
) => Promise<ApiResult<InternalProjectContact>> = async (
  projectId,
  isNotificationEnabled
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/internalProjectContact/notification/me?isNotificationEnabled=${isNotificationEnabled}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiGetGlobalHourlyRateSuggestions: () => Promise<
  ApiResult<HourlyRateSuggestion[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/hourlyRateSuggestion`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiGetProjectHourlyRate: (
  projectId: ProjectId
) => Promise<ApiResult<HourlyRate[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/HourlyRate/currentHourlyRate`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiPushHourlyRateForProject: (
  projectId: ProjectId,
  createHourlyRateRequests: CreateHourlyRateRequest[]
) => Promise<ApiResult<HourlyRate[]>> = async (
  projectId,
  createHourlyRateRequests
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/HourlyRate`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',

      body: JSON.stringify(createHourlyRateRequests),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteProjectCreationDraft: (
  projectId: ProjectId
) => Promise<ApiResult<void>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectCreation/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiDeleteProjectExtensionForProject: (
  projectId: ProjectId,
  projectExtensionId: ProjectExtensionId
) => Promise<ApiResult<void>> = async (projectId, projectExtensionId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension/${projectExtensionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiFetchProjectExtensionsForProject: (
  projectId: ProjectId
) => Promise<ApiResult<ProjectExtension[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtension[])
      : null;
  return {
    result,
    data,
  };
};

export const apiFetchAvailableProjectExtensionsForProject: (
  projectId: ProjectId
) => Promise<ApiResult<AvailableProjectExtension[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension/available`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as AvailableProjectExtension[])
      : null;
  return {
    result,
    data,
  };
};

export const apiCreateProjectExtensionForProject: (
  projectId: ProjectId,
  createProjectExtension: CreateProjectExtension[]
) => Promise<ApiResult<ProjectExtension[]>> = async (
  projectId,
  createProjectExtension
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectExtension),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtension[])
      : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectExtensionForProject: (
  projectId: ProjectId,
  projectExtensionId: ProjectExtensionId,
  updateProjectExtension: UpdateProjectExtension
) => Promise<ApiResult<ProjectExtension>> = async (
  projectId,
  projectExtensionId,
  updateProjectExtension
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension/${projectExtensionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updateProjectExtension),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtension)
      : null;

  return {
    result,

    data,
  };
};

export const apiUpdateProjectExtensionAccesses: (
  projectId: ProjectId,
  accesses: UpdateUserExtensionAccess[]
) => Promise<ApiResult<ProjectExtensionAccess[]>> = async (
  projectId,
  accesses
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectExtensionAccess`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(accesses),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtensionAccess[])
      : null;
  return {
    result,
    data,
  };
};

///api/project/ProjectExtensionAccess/health/{projectExtensionAccessId} // triggerUpdate
export const apiTriggerUpdateProjectExtensionAccess: (
  projectExtensionAccessId: string
) => Promise<ApiResult<any>> = async (projectExtensionAccessId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectExtensionAccess/health/${projectExtensionAccessId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'POST',
    }
  );

  return {
    result,
  };
};

export const apiGetAllProjectExtensionConfiguration: () => Promise<
  ApiResult<ProjectExtensionConfiguration[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectExtensionConfiguration/configuration`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtensionConfiguration[])
      : null;
  return {
    result,
    data,
  };
};

export const apiGetProjectExtensionConfigurationById: (
  ProjectExtensionId: ProjectExtensionId
) => Promise<ApiResult<ProjectExtensionConfiguration>> = async (
  ProjectExtensionId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectExtensionConfiguration/configuration/${ProjectExtensionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtensionConfiguration)
      : null;
  return {
    result,
    data,
  };
};

export const apiDeleteProjectExtensionConfigurationById: (
  ProjectExtensionId: ProjectExtensionId
) => Promise<ApiResult<ProjectExtensionConfiguration>> = async (
  ProjectExtensionId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectExtensionConfiguration/configuration/${ProjectExtensionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return {
    result,
  };
};

export const apiUpdateProjectExtensionConfiguration: (
  projectExtensionConfigurationId: ProjectExtensionId,
  updateProjectExtensionConfiguration: UpdateProjectExtensionConfiguration
) => Promise<ApiResult<ProjectExtensionConfiguration>> = async (
  projectExtensionConfigurationId,
  updateProjectExtensionConfiguration
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectExtensionConfiguration/configuration/${projectExtensionConfigurationId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updateProjectExtensionConfiguration),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiCreateProjectExtensionConfiguration: (
  createProjectExtensionConfiguration: CreateProjectExtensionConfiguration
) => Promise<ApiResult<ProjectExtensionConfiguration>> = async (
  createProjectExtensionConfiguration
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectExtensionConfiguration/configuration`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectExtensionConfiguration),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiGetAllProjectExtensionUserConfiguration: () => Promise<
  ApiResult<ProjectExtensionUserConfiguration[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectExtensionUserConfiguration/configuration`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtensionUserConfiguration[])
      : null;
  return {
    result,
    data,
  };
};

export const apiGetProjectExtensionUserConfigurationById: (
  projectExtensionUserConfigurationId: ProjectExtensionId
) => Promise<ApiResult<ProjectExtensionUserConfiguration>> = async (
  projectExtensionUserConfigurationId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectExtensionUserConfiguration/configuration/${projectExtensionUserConfigurationId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtensionUserConfiguration)
      : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectExtensionUserConfiguration: (
  projectExtensionUserConfigurationId: string,
  updateProjectExtensionUserConfiguration: UpdateProjectExtensionUserConfiguration
) => Promise<ApiResult<ProjectExtensionUserConfiguration>> = async (
  projectExtensionUserConfigurationId,
  updateProjectExtensionUserConfiguration
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/projectExtensionUserConfiguration/configuration/${projectExtensionUserConfigurationId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updateProjectExtensionUserConfiguration),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiDeleteProjectExtensionUserConfigurationById: (
  projectExtensionUserConfigurationId: string
) => Promise<ApiResult<ProjectExtensionUserConfiguration>> = async (
  projectExtensionUserConfigurationId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/projectExtensionUserConfiguration/configuration/${projectExtensionUserConfigurationId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return {
    result,
  };
};

export const apiCreateProjectExtensionUserConfiguration: (
  createProjectExtensionUserConfiguration: CreateProjectExtensionUserConfiguration
) => Promise<ApiResult<ProjectExtensionUserConfiguration>> = async (
  createProjectExtensionUserConfiguration
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectExtensionUserConfiguration/configuration`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectExtensionUserConfiguration),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiFetchProjectMemberships: (
  contactId: string
) => Promise<ApiResult<ProjectMembership>> = async (contactId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectMember/${contactId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiArchiveProject: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/archive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(projectId),
    }
  );
  return result;
};

export const apiUnarchiveProject: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/unarchive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(projectId),
    }
  );
  return result;
};

export const apiGetAdditionalProjectInformation: (
  projectId: ProjectId
) => Promise<ApiResult<AdditionalProjectInformation>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/AdditionalInformation/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiUpdateAdditionalProjectInformation: (
  projectId: ProjectId,
  additionalProjectInformation: {
    newContent1: string;
    newContent2: string;
    newContent3: string;
    newContent4: string;
    newContent5: string;
  }
) => Promise<ApiResult<AdditionalProjectInformation>> = async (
  projectId,
  additionalProjectInformation
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/AdditionalInformation/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(additionalProjectInformation),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiGetProjectMetaDataByProjectId: (
  projectId: ProjectId
) => Promise<ApiResult<ProjectMetaData>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectMetaData/project/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'GET',
    }
  );

  let data = null;

  if (result.status >= 200 && result.status < 300) {
    const text = await result.text();
    data = text ? JSON.parse(text) : null;
  }

  return {
    result,
    data,
  };
};

export const apiCreateProjectMetaData: (
  projectId: ProjectId,
  projectMetaData: ProjectMetaData
) => Promise<ApiResult<ProjectToCreatePreview>> = async (
  projectId,
  projectMetaData
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectMetaData/project/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(projectMetaData),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectMetaData: (
  projectId: ProjectId,
  projectMetaDataId: string,
  metaData: ProjectMetaData
) => Promise<ApiResult<ProjectMetaData>> = async (
  projectId,
  projectMetaDataId,
  metaData
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectMetaData/${projectMetaDataId}/project/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(metaData),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiGetJiraBoards: () => Promise<
  ApiResult<JiraBoard[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/atlassian/Jira/board`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    method: 'GET',
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

///api/atlassian/Jira/project
export const apiGetJiraProjects: () => Promise<
  ApiResult<JiraProject[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/atlassian/Jira/project`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    method: 'GET',
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiCreateProjectPhase: (
  projectId: ProjectId,
  createProjectPhase: CreateProjectPhase[]
) => Promise<ApiResult<ProjectPhase>> = async (
  projectId,
  createProjectPhase
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectPhase`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectPhase),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

// get projectPhases
export const apiGetProjectPhases: (
  projectId: ProjectId
) => Promise<ApiResult<ProjectPhase[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectPhase`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiGetProjectPhaseByProjectAndId: (
  projectId: ProjectId,
  projectPhaseId: string
) => Promise<ApiResult<ProjectPhase>> = async (projectId, projectPhaseId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectPhase/${projectPhaseId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

// update project phases
export const apiUpdateProjectPhases: (
  projectId: ProjectId,
  updateProjectPhase: UpdateProjectPhase[]
) => Promise<ApiResult<ProjectPhase[]>> = async (
  projectId,
  updateProjectPhase
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectPhase`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updateProjectPhase),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiLeaveProjectMe: (
  projectId: ProjectId
) => Promise<ApiResult<any>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/InternalProjectContact/${projectId}/archive/me`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'PUT',
    }
  );

  const data = result.status >= 400 ? await result.json() : null;

  return { result, data };
};
