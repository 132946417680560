import React from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { EmergencyContact } from '../../../models/Employee';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Button, Divider } from '@prio365/prio365-react-library';
import { useSelector } from 'react-redux';
import { getPhoneLinePreference } from '../../../apps/main/rootReducer';
import { getCallingHref } from '../../contacts/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    borderRadius: '4px',
    padding: 10,
    marginRight: theme.spacing.small,
    marginLeft: theme.spacing.large,
    marginBottom: theme.spacing.large,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  noData: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing.regular,
  },
  phoneNumberRow: {
    '& button': {
      visibility: 'hidden',
    },
    '&:hover button': {
      visibility: 'visible',
    },
  },
  h1: {
    fontSize: theme.font.fontSize.large,
    fontWeight: theme.font.fontWeight.bold,
    marginBottom: '24px !important',
  },
  label: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.extraSmall,
  },
}));

interface ProfilePersonnelEmergencyContactDataFormProps {
  emergencyContactList: EmergencyContact[];
}

export const ProfilePersonnelEmergencyContactDataForm: React.FC<
  ProfilePersonnelEmergencyContactDataFormProps
> = (props) => {
  const { emergencyContactList } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion
  //#region ------------------------------ States / Attributes / Selector
  const phoneLinePreference = useSelector(getPhoneLinePreference);

  //#region ------------------------------ Methods / Handlers
  //#endregion
  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <div className={classes.h1}>
        {t('profile:profileNavigation.emergencyContacts')}
      </div>
      {!emergencyContactList.length ? (
        <div className={classes.noData}>{t('profile:noEmergencyContact')}</div>
      ) : (
        emergencyContactList.map((emergencyContact, index) => (
          <Flex.Column
            flex={1}
            childrenGap={theme.old.spacing.unit(3)}
            key={emergencyContact.emergencyContactId}
          >
            <Flex.Row>
              <Flex.Column flex={1} overflow={'hidden'}>
                <div className={classes.label}>
                  {t('profile:form.emergencyContactName')}
                </div>
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '90%',
                  }}
                >
                  {`${
                    emergencyContact.name
                      ? emergencyContact.name
                      : ` ${t('profile:personalData.noData')} `
                  }`}
                </div>
              </Flex.Column>
              <Flex.Column flex={1}>
                <div className={classes.label}>
                  {t('profile:form.emergencyContactMobile')}
                </div>
                <Flex.Item>
                  {emergencyContact.mobile ? (
                    <Flex.Row
                      alignItems="center"
                      childrenGap={theme.spacing.small}
                      className={classes.phoneNumberRow}
                    >
                      <div>{emergencyContact.mobile}</div>
                      <Button
                        iconProp={['fal', 'phone']}
                        type={'default'}
                        size="small"
                        tooltip={t('profile:actions.call')}
                        href={getCallingHref(
                          phoneLinePreference,
                          undefined,
                          undefined,
                          emergencyContact.mobile?.replace('+', '00')
                        )}
                      />
                    </Flex.Row>
                  ) : (
                    ` ${t('profile:personalData.noData')} `
                  )}
                </Flex.Item>
              </Flex.Column>
            </Flex.Row>
            <Flex.Row>
              <Flex.Column flex={1}>
                <div className={classes.label}>
                  {t('profile:form.emergencyContactRelationship')}
                </div>
                <Flex.Item>
                  {`${
                    emergencyContact.relationship
                      ? emergencyContact.relationship
                      : ` ${t('profile:personalData.noData')} `
                  }`}
                </Flex.Item>
              </Flex.Column>
              <Flex.Column flex={1} overflow={'hidden'}>
                <div className={classes.label}>
                  {t('profile:form.emergencyContactPhone')}
                </div>
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '90%',
                  }}
                >
                  {emergencyContact.phone ? (
                    <Flex.Row
                      alignItems="center"
                      childrenGap={theme.spacing.small}
                      className={classes.phoneNumberRow}
                    >
                      <div>{emergencyContact.phone}</div>
                      <Button
                        iconProp={['fal', 'phone']}
                        type={'default'}
                        size="small"
                        tooltip={t('profile:actions.call')}
                        href={getCallingHref(
                          phoneLinePreference,
                          undefined,
                          undefined,
                          emergencyContact.phone?.replace('+', '00')
                        )}
                      />
                    </Flex.Row>
                  ) : (
                    ` ${t('profile:personalData.noData')} `
                  )}
                </div>
              </Flex.Column>
            </Flex.Row>
            {index < emergencyContactList.length - 1 && <Divider></Divider>}
          </Flex.Column>
        ))
      )}
    </Flex.Column>
  );
};

export default ProfilePersonnelEmergencyContactDataForm;
