import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import {
  Button,
  Dropdown,
  useTheme,
  VirtualTable,
} from '@prio365/prio365-react-library';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import {
  apiDeleteContactImportProcess,
  apiFetchMyImportProcesses,
} from '../../api';
import { ContactImportProcess } from '../../../../models/ContactImportProcess';
import { DefaultDateTimeFormatString } from '../../../../util';
import Flex from '../../../../components/Flex';
import { useNavigate } from 'react-router-dom';
import ActionBar from '../../../../components/ActionBar';
import { PrioTheme } from '../../../../theme/types';
import NewImportDrawer from './NewImportDrawer';

const useStyles = makePrioStyles((theme) => ({
  root: { height: '100%' },
}));

interface ContactImporterProps {
  className?: string;
}

export const ContactImporter: React.FC<ContactImporterProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const navigate = useNavigate(); // hook to navigate
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [importProcesses, setImportProcesses] = useState<
    ContactImportProcess[]
  >([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false); // Drawer state
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const handleEditClick = useCallback(
    (entry: ContactImportProcess) => {
      // Navigate to the edit page for the selected contact import process
      navigate(`edit/${entry.contactImportProcessId}`);
    },
    [navigate]
  );

  const handleNewClick = useCallback(() => {
    setIsDrawerVisible(true);
  }, []);

  const closeDrawer = async () => {
    setIsDrawerVisible(false);
    try {
      const { data } = await apiFetchMyImportProcesses();
      if (data) {
        // sort data after createdAt
        data.sort((a, b) => {
          if (a.createdAt > b.createdAt) return -1;
          if (a.createdAt < b.createdAt) return 1;
          return 0;
        });
        setImportProcesses(data);
      }
    } catch {}
  };

  const handleDeleteClick = useCallback(async (entry: ContactImportProcess) => {
    // Delete the selected contact import process
    try {
      await apiDeleteContactImportProcess(entry.contactImportProcessId);
      // Update the list of import processes
      setImportProcesses((prev) =>
        prev.filter(
          (process) =>
            process.contactImportProcessId !== entry.contactImportProcessId
        )
      );
    } catch (error) {
      console.error('Error deleting contact import process:', error);
    }
  }, []);

  const menu = useCallback(
    (entry: ContactImportProcess) => (
      <Menu>
        <Menu.Item
          onClick={async (e) => {
            e.domEvent.stopPropagation();
            handleEditClick(entry);
          }}
        >
          {t('settings:contactImportProcess.menu.edit')}
        </Menu.Item>
        <Menu.Item
          onClick={async (e) => {
            handleDeleteClick(entry);
          }}
        >
          {t('settings:contactImportProcess.menu.delete')}
        </Menu.Item>
      </Menu>
    ),
    [t, handleEditClick, handleDeleteClick]
  );
  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    const loadImportProcesses = async () => {
      try {
        const { data } = await apiFetchMyImportProcesses();
        if (data) {
          // sort data after createdAt
          data.sort((a, b) => {
            if (a.createdAt > b.createdAt) return -1;
            if (a.createdAt < b.createdAt) return 1;
            return 0;
          });
          setImportProcesses(data);
        }
      } catch {}
    };
    loadImportProcesses();
  }, []);

  //#endregion

  const relativeColumns: Column<ContactImportProcess>[] = [
    {
      id: 'date',
      alignSelf: true,
      width: 30,
      title: t('settings:contactImportProcess.table.date'),
      accessor: 'createdAt',
      Cell: (cellProps) => DefaultDateTimeFormatString(cellProps.value),
    },
    {
      id: 'externalCompanyImportsCount',
      alignSelf: true,
      width: 20,
      title: t(
        'settings:contactImportProcess.table.externalCompanyImportsCount'
      ),
      accessor: 'externalCompanyImportsCount',
      Cell: (cellProps) => cellProps.value,
      sortingFn: (a, b) =>
        a.externalCompanyImportsCount - b.externalCompanyImportsCount,
    },
    {
      id: 'externalContactImportsCount',
      alignSelf: true,
      width: 20,
      title: t(
        'settings:contactImportProcess.table.externalContactImportsCount'
      ),
      accessor: 'externalContactImportsCount',
      Cell: (cellProps) => cellProps.value,
      sortingFn: (a, b) =>
        a.externalContactImportsCount - b.externalContactImportsCount,
    },
    {
      id: 'state',
      alignSelf: true,
      width: 20,
      title: t('settings:contactImportProcess.table.state'),
      accessor: 'state',
      Cell: (cellProps) =>
        t(`settings:contactImportProcess.state.${cellProps.value}`),
      sortingFn: (a, b) => a.state - b.state,
    },
    {
      id: 'menu',
      width: 10,
      alignSelf: true,
      accessor: 'contactImportProcessId',
      title: '',
      Cell: ({ originalData }) => (
        <Flex.Row justifyContent="center">
          <Dropdown
            overlay={menu(originalData)}
            trigger={['click']}
            placement="bottomRight"
          >
            <Button
              iconProp={['fal', 'ellipsis-v']}
              // className={classes.menuButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              type="default"
            />
          </Dropdown>
        </Flex.Row>
      ),
    },
  ];

  return (
    <Flex.Column flex={1} className={classes.root}>
      <Flex.Row childrenGap={theme.spacing.regular} marginBottom={16}>
        <ActionBar>
          <Button
            key="newButtonImport"
            iconProp={['fal', 'add']}
            onClick={handleNewClick}
            type="primary"
          >
            {t('settings:companyImport.newButton')}
          </Button>
        </ActionBar>
      </Flex.Row>
      <Flex.Row className={classes.root}>
        <VirtualTable<ContactImportProcess>
          columns={relativeColumns}
          id="contactImporterProcesses-table"
          resizable="relative"
          rowsAreSelectable={true}
          data={importProcesses}
          checkBoxContainerWidth={50}
        />
      </Flex.Row>
      <NewImportDrawer visible={isDrawerVisible} onClose={closeDrawer} />
    </Flex.Column>
  );
};

export default ContactImporter;
