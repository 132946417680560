import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Drawer, Form, notification, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { MessageAttachmentDrawerItem } from './MessageAttachmentDrawerItem';
import DriveItemBreadcrumb from '../../documents/components/DriveItemBreadcrumb/DriveItemBreadcrumb';
import {
  MessageAttachment,
  SaveAttachmentsToCloud,
} from '../../../models/Message';
import { DriveItem } from '../../../models/Drive';
import {
  ConfigurationKeys,
  DriveItemId,
  MessageId,
  ProjectId,
} from '../../../models/Types';
import { downloadAttachment } from './EmailComposer/EmailComposer';
import { apiSaveAttachmentsToCloud, apiUploadAttachments } from '../api';
import FolderSelectionDrawer from '../../documents/components/Drawers/FolderSelectionDrawer';
import { Configuration } from '../../../models/Configuration';
import { apiFetchConfigurations } from '../../settings/api';
import { apiFetchDocumentPrefix } from '../../documents/api';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  abortDriveItemFetch,
  DriveItemsFetchContext,
  fetchDriveItemsSagaAction,
} from '../../documents/actions';
import { urltoFile } from '../../../util';
import {
  getCurrentFolderItem,
  getProject,
  RootReducerState,
  getAllProjects,
  getDocumentsWidgetActiveProjectId,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    zIndex: 999,
    '& .ant-drawer-body': {
      overflow: 'hidden',
    },
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  list: {
    width: '100%',
    overflow: 'auto',
  },
  buttonRow: {
    marginTop: `${theme.old.spacing.unit(4)}px!important`,
    width: '100%',
  },
  primaryColorIcon: {
    color: `${theme.old.palette.primaryColor}!important`,
  },
  breadcrumb: {
    width: '100%',
  },
}));

interface RenameAttachmentsDrawerProps {
  onClose?: VoidFunction;
  attachmentDrawerOpen: boolean;
  messageAttachments: MessageAttachment[];
  targetFolder: DriveItem;
  messageId: MessageId;
  projectId: ProjectId;
}

export const RenameAttachmentsDrawer: React.FC<RenameAttachmentsDrawerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    attachmentDrawerOpen,
    messageAttachments: initialMessageAttachments,
    targetFolder: initialTargetFolder,
    messageId,
    projectId,
    onClose,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const documentsWidgetActiveProjectId = useSelector(
    getDocumentsWidgetActiveProjectId
  );
  const projects = useSelector(getAllProjects);

  const [destinationProjectId, setDestinationProjectId] = useState<ProjectId>(
    projectId !== 'me' ? projectId : documentsWidgetActiveProjectId
  );
  const destinationProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, destinationProjectId)
  );
  const groupId = destinationProject?.groupId;

  const [savingAttachmentsToCloud, setSavingAttachmentsToCloud] =
    useState<boolean>(false);

  const [selectTargetFolderModalVisible, setSelectTargetFolderModalVisible] =
    useState<boolean>(false);

  const [destinationFolderId, setDestinationFolderId] = useState<DriveItemId>(
    initialTargetFolder?.name === 'root' ? null : initialTargetFolder?.id
  );

  const destinationFolder = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, destinationFolderId ?? `root-group-${groupId}`)
  );

  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );

  const useDocumentsPrefix =
    configurations?.find(
      (config) =>
        config.key === ConfigurationKeys.USE_PREFIX_TO_SAVE_ATTACHMENT_TO_CLOUD
    )?.value === 'true';

  const [prefix, setPrefix] = useState<string>(moment().format('YYYY-MM-DD_'));
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    dispatch(
      abortDriveItemFetch(DriveItemsFetchContext.Other, undefined, true)
    );
    form.resetFields();
    if (projectId && projectId !== 'me') {
      setDestinationProjectId(projectId);
    } else if (documentsWidgetActiveProjectId) {
      setDestinationProjectId(documentsWidgetActiveProjectId);
    } else if (projects) {
      setDestinationProjectId(projects[0].projectId);
    }
    setDestinationFolderId(initialTargetFolder?.id);
  };

  const saveAttachmentsLocally = async () => {
    const formData: { [key: string]: string } = form.getFieldsValue(true);
    const messageAttachments: MessageAttachment[] = Object.keys(formData).map(
      (key) => ({
        ...initialMessageAttachments.find(
          (attachment) => attachment.id === key
        ),
        name: formData[key],
      })
    );
    for (const messageAttachment of messageAttachments) {
      await downloadAttachment(projectId, messageId, messageAttachment, t);
    }
    form.resetFields();
    setDestinationProjectId(projectId);
    setDestinationFolderId(initialTargetFolder?.id);
  };

  const saveAttachmentsToCloud = async () => {
    setSavingAttachmentsToCloud(true);
    const formData: { [key: string]: string } = form.getFieldsValue(true);
    const messageAttachments: MessageAttachment[] = Object.keys(formData).map(
      (key) => ({
        ...initialMessageAttachments.find(
          (attachment) => attachment.id === key
        ),
        name: formData[key],
      })
    );

    const driveItemId = destinationFolder.id;
    const isRoot = destinationFolder.name === 'root';
    const saveAttachmentsData: SaveAttachmentsToCloud = {
      targetDriveItemId: driveItemId,
      groupId,
      attachments: messageAttachments
        .filter(
          (attachment) =>
            attachment.contentBytes === '' ||
            attachment.contentBytes === undefined ||
            attachment.contentBytes === null
        )
        .map((attachment) => ({
          messageId,
          attachmentId: attachment.id,
          fileName: attachment?.name,
        })),
    };

    handleOnClose();

    const promises = messageAttachments
      .filter(
        (attachment) =>
          attachment.contentBytes !== '' &&
          attachment.contentBytes !== null &&
          attachment.contentBytes !== undefined
      )
      .map((attachment) =>
        urltoFile(
          `data:${attachment.contentType};base64,${attachment.contentBytes}`,
          attachment.name,
          attachment.contentType
        ).then(async (file) => {
          const { result: uploadResult } = await apiUploadAttachments(
            file,
            destinationProjectId,
            groupId,
            driveItemId
          );
          if (!(uploadResult.status >= 200 && uploadResult.status < 300)) {
            return false;
          }
          return true;
        })
      );

    if (saveAttachmentsData.attachments.length > 0) {
      const { result } = await apiSaveAttachmentsToCloud(
        projectId,
        saveAttachmentsData
      );
      setSavingAttachmentsToCloud(false);
      form.resetFields();
      setDestinationProjectId(projectId);
      setDestinationFolderId(initialTargetFolder?.id);
      if (result.status >= 200 && result.status < 300) {
        dispatch(
          fetchDriveItemsSagaAction(
            projectId,
            groupId,
            isRoot ? null : driveItemId,
            isRoot,
            DriveItemsFetchContext.Other
          )
        );
        return true;
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'mail:messageDisplay.errorMessages.saveAttachmentsToCloud'
          ),
        });
        return false;
      }
    } else {
      const promiseResult = await Promise.all(promises).then((values) => {
        if (values.includes(false)) {
          notification.open({
            message: t('common:error'),
            description: t(
              'mail:messageDisplay.errorMessages.saveAttachmentsToCloud'
            ),
          });
        } else {
          // nothing to do
        }
        return values.includes(true);
      });
      if (promiseResult) {
        setSavingAttachmentsToCloud(false);
        form.resetFields();
        setDestinationProjectId(projectId);
        setDestinationFolderId(initialTargetFolder?.id);
        dispatch(
          fetchDriveItemsSagaAction(
            projectId,
            groupId,
            !isRoot ? driveItemId : null,
            isRoot,
            DriveItemsFetchContext.Other
          )
        );
        return true;
      }
      setSavingAttachmentsToCloud(false);
      return false;
    }
  };

  const openTargetFolderModal = () => {
    setSelectTargetFolderModalVisible(true);
  };

  const handleTargetFolderModalOk = (
    destinationFolder: DriveItem | null,
    destinationProjectId: ProjectId
  ) => {
    setSelectTargetFolderModalVisible(false);
    setDestinationProjectId(destinationProjectId);
    setDestinationFolderId(destinationFolder?.id);
  };
  const handleTargetFolderModalCancel = () => {
    setSelectTargetFolderModalVisible(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const formData: { [key: string]: string } = form.getFieldsValue(true);
    let attachmentsAmountChanged: boolean = false;
    Object.keys(formData).forEach((key) => {
      if (
        initialMessageAttachments.find((attachment) => attachment.id === key)
      ) {
        attachmentsAmountChanged = false;
      } else {
        attachmentsAmountChanged = true;
      }
    });

    if (attachmentsAmountChanged) {
      form.resetFields();
      form.setFieldsValue(
        initialMessageAttachments.reduce((map, current) => {
          map[current.id] = `${useDocumentsPrefix ? prefix : ''}${
            current.name
          }`;
          return map;
        }, {})
      );
    }
  }, [
    initialMessageAttachments,
    form,
    useDocumentsPrefix,
    prefix,
    selectTargetFolderModalVisible,
  ]);

  useEffect(() => {
    setDestinationFolderId(initialTargetFolder?.id);
  }, [initialTargetFolder?.id]);

  useEffect(() => {
    const loadConfigs = async () => {
      const { data } = await apiFetchConfigurations();
      if (data) setConfigurations(data);
    };
    loadConfigs();
  }, []);

  useEffect(() => {
    async function fetchPrefix() {
      const { data } = await apiFetchDocumentPrefix(destinationProjectId);
      if (data) {
        setPrefix(data.value);
      }
    }

    if (useDocumentsPrefix && destinationProjectId) {
      fetchPrefix();
    }
  }, [useDocumentsPrefix, destinationProjectId]);

  useEffect(() => {
    if (attachmentDrawerOpen) {
      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          destinationFolderId,
          !destinationFolderId,
          DriveItemsFetchContext.Other
        )
      );
    }
  }, [attachmentDrawerOpen, destinationFolderId, projectId, groupId, dispatch]);

  useEffect(() => {
    form.resetFields();
  }, [projectId, form]);

  useEffect(() => {
    if (attachmentDrawerOpen) {
      if (projectId && projectId !== 'me') {
        setDestinationProjectId(projectId);
      } else if (documentsWidgetActiveProjectId) {
        setDestinationProjectId(documentsWidgetActiveProjectId);
      }
    }
  }, [documentsWidgetActiveProjectId, projectId, attachmentDrawerOpen]);
  //#endregion

  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={handleOnClose}
      visible={attachmentDrawerOpen}
      width={'600px'}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      className={classes.root}
      destroyOnClose
    >
      <Flex.Column
        alignItems="flex-start"
        childrenGap={theme.old.spacing.unit(2)}
        className={classes.content}
      >
        <Typography.Title>{t('mail:renameDrawer.title')}</Typography.Title>
        <Flex.Column
          alignItems="flex-start"
          childrenGap={theme.old.spacing.unit(2)}
          className={classes.list}
          flex={1}
        >
          {initialMessageAttachments.map((attachment) => (
            <React.Fragment key={attachment.id}>
              <MessageAttachmentDrawerItem
                attachment={attachment}
                prefix={prefix}
                useDocumentsPrefix={useDocumentsPrefix}
                form={form}
              />
              <Divider />
            </React.Fragment>
          ))}
        </Flex.Column>
        <Flex.Row alignItems="center" childrenGap={theme.old.spacing.unit(1)}>
          <FontAwesomeIcon icon={['fal', 'folder-tree']} />
          <span>{t('mail:messageDisplay.saveDestination')}</span>
          <Button
            onClick={openTargetFolderModal}
            iconProp={['fal', 'pen']}
            type="link"
          ></Button>
        </Flex.Row>
        <Flex.Row
          className={classes.breadcrumb}
          alignItems="center"
          childrenGap={theme.old.spacing.unit(1)}
        >
          <DriveItemBreadcrumb
            project={destinationProject}
            driveItem={destinationFolder}
            onItemClick={(id) => {
              if (id.includes('root-group-')) {
                setDestinationFolderId(null);
              } else {
                setDestinationFolderId(id);
              }
            }}
            onRootChange={(projectId) => {
              setDestinationProjectId(projectId);
              setDestinationFolderId(null);
            }}
            disableLinkNavigation
            addProjectList
          />
        </Flex.Row>
        <Flex.Row className={classes.buttonRow}>
          <Flex.Item flex={1}>
            <Button
              type="default"
              onClick={saveAttachmentsLocally}
              disabled={savingAttachmentsToCloud}
            >
              {t('mail:messageDisplay.saveLocally')}
            </Button>
          </Flex.Item>
          <Flex.Item>
            <Button onClick={handleOnClose} type="link">
              {t('common:actions.cancel')}
            </Button>
            <Button
              onClick={saveAttachmentsToCloud}
              type="primary"
              htmlType="submit"
              disabled={savingAttachmentsToCloud}
            >
              {t('common:save')}
            </Button>
          </Flex.Item>
        </Flex.Row>
      </Flex.Column>
      <FolderSelectionDrawer
        visible={selectTargetFolderModalVisible}
        onOk={handleTargetFolderModalOk}
        onCancel={handleTargetFolderModalCancel}
        projectId={destinationProjectId}
        selectedFolder={destinationFolderId}
        allowProjectChange
      />
    </Drawer>
  );
};

export default RenameAttachmentsDrawer;
