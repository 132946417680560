import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Dropdown, Menu } from 'antd';
import {
  Button,
  Modal,
  VirtualTableBodyRef,
} from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import { useTheme } from 'react-jss';
import {
  CompensationPayment,
  CompensationPaymentsCalculatedData,
  CompensationPaymentSearchResultItem,
} from '../../../../models/TimeKeeping';
import moment from 'moment';
import ContactText from '../../../contacts/components/ContactText';
import HREditCompensationPaymentDrawer from './HREditCompensationPaymentDrawer';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrioTheme } from '../../../../theme/types';
import {
  apiDeleteCompensationPayment,
  apiPayCompensationPayment,
} from '../../../timeKeeping/api';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import FilterResultNoItemsScreen from '../../../../components/Filter/FilterResultNoItemsScreen';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import { useQueryClient } from '@tanstack/react-query';
import { OptimisticWriteGenericSearchResultItem } from '../../../../components/Filter/types';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../../apps/main/rootReducer';
import FilterContextVirtualTable from '../../../../components/Filter/FilterContextVirtualTable';
import { sortContactsHelper } from '../../../contacts/utils';
import { generateFilterOptions } from '../../../../util';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    '& svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa': {
      width: '14px',
    },
    '& .cpt1-menu-header': {
      visibility: 'hidden',
    },
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  cellCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  lastCell: {
    padding: '10px 0px 10px 2px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  row: { cursor: 'pointer' },
}));

interface CompensationPaymentModal {
  title: string;
  content: string;
  okActionLabel: string;
  cancelActionLabel: string;
  type: ModalType;
  visible: boolean;
  item?: CompensationPaymentSearchResultItem;
}

const initialModalState: CompensationPaymentModal = {
  title: '',
  content: '',
  okActionLabel: '',
  cancelActionLabel: '',
  type: undefined,
  visible: false,
  item: undefined,
};
export interface HRCompensationPaymentsPageTableRefProps {
  resetActiveFilters: () => void;
}

declare type ModalType =
  | 'deleteSingle'
  | 'deleteBulk'
  | 'payoutSingle'
  | 'payoutBulk';

interface HRCompensationPaymentsTableProps {
  className?: string;
}

export const HRCompensationPaymentsTable = forwardRef(
  (
    props: HRCompensationPaymentsTableProps,
    ref: React.Ref<HRCompensationPaymentsPageTableRefProps>
  ) => {
    //#region ------------------------------ Defaults
    const { className } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme<PrioTheme>();
    const queryClient = useQueryClient();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const innerRef = useRef<VirtualTableBodyRef>(null);

    const { getContactById } = useContactsContext();
    const userMe = useSelector(getUserMe);

    const { data, isLoading, optimisticWrite } = useFilterContext<
      CompensationPayment,
      CompensationPaymentsCalculatedData
    >();

    const compensationPayments: CompensationPaymentSearchResultItem[] =
      useMemo(() => {
        if (data) {
          return data.items;
        }
        return [];
      }, [data]);

    const tableData = compensationPayments.sort((pA, pB) => {
      const employeeIdA = pA.data.employeeId;
      const employeeIdB = pB.data.employeeId;
      const a = getContactById(employeeIdA?.toLowerCase() ?? '');
      const b = getContactById(employeeIdB?.toLowerCase() ?? '');
      const lastNameCompare = a?.lastName?.localeCompare(b?.lastName);
      if (lastNameCompare !== 0) return lastNameCompare;
      return a?.firstName?.localeCompare(b?.firstName);
    });

    const [searchItemToEdit, setCompensationPaymentToEdit] =
      useState<CompensationPaymentSearchResultItem>(null);

    const [selectedSearchItems, setSelectedSearchItems] = useState<
      CompensationPaymentSearchResultItem[]
    >([]);

    const filteredSelectedSearchItems = useMemo(() => {
      return selectedSearchItems.filter((item) => !item.calculated.isApproved);
    }, [selectedSearchItems]);

    const [modalState, setModalState] =
      useState<CompensationPaymentModal>(initialModalState);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleOnRow: (
      item: CompensationPaymentSearchResultItem
    ) => VirtualListItemOnRowProps = useCallback(
      (item) => {
        return {
          onClick: (e) => {
            setCompensationPaymentToEdit(item);
          },
          className: classes.row,
        };
      },
      [classes]
    );

    const onSelectionChange = (
      items: CompensationPaymentSearchResultItem[]
    ) => {
      setSelectedSearchItems(items);
    };

    const handleOnBulkPayOut = async () => {
      const optimisticWrites: OptimisticWriteGenericSearchResultItem<
        CompensationPayment,
        CompensationPaymentsCalculatedData
      >[] = filteredSelectedSearchItems.map((item) => ({
        ...item,
        calculated: {
          ...item.calculated,
          isApproved: true,
          paidById: userMe?.id,
        },
        data: {
          ...item.data,
          payoutDate: moment().toISOString(true),
          paidBy: userMe?.id,
        },
        method: 'update',
        callback: async () => {
          const { result, data } = await apiPayCompensationPayment(
            item.data.compensationPaymentId,
            item.calculated.officeId
          );
          if (!result.ok) {
            return {
              result,
              data: null,
            };
          }
          return {
            result,
            data: {
              data,
              calculated: {
                ...item.calculated,
                isApproved: true,
                paidById: userMe?.id,
              },
            },
          };
        },
      }));

      optimisticWrite(optimisticWrites);
      queryClient.invalidateQueries({
        queryKey: ['compensationPayments'],
        refetchType: 'all',
      });
      setSelectedSearchItems([]);
      setModalState(initialModalState);
    };

    const handleOnBulkDelete = async () => {
      const optimisticWrites: OptimisticWriteGenericSearchResultItem<
        CompensationPayment,
        CompensationPaymentsCalculatedData
      >[] = filteredSelectedSearchItems.map((item) => ({
        ...item,
        method: 'remove',
        callback: async () => {
          const response = await apiDeleteCompensationPayment(
            item.data.compensationPaymentId,
            item.calculated.officeId
          );
          if (!response.result.ok) {
            return {
              result: response.result,
              data: null,
            };
          }
          return {
            result: response.result,
            data: null,
          };
        },
      }));

      optimisticWrite(optimisticWrites);
      queryClient.invalidateQueries({
        queryKey: ['compensationPayments'],
        refetchType: 'all',
      });
      setSelectedSearchItems([]);
      setModalState(initialModalState);
    };

    const onModalCancelAction = () => {
      setCompensationPaymentToEdit(null);
      setModalState(initialModalState);
    };

    const onPayOutModalOkAction = useCallback(
      (item: CompensationPaymentSearchResultItem) => {
        const payCompensationPayment = async (
          item: CompensationPaymentSearchResultItem
        ) => {
          const response = await apiPayCompensationPayment(
            item.data.compensationPaymentId,
            item.calculated.officeId
          );

          if (!response.result.ok) {
            return {
              result: response.result,
              data: null,
            };
          }
          return {
            result: response.result,
            data: {
              data: response.data,
              calculated: {
                ...item.calculated,
                isApproved: true,
                paidById: userMe?.id,
              },
            },
          };
        };

        optimisticWrite([
          {
            data: {
              ...item.data,
              payoutDate: moment().toISOString(true),
              paidBy: userMe?.id,
            },
            calculated: {
              ...item.calculated,
              isApproved: true,
              paidById: userMe?.id,
            },
            callback: async () => await payCompensationPayment(item),
            method: 'update',
          },
        ]);

        queryClient.invalidateQueries({
          queryKey: ['compensationPayments'],
          refetchType: 'all',
        });
        setCompensationPaymentToEdit(null);
        setModalState(initialModalState);
      },

      [userMe?.id, queryClient, optimisticWrite]
    );

    const onDeleteModalOkAction = async (
      item: CompensationPaymentSearchResultItem
    ) => {
      const deleteCompensationPayment = async () => {
        const response = await apiDeleteCompensationPayment(
          item.data.compensationPaymentId,
          item.calculated.officeId
        );
        if (!response.result.ok) {
          return {
            result: response.result,
            data: null,
          };
        }
        return {
          result: response.result,
          data: null,
        };
      };

      optimisticWrite([
        {
          ...item,
          data: item.data,
          method: 'remove',
          callback: deleteCompensationPayment,
        },
      ]);
      queryClient.invalidateQueries({
        queryKey: ['compensationPayments'],
        refetchType: 'all',
      });
      setCompensationPaymentToEdit(null);
      setModalState(initialModalState);
    };

    const onEditDrawerSaveAction = () => {
      setCompensationPaymentToEdit(null);
    };
    //#endregion

    //#region ------------------------------ Components
    const menu = (item: CompensationPaymentSearchResultItem) => {
      const { data: payment } = item;
      const payoutDate = payment.payoutDate;
      const disableActionButton = !(
        payoutDate === null || payoutDate === '0001-01-01T00:00:00'
      );
      return (
        <Menu
          onClick={(e) => {
            e.domEvent.stopPropagation();
          }}
        >
          <Menu.Item
            disabled={disableActionButton}
            onClick={(e) => {
              e.domEvent.preventDefault();
              showPayOutSingleCompensationPaymentModal(item);
            }}
          >
            {t(
              'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.payOut'
            )}
          </Menu.Item>
          <Menu.Item
            disabled={disableActionButton}
            onClick={(e) => {
              e.domEvent.preventDefault();
              setCompensationPaymentToEdit(item);
            }}
          >
            {t(
              'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.edit'
            )}
          </Menu.Item>
          <Menu.Item
            disabled={disableActionButton}
            onClick={(e) => {
              e.domEvent.preventDefault();
              showDeleteSingleCompensationPaymentModal(item);
            }}
          >
            {t(
              'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.delete'
            )}
          </Menu.Item>
        </Menu>
      );
    };
    const getModalOkAction = (
      actionType: ModalType,
      item?: CompensationPaymentSearchResultItem
    ) => {
      switch (actionType) {
        case 'deleteSingle':
          return onDeleteModalOkAction(item);
        case 'deleteBulk':
          return handleOnBulkDelete();
        case 'payoutSingle':
          return onPayOutModalOkAction(item);
        case 'payoutBulk':
          return handleOnBulkPayOut();
      }
    };
    //#endregion

    //#region ------------------------------ Modals
    const showDeleteSingleCompensationPaymentModal = (
      item: CompensationPaymentSearchResultItem
    ) => {
      setModalState({
        title: t(`hr:timeAndLeaveManagement.deleteModalSingle.title`),
        content: t(`hr:timeAndLeaveManagement.deleteModalSingle.content`),
        okActionLabel: t(`hr:timeAndLeaveManagement.deleteModalSingle.okText`),
        cancelActionLabel: t(
          `hr:timeAndLeaveManagement.deleteModalSingle.cancelText`
        ),
        type: 'deleteSingle',
        visible: true,
        item: item,
      });
    };

    const showPayOutSingleCompensationPaymentModal = (
      item: CompensationPaymentSearchResultItem
    ) => {
      setModalState({
        title: t(`hr:timeAndLeaveManagement.payOutModalSingle.title`),
        content: t(`hr:timeAndLeaveManagement.payOutModalSingle.content`),
        okActionLabel: t(`hr:timeAndLeaveManagement.payOutModalSingle.okText`),
        cancelActionLabel: t(
          `hr:timeAndLeaveManagement.payOutModalSingle.cancelText`
        ),
        type: 'payoutSingle',
        visible: true,
        item: item,
      });
    };

    const showPayOutBulkModal = () => {
      const translationSuffix =
        filteredSelectedSearchItems.length > 1 ? 'Multiple' : 'Single';
      setModalState({
        title: t(
          `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.title`
        ),
        content: t(
          `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.content`
        ),
        okActionLabel: t(
          `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.okText`
        ),
        cancelActionLabel: t(
          `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.cancelText`
        ),
        type: 'payoutBulk',
        visible: true,
      });
    };

    const showDeleteBulkModal = () => {
      const translationSuffix =
        filteredSelectedSearchItems.length > 1 ? 'Multiple' : 'Single';
      setModalState({
        title: t(
          `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.title`
        ),
        content: t(
          `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.content`
        ),
        okActionLabel: t(
          `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.okText`
        ),
        cancelActionLabel: t(
          `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.cancelText`
        ),
        type: 'deleteBulk',
        visible: true,
      });
    };

    const getEmployeeLabelById = useCallback(
      (id: string): string => {
        const employee = getContactById(id);
        return employee ? `${employee.firstName} ${employee.lastName}` : '';
      },
      [getContactById]
    );

    const employeeOptions = useMemo(() => {
      return generateFilterOptions<CompensationPaymentSearchResultItem>(
        tableData || [],
        'data.employeeId',
        getEmployeeLabelById
      );
    }, [tableData, getEmployeeLabelById]);

    const paidByOptions = useMemo(() => {
      return generateFilterOptions<CompensationPaymentSearchResultItem>(
        tableData || [],
        'data.paidBy',
        getEmployeeLabelById
      );
    }, [tableData, getEmployeeLabelById]);

    const createdByOptions = useMemo(() => {
      return generateFilterOptions<CompensationPaymentSearchResultItem>(
        tableData || [],
        'data.createdBy',
        getEmployeeLabelById
      );
    }, [tableData, getEmployeeLabelById]);

    const showColumnsFilter = useMemo(() => {
      return tableData?.length > 0;
    }, [tableData]);
    //#endregion

    //#region ------------------------------ Columns
    const columns: Column<CompensationPaymentSearchResultItem>[] = [
      {
        Cell: ({
          originalData: {
            data: { payoutDate },
          },
        }) => {
          return (
            !(payoutDate === null || payoutDate === '0001-01-01T00:00:00') && (
              <div
                title={t(
                  'hr:timeAndLeaveManagement.compensationPaymentsTable.paidOut'
                )}
              >
                <FontAwesomeIcon icon={['fal', 'check']} />
              </div>
            )
          );
        },
        title: '',
        width: 3,
        minWidth: 34,
        id: 'status',
        accessor: 'data.payoutDate',
        className: classNames(classes.cell, classes.cellCenter),
        alignSelf: true,
        sortingFn: (rowA, rowB) => {
          const paymentA = !!rowA.data.payoutDate;
          const paymentB = !!rowB.data.payoutDate;
          if (paymentA === paymentB) return 0;
          if (paymentA) return -1;
          return 1;
        },
      },
      {
        id: 'employeeId',
        width: 18,
        title: t(
          'hr:timeAndLeaveManagement.compensationPaymentsTable.employeeId'
        ),
        accessor: 'data.employeeId',
        filterObject: showColumnsFilter
          ? {
              id: 'employeeId',
              options: employeeOptions,
            }
          : undefined,
        alignSelf: true,
        sortingFn: (rowA, rowB) =>
          sortContactsHelper(
            getContactById(rowA.data.employeeId),
            getContactById(rowB.data.employeeId)
          ),
        Cell: ({
          originalData: {
            data: { employeeId },
          },
        }) => {
          if (!employeeId) {
            return '';
          }
          return <ContactText contactId={employeeId} />;
        },
        className: classes.cell,
      },
      {
        sortingFn: (a, b) => {
          return a.data.hours - b.data.hours;
        },
        Cell: ({
          originalData: {
            data: { hours },
          },
        }) => {
          return (
            <Flex.Row
              width="100%"
              justifyContent="center"
            >{`${hours.toLocaleString()} h`}</Flex.Row>
          );
        },
        title: t('hr:timeAndLeaveManagement.compensationPaymentsTable.hours'),
        width: 10,
        id: 'hours',
        accessor: 'data.hours',
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) => {
          return moment(a.data.expectedPayoutDate).diff(
            moment(b.data.expectedPayoutDate)
          );
        },
        Cell: ({
          originalData: {
            data: { expectedPayoutDate },
          },
        }) => {
          if (
            expectedPayoutDate &&
            !expectedPayoutDate.includes('0001-01-01T00:00:00')
          ) {
            return (
              <Flex.Row width="100%" justifyContent="center">
                {moment(expectedPayoutDate).format('DD.MM.YYYY')}
              </Flex.Row>
            );
          }
          return (
            <Flex.Row width="100%" justifyContent="center">
              -
            </Flex.Row>
          );
        },
        title: t(
          'hr:timeAndLeaveManagement.compensationPaymentsTable.expectedPayoutDate'
        ),
        width: 11,
        id: 'expectedPayoutDate',
        accessor: 'data.expectedPayoutDate',
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) => {
          const dateA = a.data.payoutDate ?? '0001-01-01T00:00:00';
          const dateB = b.data.payoutDate ?? '0001-01-01T00:00:00';
          return moment(dateA).diff(moment(dateB));
        },
        Cell: ({
          originalData: {
            data: { payoutDate },
          },
        }) => {
          if (payoutDate && !payoutDate.includes('0001-01-01T00:00:00')) {
            return (
              <Flex.Row width="100%" justifyContent="center">
                {moment(payoutDate).format('DD.MM.YYYY')}
              </Flex.Row>
            );
          }
          return (
            <Flex.Row width="100%" justifyContent="center">
              -
            </Flex.Row>
          );
        },
        title: t(
          'hr:timeAndLeaveManagement.compensationPaymentsTable.payoutDate'
        ),
        width: 10,
        id: 'payoutDate',
        accessor: 'data.payoutDate',
        className: classes.cell,
        alignSelf: true,
      },
      {
        Cell: ({
          originalData: {
            data: { paidBy },
          },
        }) => {
          return paidBy ? (
            <ContactText contactId={paidBy} />
          ) : (
            <div style={{ marginLeft: theme.spacing.large }}>-</div>
          );
        },
        title: t('hr:timeAndLeaveManagement.compensationPaymentsTable.paidBy'),
        width: 16,
        id: 'paidBy',
        accessor: 'data.paidBy',
        className: classes.cell,
        alignSelf: true,
        filterObject: showColumnsFilter
          ? {
              id: 'paidBy',
              options: paidByOptions,
            }
          : undefined,
        sortingFn: (rowA, rowB) =>
          sortContactsHelper(
            getContactById(rowA.data.paidBy),
            getContactById(rowB.data.paidBy)
          ),
      },
      {
        sortingFn: (a, b) => {
          return moment(a.data.createdDate).diff(moment(b.data.createdDate));
        },
        Cell: ({
          originalData: {
            data: { createdDate },
          },
        }) => {
          return (
            <Flex.Row width="100%" justifyContent="center">
              {moment(createdDate).format('DD.MM.YYYY')}
            </Flex.Row>
          );
        },
        title: t(
          'hr:timeAndLeaveManagement.compensationPaymentsTable.createdDate'
        ),
        width: 10,
        id: 'createdDate',
        accessor: 'data.createdDate',
        className: classes.cell,
        alignSelf: true,
      },
      {
        Cell: ({
          originalData: {
            data: { createdBy },
          },
        }) => {
          return (
            <Flex.Row
              alignItems="center"
              childrenGap={theme.old.spacing.defaultPadding}
            >
              <Flex.Item flex={1}>
                <ContactText contactId={createdBy} />
              </Flex.Item>
            </Flex.Row>
          );
        },
        title: t(
          'hr:timeAndLeaveManagement.compensationPaymentsTable.createdBy'
        ),
        width: 16,
        alignSelf: true,
        id: 'createdBy',
        accessor: 'data.createdBy',
        filterObject: showColumnsFilter
          ? {
              id: 'createdBy',
              options: createdByOptions,
            }
          : undefined,
        className: classes.cell,
        sortingFn: (rowA, rowB) =>
          sortContactsHelper(
            getContactById(rowA.data.createdBy),
            getContactById(rowB.data.createdBy)
          ),
      },
      {
        Cell: ({ originalData }) => {
          return (
            <Dropdown
              overlay={menu(originalData)}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                iconProp={['fal', 'ellipsis-v']}
                type="default"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </Dropdown>
          );
        },
        title: '',
        width: 6,
        minWidth: 50,
        id: 'menu',
        accessor: 'data.monthlyCloseId',
        className: classes.cell,
        alignSelf: true,
      },
    ];
    //#endregion

    //#region ------------------------------ Effects
    useImperativeHandle(ref, () => ({
      resetActiveFilters: () => {
        if (innerRef.current) {
          innerRef.current.setActiveFilters({});
        }
      },
    }));
    //#endregion

    return (
      <div className={classNames(classes.root, className)}>
        <FilterContextVirtualTable<CompensationPaymentSearchResultItem>
          id={'cpt1'}
          ref={innerRef}
          className={classNames(classes.root, className)}
          columns={columns}
          data={tableData}
          selectedItems={selectedSearchItems}
          resizable="relative"
          onRow={handleOnRow}
          classNameTableRow={classes.row}
          onSelectionChange={onSelectionChange}
          checkBoxContainerWidth={50}
          onCheckEquality={(a, b) =>
            a.data.compensationPaymentId === b.data.compensationPaymentId
          }
          noItemsScreen={<FilterResultNoItemsScreen />}
          loading={
            isLoading && {
              type: 'noItems',
            }
          }
          rowsAreSelectable
          actionBarButtons={[
            {
              children: t(
                'hr:timeAndLeaveManagement.navigationBar.compensationPayments.payCompensationPayment'
              ),
              iconProp: ['fal', 'check'],
              onClick: () => {
                filteredSelectedSearchItems.length === 1
                  ? showPayOutSingleCompensationPaymentModal(
                      filteredSelectedSearchItems[0]
                    )
                  : showPayOutBulkModal();
              },
              disabled: filteredSelectedSearchItems?.length === 0,
            },
            {
              children: t('common:actions.delete'),
              iconProp: ['fal', 'trash'],
              onClick: () => {
                filteredSelectedSearchItems.length === 1
                  ? showDeleteSingleCompensationPaymentModal(
                      filteredSelectedSearchItems[0]
                    )
                  : showDeleteBulkModal();
              },
              disabled: filteredSelectedSearchItems?.length === 0,
            },
          ]}
        />
        <HREditCompensationPaymentDrawer
          drawerOpen={!!searchItemToEdit}
          searchItem={searchItemToEdit}
          onSave={onEditDrawerSaveAction}
          onCancel={onModalCancelAction}
          onPayOut={showPayOutSingleCompensationPaymentModal}
          onDelete={showDeleteSingleCompensationPaymentModal}
        />
        <Modal
          title={modalState.title}
          visible={modalState.visible}
          onOk={() => getModalOkAction(modalState.type, modalState.item)}
          onClose={() => setModalState(initialModalState)}
          okText={modalState.okActionLabel}
          cancelText={modalState.cancelActionLabel}
        >
          {modalState.content}
        </Modal>
      </div>
    );
  }
);

export default HRCompensationPaymentsTable;
