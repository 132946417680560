import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { Typography } from 'antd';
import { BarChart, Button } from '@prio365/prio365-react-library';
import PrioSpinner from '../../../../components/PrioSpinner';
import { useTheme } from 'theming';
import { PrioTheme } from '../../../../theme/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getMonthlyCloseMeByMonth,
} from '../../../../apps/main/rootReducer';
import moment from 'moment';
import { debounceAbsencesMe } from '../../../absences/actions';
import { MonthlyClose } from '../../../../models/TimeKeeping';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hexToHsv, hsvToHex } from '../util/color';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import { rounded } from '../util';
import StatBox from '../../../../components/StatBox';
import { useQuery } from '@tanstack/react-query';
import { apiGetFutureOvertimeChange } from '../../../hr/api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',

    '&:react-grid-item': {
      zIndex: 3000,
    },
  },
  table: {
    marginTop: theme.old.spacing.unit(3),
    flex: 1,
    overflow: 'visible',
    '& .ant-table': {
      fontSize: theme.old.typography.fontSize.small,
    },
    '& .ant-table-tbody > tr > td': {
      padding: theme.old.spacing.baseSpacing,
    },
  },
  overtimePanel: {
    backgroundColor: theme.old.palette.backgroundPalette.active.content,
    boxShadow: theme.old.palette.boxShadow.regular,
    padding: '3px 6px',
    margin: '0px 6px 3px 6px',
    height: '58px',
    '& .ant-form-item-label': {
      padding: '0px',
    },
  },
  content: {
    height: '100%',
    justifyContent: 'space-evenly',
  },
  chart: {
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    border: `1px solid ${theme.colors.application.border}`,
    borderRadius: theme.borderRadius.regular,
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.regular,
  },
  boxDivider: {
    borderLeft: `1px solid ${theme.colors.application.border}`,
    height: '100%',
  },
  infoFooter: {
    fontSize: theme.font.fontSize.regular,
    color: theme.colors.application.typography.default,
    fontWeight: theme.font.fontWeight.bold,
    marginTop: theme.spacing.extraSmall,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.small,
  },
  infoFooterText: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.default,
  },
  chartTitle: {
    marginBottom: theme.spacing.regular * -1,
    marginTop: theme.spacing.regular,
    fontWeight: theme.font.fontWeight.bold,
  },
}));

const parseHours = (hours: number) => {
  return parseFloat(hours.toFixed(2)).toLocaleString();
};

interface FutureOvertimeChangesHours {
  overtimeCompensations: number;
  compensationPayments: number;
}

interface DashboardOvertimeItemProps {
  className?: string;
}

export const DashboardOvertimeItem: React.FC<DashboardOvertimeItemProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { data: futureOvertimeChanges } = useQuery({
    queryKey: ['myFutureOvertimeChanges'],
    queryFn: () =>
      apiGetFutureOvertimeChange(
        encodeURIComponent(moment().utc().toISOString()),
        encodeURIComponent(moment().add(10, 'year').utc().toISOString())
      ),
  });

  const futureOvertimeChangesHours: FutureOvertimeChangesHours = useMemo(() => {
    return {
      overtimeCompensations:
        futureOvertimeChanges?.data?.overtimeCompensations.reduce(
          (acc, curr) => acc + curr.hours,
          0
        ),
      compensationPayments:
        futureOvertimeChanges?.data?.compensationPayments.reduce(
          (acc, curr) => acc + curr.hours,
          0
        ),
    };
  }, [futureOvertimeChanges?.data]);

  const futureOvertimeChangesHoursSum: number = useMemo(() => {
    return (
      Object.values(futureOvertimeChangesHours).reduce(
        (acc, curr) => acc + curr,
        0
      ) || 0
    );
  }, [futureOvertimeChangesHours]);

  const { data: futureOvertimeChangesThisMonth } = useQuery({
    queryKey: ['myFutureOvertimeChangesThisMonth'],
    queryFn: () =>
      apiGetFutureOvertimeChange(
        encodeURIComponent(moment().utc().toISOString()),
        encodeURIComponent(moment().endOf('month').utc().toISOString())
      ),
  });

  const futureOvertimeChangesHoursThisMonth: FutureOvertimeChangesHours =
    useMemo(() => {
      return {
        overtimeCompensations:
          futureOvertimeChangesThisMonth?.data?.overtimeCompensations.reduce(
            (acc, curr) => acc + curr.hours,
            0
          ),
        compensationPayments:
          futureOvertimeChangesThisMonth?.data?.compensationPayments.reduce(
            (acc, curr) => acc + curr.hours,
            0
          ),
      };
    }, [futureOvertimeChangesThisMonth?.data]);

  const futureOvertimeChangesHoursThisMonthSum: number = useMemo(() => {
    return (
      Object.values(futureOvertimeChangesHoursThisMonth).reduce(
        (acc, curr) => acc + curr,
        0
      ) || 0
    );
  }, [futureOvertimeChangesHoursThisMonth]);

  const monthlyCloseMeThisMonth = useSelector<RootReducerState, MonthlyClose>(
    (state) => getMonthlyCloseMeByMonth(state, moment().format('YYYY-MM'))
  );

  const loading = useMemo(() => {
    return !monthlyCloseMeThisMonth;
  }, [monthlyCloseMeThisMonth]);

  const {
    currentOvertimeHours,
    currentMonthOvertimeChange,
    hoursWorkedThisMonth,
    hoursToDateExpectedThisMonth,
  } = useMemo(() => {
    return {
      hoursThisMonth: monthlyCloseMeThisMonth?.expectedWorkHours ?? 0,
      hoursToDateExpectedThisMonth:
        monthlyCloseMeThisMonth?.expectedWorkHoursToDate ?? 0,
      hoursWorkedThisMonth: monthlyCloseMeThisMonth?.actualWorkHours ?? 0,
      currentOvertimeHours:
        (monthlyCloseMeThisMonth?.accumulatedOvertimeHours ?? 0) +
        futureOvertimeChangesHoursSum,
      currentMonthOvertimeChange:
        (monthlyCloseMeThisMonth?.overtimeHoursChange ?? 0) +
        futureOvertimeChangesHoursThisMonthSum,
    };
  }, [
    monthlyCloseMeThisMonth,
    futureOvertimeChangesHoursSum,
    futureOvertimeChangesHoursThisMonthSum,
  ]);

  const { workedBars, workedDividers, hasWorkedTillYesterday } = useMemo(() => {
    const workedBars = [];
    const workedDividers = [];

    const { hoursPerDay } = monthlyCloseMeThisMonth ?? {};

    const tillYesterday = hoursToDateExpectedThisMonth - hoursPerDay;

    const hasWorkedTillYesterday = hoursWorkedThisMonth >= tillYesterday;
    const barColor = hasWorkedTillYesterday
      ? theme.colors.base.green.default
      : theme.colors.application.typography.default;

    const hoursBehind = hoursToDateExpectedThisMonth - hoursWorkedThisMonth;
    const hoursBehindTillYesterday = tillYesterday - hoursWorkedThisMonth;

    const maxChartValue = Math.max(
      hoursToDateExpectedThisMonth,
      hoursWorkedThisMonth
    );
    const MIN_DISTANCE_DIVIDER = 9; // in % // durch trial and error bin ich zu der Zahl gekommen

    const minDistance = (MIN_DISTANCE_DIVIDER / 100) * maxChartValue; // 9% of the max value or MIN_DISTANCE_DIVIDER% of the max value

    const hideToDateExpectedLabel =
      hoursToDateExpectedThisMonth - tillYesterday < minDistance; // if distance between dividers is greater than the minimum distance they have to hold

    // 90 - 84 = 6

    workedBars.push({
      fraction: Math.max(hoursWorkedThisMonth, 1), // this is so the bar is always visible
      color: barColor,
      title: t('dashboard:overtime.workHours'),
      content: (
        <div>
          <span style={{ color: barColor }}>
            {rounded(hoursWorkedThisMonth)}
          </span>
          <span>/{rounded(hoursToDateExpectedThisMonth)} h</span>
          <br />
          <p
            style={{
              fontSize: theme.font.fontSize.extraSmall,
              marginBottom: -2,
            }}
          >
            {hoursBehind < 0
              ? `${t('dashboard:overtime.hoursBehind.hoursAhead')}: ${rounded(
                  Math.abs(hoursBehind)
                )}`
              : hasWorkedTillYesterday
              ? t('dashboard:overtime.hoursBehind.onTrack')
              : `${t('dashboard:overtime.hoursBehind.hoursBehind')}: ${rounded(
                  hoursBehindTillYesterday
                )}`}
          </p>
        </div>
      ),
    });

    workedDividers.push({
      atFraction: hoursToDateExpectedThisMonth,
      color: '#000000',
      text: !hideToDateExpectedLabel
        ? `${rounded(hoursToDateExpectedThisMonth)} h`
        : undefined,
      textPosition:
        hoursToDateExpectedThisMonth > hoursPerDay / 2 ? 'left' : 'right',
    });

    if (tillYesterday > 0)
      workedDividers.push({
        atFraction: tillYesterday,
        color: '#000000',
        text: `${t('dashboard:overtime.yesterday')} (${rounded(
          tillYesterday
        )} h)`,

        textPosition: 'left',
      });

    return {
      workedBars,
      workedDividers,
      hasWorkedTillYesterday,
    };
  }, [
    hoursWorkedThisMonth,
    hoursToDateExpectedThisMonth,
    theme,
    monthlyCloseMeThisMonth,
    t,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { workedOnDayBars } = useMemo(() => {
    // 🚨 Do not remove, for later use
    const { timeKeepingDays = [] } = monthlyCloseMeThisMonth ?? {};
    const COLOR = theme.colors.base.primary.default;

    const workedOnDayBars = [];
    if (timeKeepingDays.length) {
      const days = [];

      for (let i = 0; i < timeKeepingDays.length; i++) {
        const { timeKeepingEntries = [] } = timeKeepingDays[i];
        const day = {
          date: moment(),
          workedHours: 0,
        };

        for (let j = 0; j < timeKeepingEntries.length; j++) {
          const { startTime, endTime } = timeKeepingEntries[j];
          const workedHours = moment(endTime).diff(startTime, 'hours', true);
          day.workedHours += workedHours;
          day.date = moment(startTime);
        }
        days.push(day);
      }

      const percentPerDay = 100 / days.length;

      const hsv = hexToHsv(COLOR);

      for (let i = 0; i < days.length; i++) {
        const { date, workedHours } = days[i];

        const cap = 80;
        const capOpposite = 100 - cap;

        const cappedPercentage = Math.floor(
          calculatePercentage(cap, (i + 1) * percentPerDay)
        );
        const newSPercent = cappedPercentage + capOpposite;
        const hex = hsvToHex(hsv.h, newSPercent, hsv.v);

        workedOnDayBars.push({
          fraction: workedHours,
          color: hex,
          title: date.format('DD.MM'),
          // hideEndGap: i < days.length - 1,
          content: (
            <div>
              <span style={{ color: COLOR }}>{rounded(workedHours)}</span>
              <span>h</span>
            </div>
          ),
        });
      }

      // now every bar should have a lighter color blue depending on how far it is in the past
    } else {
      workedOnDayBars.push({
        fraction: 1,
        color: COLOR,
        title: 'No Data',
        content: (
          <div>
            <span
              style={{ color: theme.colors.application.typography.default }}
            >
              0
            </span>
            <span>h</span>
          </div>
        ),
      });
    }

    return { workedOnDayBars };
  }, [theme, monthlyCloseMeThisMonth]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(debounceAbsencesMe());
  }, [dispatch]);
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row childrenGap={theme.old.spacing.baseSpacing} marginBottom={8}>
        <Flex.Item flex={1}>
          <Typography.Title level={3} ellipsis style={{ marginBottom: 0 }}>
            {t('dashboard:overtime.title')}
          </Typography.Title>
        </Flex.Item>
        <Link to={'../timeAndLeaveManagement/summary'}>
          <Button
            iconProp={['fal', 'arrow-right-to-bracket']}
            type="default"
            style={{ fontSize: 16 }}
          />
        </Link>
      </Flex.Row>
      {loading ? (
        <PrioSpinner alignSelf size="large" />
      ) : (
        <Flex.Column className={classNames(classes.content)}>
          <Flex.Row width="100%" childrenGap={theme.spacing.regular}>
            <Flex.Item flex={1}>
              <StatBox
                title={t('dashboard:overtime.overtimeTotal')}
                value={`${rounded(currentOvertimeHours)} h`}
                valueInParanthesis={
                  futureOvertimeChangesHoursSum > 0
                    ? `-${parseHours(futureOvertimeChangesHoursSum)} h`
                    : undefined
                }
                infoIconContent={
                  futureOvertimeChangesHoursSum > 0 ? (
                    <CustomOverlay
                      futureOvertimeChangesHours={futureOvertimeChangesHours}
                    />
                  ) : undefined
                }
              />
            </Flex.Item>
            <Flex.Item flex={1}>
              <StatBox
                title={t('dashboard:overtime.overtimeChangeCurrentMonth', {
                  month: moment().format('MMMM'),
                })}
                value={`${rounded(currentMonthOvertimeChange)} h`}
                valueInParanthesis={
                  futureOvertimeChangesHoursThisMonthSum > 0
                    ? `-${parseHours(futureOvertimeChangesHoursThisMonthSum)} h`
                    : undefined
                }
                infoIconContent={
                  futureOvertimeChangesHoursThisMonthSum > 0 ? (
                    <CustomOverlay
                      futureOvertimeChangesHours={
                        futureOvertimeChangesHoursThisMonth
                      }
                    />
                  ) : undefined
                }
              />
            </Flex.Item>
          </Flex.Row>
          <div className={classNames(classes.chartTitle)}>
            {t('dashboard:overtime.hoursThisMonth')}
          </div>
          <div>
            {workedBars?.[0]?.fraction !== 0 && (
              <div style={{ fontSize: theme.font.fontSize.regular }}>
                <BarChart
                  hideFractionNumbers={hoursWorkedThisMonth <= 0}
                  bars={workedBars}
                  dividers={workedDividers}
                />
              </div>
            )}
            {/* {workedOnDayBars?.[0]?.fraction !== 0 && ( // 🚨 Do not remove, for later use
              <div
                style={{
                  fontSize: theme.font.fontSize.regular,
                  marginTop: '-24px',
                }}
              >
                <BarChart
                  hideFractionNumbers
                  bars={workedOnDayBars}
                  maxFraction={workedDividers?.[0]?.atFraction || undefined}
                  dividers={workedDividers.map((d) => ({
                    ...d,
                    text: undefined,
                  }))}
                />
              </div>
            )} */}
            <div className={classNames(classes.infoFooter)}>
              {hasWorkedTillYesterday ? (
                <FontAwesomeIcon
                  color={workedBars?.[0]?.color}
                  icon={[
                    'fal',
                    t('dashboard:overtime.hoursBehind.onTrackIcon') as IconName,
                  ]}
                />
              ) : (
                <FontAwesomeIcon
                  color={workedBars?.[0]?.color}
                  flip={
                    t('dashboard:overtime.hoursBehind.behindIcon') === 'swimmer'
                      ? 'horizontal'
                      : null
                  }
                  icon={[
                    'fal',
                    t('dashboard:overtime.hoursBehind.behindIcon') as IconName,
                  ]}
                />
              )}
              <div className={classNames(classes.infoFooterText)}>
                {hasWorkedTillYesterday
                  ? t('dashboard:overtime.hoursBehind.onTrack')
                  : t('dashboard:overtime.hoursBehind.behind')}
              </div>
            </div>
          </div>
        </Flex.Column>
      )}
    </Flex.Column>
  );
};

export default DashboardOvertimeItem;

function calculatePercentage(number: number, percentage: number) {
  return (number * percentage) / 100;
}

interface CustomOverlayProps {
  futureOvertimeChangesHours: FutureOvertimeChangesHours;
}

export const CustomOverlay: React.FC<CustomOverlayProps> = (props) => {
  //#region ------------------------------ Defaults
  const { futureOvertimeChangesHours } = props;
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  return (
    <Flex.Column>
      <div>{t('dashboard:overtime.popover.info')}</div>
      {futureOvertimeChangesHours['overtimeCompensations'] > 0 && (
        <Flex.Row paddingTop={theme.spacing.regular}>
          <Flex.Item flex={1}>{`${parseHours(
            futureOvertimeChangesHours['overtimeCompensations']
          )} h`}</Flex.Item>
          <Flex.Item flex={3}>
            {t('dashboard:overtime.popover.overtimeCompensations')}
          </Flex.Item>
        </Flex.Row>
      )}
      {futureOvertimeChangesHours['compensationPayments'] > 0 && (
        <Flex.Row>
          <Flex.Item flex={1}>{`${parseHours(
            futureOvertimeChangesHours['compensationPayments']
          )} h`}</Flex.Item>
          <Flex.Item flex={3}>
            {t('dashboard:overtime.popover.compensationPayments')}
          </Flex.Item>
        </Flex.Row>
      )}
    </Flex.Column>
  );
};
