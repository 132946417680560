import React, { MutableRefObject, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { Button, Tooltip } from '@prio365/prio365-react-library';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoriteProjectsWithMail } from '../../../projects/selectors';
import { ProjectId } from '../../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useClickPreventionOnDoubleClick from '../../../../hooks/useClickPreventionOnDoubleClick';
import { setMailListNavigationState } from '../../actions/actionControllers/mailNavigationActionController';
import { setActiveProjectInMessageCenter } from '../../actions/favorites/meta';
import { useDrop } from 'react-dnd';
import { DND_TYPE_EMAIL } from '../../../../dnd/types';
import { Project } from '../../../../models/Project';
import { Message } from '../../../../models/Message';
import {
  getMailFolder,
  getMailFoldersByIdState,
  getMailFoldersIsFetching,
  getProjectsSpecialMailFoldersState,
  getSpecialMailFolders,
  getUserMe,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import Flex from '../../../../components/Flex';
import { createSelector } from 'reselect';
import {
  MailFolderByMailFolderId,
  SpecialMailFolders,
} from '../../actions/types';
import { SpecialMailFolderByProjectId } from '../../reducers/projects/specialMailFolder';
import { setCurrentMailSearch } from '../../actions/actionControllers/searchActionController';
import { MailFolder } from '../../../../models/MailFolder';
import PrioSpinner from '../../../../components/PrioSpinner';
import { makePrioStyles } from '../../../../theme/utils';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import { MailSearchFormModel } from '../../../../models/MailSearch';
import { UltimateFilterSearchRef } from '../../../../components/UltimateFilter/createUltimateFilter';
import { MailFilterContext } from '../MailSearch/MailFilterContext';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    borderRight: 0,
    '& .ant-menu-title-content': {
      fontSize: theme.old.components.mailFolderItem.fontSize,
    },
    '& .ant-btn:not(.ant-btn-primary) > .svg-inline--fa': {
      fontSize: theme.old.components.mailFolderItem.fontSize,
    },
    '&.ant-menu-inline.ant-menu-root .ant-menu-item > *': {
      fontSize: theme.old.components.mailFolderItem.fontSize,
    },
    '& .ant-menu-submenu > .ant-menu-submenu-title': {
      height: theme.old.components.mailFolderItem.itemHeight,
      lineHeight: `${theme.old.components.mailFolderItem.itemHeight}px`,
    },
    '& .ant-menu-item': {
      height: theme.old.components.mailFolderItem.itemHeight,
      lineHeight: `${theme.old.components.mailFolderItem.itemHeight}px`,
    },
  },
  subMenu: {
    '& .ant-menu-submenu-title': {
      paddingLeft: `${theme.old.spacing.unit(2)}px!important`,
      paddingRight: theme.old.spacing.unit(2),
    },
    '& .ant-menu-submenu-title:hover': {
      background: theme.old.palette.backgroundPalette.hover.sub,
    },
    '& .ant-menu-submenu-arrow': {
      display: 'none',
    },
  },
  subMenuSelected: {
    '& .ant-menu-submenu-title': {
      color: theme.old.palette.primaryColor,
      fontWeight: theme.old.typography.fontWeight.bold,
      background: theme.old.palette.backgroundPalette.active.sub,
    },
    position: 'relative',
    '& .ant-menu-submenu-title::before': {
      content: '""',
      position: 'absolute',
      width: 2,
      height: '100%',
      left: 0,
      background: theme.old.palette.primaryColor,
    },
  },
  collapseButton: {
    '& > svg': {
      fontSize: 'inherit',
    },
  },
  menuItem: {
    transition: 'none',
    '&.ant-menu-item-active': {
      color: '#000',
    },
  },
  button: {
    height: 'auto',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
  },
  activeMenuItem: {
    '&.ant-menu-item': {
      color: theme.old.palette.primaryColor,
      fontWeight: theme.old.typography.fontWeight.bold,
      background: theme.old.palette.backgroundPalette.active.sub,
    },
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 2,
      height: '100%',
      left: 0,
      background: theme.old.palette.primaryColor,
    },
  },
  unreadItemCount: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.components.mailFolderItem.fontSize - 1,
    lineHeight: `${theme.old.components.mailFolderItem.fontSize - 1}px`,
    cursor: 'pointer',
    marginLeft: theme.old.components.mailFolderItem.spacing,
  },
  projectName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dropOver: {
    border: theme.old.borders.dashedHighlighted,
    borderWidth: 2,
  },
}));

const inboxFolderSelector = createSelector<
  [
    (state: RootReducerState) => Project[],
    (state: RootReducerState) => SpecialMailFolders,
    (state: RootReducerState) => MailFolderByMailFolderId,
    (state: RootReducerState) => SpecialMailFolderByProjectId,
    (state: RootReducerState) => MailFolderByMailFolderId,
  ],
  { [projectId: string]: MailFolder }
>(
  getFavoriteProjectsWithMail,
  (state) => getSpecialMailFolders(state, 'me'),
  (state) => getMailFoldersByIdState(state, 'me'),
  getProjectsSpecialMailFoldersState,
  (state) => getMailFoldersByIdState(state, 'favorites'),
  (
    projects,
    specialMailFoldersMe,
    meById,
    specialMailFoldersProjects,
    projectsById
  ) => ({
    me: meById[specialMailFoldersMe?.inboxFolder?.id],
    ...projects
      .map((project) => project.projectId)
      .reduce(
        (map, projectId) => ({
          ...map,
          [projectId]:
            projectsById[
              specialMailFoldersProjects[projectId]?.inboxFolder?.id
            ],
        }),
        {}
      ),
  })
);

interface MessageCenterNavigationMenuProps {
  className?: string;
  projectId?: ProjectId;
  pathPrefix?: string;
}

export const MessageCenterNavigationMenu: React.FC<
  MessageCenterNavigationMenuProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { projectId, pathPrefix, className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  const me = useSelector(getUserMe);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const favoriteProjects = useSelector(getFavoriteProjectsWithMail);

  const [openSubMenu, setOpenSubMenu] = useState<boolean>(true);

  const inboxFolderMap = useSelector(inboxFolderSelector);
  const isFetching = useSelector<RootReducerState, boolean>((state) =>
    getMailFoldersIsFetching(state, 'favorites')
  );
  const isFetchingMyMailFolders = useSelector<RootReducerState, boolean>(
    (state) => getMailFoldersIsFetching(state, 'me')
  );
  const ultimateFilterRef =
    useContext<MutableRefObject<UltimateFilterSearchRef<MailSearchFormModel>>>(
      MailFilterContext
    );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleFavoritesClick = () => {
    ultimateFilterRef?.current?.setFilterValue({}, true, true);
    if (projectId) {
      dispatch(setMailListNavigationState(null, projectId));
    }
    navigate(`${pathPrefix ?? ''}favorites/mail/inbox`);
  };

  const handleFavoritesUnreadCountClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    ultimateFilterRef?.current?.setFilterValue({ isRead: false }, true, true);
    navigate(`${pathPrefix ?? ''}favorites/mail/inbox`);
    if (projectId) {
      dispatch(setMailListNavigationState(null, projectId));
    }
  };

  const handleMeUnreadCountClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (inboxFolderMap?.me) {
      ultimateFilterRef?.current?.setFilterValue({ isRead: false }, true, true);
      navigate(`${pathPrefix ?? ''}me/mail/inbox`);
      dispatch(setMailListNavigationState(null, 'me'));
    }
  };
  const handleFavoritesMeClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const isAssignedToMeInFilter =
      Object.entries(ultimateFilterRef?.current?.value ?? {}).filter(
        ([key, value]) => key === 'assignedTo' && value.includes(me?.id)
      ).length > 0;

    if (!isAssignedToMeInFilter) {
      ultimateFilterRef?.current?.setFilterValue(
        { assignedTo: [me?.id] },
        true,
        true
      );
      navigate(`${pathPrefix ?? ''}favorites/mail/inbox`);
      dispatch(setMailListNavigationState(null, 'favorites'));
    }
  };

  const handleClickMenuItem = (projectId: ProjectId) => {
    dispatch(setCurrentMailSearch(null, projectId, 'inbox'));
    dispatch(setMailListNavigationState(null, projectId));
    navigate(`${pathPrefix ?? ''}${projectId}/mail/inbox`);
  };

  const handleDoubleClickMenuItem = (projectId: ProjectId) => {
    dispatch(setMailListNavigationState(null, projectId));
    dispatch(setActiveProjectInMessageCenter(null));
    navigate(`${pathPrefix ?? ''}../../${projectId}/mail/inbox`);
  };
  //#endregion

  //#region ------------------------------ Effects
  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    handleClickMenuItem,
    handleDoubleClickMenuItem,
    250
  );
  //#endregion

  return (
    <Menu
      className={classNames(classes.root, className)}
      mode="inline"
      selectedKeys={projectId === 'favorites' ? [] : [projectId]}
      openKeys={openSubMenu ? ['favorites'] : []}
    >
      <Menu.Item
        key="me"
        style={{
          padding: `0 ${theme.old.spacing.unit(2)}px`,
        }}
        className={classes.collapseButton}
        icon={<FontAwesomeIcon icon={['fal', 'user']} />}
        onClick={() => handleClick('me')}
        onDoubleClick={() => handleDoubleClick('me')}
      >
        <Flex.Row alignItems="baseline">
          <Flex.Item flex={1} className={classes.projectName}>
            {t('mail:messageCenter.navigation.me')}
          </Flex.Item>
          {isFetchingMyMailFolders ? (
            <PrioSpinner size="small" />
          ) : (
            <div
              onClick={handleMeUnreadCountClick}
              className={classes.unreadItemCount}
            >{`${inboxFolderMap?.me?.unreadItemCount ?? '0'}`}</div>
          )}
        </Flex.Row>
      </Menu.Item>
      <Menu.SubMenu
        title={
          <Flex.Row alignItems="baseline">
            <Flex.Item flex={1} className={classes.projectName}>
              {t('mail:messageCenter.navigation.favorites')}
            </Flex.Item>
            <div
              onClick={handleFavoritesMeClick}
              className={classes.unreadItemCount}
            >
              <Tooltip
                overlay={t(
                  'mail:messageCenter.navigation.infoUserCircleSelect'
                )}
                placement="top"
              >
                <FontAwesomeIcon icon={['fal', 'user-circle']} />
              </Tooltip>
            </div>
            {isFetching ? (
              <PrioSpinner size="small" />
            ) : (
              <div
                onClick={handleFavoritesUnreadCountClick}
                className={classes.unreadItemCount}
              >{`${
                favoriteProjects
                  .map((project) => project.projectId)
                  .reduce(
                    (sum, projectId) =>
                      sum + (inboxFolderMap[projectId]?.unreadItemCount ?? 0),
                    0
                  ) ?? '0'
              }`}</div>
            )}
          </Flex.Row>
        }
        className={classNames(classes.subMenu, {
          [classes.subMenuSelected]: projectId === 'favorites',
        })}
        key="favorites"
        icon={
          <Button
            iconProp={['fal', openSubMenu ? 'chevron-up' : 'chevron-down']}
            type="link"
            className={classes.button}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setOpenSubMenu(!openSubMenu);
            }}
            style={{
              padding: 0,
              border: 0,
            }}
          />
        }
        onTitleClick={handleFavoritesClick}
      >
        {favoriteProjects.map((project, index) => (
          <MessageCenterNavigationMenuItem
            project={project}
            selectedKeys={projectId === 'favorites' ? [] : [projectId]}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            inboxFolder={inboxFolderMap[project.projectId]}
            key={`${index}`}
            pathPrefix={pathPrefix}
          />
        ))}
      </Menu.SubMenu>
    </Menu>
  );
};

export default MessageCenterNavigationMenu;

interface MessageCenterNavigationMenuItemProps {
  selectedKeys: ProjectId[];
  project: Project;
  onClick: (projectId: ProjectId) => void;
  onDoubleClick: (projectId: ProjectId) => void;
  inboxFolder: MailFolder;
  key: string;
  pathPrefix?: string;
}

const MessageCenterNavigationMenuItem: React.FC<
  MessageCenterNavigationMenuItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    key,
    project,
    selectedKeys,
    pathPrefix,
    inboxFolder,
    onClick,
    onDoubleClick,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isFetching = useSelector<RootReducerState, boolean>((state) =>
    getMailFoldersIsFetching(state, project.projectId)
  );

  const mailFolder = useSelector<RootReducerState, MailFolder>((state) =>
    getMailFolder(state, inboxFolder?.id, project?.projectId)
  );
  //#endregion
  const ultimateFilterRef =
    useContext<MutableRefObject<UltimateFilterSearchRef<MailSearchFormModel>>>(
      MailFilterContext
    );
  //#region ------------------------------ Methods / Handlers
  const handleOnUnreadCountClick = (
    event: React.MouseEvent,
    inboxFolder: MailFolder
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (inboxFolder) {
      ultimateFilterRef?.current?.setFilterValue({ isRead: false }, true, true);
      navigate(`${pathPrefix ?? ''}${project.projectId}/mail/inbox`);
      dispatch(setMailListNavigationState(null, project.projectId));
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  const [{ isOver }, dropRef] = useDrop({
    accept: [DND_TYPE_EMAIL],
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        return {
          message: (item as any).message as Message,
          isFavoritesView: true,
          destinationMailFolder: (item as any).mailFolderId,
          targetProjectId: project.projectId,
        };
      }
      return undefined;
    },
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
    }),
  });
  //#endregion

  return (
    <div ref={dropRef} key={key}>
      <Menu.Item
        style={{
          paddingLeft: theme.old.spacing.unit(5),
          paddingRight: theme.old.spacing.unit(2),
        }}
        className={classNames(classes.menuItem, {
          [classes.activeMenuItem]: selectedKeys.includes(project.projectId),
          [classes.dropOver]: isOver,
        })}
        key={key}
        onClick={() => onClick(project.projectId)}
        onDoubleClick={() => onDoubleClick(project.projectId)}
        title={`${project.number} ${project.shortName}`}
      >
        <Flex.Row
          alignItems="baseline"
          style={{ width: '100%', height: '100%' }}
        >
          <Flex.Item flex={1} className={classes.projectName}>{`${
            project.number
          } ${project.shortName ?? project.name}`}</Flex.Item>
          {isFetching ? (
            <PrioSpinner size="small" />
          ) : (
            <div
              onClick={(e) => handleOnUnreadCountClick(e, inboxFolder)}
              className={classes.unreadItemCount}
            >{`${mailFolder?.unreadItemCount ?? '0'}`}</div>
          )}
        </Flex.Row>
      </Menu.Item>
    </div>
  );
};
