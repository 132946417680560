import { call, put, select } from 'redux-saga/effects';
import { DriveItemsFetchContext, fetchDriveItemsSagaAction } from '../actions';
import { DriveItemId, ProjectId } from '../../../models/Types';
import {
  RootReducerState,
  getLastOpenDriveItemFolder,
  getProject,
  getWidgetAreaActiveProjectId,
  getWidgetAreaDisplayState,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import { WidgetAreaDisplayState } from '../../widgetArea/reducers';

const documentsPattern =
  /\/module\/prio\/projects\/([a-z\-\d]+)\/documents\/((all)|(folder\/([0-9A-Z]+)))$/;

function* shouldFetch(projectId: ProjectId, driveItemId: DriveItemId) {
  const url = window.location.href;
  if (documentsPattern.test(url)) {
    const match = url.match(documentsPattern);
    if (projectId === match[1]) {
      let folderId: DriveItemId = null;
      const [, , , , , _folderId] = match;
      if (!!_folderId) {
        folderId = _folderId;
      }

      if (folderId === driveItemId || (!folderId && !driveItemId)) {
        return true;
      }
    }
  }

  const widgetAreaDisplayState: WidgetAreaDisplayState = yield select(
    getWidgetAreaDisplayState
  );

  const widgetAreaProjectId: ProjectId = yield select(
    getWidgetAreaActiveProjectId
  );

  const widgetBarLastOpenDriveFolderId = yield select(
    (state: RootReducerState) =>
      getLastOpenDriveItemFolder(state, widgetAreaProjectId)?.documentsWidget
  );

  const { open, selectedWidget } = widgetAreaDisplayState;

  if (
    open &&
    selectedWidget === 'documents' &&
    widgetAreaProjectId === projectId &&
    (widgetBarLastOpenDriveFolderId === driveItemId ||
      (!driveItemId && !widgetBarLastOpenDriveFolderId))
  ) {
    return true;
  }

  return false;
}

function* handleDocumentCacheDeltaUpdated(
  driveItemId: DriveItemId,
  projectId: ProjectId
) {
  const project: Project = yield select((state: RootReducerState) =>
    getProject(state, projectId)
  );

  if (project) {
    const isRoot = driveItemId === '';

    const _shouldFetch = yield call(
      shouldFetch,
      projectId,
      isRoot ? null : driveItemId
    );

    if (_shouldFetch) {
      yield put(
        fetchDriveItemsSagaAction(
          projectId,
          project.groupId,
          isRoot ? `root-group-${project.groupId}` : driveItemId,
          isRoot,
          DriveItemsFetchContext.Other
        )
      );
    }
  }
}

export default handleDocumentCacheDeltaUpdated;
