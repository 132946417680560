import React, {
  useMemo,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import useFilterContext from '../../../components/Filter/hooks/useFilterContext';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import FilterResultNoItemsScreen from '../../../components/Filter/FilterResultNoItemsScreen';
import { ProjectId, ProjectRole } from '../../../models/Types';

import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getAllHourlyRates,
  getContactsIsFetching,
  getUserMe,
} from '../../../apps/main/rootReducer';

import FilterContextVirtualTable from '../../../components/Filter/FilterContextVirtualTable';
import {
  ProjectMember,
  ProjectMemberCalculatedData,
  ProjectMemberSearchResultItem,
} from '../../../models/ProjectContacts';
import {
  ButtonProps,
  Pill,
  PrioSpinner,
  Tooltip,
} from '@prio365/prio365-react-library';
import UserAvatar from '../../../components/UserAvatar';
import useCompaniesContext from '../../companies/hooks/useCompaniesContext';
import { HourlyRate } from '../../../models/HourlyRate';
import SvgIcon from '../../../components/SvgIcon';
import { getSvgIconPathByType } from '../../../util/icon';
import { fetchHourlyRates } from '../../projects/actions';
import { useExportProjectMembersToCsv } from '../export';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openContactsDrawer } from '../actions/drawer';
import { makePrioStyles } from '../../../theme/utils';
import useContactsContext from '../hooks/useContactsProvider';
import { sortContactsHelper } from '../utils';
import { fetchContactsOffset } from '../actions';
import Flex from '../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  tableRow: {
    '&:hover $contactIdCell > svg': {
      display: 'block',
    },
  },
  contactIdCell: {
    '& > svg': {
      display: 'none',
    },
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  label: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.extraSmall,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export interface ProjectContactsPageTableRef {
  fetchProjectMember: () => void;
}

export declare type actions =
  | 'adjustFunction'
  | 'adjustHourlyRate'
  | 'archive'
  | 'archiveExternal'
  | 'adjustRoles';

interface ProjectContactsTableProps {
  className?: string;
  projectId: ProjectId;
  tableId: string;
  selectedProjectMembers: ProjectMemberSearchResultItem[];
  onRowClick?: (entry: ProjectMemberSearchResultItem) => void;
  onSelectionChange?: (items: ProjectMemberSearchResultItem[]) => void;
  onDelete?: () => void;
  setProjectContacts?: (value: ProjectMember[]) => void;
  onOpenModal: (action: actions) => void;
}

export const ProjectContactsPageTable = forwardRef(
  (
    props: ProjectContactsTableProps,
    ref: React.Ref<ProjectContactsPageTableRef>
  ) => {
    //#region ------------------------------ Defaults

    const {
      className,
      projectId,
      tableId,
      onSelectionChange,
      selectedProjectMembers,
      onOpenModal,
      onRowClick,
    } = props;
    const { t } = useTranslation();

    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme<PrioTheme>();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const { getContactById } = useContactsContext();
    const { getCompanyById } = useCompaniesContext();
    const hourlyRates = useSelector<RootReducerState, HourlyRate[]>((state) =>
      getAllHourlyRates(state, projectId)
    );

    const { data, isLoading, fetchSearch } = useFilterContext<
      ProjectMember,
      ProjectMemberCalculatedData
    >();

    const isContactsFetching = useSelector(getContactsIsFetching);

    const projectContacts = useMemo(() => {
      return (data?.items ?? []).sort((a, b) =>
        sortContactsHelper(
          getContactById(a.data.contactId),
          getContactById(b.data.contactId)
        )
      );
    }, [data?.items, getContactById]);

    const [isCallTriggered, setIsCallTriggered] = React.useState(true);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const exportToCsv = useExportProjectMembersToCsv();
    const userMe = useSelector(getUserMe);
    const myProjectRoles = useMemo(
      () => (userMe?.prioData.projectRoles[projectId] as ProjectRole[]) || [],
      [userMe, projectId]
    );

    const handleClick = useCallback(
      (contactId: string, e) => {
        e.stopPropagation();
        dispatch(
          openContactsDrawer({
            view: 'contactDetail',
            selectedContact: contactId,
          })
        );
      },
      [dispatch]
    );

    const actionBarButtons = useMemo((): ButtonProps[] => {
      if (
        myProjectRoles.includes('projectAdmin') ||
        (myProjectRoles.includes('projectController') &&
          myProjectRoles.includes('projectAssistance'))
      ) {
        return [
          {
            onClick: () => {
              onOpenModal('adjustFunction');
            },
            children: t('contacts:projectContactsPage.actions.adjustFunction'),
          },
          {
            iconProp: ['fal', 'euro-sign'],
            onClick: () => {
              onOpenModal('adjustHourlyRate');
            },
            children: t(
              'contacts:projectContactsPage.actions.adjustHourlyRate'
            ),
          },
          {
            iconProp: ['fal', 'lock'],
            onClick: () => {
              onOpenModal('adjustRoles');
            },
            children: t('contacts:projectContactsPage.actions.adjustRoles'),
          },
          {
            iconProp: ['fal', 'file-csv'],
            onClick: () => {
              exportToCsv(selectedProjectMembers, hourlyRates);
            },
            children: t('contacts:projectContactsPage.actions.csvExport'),
          },
          {
            iconProp: ['fal', 'trash-alt'],
            onClick: () => {
              onOpenModal('archive');
            },
            children: t('contacts:projectContactsPage.actions.archive'),
          },
        ];
      } else if (myProjectRoles.includes('projectAssistance')) {
        return [
          {
            onClick: () => {
              onOpenModal('adjustFunction');
            },
            children: t('contacts:projectContactsPage.actions.adjustFunction'),
          },
          {
            iconProp: ['fal', 'lock'],
            onClick: () => {
              onOpenModal('adjustRoles');
            },
            children: t('contacts:projectContactsPage.actions.adjustRoles'),
          },
          {
            iconProp: ['fal', 'file-csv'],
            onClick: () => {
              exportToCsv(selectedProjectMembers, hourlyRates);
            },
            children: t('contacts:projectContactsPage.actions.csvExport'),
          },
          {
            iconProp: ['fal', 'trash-alt'],
            onClick: () => {
              onOpenModal('archive');
            },
            children: t('contacts:projectContactsPage.actions.archive'),
          },
        ];
      } else if (myProjectRoles.includes('projectController')) {
        return [
          {
            iconProp: ['fal', 'euro-sign'],
            onClick: () => {
              onOpenModal('adjustHourlyRate');
            },
            children: t(
              'contacts:projectContactsPage.actions.adjustHourlyRate'
            ),
          },
          {
            iconProp: ['fal', 'file-csv'],
            onClick: () => {
              exportToCsv(selectedProjectMembers, hourlyRates);
            },
            children: t('contacts:projectContactsPage.actions.csvExport'),
          },
          {
            iconProp: ['fal', 'trash-alt'],
            onClick: () => {
              onOpenModal('archiveExternal');
            },
            children: t('contacts:projectContactsPage.actions.archiveExternal'),
            disabled: selectedProjectMembers.some(
              (member) => member.data.externalProjectContactId === null
            ),
          },
        ];
      } else {
        return [
          {
            iconProp: ['fal', 'file-csv'],
            onClick: () => {
              exportToCsv(selectedProjectMembers, hourlyRates);
            },
            children: t('contacts:projectContactsPage.actions.csvExport'),
          },
          {
            iconProp: ['fal', 'trash-alt'],
            onClick: () => {
              onOpenModal('archiveExternal');
            },
            children: t('contacts:projectContactsPage.actions.archiveExternal'),
            disabled: selectedProjectMembers.some(
              (member) => member.data.externalProjectContactId === null
            ),
          },
        ];
      }
    }, [
      myProjectRoles,
      t,
      onOpenModal,
      exportToCsv,
      selectedProjectMembers,
      hourlyRates,
    ]);

    const handleOnRowClick: (
      item: ProjectMemberSearchResultItem
    ) => VirtualListItemOnRowProps = useCallback(
      (item) => {
        return {
          onClick: (e) => {
            onRowClick(item);
          },
        };
      },
      [onRowClick]
    );

    //#endregion

    //#region ------------------------------ Columns
    const columns: Column<ProjectMemberSearchResultItem>[] = useMemo(() => {
      const commonColumns: Column<ProjectMemberSearchResultItem>[] = [
        {
          id: 'contactId',
          accessor: 'data.contactId',
          title: t('contacts:projectContactsPage.columns.name'),
          width: 20,
          className: classes.contactIdCell,
          sortingFn: (rowA, rowB) =>
            sortContactsHelper(
              getContactById(rowA.data.contactId),
              getContactById(rowB.data.contactId)
            ),
          isDefaultSortingFn: true,
          Cell: ({
            originalData: {
              data: { contactId, externalProjectContactId },
            },
          }) => {
            const contact = getContactById(contactId);
            if (!contact)
              return (
                <Flex.Row
                  alignItems="center"
                  childrenGap={theme.spacing.small}
                  width="100%"
                >
                  <PrioSpinner size="small" />
                  <Flex.Item className={classes.label}>
                    {t(
                      'contacts:projectContactsPage.loadingContactInformation'
                    )}
                  </Flex.Item>
                </Flex.Row>
              );
            return (
              <>
                <UserAvatar
                  contact={getContactById(contactId)}
                  backgroundColor={theme.colors.base.blue.default}
                  size="small"
                ></UserAvatar>
                <div
                  style={{
                    marginLeft: theme.spacing.small,
                    flex: 1,
                    overflow: 'hidden',
                  }}
                >
                  <div
                    className={classes.ellipsis}
                    style={{
                      fontSize: theme.font.fontSize.small,
                    }}
                  >
                    {getContactById(contactId)?.firstName +
                      ' ' +
                      getContactById(contactId)?.lastName}
                  </div>
                  <div
                    className={classes.ellipsis}
                    style={{
                      fontSize: theme.font.fontSize.tiny,
                    }}
                  >
                    {
                      getCompanyById(getContactById(contactId)?.companyId)
                        ?.fullName
                    }
                  </div>
                </div>
                <Tooltip
                  overlay={
                    <div>{t('contacts:projectContactsPage.toContact')}</div>
                  }
                  placement="right"
                >
                  <FontAwesomeIcon
                    onClick={(e) => handleClick(contactId, e)}
                    icon={['fal', 'address-book']}
                    style={{
                      marginLeft: theme.spacing.small,
                      cursor: 'pointer',
                      height: '14px',
                    }}
                  ></FontAwesomeIcon>
                </Tooltip>

                {externalProjectContactId && (
                  <Pill
                    size="small"
                    style={{ marginLeft: theme.spacing.small, height: '20px' }}
                    children="Ext."
                  ></Pill>
                )}
              </>
            );
          },
          alignSelf: true,
        },
        {
          id: 'jobTitle',
          accessor: 'data.jobTitle',
          title: t('contacts:projectContactsPage.columns.jobTitle'),
          width: 12,
          sortingFn: (rowA, rowB) =>
            rowA.data.jobTitle?.localeCompare(rowB.data.jobTitle),
          Cell: ({
            originalData: {
              data: { jobTitle },
            },
            originalData,
          }) => {
            const contact = getContactById(originalData.data.contactId);
            if (!contact) return null;
            return <div className={classes.ellipsis}>{jobTitle}</div>;
          },
          alignSelf: true,
        },
        {
          id: 'projectRoles',
          accessor: 'data.projectRoles',
          title: t('contacts:projectContactsPage.columns.projectRoles'),
          width: 32,
          Cell: ({
            originalData: {
              data: { projectRoles },
            },
          }) => (
            <>
              {projectRoles
                ?.slice()
                .sort((a, b) => a.localeCompare(b))
                .map((projectRole, index) => (
                  <Pill
                    key={index}
                    size="small"
                    children={t(
                      `contacts:projectContactsPage.projectRoles.${projectRole}`
                    )}
                    style={{ marginRight: theme.spacing.small }}
                  />
                ))}
            </>
          ),
          alignSelf: true,
        },
        {
          id: 'projectExtensionAccessProjectExtensionMaps',
          accessor: 'calculated.projectExtensionAccessProjectExtensionMaps',
          title: t('contacts:projectContactsPage.columns.addIns'),
          width: 18,
          Cell: ({ originalData: { calculated } }) => (
            <>
              {calculated?.projectExtensionAccessProjectExtensionMaps
                ?.slice()
                .sort((a, b) =>
                  b.projectExtensionType.localeCompare(a.projectExtensionType)
                )
                .map((projectExtensionAccessProjectExtensionMap, index) =>
                  projectExtensionAccessProjectExtensionMap.shouldHaveAccess ? (
                    <SvgIcon
                      key={index}
                      width={28}
                      height={28}
                      path={getSvgIconPathByType(
                        projectExtensionAccessProjectExtensionMap.projectExtensionType
                      )}
                    />
                  ) : null
                )}
            </>
          ),
          alignSelf: true,
        },
        {
          id: 'notes',
          accessor: 'data.notes',
          title: t('contacts:projectContactsPage.columns.notes'),
          width: 8,
          Cell: ({
            originalData: {
              data: { notes },
            },
          }) => (
            <>
              {notes !== '' ? (
                <FontAwesomeIcon
                  icon={['fal', 'notes']}
                  style={{ height: '15px' }}
                />
              ) : null}
            </>
          ),
          alignSelf: true,
        },
      ];

      if (
        myProjectRoles.includes('projectAdmin') ||
        myProjectRoles.includes('projectController')
      ) {
        commonColumns.splice(2, 0, {
          id: 'hourlyRateId',
          accessor: 'data.hourlyRateId',
          title: t('contacts:projectContactsPage.columns.hourlyRate'),
          width: 10,
          Cell: ({
            originalData: {
              data: { hourlyRateId },
            },
          }) => (
            <div className={classes.ellipsis}>
              {hourlyRateId &&
                hourlyRates &&
                hourlyRates.find(
                  (hourlyRate) => hourlyRate.hourlyRateId === hourlyRateId
                )?.name}
            </div>
          ),
          alignSelf: true,
        });
      }

      return commonColumns;
    }, [
      getContactById,
      t,
      hourlyRates,
      getCompanyById,
      handleClick,
      myProjectRoles,
      classes,
      theme,
    ]);
    //#endregion

    //#region ------------------------------ Refs
    useImperativeHandle(ref, () => ({
      fetchProjectMember: () => {
        fetchSearch();
      },
    }));
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      if (projectContacts) {
        const contactIds = projectContacts.map((item) => item.data.contactId);
        const missingContactIds = contactIds.filter(
          (contactId) => !getContactById(contactId)
        );
        if (
          missingContactIds.length > 0 &&
          !isContactsFetching &&
          isCallTriggered
        ) {
          // only trigger once to prevent too many calls when failing
          setIsCallTriggered(false);
          dispatch(fetchContactsOffset());
        }
      }
    }, [
      projectContacts,
      getContactById,
      dispatch,
      isContactsFetching,
      isCallTriggered,
    ]);

    useEffect(() => {
      if (
        myProjectRoles.includes('projectAdmin') ||
        myProjectRoles.includes('projectController')
      ) {
        dispatch(fetchHourlyRates(projectId));
      }
    }, [dispatch, projectId, myProjectRoles]);
    //#endregion

    return (
      <FilterContextVirtualTable<ProjectMemberSearchResultItem>
        id={tableId}
        className={className}
        data={projectContacts}
        columns={columns}
        onSelectionChange={onSelectionChange}
        onCheckEquality={(a, b) => a.data.contactId === b.data.contactId}
        resizable="relative"
        noItemsScreen={<FilterResultNoItemsScreen />}
        loading={
          isLoading && {
            type: 'noItems',
          }
        }
        checkBoxContainerWidth={50}
        rowsAreSelectable
        actionBarButtons={actionBarButtons}
        onRow={handleOnRowClick}
        classNameTableRow={classes.tableRow}
      />
    );
  }
);

export default ProjectContactsPageTable;
