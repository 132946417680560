import React from 'react';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import SvgIcon from '../../../components/SvgIcon';
import { getSvgIconPathByType } from '../../../util/icon';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import UserAvatar from '../../../components/UserAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaskDetail } from '../../../models/Task';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '10px',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    backgroundColor: '#E6EFFC',
    '&:hover $icon': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    alignSelf: 'center',
    visibility: 'hidden',
    opacity: 0,
    color: theme.colors.application.typography.active,
  },
  discription: {
    display: 'flex',
    flexDirection: 'column',
    allignItems: 'flex-start',
    flex: '1 0 0',
    color: theme.colors.application.typography.default,
  },
  title: {
    fontWeight: '700',
  },
}));

interface MailTaskItemProps {
  taskDetail: TaskDetail;
}

export const MailTaskItem: React.FC<MailTaskItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { taskDetail } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleClick = () => {
    window.open(
      taskDetail.url,
      '_blank' // '_blank' öffnet die URL in einem neuen Tab
    );
  };
  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('de-DE').format(date);
  };

  //#endregion

  //#region ------------------------------ Effects

  //#endregion

  return (
    <div className={classes.root}>
      <SvgIcon
        width={28}
        height={28}
        path={getSvgIconPathByType(taskDetail.taskType)}
      ></SvgIcon>
      <div className={classes.discription}>
        <span className={classes.title}>{taskDetail.taskTitle}</span>
        <span>
          {t('common:widgetArea.state') +
            ': ' +
            (taskDetail.taskType === 'jira'
              ? taskDetail.status
              : t(`common:widgetArea.taskState.${taskDetail.status}`))}
        </span>
        {taskDetail.dueDate &&
          taskDetail.dueDate !== '0001-01-01T00:00:00Z' && (
            <span>
              {t('common:widgetArea.dueDate') +
                ': ' +
                formatDate(taskDetail.dueDate)}
            </span>
          )}
      </div>
      {taskDetail.assignees.length > 0 && (
        <div>
          <UserAvatar
            size="medium"
            backgroundColor={theme.colors.application.background.dark}
            displayName={taskDetail.assignees[0]}
          ></UserAvatar>
        </div>
      )}
      <div className={classes.icon}>
        <FontAwesomeIcon
          icon={['fal', 'external-link-alt']}
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        ></FontAwesomeIcon>
      </div>
    </div>
  );
};

export default MailTaskItem;
