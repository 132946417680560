import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import { UpdateSetting } from '../../../models/Settings';

export const FETCH_SETTING_REQUEST = PRIO + 'FETCH_SETTING_REQUEST';
export const FETCH_SETTING_COMMIT = PRIO + 'FETCH_SETTING_COMMIT';
export const FETCH_SETTING_ROLLBACK = PRIO + 'FETCH_SETTING_ROLLBACK';

export const fetchInitialSetting = (userId: string, officeId: string) => ({
  type: FETCH_SETTING_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      effect: {
        url: `${apiUrl}/settings/Settings/GetSettingsByTags`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        json: [
          {
            scopeId: userId,
            eSettingScope: 'User',
            relationId: '',
            tags: ['initial'],
          },
          {
            scopeId: officeId,
            eSettingScope: 'Office',
            relationId: '',
            tags: ['initial'],
          },
          {
            scopeId: '',
            eSettingScope: 'Global',
            relationId: '',
            tags: ['initial'],
          },
        ],
      },
      commit: { type: FETCH_SETTING_COMMIT, meta: { userId, officeId } },
      rollback: {
        type: FETCH_SETTING_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.fetchSettingsError',
          timeout: 6,
        },
      },
    },
  },
});

export const CREATE_USER_SETTING_REQUEST = PRIO + 'CREATE_USER_SETTING_REQUEST';
export const CREATE_USER_SETTING_COMMIT = PRIO + 'CREATE_USER_SETTING_COMMIT';
export const CREATE_USER_SETTING_ROLLBACK =
  PRIO + 'CREATE_USER_SETTING_ROLLBACK';

export const createUserSetting = (
  setting: UpdateSetting,
  userId: string,
  key: string
) => ({
  type: CREATE_USER_SETTING_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      effect: {
        url: `${apiUrl}/settings/Settings/user/${userId}/${key}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        json: setting,
      },
      commit: { type: CREATE_USER_SETTING_COMMIT, meta: { setting } },
      rollback: {
        type: CREATE_USER_SETTING_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.updateSettingsError',
          timeout: 6,
        },
      },
    },
  },
});

export const UPDATE_USER_SETTING_REQUEST = PRIO + 'UPDATE_USER_SETTING_REQUEST';
export const UPDATE_USER_SETTING_COMMIT = PRIO + 'UPDATE_USER_SETTING_COMMIT';
export const UPDATE_USER_SETTING_ROLLBACK =
  PRIO + 'UPDATE_USER_SETTING_ROLLBACK';

export const updateUserSetting = (
  setting: UpdateSetting,
  settingId: string
) => ({
  type: UPDATE_USER_SETTING_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      effect: {
        url: `${apiUrl}/settings/Settings/${settingId}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        json: setting,
      },
      commit: { type: UPDATE_USER_SETTING_COMMIT, meta: { setting } },
      rollback: {
        type: UPDATE_USER_SETTING_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.updateSettingsError',
          timeout: 6,
        },
      },
    },
  },
});
