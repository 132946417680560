import React, { CSSProperties, useCallback, useState } from 'react';
import classNames from 'classnames';
import { List, Checkbox, Tooltip, notification } from 'antd';
import { Button, useTheme } from '@prio365/prio365-react-library';

import { Link } from 'react-router-dom';

import { makePrioStyles } from '../../../theme/utils';
import { CompanyText } from '../../companies/components/CompanyText';
import {
  Contact,
  ExternalContact,
  InternalContact,
} from '../../../models/Contact';
import { Company } from '../../../models/Company';
import { ContactId, CompanyId } from '../../../models/Types';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useTranslation } from 'react-i18next';
import PrioSpinner from '../../../components/PrioSpinner';
import { copyToClipboard } from '../../../components/CopyableTextTile';
import { useSelector } from 'react-redux';
import {
  getCompany,
  getPhoneLinePreference,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { apiCreateMessageDraft, apiUpdateMessageDraft } from '../../mail/api';
import { getCallingHref } from '../utils';
import useContactsContext from '../hooks/useContactsProvider';
import { PrioTheme } from '../../../theme/types';

const listItemHeight = 100;
const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  listItem: {
    paddingLeft: theme.old.spacing.defaultPadding,
    paddingRight: theme.old.spacing.defaultPadding,
    paddingTop: theme.old.spacing.unit(2),
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
    '&:hover > ul > li  > svg': {
      visibility: 'visible',
    },
    '& > ul > li > svg': {
      visibility: 'hidden',
    },
    '&:hover > ul > li  > a': {
      visibility: 'visible',
    },
    '& > ul > li > a': {
      visibility: 'hidden',
    },
    '& .ant-list-item-action': {
      marginLeft: theme.old.spacing.baseSpacing,
      '& > li': {
        padding: `0 4px`,
      },
    },
    height: listItemHeight,
  },
  activeListItem: {
    backgroundColor: theme.old.palette.backgroundPalette.active.content,
    '& .ant-list-item-action': {
      visibility: 'visible!important',
    },
  },
  listItemMeta: {
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
    '& .ant-list-item-meta-title': {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      height: '50%',
      overflow: 'hidden',
    },
    '& .ant-list-item-meta-description': {
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    '& .ant-list-item-meta-avatar': {
      marginRight: theme.old.spacing.unit(2),
    },
  },
  maxTwoRows: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  loadingOverlay: {
    background: '#FFFFFFBF',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  showMore: {
    width: '100%',
    borderTop: theme.old.borders.content,
    '&:hover': {
      borderTop: theme.old.borders.content,
    },
    height: 64,
  },
  noData: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    borderBottom: theme.old.borders.content,
  },
}));

export declare type ContactListType = 'contact' | 'company';

export interface ContactListItem {
  type: ContactListType;
  value: Contact | Company;
}

interface ContactListProps {
  className?: string;
  listItemClassName?: string;
  items: ContactListItem[];
  selectable?: boolean;
  onSelect?: (id: string) => void;
  onUnselect?: (id: string) => void;
  selection?: string[];
  onContactClick?: (contactId: ContactId) => void;
  onCompanyClick?: (companyId: CompanyId) => void;
  pathPrefix?: string;
  activeId?: string;
  showMoreClicked?: () => void;
  onlineLoading?: boolean;
  showMore?: boolean;
}

export const ContactList: React.FC<ContactListProps> = React.memo((props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    className,
    listItemClassName,
    items,
    selectable,
    onSelect,
    onUnselect,
    selection,
    onContactClick,
    onCompanyClick,
    pathPrefix,
    activeId,
    showMoreClicked,
    onlineLoading,
    showMore,
  } = props;

  const renderItem = useCallback(
    (item: ContactListItem, style: CSSProperties) => {
      return (
        <ContactListItemSingle
          listItemClassName={listItemClassName}
          selectable={selectable}
          onSelect={onSelect}
          onUnselect={onUnselect}
          selection={selection}
          onContactClick={onContactClick}
          onCompanyClick={onCompanyClick}
          pathPrefix={pathPrefix}
          activeId={activeId}
          contactListItem={item}
          style={style}
        />
      );
    },
    [
      selectable,
      onSelect,
      onUnselect,
      selection,
      onContactClick,
      onCompanyClick,
      pathPrefix,
      activeId,
      listItemClassName,
    ]
  );

  if (items.length === 0) {
    return (
      <div id="prio-contact-list-no-data" className={classes.root}>
        <div className={classes.noData}>{t('contacts:noData')}</div>
      </div>
    );
  }

  return (
    <div id="prio-contact-list" className={classNames(classes.root, className)}>
      {onlineLoading && (
        <div className={classes.loadingOverlay}>
          <PrioSpinner />
        </div>
      )}
      <div style={{ height: showMore ? 'calc(100% - 64px)' : '100%' }}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <FixedSizeList
              height={height}
              width="100%"
              itemCount={items.length}
              itemSize={listItemHeight}
            >
              {({ index, style }) => renderItem(items[index], style)}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
      {showMore && (
        <Button
          type="link"
          onClick={() => {
            if (showMoreClicked) {
              showMoreClicked();
            }
          }}
          className={classes.showMore}
          iconProp={['fal', 'plus']}
        >
          {t('contacts:actions.showMore')}
        </Button>
      )}
    </div>
  );
});

const LinkableElement = ({ onClick, to, children }) =>
  to ? (
    <Link to={to}>{children}</Link>
  ) : (
    <div style={{ cursor: 'pointer' }} onClick={onClick}>
      {children}
    </div>
  );

export default ContactList;

interface ContactListItemSingleProps {
  listItemClassName?: string;
  contactListItem: ContactListItem;
  style: CSSProperties;
  activeId: string;
  selection: string[];
  onContactClick?: (contactId: ContactId) => void;
  onCompanyClick?: (companyId: CompanyId) => void;
  pathPrefix: string;
  selectable: boolean;
  onSelect?: (id: string) => void;
  onUnselect?: (id: string) => void;
}

const ContactListItemSingle: React.FC<ContactListItemSingleProps> = (props) => {
  const {
    listItemClassName,
    contactListItem,
    style,
    activeId,
    selection,
    onCompanyClick,
    onContactClick,
    pathPrefix,
    selectable,
    onSelect,
    onUnselect,
  } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  const phoneLinePreference = useSelector(getPhoneLinePreference);
  const { getContactById } = useContactsContext();

  const [clicked, setClicked] = useState<boolean>(false);

  const company = useSelector<RootReducerState, Company>((state) =>
    getCompany(
      state,
      (contactListItem.value as ExternalContact & InternalContact).companyId
    )
  );
  const office = company?.offices.find(
    (_office) =>
      _office.officeId ===
      (contactListItem.value as ExternalContact & InternalContact).officeId
  );

  const [isCreatingMail, setIsCreatingMail] = useState<boolean>(false);

  if (contactListItem.type === 'contact') {
    const contact: ExternalContact & InternalContact =
      contactListItem.value as ExternalContact & InternalContact;
    const id = `contact-${contact.contactId}`;
    const selected = selection?.includes(id);
    const active = activeId && activeId === contact.contactId;

    const copyCodeToClipboard = () => {
      setClicked(true);
      copyToClipboard(
        `${contact.firstName} ${contact.lastName} ${
          contact.eMail
            ? `\n${t('contacts:contactDetails.labels.email')}: ${contact.eMail}`
            : ''
        }${
          contact.phone
            ? `\n${t('contacts:contactDetails.labels.phone')}: ${contact.phone}`
            : ''
        }${
          contact.phone2
            ? `\n${t('contacts:contactDetails.labels.phone2')}: ${
                contact.phone2
              }`
            : ''
        }${
          contact.cellphone
            ? `\n${t('contacts:contactDetails.labels.cellphone')}: ${
                contact.cellphone
              }`
            : ''
        }${office?.street ? `\n${office?.street}` : ''}${
          office?.zip ? `\n${office?.zip} ` : ''
        }${office?.city ? `${office?.city}` : ''}`
      );
      setTimeout(() => setClicked(false), 750);
    };

    const handleOpenEMailMeFromContacts = async () => {
      setIsCreatingMail(true);
      const { data: message } = await apiCreateMessageDraft('me');
      if (message) {
        message.toRecipients = [
          {
            emailAddress: {
              name: '',
              address: contact.eMail ?? contact.eMail2,
            },
          },
        ];
        await apiUpdateMessageDraft('me', message.id, message);
        const width = window.screen.availWidth / 2;
        const height = window.screen.availHeight * 0.6;
        window.open(
          `/view/me/composer/${message.id}`,
          '_blank',
          `width=${width},height=${height},top=${height / 4}`
        );
      } else {
        notification.open({
          message: t('common:error'),
          description: t('mail:errorMessages.messages.createDraftError'),
        });
      }
      setIsCreatingMail(false);
    };

    return (
      <div className={classes.item} style={style}>
        <LinkableElement
          to={
            onContactClick
              ? null
              : `${pathPrefix ?? ''}contact/${contact.contactId}/details`
          }
          onClick={() => onContactClick(contact.contactId)}
        >
          <List.Item
            className={classNames(classes.listItem, listItemClassName, {
              [classes.activeListItem]: active,
            })}
            actions={
              !contact.isArchived && [
                <Tooltip title={t('contacts:contactDetails.actions.email')}>
                  {isCreatingMail ? (
                    <PrioSpinner size="small" />
                  ) : (
                    <Button
                      iconProp={['fal', 'envelope']}
                      type={'default'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleOpenEMailMeFromContacts();
                      }}
                    />
                  )}
                </Tooltip>,
                <Tooltip title={t('contacts:contactDetails.actions.call')}>
                  <Button
                    iconProp={['fal', 'phone']}
                    type={'default'}
                    href={getCallingHref(
                      phoneLinePreference,
                      getContactById,
                      contact.contactId
                    )}
                  />
                </Tooltip>,
                <Tooltip title={t('contacts:contactDetails.actions.copy')}>
                  <Button
                    iconProp={['fal', clicked ? 'check' : 'copy']}
                    type={'default'}
                    onClick={(e: React.MouseEvent) => {
                      copyCodeToClipboard();
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  />
                </Tooltip>,
              ]
            }
          >
            <List.Item.Meta
              className={classes.listItemMeta}
              title={
                <span
                  style={
                    contact.isArchived
                      ? {
                          color: theme.colors.application.typography.muted,
                          fontStyle: 'italic',
                        }
                      : {}
                  }
                >{`${contact.firstName} ${contact.lastName} ${
                  contact.isArchived
                    ? `(${t('contacts:contactDetails.archived')})`
                    : ''
                }`}</span>
              }
              description={
                <CompanyText
                  type="secondary"
                  companyId={contact.companyId}
                  className={classes.maxTwoRows}
                />
              }
              avatar={
                selectable && (
                  <Checkbox
                    checked={selected}
                    onClick={(e: React.MouseEvent) => {
                      selected ? onUnselect(id) : onSelect(id);
                      e.stopPropagation();
                    }}
                  />
                )
              }
            />
          </List.Item>
        </LinkableElement>
      </div>
    );
  } else {
    const company: Company = contactListItem.value as Company;
    const id = `company-${company.companyId}`;
    const selected = selection?.includes(id);
    const active = activeId && activeId === company.companyId;

    const copyCodeToClipboard = () => {
      setClicked(true);
      copyToClipboard(
        `${company.fullName}  ${
          company.offices.find((x) => x.isMainOffice)?.eMail
            ? `\n${t('contacts:companyPreview.labels.eMail')} ${company
                .offices[0]?.eMail}`
            : ''
        }${
          company.offices[0]?.phone
            ? `\n${t('contacts:companyPreview.labels.phone')} ${company
                .offices[0]?.phone}`
            : ''
        }${
          company.offices[0]?.street ? `\n${company.offices[0]?.street}` : ''
        }${company.offices[0]?.zip ? `\n${company.offices[0]?.zip} ` : ''}${
          company.offices[0]?.city ? `${company.offices[0]?.city}` : ''
        }`
      );
      setTimeout(() => setClicked(false), 750);
    };

    const handleOpenEMailMeFromContacts = async () => {
      setIsCreatingMail(true);
      const { data: message } = await apiCreateMessageDraft('me');
      message.toRecipients = [
        {
          emailAddress: {
            name: '',
            address: company.offices && company.offices[0].eMail,
          },
        },
      ];
      await apiUpdateMessageDraft('me', message.id, message);
      const width = window.screen.availWidth / 2;
      const height = window.screen.availHeight * 0.6;
      window.open(
        `/view/me/composer/${message.id}`,
        '_blank',
        `width=${width},height=${height},top=${height / 4}`
      );

      setIsCreatingMail(false);
    };

    return (
      <div className={classes.item} style={style}>
        <LinkableElement
          to={
            onCompanyClick
              ? null
              : `${pathPrefix ?? ''}company/${company.companyId}/details`
          }
          onClick={() => {
            onCompanyClick(company.companyId);
          }}
        >
          <List.Item
            className={classNames(classes.listItem, listItemClassName, {
              [classes.activeListItem]: active,
            })}
            actions={[
              <Tooltip title={t('contacts:contactDetails.actions.email')}>
                {isCreatingMail ? (
                  <PrioSpinner size="small" />
                ) : (
                  <Button
                    iconProp={['fal', 'envelope']}
                    type="default"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleOpenEMailMeFromContacts();
                    }}
                  />
                )}
              </Tooltip>,
              <Tooltip title={t('contacts:contactDetails.actions.copy')}>
                <Button
                  iconProp={['fal', clicked ? 'check' : 'copy']}
                  type="default"
                  onClick={(e: React.MouseEvent) => {
                    copyCodeToClipboard();
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </Tooltip>,
            ]}
          >
            <List.Item.Meta
              className={classes.listItemMeta}
              title={Boolean(company.fullName) ? company.fullName : '-'}
              description={Boolean(company.shortName) ? company.shortName : '-'}
              avatar={
                selectable && (
                  <Checkbox
                    checked={selected}
                    onClick={(e: React.MouseEvent) => {
                      selected ? onUnselect(id) : onSelect(id);
                      e.stopPropagation();
                    }}
                  />
                )
              }
            />
          </List.Item>
        </LinkableElement>
      </div>
    );
  }
};
