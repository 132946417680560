import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import {
  FlagMessagePayload,
  MarkAsReadPayload,
  Message,
  MessageCategorizationPayload,
} from '../../../../models/Message';
import { MailFolderId, MessageId, ProjectId } from '../../../../models/Types';
import { distinct } from '../../../../util';
import {
  FETCH_MESSAGES_PROJECT_REQUEST,
  FETCH_MESSAGES_PROJECT_ROLLBACK,
  FETCH_MESSAGES_PROJECT_COMMIT,
  DELETE_MESSAGES_PROJECT_REQUEST,
  DELETE_MESSAGES_PROJECT_ROLLBACK,
  CATEGORIZED_MESSAGE_PROJECT,
  MARK_AS_READ_REQUEST,
  MARK_AS_READ_ROLLBACK,
  FLAG_MESSAGE_PROJECT_REQUEST,
  FLAG_MESSAGE_PROJECT_ROLLBACK,
  UPDATE_MESSAGE_PROJECT,
  DELETE_LOCAL_MESSAGE_PROJECT,
  FETCH_MESSAGE_PROJECT_INLINE_IMAGE_COMMIT,
  FETCH_MESSAGE_PROJECT_INLINE_IMAGE_REQUEST,
  FETCH_MESSAGE_PROJECT_INLINE_IMAGE_ROLLBACK,
  FETCH_MESSAGE_PROJECT_DECODE_MIME_COMMIT,
  DELETE_MESSAGES_PROJECT_COMMIT,
  SOFTDELETE_MESSAGES_PROJECT_REQUEST,
  SOFTDELETE_MESSAGES_PROJECT_ROLLBACK,
  SOFTDELETE_MESSAGES_PROJECT_COMMIT,
  PUT_MESSAGE_PROJECT_ATTACHMENT_TO_CACHE,
  COPY_MAIL_TO_PROJECT_PROJECT_COMMIT,
  FETCH_MESSAGE_PROJECT_COMMIT,
  MOVE_MESSAGE_PROJECT_REQUEST,
  MOVE_MESSAGE_PROJECT_ROLLBACK,
  MOVE_MESSAGE_PROJECT_COMMIT,
  WS_REVERT_MESSAGE_DELETE_PROJECT,
  WS_REVERT_MESSAGE_MOVE_PROJECT,
  DELETE_LOCAL_PROJECT_MESSAGES_BEFORE_INITIAL_INBOX_COMMIT_PROJECT,
  SET_IS_FETCHING_PROJECT_MAILFOLDER,
  ADD_PROJECT_MESSAGE_TO_CACHE,
  UPDATE_MESSAGES_PROJECT,
  ASSIGNEE_READ_MESSAGE_PROJECT_COMMIT,
} from '../../actions/projects/messages';
import {
  WS_EMAIL_PROJECT_DELETED,
  WS_EMAIL_PROJECT_CREATED,
  WS_EMAIL_PROJECT_UPDATED,
} from '../../actions/ws';
import { MessageIdsByMailFolderId, ByMessageId } from '../../actions/types';
import {
  DELETE_LOCAL_MESSAGES,
  DELETE_LOCAL_MESSAGES_OF_MAILBOX_PROJECT,
} from '../../actions/actionControllers/messageActionController';
import { UPDATE_CACHING_ENABLED } from '../../../userSettings/actions/mailSettings/mail';
import { COPY_MAIL_ME_TO_PROJECT_COMMIT } from '../../actions/me/messagesMe';
import { SAGA_REBUILD } from '../../../../util/sagas';

export interface MessagesState {
  byId: ByMessageId;
  ids: ByProjectId;
  meta: MessagesMeta;
  nextLink: NextLinkState;
}

export const initialState: MessagesState = {
  byId: {},
  ids: {},
  meta: {
    isFetching: false,
    hasError: false,
    isSingleMessageFetching: false,
    fetchingProjectIds: {},
  },
  nextLink: {},
};

const byId: Reducer<ByMessageId, any> = (state = initialState.byId, action) => {
  switch (action.type) {
    case FETCH_MESSAGES_PROJECT_COMMIT: {
      const {
        payload,
        meta: { messageIdsToDelete },
      } = action;
      let newState = { ...state };
      ((messageIdsToDelete as MessageId[]) ?? []).forEach((messageId) => {
        if (newState[messageId]) {
          delete newState[messageId];
        }
      });

      return (payload.messages as Array<Message>).reduce(
        (map: ByMessageId | {}, item) => {
          map[item.id] = item;
          return map;
        },
        newState
      );
    }

    case FETCH_MESSAGE_PROJECT_COMMIT: {
      const { payload } = action;
      const { body, event, messageAttachments, ...restMessage } =
        payload as Message;
      return {
        ...state,
        [payload.id]: {
          ...restMessage,
          attachments: messageAttachments ?? state[payload.id]?.attachments,
        },
      };
    }

    case CATEGORIZED_MESSAGE_PROJECT: {
      const { payload } = action;
      const { messages, isDelete } = payload as MessageCategorizationPayload;
      return messages.reduce(
        function (map, { messageId, categories }) {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              categories: isDelete
                ? (map[messageId]?.categories ?? [])?.filter(
                    (category) => !categories.includes(category)
                  )
                : distinct([
                    ...(map[messageId]?.categories ?? []),
                    ...categories,
                  ]),
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case MARK_AS_READ_REQUEST: {
      const { payload } = action;
      const changes = payload as MarkAsReadPayload;
      return changes.reduce(
        function (map, { messageId, isRead }) {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              isRead,
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case MARK_AS_READ_ROLLBACK: {
      const {
        meta: { payload },
      } = action;
      const changes = payload as MarkAsReadPayload;
      return changes.reduce(
        function (map, { messageId, isRead }) {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              isRead: !isRead,
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case FLAG_MESSAGE_PROJECT_REQUEST: {
      const { payload } = action;
      const changes = payload as FlagMessagePayload;
      return changes.reduce(
        function (map, { messageId, flag }) {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              flag,
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case FLAG_MESSAGE_PROJECT_ROLLBACK: {
      const {
        meta: { originalPayload },
      } = action;
      const changes = originalPayload as FlagMessagePayload;
      return changes.reduce(
        function (map, { messageId, flag }) {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              flag,
            };
          }
          return map;
        },
        { ...state }
      );
    }

    case COPY_MAIL_ME_TO_PROJECT_COMMIT:
    case COPY_MAIL_TO_PROJECT_PROJECT_COMMIT: {
      const {
        payload,
        meta: { deleteMail, messages },
      } = action;
      if (deleteMail) {
        const messageIdsToDelete = messages.map((message) => message.id);
        const newState = Object.keys(state).reduce((map, messageId) => {
          if (messageIdsToDelete.includes(messageId)) {
            return map;
          }
          return {
            ...map,
            [messageId]: state[messageId],
          };
        }, {});
        return {
          ...newState,
          ...(payload ?? []).reduce(
            (map, message) => ({
              ...map,
              [message.id]: message,
            }),
            {}
          ),
        };
      }
      return {
        ...state,
        ...payload.reduce(
          (map, message) => ({ ...map, [message.id]: message }),
          {}
        ),
      };
    }

    case WS_EMAIL_PROJECT_UPDATED: {
      const {
        payload: { message },
      }: { payload: { message: Message } } = action;
      if (state[message.id]) {
        return {
          ...state,
          [message.id]: message,
        };
      }
      return state;
    }

    case WS_EMAIL_PROJECT_CREATED: {
      const {
        payload: { message },
      }: { payload: { message: Message } } = action;
      return {
        ...state,
        [message.id]: message,
      };
    }

    case UPDATE_MESSAGE_PROJECT: {
      const {
        payload: { messageUpdate },
        meta: { messageId },
      }: {
        payload: { messageUpdate: Partial<Message> };
        meta: { messageId: MessageId };
      } = action;
      if (!state[messageId]) {
        return state;
      }
      return {
        ...state,
        [messageId]: {
          ...(state[messageId] ?? {}),
          ...messageUpdate,
        },
      };
    }
    case UPDATE_MESSAGES_PROJECT: {
      const {
        payload: { messageUpdates },
        meta: { messageIds },
      } = action;

      return messageIds.reduce(
        (map, messageId) => {
          const existingMessage = map[messageId];

          if (existingMessage) {
            const update = messageUpdates.find(
              (u) => u.messageId === messageId
            );

            if (update) {
              map[messageId] = {
                ...existingMessage,
                ...update.messageUpdate,
              };
            }
          }

          return map;
        },
        { ...state }
      );
    }
    case DELETE_LOCAL_PROJECT_MESSAGES_BEFORE_INITIAL_INBOX_COMMIT_PROJECT: {
      const {
        meta: { messageIds },
      } = action;
      return ((messageIds as MessageId[]) ?? []).reduce((map, id) => {
        const { [id]: message, ...rest } = map;
        if (message) {
          return rest;
        }
        return map;
      }, state);
    }

    case DELETE_LOCAL_MESSAGE_PROJECT: {
      const {
        meta: { messageId },
      } = action;
      if (state[messageId]) {
        const { [messageId]: _, ...rest } = state;
        return { ...rest };
      }
      return state;
    }

    case SOFTDELETE_MESSAGES_PROJECT_REQUEST: {
      const {
        meta: { messageIds },
      } = action;
      return ((messageIds as string[]) ?? []).reduce((map, id) => {
        const { [id]: message, ...rest } = map;
        if (message) {
          return rest;
        }
        return map;
      }, state);
    }
    case SOFTDELETE_MESSAGES_PROJECT_ROLLBACK: {
      const {
        meta: { rollbackMessages },
      } = action;
      return ((rollbackMessages as Message[]) ?? []).reduce((map, item) => {
        map[item.id] = item;
        return map;
      }, state);
    }

    case WS_EMAIL_PROJECT_DELETED:
    case DELETE_MESSAGES_PROJECT_COMMIT: {
      const {
        meta: { messageIds },
      } = action;
      return ((messageIds as string[]) ?? []).reduce((map, id) => {
        const { [id]: message, ...rest } = map;
        if (message) {
          return rest;
        }
        return map;
      }, state);
    }

    case FETCH_MESSAGE_PROJECT_DECODE_MIME_COMMIT:
    case FETCH_MESSAGE_PROJECT_INLINE_IMAGE_COMMIT: {
      const {
        payload,
        meta: { messageId },
      } = action;
      return {
        ...state,
        [messageId]: payload,
      };
    }

    case PUT_MESSAGE_PROJECT_ATTACHMENT_TO_CACHE: {
      const {
        meta: { messageId },
        payload,
      } = action;
      if (state[messageId]) {
        return {
          ...state,
          [messageId]: {
            ...state[messageId],
            messageAttachments: payload,
          },
        };
      }
      return state;
    }

    case WS_REVERT_MESSAGE_MOVE_PROJECT: {
      const { messageId, message } = action;

      return {
        ...state,
        [messageId]: message,
      };
    }

    case MOVE_MESSAGE_PROJECT_REQUEST: {
      const {
        meta: { destinationId, messageIds, inboxFolderId },
      } = action;

      const parentFolderId: MailFolderId =
        destinationId === 'inbox' ? inboxFolderId : destinationId;

      return {
        ...state,
        ...messageIds.reduce((map: ByMessageId, id: MessageId) => {
          const message = state[id];
          if (message) {
            return {
              ...map,
              [id]: { ...message, parentFolderId },
            };
          }
          return map;
        }, {} as ByMessageId),
      };
    }

    case MOVE_MESSAGE_PROJECT_COMMIT: {
      const {
        payload: { messages, failedMessageIds },
        meta: { messageIds },
      } = action;

      const removedMessageIds = messageIds.filter(
        (messageId) => !failedMessageIds.includes(messageId)
      );

      return {
        ...Object.keys(state).reduce((map, messageId) => {
          if (removedMessageIds.includes(messageId)) {
            return map;
          }
          return {
            ...map,
            [messageId]: state[messageId],
          };
        }, {}),
        ...messages.reduce(
          (map, message) => ({
            ...map,
            [message.id]: message,
          }),
          {}
        ),
      };
    }

    case MOVE_MESSAGE_PROJECT_ROLLBACK: {
      const {
        meta: { originId, messageIds, inboxFolderId },
      } = action;

      const parentFolderId = originId === 'inbox' ? inboxFolderId : originId;

      return {
        ...state,
        ...messageIds.reduce((map, id) => {
          const message = state[id];
          if (message) {
            return {
              ...map,
              [id]: { ...message, parentFolderId },
            };
          }
          return map;
        }, {}),
      };
    }

    case WS_REVERT_MESSAGE_DELETE_PROJECT: {
      const { messageId, message } = action;

      return {
        ...state,
        [messageId]: message,
      };
    }

    case ADD_PROJECT_MESSAGE_TO_CACHE: {
      const { message } = action;
      if (message && !state[message.id]) {
        const { body, event, messageAttachments, ...restMessage } =
          message as Message;
        return {
          ...state,
          [message.id]: {
            ...restMessage,
            attachments: messageAttachments ?? state[message.id]?.attachments,
          },
        };
      }
      return state;
    }

    case ASSIGNEE_READ_MESSAGE_PROJECT_COMMIT: {
      const { payload } = action;
      return {
        ...state,
        [payload.id]: payload,
      };
    }

    case DELETE_LOCAL_MESSAGES_OF_MAILBOX_PROJECT:
    case SAGA_REBUILD:
    case UPDATE_CACHING_ENABLED:
    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return initialState.byId;
    }
    default:
      return state;
  }
};

export interface ByProjectId {
  [projectId: string]: MessageIdsByMailFolderId;
}

const ids: Reducer<ByProjectId, any> = (state = initialState.ids, action) => {
  switch (action.type) {
    case FETCH_MESSAGES_PROJECT_COMMIT: {
      const {
        payload,
        meta: { projectId, mailFolderId, messageIdsToDelete },
      } = action;

      const keepedMessageIds = (
        (state[projectId] ?? {})[mailFolderId] ?? []
      ).filter((messageId) => !messageIdsToDelete.includes(messageId));
      return {
        ...state,
        [projectId]: {
          ...(state[projectId] ?? {}),
          [mailFolderId]: distinct([
            ...(payload.messages as Array<Message>).map((item) => item.id),
            ...keepedMessageIds,
          ]),
        },
      };
    }
    case SOFTDELETE_MESSAGES_PROJECT_REQUEST:
    case DELETE_MESSAGES_PROJECT_REQUEST: {
      const {
        payload: { messageIds },
        meta,
      } = action;
      const projectId = meta.projectId;
      const mailFolderId = meta.mailFolderId;
      if (mailFolderId) {
        return {
          ...state,
          [projectId]: {
            ...(state[projectId] ?? {}),
            [mailFolderId]: (
              (state[projectId] ?? {})[mailFolderId] ?? []
            )?.filter((id) => !messageIds.includes(id)),
          },
        };
      }
      return state;
    }
    case SOFTDELETE_MESSAGES_PROJECT_COMMIT: {
      const { meta } = action;
      const mailFolderId = meta.mailFolderId;
      const projectId: ProjectId = meta.projectId;
      const messageIds = meta.messageIds;
      if (!mailFolderId) {
        return {
          ...state,
          [projectId]: Object.keys(state[projectId] ?? {}).reduce(
            (map, currentId) => {
              map[currentId] = (map[currentId] ?? [])?.filter(
                (id) => !messageIds.includes(id)
              );
              return map;
            },
            state[projectId] ?? {}
          ),
        };
      }
      return state;
    }
    case SOFTDELETE_MESSAGES_PROJECT_ROLLBACK:
    case DELETE_MESSAGES_PROJECT_ROLLBACK: {
      const { meta } = action;
      const projectId = meta.projectId;
      const mailFolderId = meta.mailFolderId;
      const messageIds = meta.messageIds;
      if (mailFolderId) {
        return {
          ...state,
          [projectId]: {
            ...(state[projectId] ?? {}),
            [mailFolderId]: distinct([
              ...((state[projectId] ?? {})[mailFolderId] ?? []),
              ...messageIds,
            ]),
          },
        };
      }
      return state;
    }
    case WS_EMAIL_PROJECT_DELETED: {
      const {
        payload: { messageIds },
        meta,
      } = action;
      const projectId = meta.projectId;
      const mailFolderId = meta.mailFolderId;
      if (mailFolderId) {
        return {
          ...state,
          [projectId]: {
            ...(state[projectId] ?? {}),
            [mailFolderId]: (
              (state[projectId] ?? {})[mailFolderId] ?? []
            )?.filter((id) => !messageIds.includes(id)),
          },
        };
      }
      return {
        ...state,
        [projectId]: Object.keys(state[projectId] ?? {}).reduce(
          (map, currentId) => {
            map[currentId] = (map[currentId] ?? [])?.filter(
              (id) => !messageIds.includes(id)
            );
            return map;
          },
          state[projectId] ?? {}
        ),
      };
    }

    case MOVE_MESSAGE_PROJECT_REQUEST: {
      const {
        meta: { projectId, originId, destinationId, messageIds },
      } = action;

      return {
        ...state,
        [projectId]: {
          ...(state[projectId] ?? {}),
          [originId]: ((state[projectId] ?? {})[originId] ?? [])?.filter(
            (mId) => !messageIds.includes(mId)
          ),
          [destinationId]: distinct([
            ...((state[projectId] ?? {})[destinationId] ?? []),
            ...messageIds,
          ]),
        },
      };
    }

    case MOVE_MESSAGE_PROJECT_COMMIT: {
      const {
        payload: { messages },
        meta: { projectId, destinationId, messageIds },
      } = action;

      const redirectedMessageIds = messages.map(({ id }) => id);

      return {
        ...state,
        [projectId]: {
          ...(state[projectId] ?? {}),
          [destinationId]: distinct([
            ...((state[projectId] ?? {})[destinationId] ?? []).filter(
              (messageId) => messageIds.includes(messageId)
            ),
            ...redirectedMessageIds,
          ]),
        },
      };
    }

    case MOVE_MESSAGE_PROJECT_ROLLBACK: {
      const {
        meta: { projectId, originId, destinationId, messageIds },
      } = action;

      return {
        ...state,
        [projectId]: {
          ...(state[projectId] ?? {}),
          [originId]: distinct([
            ...((state[projectId] ?? {})[originId] ?? []),
            ...messageIds,
          ]),
          [destinationId]: (
            (state[projectId] ?? {})[destinationId] ?? []
          )?.filter((mId) => !messageIds.includes(mId)),
        },
      };
    }

    case COPY_MAIL_ME_TO_PROJECT_COMMIT:
    case COPY_MAIL_TO_PROJECT_PROJECT_COMMIT: {
      const {
        payload,
        meta: {
          deleteMail,
          messages,
          projectId,
          targetProjectId,
          destinationMailFolderId,
        },
      } = action;
      if (deleteMail) {
        const messageIdsByMailFolderId: MessageIdsByMailFolderId =
          messages.reduce(
            (map, message) => ({
              ...map,
              [message.parentFolderId]: distinct([
                ...(map[message.parentFolderId] ?? []),
                message.id,
              ]),
            }),
            {}
          );
        const changedOrigin = !projectId
          ? {}
          : {
              [projectId]: Object.keys(messageIdsByMailFolderId).reduce(
                (map, mailFolderId) => {
                  if (map[mailFolderId]) {
                    return {
                      ...map,
                      [mailFolderId]: (map[mailFolderId] ?? [])?.filter(
                        (messageId) =>
                          !messageIdsByMailFolderId[mailFolderId].includes(
                            messageId
                          )
                      ),
                    };
                  } else if (
                    map.inbox?.find((messageId) =>
                      messageIdsByMailFolderId[mailFolderId].includes(messageId)
                    )
                  ) {
                    return {
                      ...map,
                      inbox: (map.inbox ?? [])?.filter(
                        (messageId) =>
                          !messageIdsByMailFolderId[mailFolderId].includes(
                            messageId
                          )
                      ),
                    };
                  }
                  return map;
                },
                state[projectId] ?? {}
              ),
            };
        return {
          ...state,
          ...changedOrigin,
          [targetProjectId]: {
            ...(state[targetProjectId] ?? {}),
            [destinationMailFolderId]: distinct([
              ...((state[targetProjectId] ?? {})[destinationMailFolderId] ??
                []),
              ...payload.map((message) => message.id),
            ]),
          },
        };
      }
      return {
        ...state,
        [targetProjectId]: {
          ...(state[targetProjectId] ?? {}),
          [destinationMailFolderId]: distinct([
            ...((state[targetProjectId] ?? {})[destinationMailFolderId] ?? []),
            ...payload.map((message) => message.id),
          ]),
        },
      };
    }

    case WS_EMAIL_PROJECT_CREATED: {
      const {
        payload: { message },
        meta: { projectId, mailFolderId },
      }: {
        payload: { message: Message };
        meta: { projectId: ProjectId; mailFolderId: MailFolderId };
      } = action;
      if (!mailFolderId) return state;
      if ((state[projectId] ?? {})[mailFolderId]?.includes(message.id)) {
        return state;
      }
      return {
        ...state,
        [projectId]: {
          ...(state[projectId] ?? {}),
          [mailFolderId]: distinct([
            ...((state[projectId] ?? {})[mailFolderId] ?? []),
            message.id,
          ]),
        },
      };
    }

    case DELETE_LOCAL_MESSAGE_PROJECT: {
      const {
        meta: { projectId, mailFolderId, messageId },
      } = action;
      return {
        ...state,
        [projectId]: {
          ...(state[projectId] ?? {}),
          [mailFolderId]: (
            (state[projectId] ?? {})[mailFolderId] ?? []
          )?.filter((id) => id !== messageId),
        },
      };
    }

    case WS_REVERT_MESSAGE_MOVE_PROJECT: {
      const { projectId, destinationId, messageId, message, inboxFolderId } =
        action;

      const sourceId =
        inboxFolderId === message.parentFolderId
          ? 'inbox'
          : message.parentFolderId;
      const _destinationId =
        inboxFolderId === destinationId ? 'inbox' : destinationId;

      return {
        ...state,
        [projectId]: {
          ...(state[projectId] ?? {}),
          [_destinationId]: (state[projectId]?.[_destinationId] ?? []).filter(
            (id) => id !== messageId
          ),
          [sourceId]: distinct([
            ...(state[projectId]?.[sourceId] ?? []),
            messageId,
          ]),
        },
      };
    }

    case WS_REVERT_MESSAGE_DELETE_PROJECT: {
      const { projectId, sourceId, messageId } = action;

      return {
        ...state,
        [projectId]: {
          ...(state[projectId] ?? {}),
          [sourceId]: distinct([
            ...(state[projectId]?.[sourceId] ?? []),
            messageId,
          ]),
        },
      };
    }

    case DELETE_LOCAL_MESSAGES_OF_MAILBOX_PROJECT:
    case SAGA_REBUILD:
    case UPDATE_CACHING_ENABLED:
    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return initialState.ids;
    }
    default:
      return state;
  }
};

interface MessagesMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
  isSingleMessageFetching?: boolean;
  fetchingProjectIds: { [projectId: ProjectId]: MailFolderId[] };
}

const meta: Reducer<MessagesMeta, any> = (
  state = initialState.meta,
  action
) => {
  switch (action.type) {
    case FETCH_MESSAGES_PROJECT_REQUEST: {
      const {
        meta: { projectId, mailFolderId },
      } = action;
      return {
        ...state,
        isFetching: true,
        fetchingProjectIds: {
          ...state.fetchingProjectIds,
          [projectId]: [
            ...(state.fetchingProjectIds[projectId] ?? []),
            mailFolderId,
          ],
        },
      };
    }
    case FETCH_MESSAGES_PROJECT_COMMIT: {
      const {
        meta: { projectId, mailFolderId },
      } = action;
      return {
        ...state,
        isFetching: false,
        fetchingProjectIds: {
          ...state.fetchingProjectIds,
          [projectId]: (state.fetchingProjectIds[projectId] ?? []).filter(
            (id) => id !== mailFolderId
          ),
        },
      };
    }
    case FETCH_MESSAGES_PROJECT_ROLLBACK: {
      const {
        meta: { projectId, mailFolderId },
      } = action;
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'mail:errorMessages.messages.fetchError',
        fetchingProjectIds: {
          ...state.fetchingProjectIds,
          [projectId]: (state.fetchingProjectIds[projectId] ?? []).filter(
            (id) => id !== mailFolderId
          ),
        },
      };
    }

    case FETCH_MESSAGE_PROJECT_INLINE_IMAGE_REQUEST: {
      return {
        ...state,
        isSingleMessageFetching: true,
      };
    }

    case FETCH_MESSAGE_PROJECT_INLINE_IMAGE_COMMIT: {
      return {
        ...state,
        isSingleMessageFetching: false,
      };
    }

    case FETCH_MESSAGE_PROJECT_INLINE_IMAGE_ROLLBACK: {
      return {
        ...state,
        isSingleMessageFetching: false,
        hasError: true,
        errorMessage: 'mail:errorMessages.messages.fetchError',
      };
    }

    case SET_IS_FETCHING_PROJECT_MAILFOLDER: {
      const {
        meta: { projectId, mailFolderId, isFetching },
      } = action;

      let newProjectIdState = (
        state.fetchingProjectIds[projectId] ?? []
      ).filter((id) => id !== mailFolderId);
      if (isFetching) {
        newProjectIdState = [...newProjectIdState, mailFolderId];
      }

      return {
        ...state,
        isFetching: false,
        fetchingProjectIds: {
          ...state.fetchingProjectIds,
          [projectId]: newProjectIdState,
        },
      };
    }

    case DELETE_LOCAL_MESSAGES_OF_MAILBOX_PROJECT:
    case SAGA_REBUILD:
    case UPDATE_CACHING_ENABLED:
    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return initialState.meta;
    }
    default:
      return state;
  }
};

export interface NextLinkState {
  [projectId: string]: {
    [mailFolderId: string]: string;
  };
}

const nextLink: Reducer<NextLinkState, any> = (
  state = initialState.nextLink,
  action
) => {
  switch (action.type) {
    case FETCH_MESSAGES_PROJECT_COMMIT: {
      const {
        payload,
        meta: { projectId, mailFolderId },
      } = action;
      return {
        ...state,
        [projectId]: {
          ...(state[projectId] ?? []),
          [mailFolderId]: payload.nextLink ?? '',
        },
      };
    }

    case DELETE_LOCAL_MESSAGES_OF_MAILBOX_PROJECT:
    case SAGA_REBUILD:
    case UPDATE_CACHING_ENABLED:
    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return initialState.nextLink;
    }
    default:
      return state;
  }
};

export default combineReducers<MessagesState>({
  byId,
  ids,
  meta,
  nextLink,
});

export const getAllMessages: (
  state: MessagesState,
  projectId: string,
  mailFolderId: string
) => Array<Message> = (state, projectId, mailFolderId) =>
  (state.ids[projectId]?.[mailFolderId] ?? [])
    .map((id) => state.byId[id])
    .filter((message) => !!message)
    .sort((a: Message, b: Message) => {
      return Date.parse(b.receivedDateTime) - Date.parse(a.receivedDateTime);
    });

export const getAllUnreadAssignmentMessagesIds: (
  state: MessagesState,
  projectId: string,
  userId: string,
  mailFolderId: MailFolderId
) => Array<MessageId> = (state, projectId, userId, mailFolderId) => {
  const messages = getAllMessages(state, projectId, mailFolderId);

  return messages
    .filter(
      (message) =>
        message?.customSingleValueExtendedProperties?.messageAssignmentId ===
          userId &&
        message?.customSingleValueExtendedProperties
          ?.messageAssignmentCreatedDateTime &&
        !message?.customSingleValueExtendedProperties
          ?.messageAssignmentReadDateTime
    )
    .map((message) => message.id);
};

export const getMessagesById: (state: MessagesState) => {
  [messageId: string]: Message;
} = (state) => state.byId;

export const getMessage: (
  state: MessagesState,
  messageId: string
) => Message = (state, messageId) => state.byId[messageId];

export const getMessageIdsByProjecId: (state: MessagesState) => ByProjectId = (
  state
) => state.ids;

export const getIsSingleMessageFetching: (state: MessagesState) => boolean = (
  state
) => state.meta.isSingleMessageFetching;
export const getIsFetching: (
  state: MessagesState,
  projectId: ProjectId,
  mailFolderId?: MailFolderId
) => boolean = (state, projectId, mailFolderId) => {
  if (projectId === 'favorites') {
    return state.meta.isFetching;
  }
  if (mailFolderId) {
    return state.meta.fetchingProjectIds[projectId]?.includes(mailFolderId);
  }
  return (state.meta.fetchingProjectIds[projectId] ?? []).length > 0;
};
export const getHasError: (state: MessagesState) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: MessagesState) => string = (state) =>
  state.meta.errorMessage;

export const getNextLink: (
  state: MessagesState,
  projectId: string,
  mailFolderId: string
) => string = (state, projectId, mailFolderId) => {
  if (
    state.nextLink[projectId] &&
    state.nextLink[projectId][mailFolderId] !== null
  ) {
    return state.nextLink[projectId][mailFolderId];
  }
  return null;
};

export const getNextLinkState: (state: MessagesState) => NextLinkState = (
  state
) => state.nextLink;
