import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Divider } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { DateTimeString, TimeRecordId } from '../../../../models/Types';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getMonthlyCloseMeByMonth,
  getTimeRecordIds,
  getTimeRecordsByIdState,
  getUserMe,
} from '../../../../apps/main/rootReducer';
import { MonthlyClose } from '../../../../models/TimeKeeping';
import moment from 'moment';
import { useTheme } from '@prio365/prio365-react-library';
import { PrioTheme } from '../../../../theme/types';
import StatBox from '../../../../components/StatBox';
import { createSelector } from 'reselect';
import { TimeRecordsByIdState } from '../../../timeRecords/reducers/timeRecords';
import { User } from '../../../../models/User';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  label: {
    margin: 'auto',
  },
  summary: {
    margin: '12px 0px',
    overflowX: 'scroll',
    paddingBottom: theme.spacing.small,
  },
  marginDivider: {
    margin: '8px 0px',
  },
}));

export const timeRecordSumSelector = (monthDateTime: DateTimeString) =>
  createSelector<
    [
      (state: RootReducerState) => TimeRecordsByIdState,
      (state: RootReducerState) => TimeRecordId[],
      (state: RootReducerState) => User,
    ],
    number
  >(getTimeRecordsByIdState, getTimeRecordIds, getUserMe, (byId, ids, userMe) =>
    ids.reduce((sum, id) => {
      const timeRecord = byId[id];
      if (
        timeRecord &&
        timeRecord.contactId === userMe.id &&
        timeRecord.day.substring(0, 7) === monthDateTime.substring(0, 7)
      ) {
        sum += timeRecord.durationInMinutes;
      }
      return sum;
    }, 0)
  );

interface ToolbarContentProps {
  className?: string;
  date: DateTimeString;
}

export const ToolbarContent: React.FC<ToolbarContentProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, date } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const monthlyCloseMe = useSelector<RootReducerState, MonthlyClose>((state) =>
    getMonthlyCloseMeByMonth(state, date.substring(0, 7))
  );

  const timeRecordSum = useSelector(
    timeRecordSumSelector(monthlyCloseMe?.month?.substring(0, 7) ?? '')
  );

  const isCurrentMonth: boolean = useMemo(() => {
    return date.substring(0, 7) === moment().format('YYYY-MM');
  }, [date]);

  const homeOfficeDays =
    monthlyCloseMe?.timeKeepingDays?.filter(({ type }) => type === 'homeOffice')
      .length ?? 0;
  //#endregion

  return (
    <Flex.Row
      className={classNames(classes.summary, className)}
      childrenGap={12}
    >
      <Flex.Row
        flex={4}
        justifyContent="space-around"
        childrenGap={theme.spacing.extraSmall}
      >
        {/* <Flex.Item flex={1}> */}
        <StatBox
          title={t(
            'timeAndLeaveManagement:calendar.toolbar.recordedTimeKeeping'
          )}
          value={`${(monthlyCloseMe?.actualWorkHours ?? 0).toLocaleString()} h`}
          valueInParanthesis={`${t('common:of')}  ${
            isCurrentMonth
              ? (monthlyCloseMe?.expectedWorkHoursToDate ?? 0).toLocaleString()
              : (monthlyCloseMe?.expectedWorkHours ?? 0).toLocaleString()
          } h`}
          infoIconContent={
            <div>
              {t(
                `timeAndLeaveManagement:calendar.toolbar.popover1_${
                  isCurrentMonth ? 'current' : 'past'
                }`,
                {
                  expectedToDate: monthlyCloseMe?.expectedWorkHoursToDate,
                  expected: monthlyCloseMe?.expectedWorkHours,
                }
              )}
            </div>
          }
        />
        {/* </Flex.Item>
        <Flex.Item flex={1}> */}
        <StatBox
          title={t(
            'timeAndLeaveManagement:calendar.toolbar.proportionTimerecording'
          )}
          value={`${
            timeRecordSum === 0 ? 0 : (timeRecordSum / 60)?.toLocaleString()
          } h`}
          valueInParanthesis={`${t('common:of')} ${(
            monthlyCloseMe?.actualWorkHours ?? 0
          ).toLocaleString()} h`}
          infoIconContent={
            <div style={{ marginBottom: '0px' }}>
              {t('timeAndLeaveManagement:calendar.toolbar.popover2')}
            </div>
          }
        />
        {/* </Flex.Item> */}
      </Flex.Row>
      <Flex.Row flex={4} childrenGap={theme.spacing.extraSmall}>
        <StatBox
          title={t(
            'timeAndLeaveManagement:calendar.toolbar.accumulatedOvertimeHours'
          )}
          value={`${parseFloat(
            (monthlyCloseMe?.accumulatedOvertimeHours ?? 0).toFixed(2)
          ).toLocaleString()} h`}
        />
        <StatBox
          title={t('timeAndLeaveManagement:calendar.toolbar.overtimeChange')}
          value={`${(
            monthlyCloseMe?.overtimeHoursChange ?? 0
          ).toLocaleString()} h`}
          infoIconContent={
            <Overlay
              monthlyCloseMe={monthlyCloseMe}
              isCurrentMonth={isCurrentMonth}
            />
          }
        />
      </Flex.Row>
      <Flex.Row flex={3} childrenGap={theme.spacing.extraSmall}>
        <StatBox
          title={t('timeAndLeaveManagement:calendar.toolbar.homeOffice')}
          value={`${homeOfficeDays} ${
            homeOfficeDays !== 1
              ? t('common:calendar:days')
              : t('common:calendar:day')
          }`}
        />
        <StatBox
          title={t('timeAndLeaveManagement:calendar.toolbar.absence')}
          value={
            monthlyCloseMe
              ? `${monthlyCloseMe?.absenceDays ?? 0} ${
                  monthlyCloseMe.absenceDays !== 1
                    ? t('common:calendar.days')
                    : t('common:calendar.day')
                }`
              : ''
          }
        />
      </Flex.Row>
    </Flex.Row>
  );
};

export default ToolbarContent;

interface OverlayProps {
  monthlyCloseMe: MonthlyClose;
  isCurrentMonth: boolean;
}

export const Overlay: React.FC<OverlayProps> = (props) => {
  //#region ------------------------------ Defaults
  const { monthlyCloseMe, isCurrentMonth } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const nonTransferableOvertimeHoursUsed: number = useMemo(() => {
    const nonTransferableOvertimeHours =
      monthlyCloseMe?.nonTransferableOvertimeHours;
    const hoursUntilNonTransferableOvertimeHoursExceeded =
      monthlyCloseMe?.hoursUntilNonTransferableOvertimeHoursExceeded;
    if (
      nonTransferableOvertimeHours <
      hoursUntilNonTransferableOvertimeHoursExceeded
    ) {
      return 0;
    }
    if (
      nonTransferableOvertimeHours >
        hoursUntilNonTransferableOvertimeHoursExceeded &&
      hoursUntilNonTransferableOvertimeHoursExceeded >= 0
    ) {
      return (
        nonTransferableOvertimeHours -
        hoursUntilNonTransferableOvertimeHoursExceeded
      );
    }
    if (hoursUntilNonTransferableOvertimeHoursExceeded < 0) {
      return nonTransferableOvertimeHours;
    }
    return 0;
  }, [monthlyCloseMe]);

  const workingHoursBalance: number = useMemo(() => {
    const actualWorkHours = monthlyCloseMe?.actualWorkHours ?? 0;
    const expectedWorkHours = isCurrentMonth
      ? monthlyCloseMe?.expectedWorkHoursToDate
      : monthlyCloseMe?.expectedWorkHours ?? 0;
    return (
      actualWorkHours - expectedWorkHours - nonTransferableOvertimeHoursUsed
    );
  }, [monthlyCloseMe, isCurrentMonth, nonTransferableOvertimeHoursUsed]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div>
      <div style={{ marginBottom: '0px' }}>
        {t('timeAndLeaveManagement:calendar.toolbar.popover3.intro')}
      </div>
      <Divider className={classes.marginDivider} />
      <div style={{ paddingLeft: 24 }}>
        <div style={{ paddingLeft: 40 }}>
          {t('timeAndLeaveManagement:calendar.toolbar.popover3.calc1', {
            value: monthlyCloseMe?.actualWorkHours ?? 0,
          })}
        </div>
        <div>
          <span
            style={{
              width: '40px',
              display: 'inline-block',
            }}
          >
            &#8722;
          </span>
          <span>
            {t(
              `timeAndLeaveManagement:calendar.toolbar.popover3.calc2_${
                isCurrentMonth ? 'current' : 'past'
              }`,
              {
                value: monthlyCloseMe?.expectedWorkHoursToDate ?? 0,
              }
            )}
          </span>
        </div>
        {monthlyCloseMe?.nonTransferableOvertimeHours > 0 && (
          <div>
            <span
              style={{
                width: '40px',
                display: 'inline-block',
              }}
            >
              &#8722;
            </span>
            <span>
              {t(`timeAndLeaveManagement:calendar.toolbar.popover3.calc3`, {
                value: nonTransferableOvertimeHoursUsed ?? 0,
              })}
            </span>
          </div>
        )}
        <div style={{ marginBottom: '8px', marginTop: '8px' }}>
          <span
            style={{
              width: '40px',
              display: 'inline-block',
            }}
          >
            =
          </span>
          <span>
            {t(
              `timeAndLeaveManagement:calendar.toolbar.popover3.interimResult${
                workingHoursBalance < 0
                  ? 'Negative'
                  : monthlyCloseMe?.nonTransferableOvertimeHours > 0
                  ? 'PositiveNontransferables'
                  : 'Positive'
              }`,
              {
                value: workingHoursBalance ?? 0,
              }
            )}
          </span>
        </div>
        <div>
          <span
            style={{
              width: '40px',
              display: 'inline-block',
            }}
          >
            &#8722;
          </span>
          <span>
            {t('timeAndLeaveManagement:calendar.toolbar.popover3.calc4', {
              value: monthlyCloseMe?.overtimeCompensationHours ?? 0,
            })}
          </span>
        </div>
        <div>
          <span
            style={{
              width: '40px',
              display: 'inline-block',
            }}
          >
            &#8722;
          </span>
          <span>
            {t('timeAndLeaveManagement:calendar.toolbar.popover3.calc5', {
              value: monthlyCloseMe?.compensationPaymentHours ?? 0,
            })}
          </span>
        </div>
        <div style={{ marginBottom: '8px' }}>
          <span
            style={{
              width: '40px',
              display: 'inline-block',
            }}
          >
            +/&#8722;
          </span>
          <span>
            {t('timeAndLeaveManagement:calendar.toolbar.popover3.calc6', {
              value: monthlyCloseMe?.overtimeCorrection ?? 0,
            })}
          </span>
        </div>

        <div>
          <span
            style={{
              width: '40px',
              display: 'inline-block',
            }}
          >
            =
          </span>
          <span style={{ fontWeight: 500 }}>
            {t('timeAndLeaveManagement:calendar.toolbar.popover3.calc7', {
              value: monthlyCloseMe?.overtimeHoursChange ?? 0,
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
