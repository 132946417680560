import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import {
  CREATE_USER_SETTING_COMMIT,
  CREATE_USER_SETTING_ROLLBACK,
  FETCH_SETTING_COMMIT,
  FETCH_SETTING_REQUEST,
  FETCH_SETTING_ROLLBACK,
  UPDATE_USER_SETTING_COMMIT,
  UPDATE_USER_SETTING_ROLLBACK,
} from '../actions/setting';
import { Setting } from '../../../models/Settings';
import { PhoneLinePreference } from '../../../models/Types';

interface SettingAction {
  type: string;
  payload: string;
}

export interface SettingState {
  settings: Setting[];
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const initialStates: SettingState = {
  settings: [],
  isFetching: false,
  hasError: false,
};

const setting: Reducer<SettingState, SettingAction> = (
  state = initialStates,
  action
) => {
  switch (action.type) {
    case FETCH_SETTING_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CREATE_USER_SETTING_COMMIT: {
      const newSetting = action.payload as unknown as Setting;
      return {
        ...state,
        settings: [...state.settings, newSetting],
        isFetching: false,
        hasError: false,
      };
    }
    case UPDATE_USER_SETTING_COMMIT: {
      const updatedSetting = action.payload as unknown as Setting;

      const updatedSettings = state.settings.map((setting) => {
        if (setting.settingId === updatedSetting.settingId) {
          return updatedSetting;
        }
        return setting;
      });
      return {
        ...state,
        settings: updatedSettings,
        isFetching: false,
        hasError: false,
      };
    }
    case FETCH_SETTING_COMMIT: {
      // wenn keine Settings durch Nutzer gesetzt wurden (settingId !== '0000-...), werden die BE values
      // verwendet (scope="none", settingId="000-..."). Falls individuelle Settings bestehen, werden
      // nur diese berücksichtigt.
      const setSettingKeys = Array.from(
        new Set(
          (action.payload as unknown as Setting[])
            .filter(
              (setting) =>
                setting.settingId !== '00000000-0000-0000-0000-000000000000'
            )
            .map((setting) => setting.key)
        )
      );

      const filteredSettings = (action.payload as unknown as Setting[]).filter(
        (setting) =>
          !(setting.scope === 'none' && setSettingKeys.includes(setting.key))
      );

      return {
        ...state,
        settings: filteredSettings,
        isFetching: false,
        hasError: false,
      };
    }
    case FETCH_SETTING_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'settings:errorMessages.fetchSettingsError',
      };
    }
    case CREATE_USER_SETTING_ROLLBACK:
    case UPDATE_USER_SETTING_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'settings:errorMessages.updateSettingsError',
      };
    }

    case CLEAR_PRIO_CACHE: {
      return initialStates;
    }
    default:
      return state;
  }
};
export default setting;

export const getSettingsByKey = (
  state: SettingState,
  settingsKey: string
): Setting[] => state.settings?.filter((s) => s.key === settingsKey);

export const getIsSettingEnabled = (
  state: SettingState,
  key: string
): boolean => {
  const setting = state.settings?.find((s) => s.key === key);
  if (!setting) {
    return false;
  }
  const parsedValue = JSON.parse(setting?.jsonValue || '{}');
  return parsedValue?.IsEnabled;
};

export const getPhoneLinePreference = (
  state: SettingState
): PhoneLinePreference => {
  const phoneLinePreferenceSettings = state.settings?.filter(
    (s) => s.key === 'General_PhoneLinePreference'
  );

  const userPhoneLinePreference =
    phoneLinePreferenceSettings.find((setting) => setting.scope === 'user') ??
    null;

  const genericSetting = state.settings?.find(
    (s) => s.key === 'General_PhoneLinePreference'
  );
  const parsedValue = JSON.parse(
    (userPhoneLinePreference || genericSetting)?.jsonValue || '{}'
  );

  const phoneLinePreference = Object.keys(parsedValue).find(
    (key) => parsedValue[key] === true
  ) as PhoneLinePreference;
  return phoneLinePreference;
};

export const getIsFetchingAiSetting: (state: SettingState) => boolean = (
  state
) => state?.isFetching;

export const getHasErrorAiSetting: (state: SettingState) => boolean = (state) =>
  state?.hasError;

export const getErrorMessageAiSetting: (state: SettingState) => string = (
  state
) => state?.errorMessage;
