import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../theme/types';
import { makePrioStyles } from '../theme/utils';
import Flex from './Flex';
import { Button, Popover, Tooltip } from '@prio365/prio365-react-library';
import { v4 as uuid } from 'uuid';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.application.background.light,
    color: theme.colors.application.typography.default,
    width: '100%',
    padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
    minWidth: 160,
  },
  title: {
    fontSize: theme.font.fontSize.regular,
    fontWeight: theme.font.fontWeight.bold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  value: {
    fontSize: theme.font.fontSize.regular,
    fontWeight: theme.font.fontWeight.bold,
  },
  valueInParanthesis: {
    fontSize: theme.font.fontSize.small,
  },
  infoIcon: {
    height: 20,
    minWidth: 20,
    paddingInline: 4,
    marginTop: 2,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  overlay: {
    position: 'absolute',
  },
  skeleton: {
    width: '100%',
    height: 24,
    background:
      'linear-gradient(90deg, var(--prioCl-color-base-blue-60) 25%, var(--prioCl-color-base-blue-50) 37%, var(--prioCl-color-base-blue-60) 63%);',
    animation: '$loadingGradient 1.4s ease infinite',
    borderRadius: 2,
    backgroundSize: '400% 100%',
  },
  '@keyframes loadingGradient': {
    '0%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
}));

interface ComponentProps {
  className?: string;
  popoverOverlayClassName?: string;
  leftElementClassName?: string;
  rightElementClassName?: string;
  title: string;
  value: string;
  valueInParanthesis?: string;
  infoIconContent?: React.ReactNode;
  rightSideElement?: React.ReactNode;
  leftSideElement?: React.ReactNode;
  loading?: boolean;
}

export const Component: React.FC<ComponentProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    popoverOverlayClassName,
    leftElementClassName,
    rightElementClassName,
    title,
    value,
    valueInParanthesis,
    infoIconContent = undefined,
    rightSideElement = undefined,
    leftSideElement = undefined,
    loading = false,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const id = uuid();
  const [showTooltip, setShowTooltip] = useState(false);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const updateTooltip = () => {
      const element = document.getElementById(`statBox-title-${id}`);
      if (!element) return;
      if (element.scrollWidth > element.clientWidth) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    };
    updateTooltip();
    window.addEventListener('resize', updateTooltip);
    return () => {
      window.removeEventListener('resize', updateTooltip);
    };
  }, [title, id]);
  //#endregion

  return (
    <Flex.Row
      className={classNames(classes.root, className)}
      childrenGap={theme.spacing.small}
      alignItems="center"
    >
      {leftSideElement && (
        <Flex.Item className={leftElementClassName}>
          {leftSideElement}
        </Flex.Item>
      )}
      <Flex.Column
        childrenGap={theme.spacing.regular}
        height="100%"
        width="100%"
        justifyContent="space-between"
      >
        <Flex.Row
          alignItems="flex-start"
          childrenGap={theme.spacing.small}
          width="100%"
        >
          <Tooltip overlay={showTooltip ? title : undefined} placement="top">
            <div
              id={`statBox-title-${id}`}
              className={classNames(classes.title, 'statBox-title')}
            >
              {title}
            </div>
          </Tooltip>
          {infoIconContent && (
            <Popover
              overlayClassName={classNames(
                classes.overlay,
                popoverOverlayClassName
              )}
              content={infoIconContent}
            >
              <Button
                className={classes.infoIcon}
                iconProp={['fal', 'info-circle']}
                iconColor={theme.colors.application.typography.muted}
                type="link"
              ></Button>
            </Popover>
          )}
        </Flex.Row>
        {loading ? (
          <div className={classes.skeleton} style={{ marginRight: 8 }} />
        ) : (
          <Flex.Row childrenGap={theme.spacing.regular} alignItems="flex-end">
            <div className={classNames(classes.value, 'statBox-value')}>
              {value}
            </div>
            {valueInParanthesis && (
              <div
                className={classNames(
                  classes.valueInParanthesis,
                  'statBox-value-in-paranthesis'
                )}
              >{`(${valueInParanthesis})`}</div>
            )}
          </Flex.Row>
        )}
      </Flex.Column>
      {rightSideElement && (
        <Flex.Item className={rightElementClassName}>
          {rightSideElement}
        </Flex.Item>
      )}
    </Flex.Row>
  );
};

export default Component;
