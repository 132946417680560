import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Divider, Form, InputNumber } from 'antd';
import { Button, Drawer, TextArea } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import CustomSingleDatePicker from '../../../../components/CustomSingleDatePicker';
import moment from 'moment';
import {
  CompensationPayment,
  CompensationPaymentsCalculatedData,
  CompensationPaymentSearchResultItem,
} from '../../../../models/TimeKeeping';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getContact,
} from '../../../../apps/main/rootReducer';
import { Contact } from '../../../../models/Contact';
import equals from 'deep-equal';
import { apiUpdateCompensationPayment } from '../../../timeKeeping/api';
import { fullDateFormatFormatString } from '../../../../util';
import classNames from 'classnames';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  label: {
    fontSize: theme.old.typography.fontSize.label,
    color: theme.old.typography.colors.muted,
    paddingBottom: 4,
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  dividerMargin: {
    margin: `0px ${theme.old.spacing.unit(3)}px`,
  },
  notes: { width: '100%' },
  danger: {
    '&:not([disabled])': {
      color: theme.old.palette.chromaticPalette.red,
      '&:hover': {
        backgroundColor: theme.old.palette.chromaticPalette.red,
        color: theme.old.palette.chromaticPalette.white,
      },
      '& > .prio-button-icon': {
        color: theme.old.palette.chromaticPalette.red,
      },
    },
  },
}));

interface HREditCompensationPaymentDrawerProps {
  className?: string;
  searchItem: CompensationPaymentSearchResultItem;
  drawerOpen: boolean;
  onSave: VoidFunction;
  onCancel: VoidFunction;
  onPayOut: (
    payment: CompensationPaymentSearchResultItem
  ) => Promise<void> | void;
  onDelete: (
    payment: CompensationPaymentSearchResultItem
  ) => Promise<void> | void;
}

export const HREditCompensationPaymentDrawer: React.FC<
  HREditCompensationPaymentDrawerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    searchItem,
    drawerOpen,
    onSave,
    onCancel,
    onPayOut,
    onDelete,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  const isCompansationPaymentPaid = !!searchItem?.data?.payoutDate;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { optimisticWrite } = useFilterContext<
    CompensationPayment,
    CompensationPaymentsCalculatedData
  >();

  const officeId = searchItem?.calculated?.officeId;

  const [isEqual, setIsEqual] = useState<boolean>(true);

  const [updatedSearchItem, setUpdatedSearchItem] =
    useState<CompensationPaymentSearchResultItem>(searchItem);

  const employee = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, searchItem?.data?.employeeId)
  );

  const createdBy = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, searchItem?.data?.createdBy)
  );

  const paidBy = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, searchItem?.data?.paidBy)
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleSave = async () => {
    optimisticWrite([
      {
        ...updatedSearchItem,
        method: 'update',
        callback: async () => {
          const { result, data } = await apiUpdateCompensationPayment(
            updatedSearchItem?.data,
            searchItem?.data.compensationPaymentId,
            officeId
          );

          return {
            result,
            data: result.ok
              ? {
                  data,
                  calculated: searchItem.calculated,
                }
              : null,
          };
        },
      },
    ]);
    onSave();
  };

  const handleOnCancel = () => {
    onCancel();
  };

  const handleOnPayOut = async () => {
    onPayOut(searchItem);
  };

  const handleOnDelete = async () => {
    onDelete(searchItem);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setUpdatedSearchItem(searchItem);
  }, [searchItem]);

  useEffect(() => {
    setIsEqual(equals(updatedSearchItem, searchItem));
  }, [updatedSearchItem, searchItem]);
  //#endregion

  return (
    <Drawer
      title={t(
        'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.title'
      )}
      closable={true}
      onClose={handleOnCancel}
      visible={drawerOpen}
      className={classNames(classes.root, className)}
    >
      <Form<CompensationPayment>
        layout="vertical"
        className={classes.fullHeight}
        onFinish={handleSave}
        onChange={() => setIsEqual(equals(updatedSearchItem, searchItem))}
      >
        <Flex.Column
          childrenGap={theme.old.spacing.unit(2)}
          className={classes.fullHeight}
        >
          <Flex.Item flex={1}>
            <Flex.Row childrenGap={theme.old.spacing.unit(4)}>
              <Flex.Column flex={1}>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.employee'
                    )}
                  >
                    {`${employee?.firstName ?? ''} ${employee?.lastName ?? ''}`}
                  </Form.Item>
                </Flex.Item>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.hours'
                    )}
                  >
                    <InputNumber
                      value={updatedSearchItem?.data?.hours}
                      placeholder={searchItem?.data?.hours?.toString()}
                      onChange={(value) => {
                        if (value) {
                          setUpdatedSearchItem({
                            ...updatedSearchItem,
                            data: {
                              ...updatedSearchItem?.data,
                              hours: value,
                            },
                          });
                        }
                      }}
                      onBlur={(value) => {
                        if (!value.target.value) {
                          setUpdatedSearchItem({
                            ...updatedSearchItem,
                            data: {
                              ...updatedSearchItem?.data,
                              hours: searchItem?.data?.hours,
                            },
                          });
                        }
                      }}
                      min={0.25}
                      step={0.25}
                      precision={2}
                      formatter={(value) => `${value}`.replace('.', ',')}
                      parser={(value) =>
                        parseFloat(value.replace(',', '.')) as 0.25
                      }
                      disabled={isCompansationPaymentPaid}
                    />
                  </Form.Item>
                </Flex.Item>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.expectedPayoutDate'
                    )}
                  >
                    <CustomSingleDatePicker
                      id="create_compensation_payments_modal_id"
                      value={moment(
                        updatedSearchItem?.data?.expectedPayoutDate
                      )}
                      onChange={(date) => {
                        setUpdatedSearchItem({
                          ...updatedSearchItem,
                          data: {
                            ...updatedSearchItem?.data,
                            expectedPayoutDate: date
                              ?.startOf('day')
                              .toISOString(true)
                              .split('.')[0],
                          },
                        });
                      }}
                      anchorDirection={'ANCHOR_RIGHT'}
                      small={true}
                      regular={false}
                      twoMonths={false}
                      withFullScreenPortal={false}
                      daySize={30}
                      hideKeyboardShortcutsPanel={true}
                      showDefaultInputIcon={true}
                      inputIconPosition={'after'}
                      disabled={isCompansationPaymentPaid}
                    />
                  </Form.Item>
                </Flex.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.createdBy'
                    )}
                  >
                    {`${createdBy?.firstName ?? ''} ${
                      createdBy?.lastName ?? ''
                    }`}
                  </Form.Item>
                </Flex.Item>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.createdDate'
                    )}
                  >
                    {searchItem?.data?.createdDate &&
                      fullDateFormatFormatString(searchItem?.data?.createdDate)}
                  </Form.Item>
                </Flex.Item>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.paidBy'
                    )}
                  >
                    {searchItem?.data?.payoutDate
                      ? `${paidBy?.firstName ?? ''} ${paidBy?.lastName ?? ''}`
                      : '-'}
                  </Form.Item>
                </Flex.Item>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.payoutDate'
                    )}
                  >
                    {searchItem?.data?.payoutDate
                      ? fullDateFormatFormatString(searchItem?.data?.payoutDate)
                      : '-'}
                  </Form.Item>
                </Flex.Item>
              </Flex.Column>
            </Flex.Row>
            <Divider />
            <Flex.Row>
              <Flex.Item className={classes.fullWidth}>
                <Form.Item
                  className={classes.fullWidth}
                  label={t(
                    'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.notes'
                  )}
                >
                  <TextArea
                    className={classes.notes}
                    disabled={isCompansationPaymentPaid}
                    value={updatedSearchItem?.data?.notes}
                    onChange={(notes) => {
                      setUpdatedSearchItem({
                        ...updatedSearchItem,
                        data: {
                          ...updatedSearchItem?.data,
                          notes: notes.target.value,
                        },
                      });
                    }}
                  >
                    {updatedSearchItem?.data?.notes}
                  </TextArea>
                </Form.Item>
              </Flex.Item>
            </Flex.Row>
          </Flex.Item>
          {isCompansationPaymentPaid && (
            <Flex.Row clasName={classes.fullWidth}>
              <Alert
                message={t(
                  'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.warningAlreadyPaid'
                )}
                type="warning"
              />
            </Flex.Row>
          )}
          <Flex.Row justifyContent="flex-end">
            <Button
              disabled={isCompansationPaymentPaid || !isEqual}
              onClick={handleOnPayOut}
              style={{ marginRight: '8px' }}
            >
              {t(
                'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.payOut'
              )}
            </Button>
            <Button
              disabled={isCompansationPaymentPaid}
              onClick={handleOnDelete}
              style={{ marginRight: 'auto' }}
              className={classes.danger}
              type="link"
            >
              {t(
                'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.delete'
              )}
            </Button>
            <Button
              disabled={false}
              onClick={handleOnCancel}
              type="link"
              style={{ marginRight: '8px' }}
            >
              {t(
                'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.cancel'
              )}
            </Button>
            <Button
              type="primary"
              disabled={isCompansationPaymentPaid || isEqual}
              onClick={handleSave}
            >
              {t(
                'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.save'
              )}
            </Button>
          </Flex.Row>
        </Flex.Column>
      </Form>
    </Drawer>
  );
};

export default HREditCompensationPaymentDrawer;
