import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMe } from '../../../apps/main/rootReducer';
import { ProjectDistributionList } from '../../../models/ProjectDistributionList';
import { ProjectId } from '../../../models/Types';
import { createProjectDistributionList } from '../actions';
import { createTemporaryId } from '../../../util';
import { Input, Modal, useTheme } from '@prio365/prio365-react-library';
import Flex from '../../../components/Flex';
import ProjectPickerPrioCL from './ProjectPickerPrioCL';
import { isProjectAdmin, isProjectAssistance } from '../utils';
import { useParams } from 'react-router-dom';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  label: {
    fontSize: theme.font.fontSize.extraSmall,
    color: theme.colors.application.typography.muted,
    paddingBottom: theme.spacing.extraSmall,
  },
  error: {
    paddingTop: theme.spacing.extraSmall,
    fontSize: theme.font.fontSize.extraSmall,
    color: theme.colors.base.red.default,
  },
}));

interface ProjectDistributionListDuplicateModalProps {
  selectedDistributionList: ProjectDistributionList | null;
  existingNames: string[];
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
}

export const ProjectDistributionListDuplicateModal: React.FC<
  ProjectDistributionListDuplicateModalProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    selectedDistributionList,
    existingNames,
    isModalVisible,
    setIsModalVisible,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const dispatch = useDispatch();
  const userMe = useSelector(getUserMe);
  const { projectId } = useParams();

  const [destinationProjectId, setDestinationProjectId] =
    useState<ProjectId>(projectId);
  const [copiedDistributionListName, setCopiedDistributionListName] = useState<
    string | undefined
  >(undefined);

  const isUniqueName = useMemo(
    () => !existingNames.includes(copiedDistributionListName?.trim()),
    [copiedDistributionListName, existingNames]
  );

  const showError = useMemo(
    () => !isUniqueName && projectId === destinationProjectId,
    [isUniqueName, projectId, destinationProjectId]
  );

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const duplicateDistributionList = () => {
    dispatch(
      createProjectDistributionList(
        {
          name: copiedDistributionListName,
          projectId: destinationProjectId,
          projectDistributionListId: createTemporaryId(),
          projectDistributionContacts:
            selectedDistributionList.projectDistributionContacts,
        },
        createTemporaryId(),
        projectId
      )
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setCopiedDistributionListName(
      `${selectedDistributionList?.name} ${t(
        'projects:projectDistributionList.table.deleteModal.nameSuffix'
      )}`
    );
  }, [selectedDistributionList, t]);
  //#endregion

  return (
    <Modal
      title={t('projects:projectDistributionList.table.deleteModal.title')}
      visible={isModalVisible}
      onOk={() => {
        duplicateDistributionList();
        setIsModalVisible(false);
      }}
      onClose={() => {
        setIsModalVisible(false);
      }}
      okText={t('common:actions.duplicate')}
      cancelText={t('common:actions.cancel')}
      maskClosable={false}
      okButtonProps={{
        disabled: showError,
      }}
    >
      <Flex.Column childrenGap={theme.spacing.regular} width={'100%'}>
        <div>
          <div className={classes.label}>
            {t('projects:projectDistributionList.table.deleteModal.label.name')}
          </div>
          <Input
            onChange={(value) => setCopiedDistributionListName(value)}
            defaultValue={`${selectedDistributionList?.name} ${t(
              'projects:projectDistributionList.table.deleteModal.nameSuffix'
            )}`}
            value={copiedDistributionListName}
            full
            isValid={!showError}
          />
          {showError && (
            <div className={classes.error}>
              {t('projects:projectDistributionList.table.deleteModal.error')}
            </div>
          )}
        </div>
        <div>
          <div className={classes.label}>
            {t(
              'projects:projectDistributionList.table.deleteModal.label.project'
            )}
          </div>
          <ProjectPickerPrioCL
            value={destinationProjectId}
            size="default"
            filter={(project) =>
              isProjectAdmin(project, userMe) ||
              isProjectAssistance(project, userMe)
            }
            multiple={false}
            onChange={(value) => setDestinationProjectId(value as ProjectId)}
          />
        </div>
      </Flex.Column>
    </Modal>
  );
};

export default ProjectDistributionListDuplicateModal;
