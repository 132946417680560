import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getContactsIsFetching,
  getCompaniesIsFetching,
} from '../../../apps/main/rootReducer';
import { Contact } from '../../../models/Contact';
import { Company } from '../../../models/Company';
import { ContactListItem } from './ContactList';
import {
  ProjectId,
  ContactSearchType,
  ContactType,
  OfficeId,
} from '../../../models/Types';
import {
  InternalProjectContactItem,
  ExternalProjectContactItem,
} from '../../../models/ProjectContacts';
import {
  internalProjectContactsSelector,
  externalProjectContactsSelector,
  projectCompaniesSelector,
} from '../../projects/selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiSearchContacts, apiSearchContactsByProps } from '../api';
import { ContactProps } from './ContactSelectionList';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import useContactsContext from '../hooks/useContactsProvider';
import useCompaniesContext from '../../companies/hooks/useCompaniesContext';

interface ContactSearchProps {
  searchTerm: string;
  type: ContactSearchType;
  selectedContactProp?: ContactProps;
  projectId?: ProjectId;
  officeId?: OfficeId;
  isOnline?: boolean;
  includeArchived?: boolean;
}

function useContactSearch(
  props: ContactSearchProps
): [ContactListItem[], boolean] {
  const {
    projectId,
    officeId,
    isOnline,
    type,
    searchTerm: notFilteredSearchTerm,
    selectedContactProp = undefined,
    includeArchived = false,
  } = props;

  const { t } = useTranslation();

  const searchTerm = notFilteredSearchTerm.replace(/%/, '');

  const { contacts } = useContactsContext(includeArchived);
  const isFetchingContacts = useSelector(getContactsIsFetching);

  const [onlineContacts, setOnlineContacts] = useState<Contact[]>([]);
  const [isFetchingOnline, setIsFetchingOnline] = useState<boolean>(false);

  const [propSearchItems, setPropSearchItems] = useState<ContactListItem[]>([]);
  const [isPropSearchItemsFetching, setIsPropSearchItemsFetching] =
    useState<boolean>(false);

  const prefixData: ContactProps[] = useMemo(
    () => ['FirstName', 'LastName', 'Notes', 'City', 'Title'],
    []
  );

  useEffect(() => {
    if (
      isOnline &&
      !(type === 'allCompanies' || type === 'companies') &&
      searchTerm !== '' &&
      searchTerm.length > 2
    ) {
      const fetchOnlineContacts = async () => {
        const isInternal =
          type === 'allInternalContacts' ||
          type === 'internal' ||
          type === 'allInternalContactsInOffice';
        setIsFetchingOnline(true);
        const { result, data } = await apiSearchContacts(
          searchTerm,
          isInternal ? 'InternalContact' : null
        );
        if (result.status >= 200 && result.status < 300) {
          setOnlineContacts(data.filter((item) => !item.isArchived));
        }
        setIsFetchingOnline(false);
      };
      fetchOnlineContacts();
    } else if (isOnline && searchTerm !== '' && searchTerm.length <= 2) {
      setOnlineContacts([]);
    }
  }, [isOnline, searchTerm, type]);

  const { companies: allCompanies } = useCompaniesContext();
  const isFetchingCompanies = useSelector(getCompaniesIsFetching);

  const internalProjectContacts: InternalProjectContactItem[] = useSelector(
    (state) =>
      projectId && !type.startsWith('all')
        ? internalProjectContactsSelector(projectId)(state)
        : null
  );
  const externalProjectContacts: ExternalProjectContactItem[] = useSelector(
    (state) =>
      projectId && !type.startsWith('all')
        ? externalProjectContactsSelector(projectId)(state)
        : null
  );
  const projectCompanies: Company[] = useSelector<RootReducerState, Company[]>(
    (state) =>
      projectId && type === 'companies'
        ? projectCompaniesSelector(projectId)(state)
        : null
  );

  const items: ContactListItem[] = useMemo(() => {
    switch (type) {
      case 'all': {
        if (isOnline) {
          const contactItems: ContactListItem[] = onlineContacts.map(
            (contact) => ({
              type: 'contact',
              value: contact,
            })
          );
          return [
            ...contactItems,
            ...filterCompanys(allCompanies, searchTerm),
          ].sort((a, b) => {
            let aTerm: string;
            let bTerm: string;
            if (a.type === 'contact') {
              aTerm = (a.value as Contact).lastName;
            } else {
              aTerm = (a.value as Company).fullName;
            }
            if (b.type === 'contact') {
              bTerm = (b.value as Contact).lastName;
            } else {
              bTerm = (b.value as Company).fullName;
            }
            return (aTerm ?? '').localeCompare(bTerm ?? '');
          });
        }
        const companyItems: ContactListItem[] = allCompanies.map((company) => ({
          type: 'company',
          value: company,
        }));
        const contactItems: ContactListItem[] = contacts.map((contact) => ({
          type: 'contact',
          value: contact,
        }));

        return filterAndSortMixedItems(
          [...contactItems, ...companyItems],
          searchTerm
        );
      }
      case 'members': {
        const internalContactItems: Contact[] = internalProjectContacts.map(
          (item) => item.contact
        );
        const externalContactItems: Contact[] = externalProjectContacts.map(
          (item) => item.contact
        );
        return filterContacts(
          [...internalContactItems, ...externalContactItems],
          searchTerm,
          undefined,
          undefined
        );
      }
      case 'internal': {
        const internalContactItems: Contact[] = internalProjectContacts.map(
          (item) => item.contact
        );
        return filterContacts(
          internalContactItems,
          searchTerm,
          undefined,
          undefined
        );
      }
      case 'external': {
        const externalContactItems: Contact[] = externalProjectContacts.map(
          (item) => item.contact
        );
        return filterContacts(
          externalContactItems,
          searchTerm,
          undefined,
          undefined
        );
      }
      case 'companies':
        return filterCompanys(projectCompanies, searchTerm);
      case 'allContacts':
        return isOnline
          ? filterOnlineContacts(
              onlineContacts,
              searchTerm,
              undefined,
              undefined
            )
          : filterContacts(contacts, searchTerm, undefined, undefined);
      case 'allInternalContacts':
        return isOnline
          ? filterOnlineContacts(
              onlineContacts,
              searchTerm,
              'InternalContact',
              undefined
            )
          : filterContacts(contacts, searchTerm, 'InternalContact', undefined);
      case 'allInternalContactsInOffice':
        return isOnline
          ? filterOnlineContacts(
              onlineContacts,
              searchTerm,
              'InternalContact',
              officeId
            )
          : filterContacts(contacts, searchTerm, 'InternalContact', officeId);
      case 'allCompanies':
        return filterCompanys(allCompanies, searchTerm);
    }
  }, [
    allCompanies,
    contacts,
    externalProjectContacts,
    internalProjectContacts,
    isOnline,
    type,
    officeId,
    onlineContacts,
    projectCompanies,
    searchTerm,
  ]);

  const searchForContactProps = useCallback(
    async (searchInput: string, controller?: AbortController) => {
      const signal = controller.signal;

      const forceResult = false;
      const forceThreshold = 1000;

      const searchStringProp = `${
        prefixData.find((prefix) => prefix === selectedContactProp)
          ? 'Data'
          : 'Calculated'
      }.${selectedContactProp} like '${searchInput}'`;

      const searchStringTypeFilter = `${
        type === 'allCompanies' || type === 'allContacts'
          ? `%26Calculated.${'CompanyContact'} eq ${
              type === 'allCompanies' ? "'Company'" : "'Contact'"
            }`
          : ''
      }`;

      const searchStringArchivedFilter = `${
        !includeArchived ? `%26Data.${'IsArchived'} eq 'false'` : ''
      }`;

      const searchString = `${searchStringProp}${searchStringTypeFilter}${searchStringArchivedFilter}`;

      if (!!selectedContactProp) {
        setIsPropSearchItemsFetching(true);
        const { result, data } = await apiSearchContactsByProps(
          searchString,
          forceThreshold,
          forceResult,
          signal
        );

        const contacts: ContactListItem[] = data?.items?.map((item) => {
          const companyValue = item['data']['company'];
          const contactValue = item['data']['contact'];
          if (companyValue) {
            return { value: companyValue, type: 'company' } as ContactListItem;
          } else if (contactValue) {
            return { value: contactValue, type: 'contact' } as ContactListItem;
          } else return null;
        });
        setPropSearchItems(contacts);
        setIsPropSearchItemsFetching(false);

        if (result.status >= 400) {
          notification.open({
            message: t('common:error'),
            description: t(`contacts:errorMessages.searchContactsbyPropsError`),
          });
        }
      }
    },
    [selectedContactProp, includeArchived, prefixData, type, t]
  );

  useEffect(() => {
    let controller = new AbortController();

    searchForContactProps(searchTerm, controller);
    return () => {
      if (controller) controller.abort();
    };
  }, [searchTerm, searchForContactProps]);

  useEffect(() => {
    if (!!selectedContactProp) {
      setPropSearchItems([]);
    } else {
      setPropSearchItems(null);
    }
  }, [selectedContactProp, searchTerm]);

  if (selectedContactProp)
    return [propSearchItems ?? [], isPropSearchItemsFetching];
  else
    return [
      items,
      isOnline
        ? isFetchingOnline || isFetchingCompanies
        : isFetchingContacts || isFetchingCompanies,
    ];
}

const filterCompanys = (
  companies: Company[],
  searchTerm: string
): ContactListItem[] =>
  companies
    .filter((company) => companyFound(company, searchTerm))
    .map((company) => ({
      type: 'company',
      value: company,
    }));

const filterContacts = (
  contacts: Contact[],
  searchTerm: string,
  contactType?: ContactType,
  officeId?: OfficeId
): ContactListItem[] => {
  return contacts
    .filter((contact) => {
      if (contactType && contact.contactType !== contactType) {
        return false;
      }
      if (officeId && contact.officeId !== officeId) {
        return false;
      }
      return contactFound(contact, searchTerm);
    })
    .map((contact) => ({
      type: 'contact',
      value: contact,
    }));
};

const filterOnlineContacts = (
  contacts: Contact[],
  searchTerm: string,
  contactType?: ContactType,
  officeId?: OfficeId
): ContactListItem[] =>
  contacts
    .filter((contact) => {
      if (contactType && contact.contactType !== contactType) {
        return false;
      }
      if (officeId && contact.officeId !== officeId) {
        return false;
      }
      return contactFound(contact, searchTerm);
    })
    .map((contact) => ({
      type: 'contact',
      value: contact,
    }));

const companyFound = (company: Company, searchTerm: string): boolean => {
  // prettier-ignore
  const searchTermLowerCase = searchTerm?.toLocaleLowerCase();
  // prettier-ignore
  if (company.fullName?.toLocaleLowerCase()?.includes(searchTermLowerCase)) return true;
  // prettier-ignore
  if (company.fullName2?.toLocaleLowerCase()?.includes(searchTermLowerCase)) return true;
  // prettier-ignore
  if (company.shortName?.toLocaleLowerCase()?.includes(searchTermLowerCase)) return true;
  // prettier-ignore
  if (company.offices.some((o) => o.eMail?.toLocaleLowerCase()?.includes(searchTermLowerCase))) return true;
  return false;
};

const parsePhoneNumber = (phoneNumber: string, hasPlus49: boolean): string => {
  const specialChars = /[()_-]/g;
  const spaces = /\s/g;
  return phoneNumber
    ?.slice(0, hasPlus49 ? phoneNumber.length : undefined)
    ?.replace('+49', '0')
    ?.replace(specialChars, '')
    ?.replace(spaces, '');
};

const contactFound = (contact: Contact, searchTerm: string): boolean => {
  const hasPlus49 = searchTerm.includes('+49');
  const _splittedSearchTerm = searchTerm
    .replace('+49', '0')
    .split(/\s/)
    .map((split) => split.toLocaleLowerCase());

  const allSplitsAreNumbers = _splittedSearchTerm.every((split) =>
    /^\d+$/.test(split)
  );

  const splittedSearchTerm = allSplitsAreNumbers
    ? [_splittedSearchTerm.join('')]
    : _splittedSearchTerm;

  const partialContact = {
    title: contact.title,
    firstName: contact.firstName,
    lastName: contact.lastName,
    department: contact.department,
    position: contact.position,
    eMail: contact.eMail,
    eMail2: contact.eMail2,
    phone: parsePhoneNumber(contact.phone, hasPlus49),
    phone2: parsePhoneNumber(contact.phone2, hasPlus49),
    cellphone: parsePhoneNumber(contact.cellphone, hasPlus49),
    cellphone2: parsePhoneNumber(contact.cellphone2, hasPlus49),
    fax: parsePhoneNumber(contact.fax, hasPlus49),
  };

  const contactArray = Object.values(partialContact)
    .filter((value) => !!value)
    .map((value) => value.toLocaleLowerCase());

  let matches = 0;
  splittedSearchTerm.forEach((split) => {
    if (contactArray.find((value) => value.includes(split))) {
      matches++;
    }
  });

  if (matches >= splittedSearchTerm.length) {
    return true;
  }

  return false;
};

const filterAndSortMixedItems: (
  items: ContactListItem[],
  searchTerm: string
) => ContactListItem[] = (items: ContactListItem[], searchTerm: string) => {
  return items
    .filter((item) => {
      return (
        (item.type === 'contact' &&
          contactFound(item.value as Contact, searchTerm)) ||
        (item.type === 'company' &&
          companyFound(item.value as Company, searchTerm))
      );
    })
    .sort((a, b) => {
      let aTerm: string;
      let bTerm: string;
      if (a.type === 'contact') {
        aTerm = (a.value as Contact).lastName;
      } else {
        aTerm = (a.value as Company).fullName;
      }
      if (b.type === 'contact') {
        bTerm = (b.value as Contact).lastName;
      } else {
        bTerm = (b.value as Company).fullName;
      }
      return (aTerm ?? '').localeCompare(bTerm ?? '');
    });
};
export default useContactSearch;
