import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Form, Menu, notification, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import Flex from '../../../../components/Flex';
import DriveItemBreadcrumb from '../DriveItemBreadcrumb/DriveItemBreadcrumb';
import { makePrioStyles } from '../../../../theme/utils';
import PrioSpinner from '../../../../components/PrioSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewFolderForm, {
  CreateFolderRequest,
} from '../../../settings/components/NewFolderForm';
import { DriveItemId, ProjectId } from '../../../../models/Types';
import { DriveItem } from '../../../../models/Drive';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentFolderChildren,
  getCurrentFolderItem,
  getCurrentRemoteItem,
  getDriveItemIsFetching,
  getProject,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import {
  abortDriveItemFetch,
  driveItemNewFolderCreated,
  DriveItemsFetchContext,
  fetchDriveItemsSagaAction,
} from '../../actions';
import { apiCreateDriveFolder } from '../../api';
import { Project } from '../../../../models/Project';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { isDriveItemFolder } from '../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-drawer-body': {
      overflow: 'hidden',
    },
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  menu: {
    background: 'none',
    border: 'none',
    overflow: 'auto',
    flex: 1,
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
}));

interface FolderSelectionDrawerProps {
  visible: boolean;
  selectedFolder?: DriveItemId;
  onOk: (
    selectedFolder: DriveItem | null,
    selectedProjectId?: ProjectId
  ) => void;
  onCancel: () => void;
  projectId: ProjectId;
  allowProjectChange?: boolean;
  excludedFolders?: DriveItemId[];
  setProjectId?: (projectId: ProjectId) => void;
}

export const FolderSelectionDrawer: React.FC<FolderSelectionDrawerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    visible,
    onOk,
    onCancel,
    selectedFolder,
    projectId,
    allowProjectChange,
    excludedFolders,
    setProjectId,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<CreateFolderRequest>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [selectedProjectId, setSelectedProjectId] =
    useState<ProjectId>(projectId);
  const selectedProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, selectedProjectId)
  );

  const groupId = selectedProject?.groupId;

  const [driveItemId, setDriveItemId] = useState<DriveItemId>(selectedFolder);

  const driveItem = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, driveItemId ?? `root-group-${groupId}`)
  );

  const driveItemChildren = useSelector<RootReducerState, DriveItem[]>(
    (state) => {
      const items = getCurrentFolderChildren(
        state,
        driveItemId ?? `root-group-${groupId}`
      );
      return items;
    }
  );

  const { isFetching } = useSelector<RootReducerState, { isFetching: boolean }>(
    (state) =>
      getDriveItemIsFetching(
        state,
        !driveItemId ? `root-group-${groupId}` : driveItemId
      )
  );

  const [folderCreation, setFolderCreation] = useState<boolean>(false);

  const [newFolderCreating, setNewFolderCreating] = useState<boolean>(false);
  const currentRemoteItem = useSelector(getCurrentRemoteItem);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const selectFolder = (folderId: DriveItemId) => {
    setDriveItemId(folderId);
  };

  const handleFinish = async (value: CreateFolderRequest) => {
    setFolderCreation(false);
    if (value.folderName === '') {
      return;
    }
    setNewFolderCreating(true);
    const { data } = await apiCreateDriveFolder(
      groupId,
      driveItem?.id,
      value.folderName
    );
    setNewFolderCreating(false);
    if (data) {
      dispatch(driveItemNewFolderCreated(data, driveItemId, groupId));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.newFolderCreateError'),
      });
    }
    setFolderCreation(false);
  };

  const toggleFolderCreation = useCallback(async () => {
    if (!!groupId) {
      if (folderCreation) {
        setFolderCreation(false);
      } else {
        setFolderCreation(true);
      }
    }
  }, [groupId, folderCreation]);

  const handleCancel = useCallback(() => {
    setFolderCreation(false);
    setNewFolderCreating(false);
    setDriveItemId(selectedFolder);
    onCancel();
    dispatch(
      abortDriveItemFetch(
        DriveItemsFetchContext.Other,
        selectedFolder ?? `root-group-${groupId}`,
        true
      )
    );
  }, [selectedFolder, groupId, onCancel, dispatch]);
  //#endregion

  //#region ------------------------------ Components
  const footer = useMemo(
    () => (
      <Flex.Row>
        <Flex.Item flex={1}>
          <Button
            disabled={
              isFetching ||
              newFolderCreating ||
              (driveItemId !== null && !driveItem) ||
              driveItem?.name === 'root'
            }
            onClick={toggleFolderCreation}
            iconProp={['fal', folderCreation ? 'chevron-left' : 'folder-plus']}
            type="link"
          >
            {folderCreation
              ? t('documents:folderSelectionModal.back')
              : t('documents:folderSelectionModal.newFolder')}
          </Button>
        </Flex.Item>
        <Button onClick={handleCancel} type="link">
          {t('documents:folderSelectionModal.cancelText')}
        </Button>
        <Button
          onClick={() => {
            if (folderCreation) {
              form.submit();
            } else {
              onOk(driveItem, selectedProjectId);
            }
            dispatch(
              abortDriveItemFetch(
                DriveItemsFetchContext.Other,
                driveItemId ?? `root-group-${groupId}`,
                true
              )
            );
          }}
          disabled={
            isFetching ||
            newFolderCreating ||
            (driveItemId !== null && !driveItem) ||
            driveItem?.name === 'root'
          }
          loading={isFetching}
        >
          {t(
            `documents:folderSelectionModal.${
              folderCreation ? 'okTextFolder' : 'okText'
            }`
          )}
        </Button>
      </Flex.Row>
    ),
    [
      isFetching,
      driveItemId,
      driveItem,
      folderCreation,
      form,
      newFolderCreating,
      selectedProjectId,
      groupId,
      dispatch,
      toggleFolderCreation,
      handleCancel,
      onOk,
      t,
    ]
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (visible && projectId && groupId) {
      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          driveItemId,
          driveItemId === null || driveItemId === undefined,
          DriveItemsFetchContext.Other
        )
      );
    }
  }, [dispatch, groupId, driveItemId, visible, projectId]);

  useEffect(() => {
    setDriveItemId(selectedFolder);
  }, [selectedFolder]);

  useEffect(() => {
    setSelectedProjectId(projectId);
  }, [projectId]);
  //#endregion

  return (
    <Drawer
      visible={visible}
      className={classes.root}
      onClose={handleCancel}
      width={600}
      closable
    >
      <Flex.Column
        childrenGap={theme.old.spacing.unit(2)}
        className={classes.content}
      >
        <Typography.Title>
          {t('documents:folderSelectionModal.title')}
        </Typography.Title>
        {!folderCreation && (
          <DriveItemBreadcrumb
            driveItem={driveItem}
            onItemClick={selectFolder}
            remoteItem={currentRemoteItem}
            project={selectedProject}
            onRootChange={(id) => {
              setSelectedProjectId(id);
              if (setProjectId) {
                setProjectId(id);
              }
              setDriveItemId(null);
            }}
            addProjectList={allowProjectChange}
            disableLinkNavigation
          />
        )}
        {newFolderCreating ||
        (isFetching && driveItemChildren?.length === 0) ? (
          <div className="prio-flex-center-center prio-flex-column">
            <PrioSpinner />
          </div>
        ) : !folderCreation ? (
          <Menu className={classes.menu}>
            {driveItemChildren
              ?.filter(
                (driveItem) =>
                  isDriveItemFolder(driveItem) &&
                  !excludedFolders?.includes(driveItem.id)
              )
              ?.map((folder) => (
                <Menu.Item
                  key={folder.id}
                  onClick={() => selectFolder(folder.id)}
                >
                  <FontAwesomeIcon
                    fixedWidth
                    icon={['fal', 'folder']}
                    className={classes.icon}
                  />

                  {folder.name}
                </Menu.Item>
              ))}
          </Menu>
        ) : (
          <Flex.Item flex={1}>
            <NewFolderForm
              currentPath={[
                driveItem?.name === 'root'
                  ? t('documents:rootFolder')
                  : driveItem?.name ?? '',
              ]}
              form={form}
              onFinish={handleFinish}
            />
          </Flex.Item>
        )}
        {footer}
      </Flex.Column>
    </Drawer>
  );
};

export default FolderSelectionDrawer;
