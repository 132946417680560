import { ApiResult, apiUrl } from '../../../api';
import { GenericSearchResult } from '../../../components/Filter/types';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiFetchSearch: <ResultData = unknown, CalculatedData = unknown>(
  searchType: string,
  searchString: string,
  forceThreshold: number,
  forceResult: boolean,
  signal?: AbortSignal
) => Promise<
  ApiResult<GenericSearchResult<ResultData, CalculatedData>>
> = async (searchType, searchString, forceThreshold, forceResult, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/search/Search?searchType=${searchType}&searchString=${searchString}&forceThreshold=${forceThreshold}&forceResult=${forceResult}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'GET',
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
