import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { Popover, Typography } from 'antd';
import PrioSpinner from '../../../../components/PrioSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'theming';
import { PrioTheme } from '../../../../theme/types';
import {
  apiFetchReteachCourses,
  apiFetchReteachParticipation,
} from '../../../training/api';
import {
  CustomerCourseParticipationReadDto,
  CustomerCourseParticipationReadDtoStatus,
  ReteachCourseReadDto,
  ReteachCourseReadDtoType,
  ReteachThumbnails,
} from '../../../../models/Training';
import moment from 'moment';
import {
  Pill,
  Tabs,
  Tooltip,
  VirtualList,
} from '@prio365/prio365-react-library';
import { DateTimeString } from '../../../../models/Types';
import i18n from '../../../../i18n';
import { sanitizeHTML } from '../../../../util';
import ReactHtmlParser from 'react-html-parser';
import * as ConfigValues from '../../../../util/configValues';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface ExtendedCourse extends ReteachCourseReadDto {
  courseType: 'newCourses' | 'updatedCourses' | 'myUpcomingCourses';
}

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& .prio-tabs-container': {
      flex: 0,
    },
    '& .prio-tabs-content': {
      height: '100%',
    },
    '& .prio-tab-panes-container': {
      height: 'fit-content',
    },
    '& .prio-tab-pane': {
      paddingTop: 0,
      paddingBottom: theme.spacing.small,
    },
  },
  spinner: {
    marginTop: theme.spacing.regular,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reloadIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: theme.colors.application.typography.muted,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    width: '100%',
    '& a': {
      display: 'none',
    },
    '&:hover a': {
      display: 'block',
    },
  },
  image: {
    width: 80,
    overflow: 'hidden',
    display: 'flex',
    marginLeft: theme.spacing.small,
    justifyContent: 'center',
  },
  imagePlaceholder: {
    width: '80px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.application.background.light,
  },
  listItemContent: {
    flex: 1,
    overflow: 'hidden',
    flexDirection: 'column',
    marginLeft: theme.spacing.small,
  },
  listItemTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
  },
  listItemTitle: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing.regular,
    fontSize: theme.font.fontSize.small,
  },
  listItemDescription: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.small,
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '30vh',
    maxWidth: '30vw',
    overflow: 'auto',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing.small,
    },
  },
  popoverContentLabel: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.small,
  },
  popoverContentDescription: {
    marginBottom: theme.spacing.small,
  },
  textWrapEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  noCoursesLabel: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.muted,
    paddingTop: theme.spacing.small,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
}));

const getCourseType: (type: number) => ReteachCourseReadDtoType = (type) => {
  switch (type) {
    case 1: {
      return 'SINGLE_DAY_ONLINE_COURSE';
    }
    case 2: {
      return 'MULTIPLE_DAYS_ONLINE_COURSE';
    }
    case 3: {
      return 'ONLINE_COURSE';
    }
    case 4: {
      return 'SINGLE_DAY_PRESENCE_TRAINING';
    }
    case 5: {
      return 'MULTIPLE_DAYS_PRESENCE_TRAINING';
    }
    case 6: {
      return 'SINGLE_DAY_LIVE_TRAINING';
    }
    case 7: {
      return 'MULTIPLE_DAYS_LIVE_TRAINING';
    }
    case 8: {
      return 'SINGLE_DAY_HYBRID_TRAINING';
    }
    case 9: {
      return 'MULTIPLE_DAYS_HYBRID_TRAINING';
    }
    default: {
      return 'SINGLE_DAY_ONLINE_COURSE';
    }
  }
};

const getCourseStatus: (
  status: number
) => CustomerCourseParticipationReadDtoStatus = (status) => {
  switch (status) {
    case 1: {
      return 'ADDED';
    }
    case 2: {
      return 'INVITED';
    }
    case 3: {
      return 'INVITATION_ACCEPTED';
    }
    case 4: {
      return 'JOINED';
    }
    case 5: {
      return 'STARTED';
    }
    case 6: {
      return 'COMPLETED';
    }
    case 7: {
      return 'DECLINED';
    }
    case 8: {
      return 'EXPIRED';
    }
    default: {
      return 'ADDED';
    }
  }
};

const parseCourseStatus: (status: number) => string = (status) => {
  const _status = getCourseStatus(status).toLowerCase();
  return i18n.t(`dashboard:reteach.courseStatus.${_status}`);
};

const parseCourseType: (type: number) => string = (type) => {
  const _type = getCourseType(type);
  const suffix = _type.includes('ONLINE')
    ? i18n.t('dashboard:reteach.courseType.online')
    : _type.includes('PRESENCE')
    ? i18n.t('dashboard:reteach.courseType.presence')
    : _type.includes('PRESENCE')
    ? i18n.t('dashboard:reteach.courseType.live')
    : i18n.t('dashboard:reteach.courseType.hybrid');
  return suffix;
};

const parseDateTime: (
  startsAt: DateTimeString,
  endsAt: DateTimeString,
  type: number
) => string = (startsAt, endsAt, type) => {
  const _type = getCourseType(type);
  if (startsAt && endsAt) {
    if (_type.includes('MULTIPLE')) {
      return `${moment(startsAt).format('dd. DD.MM.YYYY')} - ${moment(
        endsAt
      ).format('dd. DD.MM.YYYY')}`;
    } else if (_type.includes('SINGLE')) {
      return `${moment(startsAt).format('dd. DD.MM.YYYY')}, ${moment(
        startsAt
      ).format('HH:mm')} - ${moment(endsAt).format('HH:mm')} ${i18n.t(
        'common:moment.aClock'
      )}`;
    }
  }
  return '';
};

const useFetchNewReteachCourse = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['reteach', 'newReteachCourses'],
    queryFn: apiFetchReteachCourses,
    staleTime: 1000 * 60 * 60, // 1 hour
  });

  const newCourses = data?.data
    ?.filter(({ createdAt, isDraft, isPublic, description }) => {
      const isPrio365 = description?.includes('#Prio365');
      const weeks = isPrio365 ? 3 : 5;
      return (
        moment(createdAt)?.isAfter(moment().subtract(weeks, 'weeks'), 'days') &&
        !isDraft &&
        isPublic
      );
    })
    ?.sort(({ createdAt: a }, { createdAt: b }) =>
      moment(a ?? undefined).diff(moment(b ?? undefined))
    );

  const updatedPrioCourses = data?.data
    ?.filter(
      ({
        createdAt,
        updatedAt,
        isDraft,
        isPublic,
        isArchived,
        description,
      }) => {
        const isAtLeast14Days =
          moment(updatedAt).diff(moment(createdAt), 'days') >= 14;
        return (
          isAtLeast14Days &&
          moment(updatedAt)?.isAfter(moment().subtract(3, 'weeks'), 'days') &&
          !isDraft &&
          isPublic &&
          !isArchived &&
          description?.includes('#Prio365')
        );
      }
    )
    ?.sort(({ updatedAt: a }, { updatedAt: b }) =>
      moment(b ?? undefined).diff(moment(a ?? undefined))
    );

  return { newCourses, updatedPrioCourses, isNewCoursesLoading: isLoading };
};

const useFetchMyNextParticipations = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['reteach', 'myNextParticipations'],
    queryFn: apiFetchReteachParticipation,
    staleTime: 1000 * 60 * 60 * 20, // 20 hours
  });

  const myNextParticipations = data?.data
    ?.filter(({ course: { startsAt, endsAt, isDraft }, completedAt }) => {
      return (
        ((moment(startsAt)?.isSameOrAfter(moment(), 'days') &&
          moment(startsAt)?.isBefore(moment().add(8, 'weeks'), 'days')) ||
          (moment(endsAt)?.isSameOrAfter(moment(), 'days') &&
            moment(startsAt)?.isBefore(moment(), 'days'))) &&
        !isDraft &&
        !completedAt
      );
    })
    ?.sort(({ course: { startsAt: a } }, { course: { startsAt: b } }) =>
      moment(a ?? undefined).diff(moment(b ?? undefined))
    );
  const myCourseIds = data?.data?.map(({ course: { id } }) => id);

  return {
    myNextParticipations,
    myCourseIds,
    isMyNextParticipationsLoading: isLoading,
  };
};

interface DashboardReteachItemProps {
  className?: string;
}

export const DashboardReteachItem: React.FC<DashboardReteachItemProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const queryClient = useQueryClient();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { newCourses, isNewCoursesLoading, updatedPrioCourses } =
    useFetchNewReteachCourse();
  const { myNextParticipations, myCourseIds, isMyNextParticipationsLoading } =
    useFetchMyNextParticipations();

  const newAndNotCompletedCourses = newCourses?.filter(
    ({ id }) => !myCourseIds?.includes(id)
  );

  const isLoading = isNewCoursesLoading || isMyNextParticipationsLoading;

  const allCoursesLength = new Set([
    ...(newAndNotCompletedCourses?.map((course) => course.id) ?? []),
    ...(updatedPrioCourses?.map((course) => course.id) ?? []),
    ...(myNextParticipations?.map((participation) => participation.course.id) ??
      []),
  ]).size;
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const renderCourses = useCallback(
    (courses, noCoursesLabel, type, isAllTab = false) => {
      if (isLoading) {
        return (
          <Flex.Row className={classes.spinner}>
            <PrioSpinner />
          </Flex.Row>
        );
      }

      if (!courses || courses.length === 0) {
        return (
          <Flex.Row className={classes.noCoursesLabel}>
            {!isLoading && (
              <div>{t('dashboard:reteach.' + noCoursesLabel)}</div>
            )}
          </Flex.Row>
        );
      }

      if (courses.length > 0) {
        return (
          <Flex.Item flex={1} className={classes.fullHeight}>
            <ReteachList type={type} items={courses} isAllTab={isAllTab} />
          </Flex.Item>
        );
      }

      return null;
    },
    [isLoading, classes.spinner, classes.noCoursesLabel, classes.fullHeight, t]
  );

  const fetchReteachData = () => {
    queryClient.refetchQueries({ queryKey: ['reteach'] });
  };

  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Components
  const renderMyNextParticipations = useCallback(() => {
    const _myNextParticipations = myNextParticipations?.filter(
      ({ id }) => !myCourseIds?.includes(id)
    );

    return renderCourses(_myNextParticipations, 'noCourses', 'participations');
  }, [myNextParticipations, myCourseIds, renderCourses]);

  const renderNewCourses = useCallback(() => {
    return renderCourses(newAndNotCompletedCourses, 'noNewCourses', 'courses');
  }, [newAndNotCompletedCourses, renderCourses]);

  const renderUpdatedCourses = useCallback(() => {
    return renderCourses(updatedPrioCourses, 'noUpdatedCourses', 'courses');
  }, [updatedPrioCourses, renderCourses]);

  const renderAllCourses = useCallback(() => {
    const allCoursesMap = new Map<string, ExtendedCourse>();

    newAndNotCompletedCourses?.forEach((course) => {
      allCoursesMap.set(course.id, { ...course, courseType: 'newCourses' });
    });

    updatedPrioCourses?.forEach((course) => {
      if (allCoursesMap.has(course.id)) {
        allCoursesMap.get(course.id).courseType += ', updatedCourses';
      } else {
        allCoursesMap.set(course.id, {
          ...course,
          courseType: 'updatedCourses',
        });
      }
    });

    myNextParticipations?.forEach((participation) => {
      const course = participation.course;
      if (allCoursesMap.has(course.id)) {
        allCoursesMap.get(course.id).courseType += ', myUpcomingCourses';
      } else {
        allCoursesMap.set(course.id, {
          ...course,
          courseType: 'myUpcomingCourses',
        });
      }
    });

    const allCourses = Array.from(allCoursesMap.values());

    return renderCourses(allCourses, 'noCourses', 'courses', true);
  }, [
    newAndNotCompletedCourses,
    updatedPrioCourses,
    myNextParticipations,
    renderCourses,
  ]);

  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row childrenGap={theme.spacing.small} marginBottom={8}>
        <Flex.Item>
          <Typography.Title level={3} ellipsis style={{ marginBottom: 0 }}>
            {t('dashboard:reteach.title')}
          </Typography.Title>
        </Flex.Item>
        <Flex.Item flex={1}>
          <Tooltip overlay={t('dashboard:reteach.updateTooltip')}>
            <FontAwesomeIcon
              icon={['fal', 'sync']}
              onClick={fetchReteachData}
              className={classes.reloadIcon}
            />
          </Tooltip>
        </Flex.Item>
        <a
          href={ConfigValues.RETEACH_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Tooltip overlay={t('dashboard:reteach.openReteachTooltip')}>
            <FontAwesomeIcon
              icon={['fal', 'arrow-up-right-from-square']}
              color={theme.colors.application.typography.muted}
            />
          </Tooltip>
        </a>
      </Flex.Row>
      <Tabs
        items={[
          {
            label: `${t('dashboard:reteach.allCourses')}${
              !isLoading && allCoursesLength > 0 ? ` (${allCoursesLength})` : ''
            }`,
            key: 'allCoursesTab',
            content: renderAllCourses(),
          },
          {
            label: `${t('dashboard:reteach.newCourses')}${
              !isLoading && newAndNotCompletedCourses?.length > 0
                ? ` (${newAndNotCompletedCourses?.length})`
                : ''
            }`,
            key: 'newCoursesTab',
            content: renderNewCourses(),
          },
          {
            label: `${t('dashboard:reteach.updatedCourses')}${
              !isLoading && updatedPrioCourses?.length > 0
                ? ` (${updatedPrioCourses?.length})`
                : ''
            }`,
            key: 'updatedCoursesTab',
            content: renderUpdatedCourses(),
          },
          {
            label: `${t('dashboard:reteach.myUpcomingCourses')}${
              !isLoading && myNextParticipations?.length > 0
                ? ` (${myNextParticipations?.length})`
                : ''
            }`,
            key: 'myUpcomingCoursesTab',
            content: renderMyNextParticipations(),
          },
        ]}
      />
    </Flex.Column>
  );
};

export default DashboardReteachItem;

interface ReteachListProps {
  type: 'courses' | 'participations';
  items: ReteachCourseReadDto[] | CustomerCourseParticipationReadDto[];
  isAllTab: boolean;
}

const ReteachList: React.FC<ReteachListProps> = (props) => {
  //#region ------------------------------ Defaults
  const { type, items, isAllTab } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isCourses = type === 'courses';
  const coursesItems = isCourses ? (items as ReteachCourseReadDto[]) : [];
  const participationsItems = !isCourses
    ? (items as CustomerCourseParticipationReadDto[])
    : [];
  //#endregion

  //#region ------------------------------ Components
  const listItem = (
    key: string,
    course: ExtendedCourse,
    thumbnail: ReteachThumbnails,
    description: string,
    simplify: boolean
  ) => {
    const listItemWithoutPopover = () => {
      return (
        <div key={key} className={classes.listItem}>
          <Popover
            title={course.name}
            content={
              <div className={classes.popoverContent}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  {thumbnail && (
                    <img
                      src={thumbnail.url}
                      width={'50%'}
                      alt={course.name}
                      style={{
                        paddingRight: theme.spacing.regular,
                        height: 'fit-content',
                      }}
                    />
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                    }}
                  >
                    {course.startsAt && course.endsAt && (
                      <>
                        <div className={classes.popoverContentLabel}>
                          {t('dashboard:reteach.dateTitle')}
                        </div>
                        <div className={classes.popoverContentDescription}>
                          {parseDateTime(
                            course.startsAt,
                            course.endsAt,
                            course.type
                          )}
                        </div>
                      </>
                    )}
                    <div className={classes.popoverContentLabel}>
                      {t('dashboard:reteach.courseStatusTitle')}
                    </div>
                    <div className={classes.popoverContentDescription}>
                      {parseCourseStatus(course.status)}
                    </div>
                    <div className={classes.popoverContentLabel}>
                      {t('dashboard:reteach.courseTypeTitle')}
                    </div>
                    <div className={classes.popoverContentDescription}>
                      {parseCourseType(course.type)}
                    </div>
                  </div>
                </div>
                <div>{ReactHtmlParser(sanitizeHTML(course.description))}</div>
              </div>
            }
            placement="top"
          >
            <div className={classes.image}>
              {thumbnail?.url ? (
                <img
                  src={thumbnail?.url}
                  style={{
                    width: '100%',
                  }}
                  alt={''}
                />
              ) : (
                <div className={classes.imagePlaceholder}>
                  <FontAwesomeIcon
                    icon={['fal', 'image-slash']}
                    size="2x"
                    color={theme.colors.application.typography.muted}
                  />
                </div>
              )}
            </div>
          </Popover>

          <div className={classes.listItemContent}>
            <div className={classes.listItemTitleRow}>
              <div className={classes.listItemTitle} title={course?.name}>
                {course?.name}
              </div>
            </div>
            <div
              className={classNames(
                classes.listItemDescription,
                classes.textWrapEllipsis
              )}
              title={description}
            >
              {description}
            </div>
            {isAllTab && (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {course.courseType.split(', ').map((type) => (
                  <Pill
                    key={type}
                    children={t(`dashboard:reteach.${type}`)}
                    size="small"
                    style={{
                      backgroundColor:
                        theme.colors.base[
                          type === 'newCourses'
                            ? 'green'
                            : type === 'updatedCourses'
                            ? 'yellow'
                            : 'primary'
                        ].default,
                      color: theme.colors.base.white.default,
                      marginRight: theme.spacing.small,
                    }}
                  />
                ))}
              </div>
            )}
          </div>
          <a href={course.url} target="_blank" rel="noopener noreferrer">
            <Tooltip overlay={t('dashboard:reteach.openCourseTooltip')}>
              <FontAwesomeIcon
                icon={['fal', 'arrow-up-right-from-square']}
                color={theme.colors.base.primary.default}
                style={{
                  marginRight: theme.spacing.regular,
                }}
              />
            </Tooltip>
          </a>
        </div>
      );
    };

    if (simplify) {
      return listItemWithoutPopover();
    }
    return listItemWithoutPopover();
  };

  //#endregion

  return (
    <VirtualList<CustomerCourseParticipationReadDto | ReteachCourseReadDto>
      id="reteachVList"
      items={isCourses ? coursesItems ?? [] : participationsItems ?? []}
      rowHeight={80}
      enableContainer={false}
      rowRenderer={({ index, key, isScrolling, isVisible }) => {
        const course = isCourses ? items[index] : items[index]?.['course'];
        const thumbnails = course?.image?.thumbnails;
        const thumbnail: ReteachThumbnails = thumbnails?.reduce(
          (current, thumbnail) => {
            if (!current) {
              return thumbnail;
            }
            if (current.width < thumbnail.width) {
              return current;
            }
            return current;
          },
          null
        );
        const dateTime = parseDateTime(
          course.startsAt,
          course.endsAt,
          course.type
        );
        const description = `${dateTime && dateTime}`;
        const simplify = isScrolling || !isVisible;

        return listItem(key, course, thumbnail, description, simplify);
      }}
    />
  );
};
