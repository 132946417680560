import React, { useEffect, useRef, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import {
  FILTER_DATA_LIST_CLASS_PREFIX,
  FilterBar,
} from '../../../components/Filter/FilterBar';
import classNames from 'classnames';
import ProjectContactsPageTable, {
  ProjectContactsPageTableRef,
  actions,
} from './ProjectContactsPageTable';
import {
  ExternalProjectContact,
  InternalProjectContact,
  ProjectMember,
  ProjectMemberCalculatedData,
  ProjectMemberSearchResultItem,
} from '../../../models/ProjectContacts';
import { Modal, Select } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getAllHourlyRates,
  getUserMe,
} from '../../../apps/main/rootReducer';
import {
  fetchHourlyRates,
  updateExternalProjectContact,
  updateInternalProjectContactForController,
  updateProjectContacts,
} from '../../projects/actions';
import { HourlyRate } from '../../../models/HourlyRate';
import { ProjectRole } from '../../../models/Types';
import ProjectContactsDrawer from './ProjectContactsDrawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JobTitleSelect from './JobTitleSelect';
import useFilterContext from '../../../components/Filter/hooks/useFilterContext';
import { ApiResult } from '../../../api';
import { GenericSearchResultItem } from '../../../components/Filter/types';
import { notification } from 'antd';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: 24,
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.application.typography.default,
  },
  panelHeadline: {
    '&.ant-typography': {
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  form: {
    height: 'calc(100% - 33px)',
  },
}));

interface ProjectContactsPageProps {
  projectId: string;
  className?: string;
  showProject?: boolean;
}

export const ProjectContactsPage: React.FC<ProjectContactsPageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { projectId, className } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const tableRef = useRef<ProjectContactsPageTableRef>(null);

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<actions | null>(null);
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const { optimisticWrite } = useFilterContext<
    ProjectMember,
    ProjectMemberCalculatedData
  >();

  const userMe = useSelector(getUserMe);

  const [selectedProjectMembers, setSelectedProjectMembers] = useState<
    ProjectMemberSearchResultItem[]
  >([]);
  const [clickedProjectMember, setClickedProjectMember] =
    useState<ProjectMemberSearchResultItem | null>(null);

  //#region ------------------------------ Methods / Handlers

  const onSelectionChange = (items: ProjectMemberSearchResultItem[]) => {
    setSelectedProjectMembers(items);
  };

  const onOpenModal = (action: actions) => {
    setModalAction(action);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setModalAction(null);
  };

  const onOkModal = (selectedOption: string | string[]) => {
    switch (modalAction) {
      case 'adjustFunction':
        optimisticWrite(
          selectedProjectMembers.map((member) => ({
            data: { ...member.data, jobTitle: selectedOption as string },
            calculated: member.calculated,
            method: 'update',
          })),
          async (): Promise<
            ApiResult<
              GenericSearchResultItem<
                ProjectMember,
                ProjectMemberCalculatedData
              >[]
            >
          > => {
            const updatedItems = selectedProjectMembers.map((i) => ({
              ...i.data,
              jobTitle: selectedOption as string,
            })) as ProjectMember[];

            const rollBackItems = selectedProjectMembers.map(
              (i) =>
                ({
                  ...i.data,
                  jobTitle: i.data.jobTitle,
                }) as InternalProjectContact | ExternalProjectContact
            );

            const response = await (dispatch as any)(
              updateProjectContacts(projectId, updatedItems, rollBackItems)
            );
            if (response.status >= 200 && response.status < 300) {
              return {
                result: response,
                data: selectedProjectMembers.map((item) => ({
                  data: { ...item.data, jobTitle: selectedOption as string },
                  calculated: item.calculated,
                })),
              };
            } else {
              notification.open({
                message: t('common:error'),
                description: t('contacts:projectContactsPage.messageError'),
              });
              return {
                result: response,
                data: null,
              };
            }
          }
        );
        break;

      case 'adjustRoles':
        const updatedItems1 = selectedProjectMembers.some(
          (member) => member.data.externalProjectContactId !== null
        )
          ? selectedProjectMembers
              ?.filter(
                (member) => member.data.internalProjectContactId !== null
              )
              .map((i) => ({
                ...i.data,
                projectRoles: selectedOption as ProjectRole[],
              }))
          : selectedProjectMembers.map((i) => ({
              ...i.data,
              projectRoles: selectedOption as ProjectRole[],
            }));
        const rollBackItems1 = selectedProjectMembers?.map(
          (i) =>
            ({
              ...i.data,
              projectRoles: i.data.projectRoles,
            }) as InternalProjectContact | ExternalProjectContact
        );
        optimisticWrite(
          selectedProjectMembers
            .filter((member) => member.data.internalProjectContactId !== null)
            .map((member) => ({
              data: {
                ...member.data,
                projectRoles: selectedOption as ProjectRole[],
              },
              calculated: member.calculated,
              method: 'update',
            })),
          async (): Promise<
            ApiResult<
              GenericSearchResultItem<
                ProjectMember,
                ProjectMemberCalculatedData
              >[]
            >
          > => {
            const response = await (dispatch as any)(
              updateProjectContacts(projectId, updatedItems1, rollBackItems1)
            );
            if (response.status >= 200 && response.status < 300) {
              return {
                result: response,
                data: selectedProjectMembers
                  .filter(
                    (member) => member.data.internalProjectContactId !== null
                  )
                  .map((item) => ({
                    data: {
                      ...item.data,
                      projectRoles: selectedOption as ProjectRole[],
                    },
                    calculated: item.calculated,
                  })),
              };
            } else {
              notification.open({
                message: t('common:error'),
                description: t('contacts:projectContactsPage.messageError'),
              });
              return {
                result: response,
                data: null,
              };
            }
          }
        );
        break;
      case 'adjustHourlyRate':
        const updatedItems2 = selectedProjectMembers.some(
          (member) => member.data.externalProjectContactId !== null
        )
          ? selectedProjectMembers
              ?.filter(
                (member) => member.data.internalProjectContactId !== null
              )
              .map(
                (i) =>
                  ({
                    ...i.data,
                    hourlyRateId: selectedOption,
                  }) as InternalProjectContact
              )
          : selectedProjectMembers?.map(
              (i) =>
                ({
                  ...i.data,
                  hourlyRateId: selectedOption,
                }) as InternalProjectContact
            );
        const rollBackItems2 = selectedProjectMembers?.map(
          (i) =>
            ({
              ...i.data,
              hourlyRateId: i.data.hourlyRateId,
            }) as InternalProjectContact
        );
        optimisticWrite(
          selectedProjectMembers
            .filter((member) => member.data.internalProjectContactId !== null)
            .map((member) => ({
              data: { ...member.data, hourlyRateId: selectedOption as string },
              calculated: member.calculated,
              method: 'update',
            })),
          async (): Promise<
            ApiResult<
              GenericSearchResultItem<
                ProjectMember,
                ProjectMemberCalculatedData
              >[]
            >
          > => {
            const response = await (dispatch as any)(
              updateInternalProjectContactForController(
                projectId,
                updatedItems2,
                rollBackItems2
              )
            );
            if (response.status >= 200 && response.status < 300) {
              return {
                result: response as unknown as Response,
                data: selectedProjectMembers
                  .filter(
                    (member) => member.data.internalProjectContactId !== null
                  )
                  .map((item) => ({
                    data: {
                      ...item.data,
                      hourlyRateId: selectedOption as string,
                    },
                    calculated: item.calculated,
                  })),
              };
            } else {
              notification.open({
                message: t('common:error'),
                description: t('contacts:projectContactsPage.messageError'),
              });
              return {
                result: response,
                data: null,
              };
            }
          }
        );
        break;
      case 'archiveExternal':
        const updatedItems4 = selectedProjectMembers
          ?.filter((member) => member.data.externalProjectContactId !== null)
          .map((i) => ({
            ...i.data,
            isArchived: true,
          })) as ExternalProjectContact[];
        const rollBackItems4 = selectedProjectMembers?.map(
          (i) =>
            ({
              ...i.data,
              isArchived: false,
            }) as ExternalProjectContact
        );
        optimisticWrite(
          selectedProjectMembers
            .filter((member) => member.data.externalProjectContactId !== null)
            .map((member) => ({
              data: member.data,
              method: 'remove',
            })),
          async (): Promise<
            ApiResult<
              GenericSearchResultItem<
                ProjectMember,
                ProjectMemberCalculatedData
              >[]
            >
          > => {
            const response = await (dispatch as any)(
              updateExternalProjectContact(
                projectId,
                updatedItems4,
                rollBackItems4
              )
            );
            if (response.status >= 200 && response.status < 300) {
              return {
                result: response as unknown as Response,
                data: selectedProjectMembers.map((item) => ({
                  data: { ...item.data, isArchived: true },
                  calculated: item.calculated,
                })),
              };
            } else {
              notification.open({
                message: t('common:error'),
                description: t('contacts:projectContactsPage.messageError'),
              });
              return {
                result: response as Response,
                data: null,
              };
            }
          }
        );
        break;
      case 'archive':
        const updatedItems3 = selectedProjectMembers?.map((i) => ({
          ...i.data,
          isArchived: true,
        }));
        const rollBackItems3 = selectedProjectMembers?.map(
          (i) =>
            ({
              ...i.data,
              isArchived: false,
            }) as InternalProjectContact | ExternalProjectContact
        );
        const iAmRemoved = selectedProjectMembers.some(
          (member) => member.data.contactId === userMe.id
        );
        optimisticWrite(
          selectedProjectMembers.map((member) => ({
            data: member.data,
            method: 'remove',
          })),
          async (): Promise<
            ApiResult<
              GenericSearchResultItem<
                ProjectMember,
                ProjectMemberCalculatedData
              >[]
            >
          > => {
            const response = await (dispatch as any)(
              updateProjectContacts(projectId, updatedItems3, rollBackItems3)
            );
            if (response.status >= 200 && response.status < 300) {
              return {
                result: response as unknown as Response,
                data: selectedProjectMembers.map((item) => ({
                  data: { ...item.data, isArchived: true },
                  calculated: item.calculated,
                })),
              };
            } else {
              notification.open({
                message: t('common:error'),
                description:
                  response.statusText ||
                  t('contacts:projectContactsPage.messageError'),
              });
              return {
                result: response as Response,
                data: null,
              };
            }
          }
        );
        if (iAmRemoved) navigate('/module/prio/projects/me/dashboard/');
        break;
      default:
        break;
    }
    setOpenModal(false);
    setModalAction(null);
  };

  const handleRowClick = (projectMember: ProjectMemberSearchResultItem) => {
    setClickedProjectMember(projectMember);
    setOpenDrawer(true);
  };

  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <FilterBar hiddenPickers={['Data.ProjectId']} />
      <ProjectContactsPageTable
        className={FILTER_DATA_LIST_CLASS_PREFIX}
        ref={tableRef}
        projectId={projectId}
        tableId="pcp1"
        selectedProjectMembers={selectedProjectMembers}
        onSelectionChange={onSelectionChange}
        onOpenModal={onOpenModal}
        onRowClick={handleRowClick}
      />
      <ActionsModal
        visible={openModal}
        onClose={onCloseModal}
        action={modalAction}
        onOk={onOkModal}
        projectId={projectId}
        projectMembers={selectedProjectMembers}
      />
      <ProjectContactsDrawer
        projectId={projectId}
        projectMember={clickedProjectMember}
        visible={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
    </Flex.Column>
  );
};
export default ProjectContactsPage;

//#region ------------------------------ ActionsModal
interface ActionsModalProps {
  action: actions;
  visible: boolean;
  onClose: () => void;
  onOk: (selectedOption: string | string[]) => void;
  projectId: string;
  projectMembers: ProjectMemberSearchResultItem[];
}

const ActionsModal: React.FC<ActionsModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const { action, visible, onClose, onOk, projectId, projectMembers } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hourlyRates = useSelector<RootReducerState, HourlyRate[]>((state) =>
    getAllHourlyRates(state, projectId)
  );

  const pojectRoles: ProjectRole[] = [
    'projectAdmin',
    'projectAssistance',
    'projectController',
    'projectMember',
  ];

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([
    'projectMember',
  ]);

  //#endregion

  const hourlyRateOptions = hourlyRates?.map((i) => ({
    label: i.name,
    value: i.hourlyRateId,
  }));

  const roleOptions = pojectRoles?.map((i) => ({
    label: t(`contacts:projectContactsPage.projectRoles.${i}`),
    value: i,
    disabled: i === 'projectMember' ? true : false,
  }));

  const title = action
    ? t(`contacts:projectContactsPage.actions.${action}`)
    : '';
  //#endregion

  //#region ------------------------------ Effects
  const handleSelectChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleSelectChangeMultiple = (value: string[]) => {
    setSelectedOptions(value);
  };

  useEffect(() => {
    if (action === 'adjustHourlyRate') {
      dispatch(fetchHourlyRates(projectId));
    }
  }, [dispatch, action, projectId]);

  useEffect(() => {
    if (projectMembers.length === 1) {
      if (action === 'adjustFunction') {
        setSelectedOption(projectMembers[0]?.data.jobTitle);
      }
      if (action === 'adjustHourlyRate') {
        setSelectedOption(projectMembers[0]?.data.hourlyRateId);
      }
      if (action === 'adjustRoles') {
        setSelectedOptions(projectMembers[0]?.data.projectRoles);
      }
    } else {
      setSelectedOption(null);
      setSelectedOptions(['projectMember']);
    }
  }, [action, projectMembers]);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const handleOk = () => {
    switch (action) {
      case 'adjustFunction':
        onOk(selectedOption);
        setSelectedOption(null);
        break;
      case 'adjustHourlyRate':
        onOk(selectedOption);
        setSelectedOption(null);
        break;
      case 'adjustRoles':
        onOk(selectedOptions);
        setSelectedOptions(['projectMember']);
        break;
      case 'archiveExternal':
        onOk(null);
        break;
      case 'archive':
        onOk(null);
        break;
      default:
        break;
    }
  };

  //#endregion
  const renderContent = () => {
    switch (action) {
      case 'adjustFunction':
        return (
          <>
            {projectMembers.length}
            {t('contacts:projectContactsPage.itemsSelected')}
            <JobTitleSelect
              onChange={handleSelectChange}
              value={selectedOption}
            />
          </>
        );
      case 'adjustRoles':
        return (
          <>
            {projectMembers.length}
            {t('contacts:projectContactsPage.itemsSelected')}
            <Select
              showSearch={false}
              options={roleOptions}
              onChange={handleSelectChangeMultiple}
              mode="tags"
              value={selectedOptions}
            ></Select>
            {projectMembers.some(
              (member) => member.data.internalProjectContactId !== null
            ) && (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'info-circle']}
                    style={{ margin: 'auto 0' }}
                  />
                  <span>{t('contacts:projectContactsPage.info')}</span>
                </div>
              </>
            )}
          </>
        );
      case 'adjustHourlyRate':
        return (
          <>
            {projectMembers.length}
            {t('contacts:projectContactsPage.itemsSelected')}
            <Select
              showSearch={false}
              options={hourlyRateOptions}
              onChange={handleSelectChange}
              value={selectedOption}
            ></Select>
            {projectMembers.some(
              (member) => member.data.externalProjectContactId !== null
            ) && (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'info-circle']}
                    style={{ margin: 'auto 0' }}
                  />
                  <span>{t('contacts:projectContactsPage.info')}</span>
                </div>
              </>
            )}
          </>
        );
      case 'archiveExternal':
        return projectMembers.length !== 1
          ? t('contacts:projectContactsPage.deleteItems', {
              count: projectMembers.length,
            })
          : t('contacts:projectContactsPage.deleteSingleItem');

      case 'archive':
        return projectMembers.length !== 1
          ? t('contacts:projectContactsPage.deleteItems', {
              count: projectMembers.length,
            })
          : t('contacts:projectContactsPage.deleteSingleItem');
      default:
        return null;
    }
  };

  return (
    <Modal
      width="635px"
      visible={visible}
      title={title}
      onClose={onClose}
      onOk={handleOk}
      okText={t('contacts:projectContactsPage.okText')}
      cancelText={t('contacts:projectContactsPage.cancelText')}
      destroyOnClose
    >
      {renderContent()}
    </Modal>
  );
};
