import React from 'react';
import { Select } from '@prio365/prio365-react-library';
import { ProjectId } from '../../../models/Types';
import { useTranslation } from 'react-i18next';
import { apiGetProjectPhases } from '../../projects/api';
import { useQuery } from '@tanstack/react-query';

interface ProjectPhasePickerProps {
  projectId: ProjectId;
  onChange: (value: string) => void;
  disabled?: boolean;
  setHasProjectPhases?: (hasProjectPhases: boolean) => void;
}

const ProjectPhasePicker: React.FC<ProjectPhasePickerProps> = ({
  projectId,
  onChange,
  disabled,
  setHasProjectPhases,
}) => {
  //#region ------------------------------ Defaults
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { data: projectPhases, isLoading } = useQuery({
    queryKey: ['projectPhases', projectId],
    queryFn: async () => {
      const data = await apiGetProjectPhases(projectId);
      if (setHasProjectPhases) {
        setHasProjectPhases(data.data.length > 0);
      }
      return data;
    },
    enabled: !!projectId,
  });
  //#endregion

  return (
    <Select
      loading={isLoading}
      onChange={onChange}
      disabled={disabled}
      placeholder={t(
        `projects:projectPhase.${disabled ? 'empty' : 'pickerPlaceholder'}`
      )}
    >
      {projectPhases?.data
        ?.sort((a, b) => a.name.localeCompare(b.name))
        ?.map((phase) => (
          <Select.Option key={phase.name} value={phase.projectPhaseId}>
            {phase.name}
          </Select.Option>
        ))}
    </Select>
  );
};

export default ProjectPhasePicker;
