import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Button, Drawer } from '@prio365/prio365-react-library';
import {
  MonthlyClose,
  MonthlyCloseCalculatedData,
  MonthlyCloseSearchResultItem,
} from '../../../models/TimeKeeping';
import HRMonthlyCloseDetailsDrawer from '../../hr/components/timeAndLeaveManagement/HRMonthlyCloseDetailsDrawer';
import useContactsContext from '../../contacts/hooks/useContactsProvider';
import moment from 'moment';
import { Contact } from '../../../models/Contact';
import Flex from '../../../components/Flex';
import { apiFetchSearch } from '../../dashboard/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import MonthlyCloseOverview, {
  MonthlyCloseOverviewRefProps,
} from './MonthlyCloseOverview';
import { apiFetchEditableMonthlyClose } from '../api';
import useFilterContext from '../../../components/Filter/hooks/useFilterContext';
import { useDispatch } from 'react-redux';
import { debouncedFetchOfficeHolidaysMe } from '../../absences/actions';
import {
  TimeRecord,
  TimeRecordCalculatedData,
} from '../../../models/TimeRecord';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  title: {
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const useFetchPrevMonth = (
  monthlyCloseSearchResultItem: MonthlyCloseSearchResultItem,
  contact: Contact
) => {
  const prevMonth = moment(monthlyCloseSearchResultItem?.data?.month)
    .subtract(1, 'month')
    .utc(true)
    .toISOString();

  const { data: prevMonthMonthlyClose } = useQuery({
    queryKey: [
      'monthlyClose',
      moment(prevMonth).format('YYYY-MM'),
      contact?.contactId,
    ],
    queryFn: () =>
      apiFetchSearch(
        'monthlyCloses',
        `Data.Month eq '${prevMonth}' %26 Data.EmployeeId eq '${contact?.contactId}'`,
        1000,
        false
      ),
    staleTime: 1000 * 60 * 15, // 15 min
  });
  return prevMonthMonthlyClose?.data.items as MonthlyCloseSearchResultItem[];
};

const useFetchNextMonth = (
  monthlyCloseSearchResultItem: MonthlyCloseSearchResultItem,
  contact: Contact
) => {
  const nextMonth = moment(monthlyCloseSearchResultItem?.data?.month)
    .add(1, 'month')
    .utc(true)
    .toISOString();

  const { data: nextMonthMonthlyClose } = useQuery({
    queryKey: [
      'monthlyClose',
      moment(nextMonth).format('YYYY-MM'),
      contact?.contactId,
    ],
    queryFn: () =>
      apiFetchSearch(
        'monthlyCloses',
        `Data.Month eq '${nextMonth}' %26 Data.EmployeeId eq '${contact?.contactId}'`,
        1000,
        false
      ),
    staleTime: 1000 * 60 * 15, // 15 min
  });
  return nextMonthMonthlyClose?.data.items as MonthlyCloseSearchResultItem[];
};

const useFetchTimeRecordsSum = (
  contact: Contact,
  month: string
): [boolean, number] => {
  const { data: timeRecords, isLoading } = useQuery({
    queryKey: ['timeRecords', contact?.contactId, month],
    queryFn: () =>
      apiFetchSearch<TimeRecord, TimeRecordCalculatedData>(
        'timeRecords',
        `Data.ContactId eq '${contact?.contactId}' %26 Data.Day ge '${moment(
          month
        )
          .startOf('month')
          .toISOString()}' %26 Data.Day le '${moment(month)
          .endOf('month')
          .toISOString()}'`,
        1000,
        false
      ),
    staleTime: 1000 * 60 * 15, // 15 min
  });
  return [
    isLoading,
    (timeRecords?.data?.items ?? []).reduce((acc, item) => {
      return acc + (item.calculated.hours ?? 0);
    }, 0) * 60,
  ];
};

const useFetchCurrentMonth = (contact: Contact) => {
  const currentMonth = moment(`${moment().format('YYYY-MM')}-15`)
    .utc(true)
    .toISOString();

  const { data: nextMonthMonthlyClose } = useQuery({
    queryKey: [
      'monthlyClose',
      moment(currentMonth).format('YYYY-MM'),
      contact?.contactId,
    ],
    queryFn: () =>
      apiFetchSearch(
        'monthlyCloses',
        `Data.Month eq '${currentMonth}' %26 Data.EmployeeId eq '${contact?.contactId}'`,
        1000,
        false
      ),
    staleTime: 1000 * 60 * 15, // 15 min
  });
  return nextMonthMonthlyClose?.data.items as MonthlyCloseSearchResultItem[];
};

interface MonthlyCloseDrawerProps {
  className?: string;
  isDrawerVisible: boolean;
  monthlyCloseSearchResultItem: MonthlyCloseSearchResultItem;
  setSelectedMonthlyCloseSearchResultItem: (
    value: MonthlyCloseSearchResultItem
  ) => void;
}

export const MonthlyCloseTimekeepingDayDrawer: React.FC<
  MonthlyCloseDrawerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    isDrawerVisible,
    monthlyCloseSearchResultItem,
    setSelectedMonthlyCloseSearchResultItem,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const currentMonthDate = useMemo(() => {
    if (monthlyCloseSearchResultItem?.data?.month) {
      return moment(monthlyCloseSearchResultItem?.data?.month).format(
        'YYYY-MM'
      );
    }
    return null;
  }, [monthlyCloseSearchResultItem]);

  const monthlyCloseTimekeepingDaysTableRef =
    useRef<MonthlyCloseOverviewRefProps>(null);

  const { fetchSearch } = useFilterContext<
    MonthlyClose,
    MonthlyCloseCalculatedData
  >();
  const { getContactById } = useContactsContext();
  const queryClient = useQueryClient();

  const contact = getContactById(
    monthlyCloseSearchResultItem?.data?.employeeId
  );

  const prevMonthResults = useFetchPrevMonth(
    monthlyCloseSearchResultItem,
    contact
  );

  const nextMonthResults = useFetchNextMonth(
    monthlyCloseSearchResultItem,
    contact
  );

  const [isFetchingTimeRecords, currentTimeRecordSum] = useFetchTimeRecordsSum(
    contact,
    monthlyCloseSearchResultItem?.data?.month
  );

  const currentMonthResults = useFetchCurrentMonth(contact);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const getTabs = useCallback(() => {
    return [
      {
        key: 'monthlyCloseDetails',
        label: t(
          'hr:timeAndLeaveManagement.monthlyCloseDrawer.detailsTab.tabName'
        ),
        content: (
          <HRMonthlyCloseDetailsDrawerWrapper
            setIsDrawerVisible={() =>
              setSelectedMonthlyCloseSearchResultItem(null)
            }
            monthlyCloseSearchResultItem={monthlyCloseSearchResultItem}
            setSelectedMonthlyCloseSearchResultItem={
              setSelectedMonthlyCloseSearchResultItem
            }
            contact={contact}
          />
        ),
      },
      {
        key: 'monthyCloseTimekeepingDays',
        label: t(
          'hr:timeAndLeaveManagement.monthlyCloseDrawer.timekeepingDaysTable.tabName'
        ),
        content: (
          <>
            <MonthlyCloseOverview
              ref={monthlyCloseTimekeepingDaysTableRef}
              monthlyClose={
                monthlyCloseSearchResultItem?.data
                  ? {
                      ...monthlyCloseSearchResultItem?.data,
                      expectedWorkHoursToDate:
                        monthlyCloseSearchResultItem?.data?.expectedWorkHours ??
                        0,
                    }
                  : undefined
              }
              timeRecordSum={currentTimeRecordSum}
              isFetchingTimeRecordSum={isFetchingTimeRecords}
            />
          </>
        ),
      },
    ];
  }, [
    monthlyCloseSearchResultItem,
    isFetchingTimeRecords,
    contact,
    t,
    setSelectedMonthlyCloseSearchResultItem,
    currentTimeRecordSum,
  ]);

  const getTitle = useCallback(() => {
    return `${moment(monthlyCloseSearchResultItem?.data?.month).format(
      'MMMM YYYY'
    )}${
      contact ? `, ${contact?.firstName ?? ''} ${contact?.lastName ?? ''}` : ''
    }`;
  }, [monthlyCloseSearchResultItem, contact]);

  const handleOnClose = () => {
    if (setSelectedMonthlyCloseSearchResultItem)
      setSelectedMonthlyCloseSearchResultItem(null);
    fetchSearch();
  };

  const onPrevMonthClick = () => {
    setSelectedMonthlyCloseSearchResultItem(prevMonthResults[0]);
    queryClient.invalidateQueries({
      queryKey: [
        'monthlyClose',
        moment(currentMonthResults[0].data.month).format('YYYY-MM'),
        contact?.contactId,
      ],
      refetchType: 'all',
    });
    queryClient.invalidateQueries({
      queryKey: ['timeRecords', contact?.contactId, currentMonthDate],
      refetchType: 'all',
    });
    monthlyCloseTimekeepingDaysTableRef.current?.handleUpdateTableData(null);
  };
  const onNextMonthClick = () => {
    setSelectedMonthlyCloseSearchResultItem(nextMonthResults[0]);
    queryClient.invalidateQueries({
      queryKey: [
        'monthlyClose',
        moment(currentMonthResults[0].data.month).format('YYYY-MM'),
        contact?.contactId,
      ],
      refetchType: 'all',
    });
    queryClient.invalidateQueries({
      queryKey: ['timeRecords', contact?.contactId, currentMonthDate],
      refetchType: 'all',
    });
    monthlyCloseTimekeepingDaysTableRef.current?.handleUpdateTableData(null);
  };

  const onCurrentMonthClick = () => {
    setSelectedMonthlyCloseSearchResultItem(currentMonthResults[0]);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (currentMonthDate) {
      const start = moment(currentMonthDate)
        .subtract(1, 'month')
        .startOf('month')
        .toISOString(true)
        .split('T')[0];
      const end = moment(currentMonthDate)
        .add(1, 'month')
        .endOf('month')
        .toISOString(true)
        .split('T')[0];
      if (contact?.officeId) {
        dispatch(debouncedFetchOfficeHolidaysMe(contact?.officeId, start, end));
      }
    }
  }, [currentMonthDate, contact, dispatch]);
  //#endregion

  return (
    <Drawer
      className={classNames(classes.root, className)}
      title={
        <Flex.Row childrenGap={theme.spacing.small} alignItems="center">
          <Button
            type="link"
            iconProp={['fal', 'chevron-left']}
            disabled={(prevMonthResults ?? []).length === 0}
            onClick={onPrevMonthClick}
          />
          <div className={classes.title}>{getTitle()}</div>
          <Button
            type="link"
            iconProp={['fal', 'chevron-right']}
            disabled={(nextMonthResults ?? []).length === 0}
            onClick={onNextMonthClick}
          />
          <Button
            type="link"
            iconProp={['fal', 'calendar']}
            disabled={
              monthlyCloseSearchResultItem?.data.month.slice(0, 7) ===
              moment().format('YYYY-MM')
            }
            onClick={onCurrentMonthClick}
            tooltip={t(
              'hr:timeAndLeaveManagement.monthlyCloseDrawer.goToCurrentMonth'
            )}
          />
        </Flex.Row>
      }
      visible={isDrawerVisible}
      onClose={handleOnClose}
      tabs={getTabs()}
    />
  );
};

export default MonthlyCloseTimekeepingDayDrawer;

interface HRMonthlyCloseDetailsDrawerWrapperProps {
  monthlyCloseSearchResultItem?: MonthlyCloseSearchResultItem;
  setSelectedMonthlyCloseSearchResultItem: (
    value: MonthlyCloseSearchResultItem
  ) => void;
  setIsDrawerVisible: (value: boolean) => void;
  contact: Contact;
}

const HRMonthlyCloseDetailsDrawerWrapper: React.FC<
  HRMonthlyCloseDetailsDrawerWrapperProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    monthlyCloseSearchResultItem,
    setSelectedMonthlyCloseSearchResultItem,
    setIsDrawerVisible,
    contact,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { data: editableAndClosableMonthlyCloseIds } = useQuery({
    queryKey: ['editableAndClosableMonthlyCloseIds', contact?.contactId],
    queryFn: () => apiFetchEditableMonthlyClose(contact?.contactId),
    enabled: !!contact?.contactId,
  });

  //#endregion

  return (
    <HRMonthlyCloseDetailsDrawer
      setOpen={() => setIsDrawerVisible(false)}
      monthlyClose={monthlyCloseSearchResultItem?.data}
      setSelectedMonthlyClose={() =>
        setSelectedMonthlyCloseSearchResultItem(null)
      }
      officeId={contact?.officeId}
      editableMonthlyCloseId={
        editableAndClosableMonthlyCloseIds?.data?.editableMonthlyCloseId
      }
    />
  );
};
