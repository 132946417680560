// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/third_party/font_awesome.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// plugins
import 'froala-editor/js/plugins.pkgd.min.js';

import { Component, createRef } from 'react';
import FroalaEditor, { FroalaEvents, FroalaOptions } from 'froala-editor';

type PrioFroalaEvents = Omit<FroalaEvents, 'initialized' | 'contentChanged'> & {
  initialized?: (editor: FroalaEditor) => void;
};

export type FroalaConfig = Omit<FroalaOptions, 'events'> & {
  events?: PrioFroalaEvents;
};

class FroalaEditorWrapper extends Component<
  {
    config: FroalaConfig;
    model: string;
    onModelChange: (model: string) => void;
  },
  { content: string }
> {
  editorRef = null;
  editorInstance: FroalaEditor = null;
  constructor(props) {
    super(props);
    this.editorRef = createRef();
    this.editorInstance = null;
    this.state = {
      content: props.model || '', // Initialize the content from props
    };
  }
  componentDidMount() {
    const { config: froalaConfig } = this.props;

    const { events, ...config } = froalaConfig;

    // Initialize Froala Editor
    this.editorInstance = new FroalaEditor(this.editorRef.current, {
      ...config,
      events: {
        ...(events || {}), // Merge additional event handlers if provided in config
        initialized: () => {
          // Ensure the editor instance is fully initialized
          if (this.editorInstance) {
            this.editorInstance.html.set(this.state.content); // Safely set the initial content
          }
          if (events?.initialized) {
            events.initialized(this.editorInstance);
          }
        },
        contentChanged: () => {
          const newContent = this.editorInstance.html.get();
          this.setState({ content: newContent });

          // Call the onModelChange callback if provided
          if (this.props.onModelChange) {
            this.props.onModelChange(newContent);
          }
        },
      },
    });
  }

  componentWillUnmount() {
    // Destroy the editor instance to clean up resources
    if (this.editorInstance) {
      this.editorInstance.destroy();
      this.editorInstance = null;
    }
  }

  render() {
    return <textarea ref={this.editorRef} />;
  }
}

export default FroalaEditorWrapper;
