import { combineReducers, Reducer } from 'redux';
import {
  DELETE_ALL_PROJECT_NEWS_COMMIT,
  DELETE_PROJECT_NEWS_BY_PROJECT_ID_AND_REFERENCE_OBJECT_ID_COMMIT,
  DELETE_PROJECT_NEWS_BY_PROJECT_ID_AND_TYPE_COMMIT,
  DELETE_PROJECT_NEWS_BY_PROJECT_ID_COMMIT,
  FETCH_PROJECT_NEWS_COMMIT,
  FETCH_PROJECT_NEWS_REQUEST,
  FETCH_PROJECT_NEWS_ROLLBACK,
} from '../actions';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { ProjectNews, ProjectNewsCategoryKey } from '../../../models/Project';
import { ProjectId } from '../../../models/Types';
import { isObjEmpty } from '../../../util';

export interface ProjectNewsState {
  byId: ProjectNews;
  meta: ProjectNewsMeta;
}

const byId: Reducer<ProjectNews, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROJECT_NEWS_COMMIT: {
      const { payload } = action;
      return Object.entries(payload).reduce((acc, [projectId, news]) => {
        acc[projectId] = news;
        return acc;
      }, {});
    }

    case DELETE_PROJECT_NEWS_BY_PROJECT_ID_COMMIT: {
      const {
        meta: { projectId },
      } = action;
      const { [projectId]: news, ...rest } = state;
      if (news) {
        return rest;
      }
      return state;
    }

    case DELETE_PROJECT_NEWS_BY_PROJECT_ID_AND_TYPE_COMMIT: {
      const {
        meta: { projectId, types },
      } = action;
      const { [projectId]: news, ...rest } = state;
      types.forEach((type) => {
        if (news[type]) {
          delete news[type];
        }
      });

      if (isObjEmpty(news) && !isObjEmpty(rest)) {
        return rest;
      }
      if (isObjEmpty(news) && isObjEmpty(rest)) {
        return {};
      }
      return { [projectId]: news, ...rest };
    }

    case DELETE_PROJECT_NEWS_BY_PROJECT_ID_AND_REFERENCE_OBJECT_ID_COMMIT: {
      const {
        meta: { projectId, referenceObjectId },
      } = action;
      const { [projectId]: news, ...rest } = state;

      const projectNewsTypes = Object.keys(news);

      const _news = projectNewsTypes.reduce((acc, type) => {
        const _value = news?.[type]?.filter(
          (value) => value.referenceObjectId !== referenceObjectId
        );

        if (_value?.length === 0) {
          return acc;
        }

        if (!!_value) {
          acc[type] = _value;
        }

        return acc;
      }, {});

      return { [projectId]: _news, ...rest };
    }

    case DELETE_ALL_PROJECT_NEWS_COMMIT:
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

interface ProjectNewsMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<ProjectNewsMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_PROJECT_NEWS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_PROJECT_NEWS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_PROJECT_NEWS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'projects:errorMessages.fetchProjectNews',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<ProjectNewsState>({
  byId,
  meta,
});

export const getProjectNewsByProjectId: (
  state: ProjectNewsState,
  projectId: ProjectId
) => ProjectNewsCategoryKey = (state, projectId) =>
  state?.byId?.[projectId?.toUpperCase()] ||
  state?.byId?.[projectId?.toLowerCase()] ||
  state?.byId?.[projectId];

export const getProjectNews: (
  state: ProjectNewsState,
  projectIds?: string[]
) => ProjectNews = (state, projectIds) =>
  filterProjectNewsByIds(state, projectIds);

function filterProjectNewsByIds(
  state: ProjectNewsState,
  projectIds?: string[]
) {
  if (!projectIds) return state.byId;
  const projectNews = {};
  projectIds.forEach((projectId) => {
    if (state.byId[projectId]) {
      projectNews[projectId] = state.byId[projectId];
    }
  });

  return projectNews;
}

export const getIsFetching: (state: ProjectNewsState) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: ProjectNewsState) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: ProjectNewsState) => string = (state) =>
  state.meta.errorMessage;
