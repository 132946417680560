import React, { useState } from 'react';
import Flex from '../../../components/Flex';
import { TemplatesMenu } from './TemplatesMenu';
import {
  useParams,
  Routes as ReactRouterDomRoutes,
  Route,
} from 'react-router-dom';
import { makePrioStyles } from '../../../theme/utils';
import ProjectFileSystemStructureTable from './ProjectFileSystemStructureTable';
import NavigationBar from '../../../components/NavigationBar';
import { Button, Drawer, Input, Toggle } from '@prio365/prio365-react-library';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  textBlockManagementSetOpen,
  textBlockManagementSetSelectedEntry,
} from '../actions/textBlockManagement';
import classNames from 'classnames';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../../theme/types';
import { documentTemplateManagementSetAction } from '../../documents/actions/documentTemplateManagement';
import DocumentTemplateCategoryContextProvider from '../../documents/components/DocumentTemplateCategoryContextProvider';
import DocumentTemplateTagContextProvider from '../../documents/components/DocumentTemplateTagContextProvider';
import UploadField, { PrioFile } from '../../../components/Upload/UploadField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'react-jss';
import { apiCreateProjectFileSystemStructureByZip } from '../api';
import { fetchProjectFileSystemStructures } from '../actions/projectFileSystemStructure';
import { notification } from 'antd';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const ProjectFileSystemStructureEditor = React.lazy(
  () => import('./ProjectFileSystemStructureEditor')
);
const DocumentTemplateManagement = React.lazy(
  () => import('../../documents/components/DocumentTemplateManagement')
);
const TextBlocksManagement = React.lazy(() => import('./TextBlocksManagement'));

function getOS() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('mac') !== -1) {
    return 'macOS';
  } else if (userAgent.indexOf('win') !== -1) {
    return 'Windows';
  }
  return 'unknown';
}

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  projectFileSystemStructureEditor: {
    flex: 1,
    width: '100%',
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    width: '100%',
  },
  saveNameButton: {},
  mainContent: {
    height: '100%',
    overflow: 'hidden',
  },
  upload: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 200,
    border: `1px dashed ${theme.colors.application.border}`,
    borderRadius: theme.borderRadius.small,
    cursor: 'pointer',
    backgroundColor: theme.colors.application.background.light,
    transition: 'all 0.3s',
    '&:hover': {
      borderColor: theme.old.palette.primaryColor,
    },
  },
}));

interface TemplateSettingsProps {
  className?: string;
  pathPrefix?: string;
}

export const TemplateSettings: React.FC<TemplateSettingsProps> = (props) => {
  const classes = useStyles();
  const { className, pathPrefix } = props;
  const { selectedList, officeId } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const createTextBlock = () => {
    dispatch(textBlockManagementSetOpen(true));
    dispatch(textBlockManagementSetSelectedEntry(null));
  };
  const [documentTemplateDrawerOpen, setDocumentTemplateDrawerOpen] =
    useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const theme = useTheme<PrioTheme>();
  const [fileToUpload, setFileToUpload] = useState<PrioFile>(null);
  const [folderStructureName, setFolderStructureName] = useState<string>('');

  const [newFolder, setNewFolder] = useState<boolean>(false);
  const skipRootDirectoryDefault = getOS() === 'Windows';
  const [skipRootDirectory, setSkipRootDirectory] = useState<boolean>(
    skipRootDirectoryDefault
  );

  const openNewFolder = () => setNewFolder(true);
  const closeNewFolder = () => setNewFolder(false);
  const openDrawerToCreateDocumentTemplate = () => {
    dispatch(documentTemplateManagementSetAction('CreateDocumentTemplate'));
    setDocumentTemplateDrawerOpen(true);
  };

  //#region ------------------------------ Handle Upload ZIP
  const closeDrawer = () => {
    setDrawerVisible(false);
    setFileToUpload(null);
    setFolderStructureName('');
  };

  const handleCreatePrjectFileSystemStructure = async () => {
    const { result, data } = await apiCreateProjectFileSystemStructureByZip(
      fileToUpload,
      folderStructureName,
      skipRootDirectory
    );
    if (result.status >= 400) {
      notification.open({
        message: t('common:error'),
        description: `${t(
          'settings:projectFileSystemStructure.notificationError'
        )} ${
          data?.['TranslatedMessage'] !== 'UnhandledException'
            ? data?.['TranslatedMessage']
            : ''
        }`,
        duration: 6,
      });
    } else {
      dispatch(fetchProjectFileSystemStructures());
      notification.open({
        message: t('common:success'),
        description: t(
          'settings:projectFileSystemStructure.notificationSuccess'
        ),
        duration: 6,
      });
    }
    closeDrawer();
  };

  //#endregion

  let component: React.ReactNode;
  switch (selectedList) {
    case 'folders':
      component = (
        <Routes>
          <Route
            path=":projectFileSystemStructureId"
            element={
              <ProjectFileSystemStructureEditor
                className={classes.projectFileSystemStructureEditor}
                newFolder={newFolder}
                closeNewFolder={closeNewFolder}
              />
            }
          />
          <Route
            path="new"
            element={
              <ProjectFileSystemStructureEditor
                className={classes.projectFileSystemStructureEditor}
                newFolder={newFolder}
                closeNewFolder={closeNewFolder}
              />
            }
          />
          <Route
            path="/"
            element={
              <ProjectFileSystemStructureTable className={classes.content} />
            }
          />
        </Routes>
      );
      break;
    case 'documents':
      component = (
        <DocumentTemplateManagement
          officeId={officeId}
          setDocumentTemplateDrawerOpen={setDocumentTemplateDrawerOpen}
          documentTemplateDrawerOpen={documentTemplateDrawerOpen}
        />
      );
      break;
    case 'textBlocks':
      component = <TextBlocksManagement officeId={officeId} />;
      break;
    default:
  }
  let navigationItems: React.ReactNode;
  switch (selectedList) {
    case 'folders':
      navigationItems = (
        <Routes>
          <Route
            path="/"
            element={
              <Link to="new">
                <Button
                  iconProp={['fal', 'folder-tree']}
                  dropdownOptions={[
                    {
                      label: t(
                        'settings:navigationBar.uploadProjectFileSystemStructureZIP'
                      ),
                      value: t(
                        'settings:navigationBar.uploadProjectFileSystemStructureZIP'
                      ),
                      onClick: () => {
                        setDrawerVisible(true);
                      },
                    },
                  ]}
                >
                  <span>
                    {t(
                      'settings:navigationBar.createProjectFileSystemStructure'
                    )}
                  </span>
                </Button>
              </Link>
            }
          />
          <Route
            path="/*"
            element={
              <Button onClick={openNewFolder} iconProp={['fal', 'folder-plus']}>
                <span>{t('settings:navigationBar.createFolder')}</span>
              </Button>
            }
          />
        </Routes>
      );
      break;
    case 'documents':
      navigationItems = (
        <Button
          onClick={openDrawerToCreateDocumentTemplate}
          iconProp={['fal', 'file-alt']}
        >
          <span>{t('documents:navigationBar.createDocumentTemplate')}</span>
        </Button>
      );
      break;
    case 'textBlocks':
      navigationItems = (
        <Button onClick={createTextBlock} iconProp={['fal', 'file-alt']}>
          <span>{t('settings:textBlock.navigationBar.createTextBlock')}</span>
        </Button>
      );
      break;
    default:
  }

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <DocumentTemplateCategoryContextProvider>
        <DocumentTemplateTagContextProvider>
          <NavigationBar children={navigationItems} />
          <Flex.Row flex={1} className={classes.mainContent}>
            <TemplatesMenu
              selectedList={selectedList}
              urlPrefix={pathPrefix}
              className={classes.menu}
              officeId={officeId}
            />
            {component}
          </Flex.Row>
        </DocumentTemplateTagContextProvider>
      </DocumentTemplateCategoryContextProvider>
      <Drawer
        visible={drawerVisible}
        closable
        onClose={closeDrawer}
        title={t('settings:navigationBar.uploadProjectFileSystemStructureZIP')}
        children={
          <div
            style={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing.regular,
            }}
          >
            {!fileToUpload ? (
              <>
                <div>
                  <span
                    style={{
                      marginRight: 8,
                      fontSize: theme.font.fontSize.small,
                    }}
                  >
                    {t(
                      'settings:projectFileSystemStructure.uploadZIPDescription'
                    )}
                  </span>
                </div>
                <UploadField
                  id="upload-zip"
                  multiple={false}
                  onUploadRequest={async (file) => {
                    setFileToUpload(file);
                  }}
                  clickable
                  accept=".zip"
                  className={classes.upload}
                >
                  <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
                    <FontAwesomeIcon
                      icon={['fal', 'upload']}
                      size="2x"
                      color={theme.colors.application.typography.active}
                    />
                    <span style={{ fontSize: theme.font.fontSize.small }}>
                      {t('settings:projectFileSystemStructure.uploadZIP')}
                    </span>
                  </Flex.Column>
                </UploadField>
              </>
            ) : (
              <>
                <Flex.Column>
                  <div
                    style={{
                      fontSize: theme.font.fontSize.extraSmall,
                      color: theme.colors.application.typography.muted,
                    }}
                  >
                    {t('settings:projectFileSystemStructure.zipFile')}
                  </div>
                  <Flex.Row alignItems="center">
                    <Flex.Item
                      flex={1}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {fileToUpload.name}
                    </Flex.Item>
                    <Button
                      iconProp={['fal', 'trash']}
                      iconColor={theme.old.palette.chromaticPalette.red}
                      onClick={() => setFileToUpload(null)}
                      type="default"
                    />
                  </Flex.Row>
                </Flex.Column>
                <Input
                  label={t('settings:projectFileSystemStructure.fileName')}
                  value={folderStructureName}
                  onChange={(e) => {
                    setFolderStructureName(e);
                  }}
                  full
                />
                <Flex.Column childrenGap={theme.spacing.regular}>
                  <Toggle
                    checked={skipRootDirectory}
                    onChange={(value) => setSkipRootDirectory(value)}
                  >
                    {t('settings:projectFileSystemStructure.ignoreFirstLevel')}
                  </Toggle>
                  <div>
                    <span
                      style={{
                        marginRight: 8,
                        fontSize: theme.font.fontSize.small,
                      }}
                    >
                      {t(
                        'settings:projectFileSystemStructure.ignoreFirstLevelDescription'
                      )}
                    </span>
                  </div>
                </Flex.Column>
              </>
            )}
          </div>
        }
        footer={
          fileToUpload && (
            <Flex.Row justifyContent="space-between">
              <Button
                onClick={() => {
                  setDrawerVisible(false);
                  setFileToUpload(null);
                  setFolderStructureName('');
                }}
                type="default"
              >
                {t('common:actions.cancel')}
              </Button>
              <Button
                onClick={handleCreatePrjectFileSystemStructure}
                disabled={!!!folderStructureName}
              >
                {t('settings:projectFileSystemStructure.uploadButton')}
              </Button>
            </Flex.Row>
          )
        }
      ></Drawer>
    </Flex.Column>
  );
};

export default TemplateSettings;
