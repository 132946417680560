import React, { useEffect } from 'react';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
} from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Typography } from 'antd';

import ProjectsNavigation from '../projects/components/ProjectsNavigation';
import RemoteFoldersSubModule from '../documents/components/RemoteFoldersSubModule';
import WidgetArea from '../widgetArea/components/WidgetArea';
import { officeContext } from '../../util/officeJs';
import { makePrioStyles } from '../../theme/utils';
import { useTranslation } from 'react-i18next';
import GlobalSider from '../../components/GlobalSider';
import Flex from '../../components/Flex';
import GlobalNavigationBar from '../../components/GlobalNavigationBar';
import CustomDragLayer from '../../dnd/components/CustomDragLayer';
import MailMeSubModule from '../mail/components/me/MailMeSubModule';
import MessageCenterSubModule from '../mail/components/MessageCenter/MessageCenterSubModule';
import PreviewModal from '../documents/components/Modals/PreviewModal';
import ProjectsModule from '../projects/components/ProjectsModule';
import TimeAndLeaveManagement from '../projects/components/TimeAndLeaveManagement';
import Dashboard from '../dashboard/components/Dashboard';
import CreateProject from '../projects/components/ProjectCreation/CreateProject';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../theme/types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  abortDriveItemFetch,
  DriveItemsFetchContext,
} from '../documents/actions';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  //@ts-ignore
  navigation: {
    ...theme.old.components.navigation,
  },
  content: {
    flex: 2,
    overflow: 'auto',
  },
  fullHeight: {
    height: '100%',
    overflow: 'hidden',
  },
  mainContent: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
}));

export const Projects: React.FC<{}> = () => {
  //#region ------------------------------ Defaults
  const classes = useStyles();

  const { t } = useTranslation();

  const isOutsideOfficeContext = !officeContext;

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    return () => {
      dispatch(
        abortDriveItemFetch(DriveItemsFetchContext.Other, undefined, true)
      );
    };
  }, [dispatch]);
  //#endregion

  return (
    <DndProvider backend={HTML5Backend}>
      <PreviewModal />
      <CustomDragLayer />
      <div className={classNames('prio-deprecated-design', classes.root)}>
        <GlobalSider
          title={t('projects:navigation.projects')}
          selectedMainMenuItem={'projects'}
          subMenu={
            <Routes>
              <Route
                path="*"
                element={
                  <ProjectsNavigation
                    className={classes.navigation}
                    pathPrefix={''}
                    isSubMenu
                  />
                }
              />
              <Route
                path=":projectId"
                element={
                  <ProjectsNavigation
                    className={classes.navigation}
                    pathPrefix={'../'}
                    isSubMenu
                  />
                }
              >
                <Route
                  path=":subModule/*"
                  element={
                    <ProjectsNavigation
                      className={classes.navigation}
                      pathPrefix={'../../'}
                      isSubMenu
                    />
                  }
                />
              </Route>
            </Routes>
          }
          additionalTriggerBarButton={{
            type: 'link',
            link: '/module/prio/projects/create',
            iconName: 'plus',
            text: t('projects:navigation.newProject'),
          }}
        >
          <Routes>
            <Route
              path="*"
              element={
                <ProjectsNavigation
                  className={classes.navigation}
                  pathPrefix={''}
                />
              }
            />
            <Route
              path=":projectId"
              element={
                <ProjectsNavigation
                  className={classes.navigation}
                  pathPrefix={'../'}
                />
              }
            >
              <Route
                path=":subModule/*"
                element={
                  <ProjectsNavigation
                    className={classes.navigation}
                    pathPrefix={'../../'}
                  />
                }
              />
            </Route>
          </Routes>
        </GlobalSider>
        <Flex.Column flex={1} className={classes.fullHeight}>
          <GlobalNavigationBar module="projects" />
          <Flex.Row flex={1} className={classes.mainContent}>
            <div className={classes.content}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Flex.Column className={classes.fullHeight}>
                      <div
                        className={
                          isOutsideOfficeContext
                            ? 'prio-flex-center-center-navigation-bar'
                            : 'prio-flex-center-center'
                        }
                      >
                        <Typography.Text>
                          {t('projects:noProjectSelected')}
                        </Typography.Text>
                      </div>
                    </Flex.Column>
                  }
                />
                <Route path="create/*" element={<CreateProject />} />
                <Route path="me">
                  <Route path="*" element={<Navigate to="mail" replace />} />
                  <Route path="mail/*" element={<MailMeSubModule />} />
                  <Route path="messageCenter/*">
                    <Route
                      path="*"
                      element={<Navigate to="favorites/mail/inbox" replace />}
                    />
                    <Route
                      path="favorites/mail/*"
                      element={<MessageCenterSubModule pathPrefix={'../'} />}
                    />
                    <Route
                      path=":projectId/mail/*"
                      element={<MessageCenterSubModule pathPrefix={'../'} />}
                    />
                  </Route>
                  <Route path="timeAndLeaveManagement/*">
                    <Route
                      path="*"
                      element={<Navigate to="summary" replace />}
                    />
                    <Route
                      path=":selectedList/*"
                      element={<TimeAndLeaveManagement pathPrefix={'../'} />}
                    />
                  </Route>
                  <Route
                    path="remoteFolders/*"
                    element={<RemoteFoldersSubModule />}
                  />
                  <Route path="dashboard/*" element={<Dashboard />} />
                </Route>
                <Route path=":projectId/*" element={<ProjectsModule />} />
              </Routes>
            </div>
            <WidgetArea />
          </Flex.Row>
        </Flex.Column>
      </div>
    </DndProvider>
  );
};

export default Projects;
