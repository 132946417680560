import React, { useEffect, useState } from 'react';

import { ContactList } from './ContactList';
import ContactListSkeleton from './ContactListSkeleton';
import useContactSearch from './useContactSearch';
import { makePrioStyles } from '../../../theme/utils';
import {
  ContactSearchType,
  ContactId,
  CompanyId,
  OfficeId,
} from '../../../models/Types';
import classNames from 'classnames';
import { ContactProps } from './ContactSelectionList';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  contactList: {
    borderTop: theme.old.borders.content,
  },
  contactListItem: {
    '& .ant-list-item-action': {
      visibility: 'hidden',
    },
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
      '& .ant-list-item-action': {
        visibility: 'visible',
      },
    },
  },
}));

interface ContactSearchProps {
  className?: string;
  type: ContactSearchType;
  searchTerm: string;
  selectable?: boolean;
  onSelect?: (id: string) => void;
  onUnselect?: (id: string) => void;
  selection?: string[];
  onContactClick?: (contactId: ContactId) => void;
  onCompanyClick?: (companyId: CompanyId) => void;
  searchTypeChanged?: (isOnline: boolean) => void;
  officeId?: OfficeId;
  isOnlineFromOuter?: boolean;
  selectedContactProp?: ContactProps;
  includeArchived?: boolean;
  autoOnlineSearchWhenNoResults?: boolean;
  isShowMoreButtonVisible?: boolean;
}

export const ContactSearch: React.FC<ContactSearchProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    className,
    type,
    searchTerm,
    selectable,
    onSelect,
    onUnselect,
    selection,
    onContactClick,
    onCompanyClick,
    officeId,
    searchTypeChanged,
    isOnlineFromOuter,
    selectedContactProp = undefined,
    includeArchived = false,
    autoOnlineSearchWhenNoResults = true,
    isShowMoreButtonVisible = true,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isOnline, setIsOnline] = useState<boolean>(false);

  const [items, isFetching] = useContactSearch({
    searchTerm,
    type,
    selectedContactProp,
    officeId: officeId ?? undefined,
    isOnline,
    includeArchived,
  });
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleShowMoreClicked = () => {
    setIsOnline(true);
    if (searchTypeChanged) {
      searchTypeChanged(true);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (isOnlineFromOuter !== undefined) {
      setIsOnline(isOnlineFromOuter);
    }
  }, [isOnlineFromOuter]);

  useEffect(() => {
    if (searchTerm === '') {
      setIsOnline(false);
      if (searchTypeChanged) {
        searchTypeChanged(false);
      }
    }
  }, [searchTerm, searchTypeChanged]);

  useEffect(() => {
    if (autoOnlineSearchWhenNoResults) {
      if (items.length === 0 && !isOnline && searchTerm !== '') {
        setIsOnline(true);
        if (searchTypeChanged) {
          searchTypeChanged(true);
        }
      }
    }
  }, [
    autoOnlineSearchWhenNoResults,
    items,
    isOnline,
    searchTerm,
    searchTypeChanged,
  ]);
  //#endregion

  if (items.length === 0 && isFetching) {
    return <ContactListSkeleton />;
  }

  return (
    <ContactList
      items={items}
      selectable={selectable}
      onSelect={onSelect}
      onUnselect={onUnselect}
      selection={selection}
      className={classNames(classes.contactList, className)}
      listItemClassName={classes.contactListItem}
      onContactClick={onContactClick}
      onCompanyClick={onCompanyClick}
      showMoreClicked={handleShowMoreClicked}
      showMore={isShowMoreButtonVisible && !isOnline && searchTerm !== ''}
    />
  );
};

export default ContactSearch;
