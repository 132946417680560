import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { PrioTheme } from '../../../../theme/types';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { Button, useTheme } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../../../../components/UserAvatar';
import useContactsContext from '../../hooks/useContactsProvider';
import { ProjectId } from '../../../../models/Types';
import { apiAddInternalProjectMembers } from '../../api';
import { notification } from 'antd';
import AddInternalContactDrawerUserConfigForm from './AddInternalContactDrawerUserConfigForm';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import {
  ProjectMember,
  ProjectMemberCalculatedData,
  ProjectExtensionAccessProjectExtensionMap,
} from '../../../../models/ProjectContacts';
import { OptimisticWriteGenericSearchResultItem } from '../../../../components/Filter/types';
import { useQuery } from '@tanstack/react-query';
import { apiFetchProjectExtensionsForProject } from '../../../projects/api';
import { ProjectExtension } from '../../../../models/ProjectExtension';
import AddInternalContactDrawerContext from './AddInternalContactDrawerContext';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  avatarRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'left',
    '& > *': {
      marginRight: `${-theme.spacing.small}px!important`,
    },
  },
  avatarRowWrapper: {
    paddingBottom: theme.spacing.regular,
  },
}));

interface AddInternalContactDrawerUserConfigStepProps {
  className?: string;
  setCurrentStep: (step: number) => void;
  setDrawerVisible: (visible: boolean) => void;
  projectId?: ProjectId;
}

export const AddInternalContactDrawerUserConfigStep: React.FC<
  AddInternalContactDrawerUserConfigStepProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, setCurrentStep, setDrawerVisible, projectId = '' } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  const {
    selectedContacts,
    setSelectedContacts,
    configValues,
    contactsToConfigure,
    setContactsToConfigure,
  } = useContext(AddInternalContactDrawerContext);

  const [maxAvatarInARow, setMaxAvatarInARow] = useState(8);

  const [userState, setUserState] = useState<{
    [contactId: string]: boolean;
  }>({});

  const { optimisticWrite } = useFilterContext<
    ProjectMember,
    ProjectMemberCalculatedData
  >();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getContactById } = useContactsContext();

  const [configureIndividually, setConfigureIndividually] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);

  const { data: _projectExtensions } = useQuery({
    queryKey: ['projectExtensions', projectId],
    queryFn: () => apiFetchProjectExtensionsForProject(projectId),
    staleTime: 1000 * 60 * 15, // 15 minutes
  });

  const projectExtensions: ProjectExtension[] = useMemo(
    () => _projectExtensions?.data ?? [],
    [_projectExtensions]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClose = useCallback(() => {
    setDrawerVisible(false);
    setCurrentStep(0);
    setSelectedContacts([]);
  }, [setCurrentStep, setDrawerVisible, setSelectedContacts]);

  const onHandleConfigureIndividuallyChange = useCallback(() => {
    setConfigureIndividually(!configureIndividually);
    setContactsToConfigure(
      selectedContacts[0]?.data?.contact?.contactId
        ? [selectedContacts[0]?.data?.contact?.contactId]
        : []
    );
  }, [configureIndividually, selectedContacts, setContactsToConfigure]);

  const addInternalProjectContacts = useCallback(() => {
    const projectMembersToAdd: ProjectMember[] = configValues?.map((i) => {
      return {
        contactId: i.contactId,
        projectId: projectId,
        hourlyRateId: i.hourlyRateId,
        jobTitle: i.jobTitle,
        isArchived: false,
        notes: i.notes,
        projectRoles: i.projectRoles,
        projectExtensionAccesses: [],
      };
    });

    optimisticWrite(
      projectMembersToAdd?.map((i) => {
        return {
          data: i,
          method: 'add',
        } as OptimisticWriteGenericSearchResultItem<
          ProjectMember,
          ProjectMemberCalculatedData
        >;
      }),
      async () => {
        const { result, data } = await apiAddInternalProjectMembers(
          projectId,
          configValues
        );
        let _projectMembersToAdd: ProjectMember[] = [];

        if (result.status >= 200 && result.status < 300) {
          _projectMembersToAdd = projectMembersToAdd;
        } else {
          const errorMessage =
            (data as any)?.TranslatedMessage !== 'UnhandledException'
              ? (data as any)?.TranslatedMessage
              : t(`contacts:addInternalContactDrawer.error`);
          notification.open({
            message: t('common:error'),
            description: errorMessage,
          });
        }
        return {
          result,
          data: _projectMembersToAdd?.map((i) => {
            return {
              data: i,
              calculated: {
                projectExtensionAccessProjectExtensionMaps:
                  configValues
                    ?.find((contact) => contact.contactId === i.contactId)
                    .extensionAccesses?.map((access) => {
                      return {
                        projectId: projectId,
                        projectExtensionId: access.projectExtensionId,
                        shouldHaveAccess: access.shouldHaveAccess,
                        projectExtensionType: projectExtensions.find(
                          (ext) =>
                            ext.projectExtensionId === access.projectExtensionId
                        ).projectExtensionType,
                      };
                    })
                    ?.sort((a, b) =>
                      b.projectExtensionType.localeCompare(
                        a.projectExtensionType
                      )
                    ) ?? ([] as ProjectExtensionAccessProjectExtensionMap[]),
              },
            };
          }),
        };
      }
    );
  }, [configValues, projectId, optimisticWrite, t, projectExtensions]);

  const handleOnSave = () => {
    addInternalProjectContacts();
    handleOnClose();
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Row
        alignItems="center"
        justifyContent="space-between"
        childrenGap={theme.spacing.small}
        className={classes.avatarRowWrapper}
      >
        <Flex.Item flex={1} className={classes.avatarRow}>
          {selectedContacts?.slice(0, maxAvatarInARow)?.map((item) => {
            const contactId = item?.data?.contact?.contactId;
            var contact = getContactById(contactId);
            if (contactId)
              return (
                <UserAvatar
                  key={contactId}
                  size="large"
                  contact={contact ?? null}
                  backgroundColor={
                    contactsToConfigure.includes(contactId) &&
                    configureIndividually
                      ? theme.colors.base.primary.default
                      : theme.colors.base.blue.default
                  }
                  showGreenCheckMark={
                    configureIndividually && userState[contactId]
                  }
                  addWhiteBorder
                />
              );
            return <></>;
          })}
          {selectedContacts.length > maxAvatarInARow && (
            <div
              style={{ cursor: 'pointer', zIndex: 1 }}
              onClick={() => setMaxAvatarInARow(selectedContacts.length)}
            >
              <UserAvatar
                size="large"
                backgroundColor="#1a2f41"
                numberOfInvisibleAvatars={
                  selectedContacts?.length - maxAvatarInARow
                }
                addWhiteBorder
              />
            </div>
          )}
        </Flex.Item>
        {selectedContacts.length > 1 && (
          <Button
            type="link"
            iconProp={['fal', 'gear']}
            onClick={onHandleConfigureIndividuallyChange}
          >
            {t(
              `contacts:addInternalContactDrawer.userConfigStep.${
                configureIndividually ? 'configureAll' : 'configureIndividually'
              }`
            )}
          </Button>
        )}
      </Flex.Row>
      <AddInternalContactDrawerUserConfigForm
        projectId={projectId}
        setDisableSaveButton={setDisableSaveButton}
        projectExtensions={projectExtensions}
        configureIndividually={configureIndividually}
        setUserState={configureIndividually ? setUserState : null}
      />
      <Flex.Row childrenGap={8} justifyContent="flex-end">
        <Flex.Item flex={1}>
          <Button
            type="default"
            disabled={selectedContacts.length === 0}
            onClick={() => setCurrentStep(0)}
          >
            {t('common:actions.back')}
          </Button>
        </Flex.Item>
        <Button type="default" onClick={handleOnClose}>
          {t('common:actions.cancel')}
        </Button>

        <Button disabled={disableSaveButton} onClick={handleOnSave}>
          {t('common:actions.save')}
        </Button>
      </Flex.Row>
    </div>
  );
};

export default AddInternalContactDrawerUserConfigStep;
