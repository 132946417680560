import React from 'react';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
} from 'react-router-dom';

import Projects from './Projects';
import Contacts from './Contacts';
import Settings from './Settings';
import Accounting from './Controlling';
import Profile from './Profile';
import { useDispatch } from 'react-redux';
import { useKeyboardListener } from '@prio365/prio365-react-library';
import {
  closeContactsDrawer,
  openContactsDrawer,
} from '../contacts/actions/drawer';
import { openZendeskDrawer } from '../zendesk/actions/drawer';
import { closeNotificationsDrawer } from '../notifications/actions/drawer';
import { makePrioStyles } from '../../theme/utils';
import { closeTimeAndLeaveManagementDrawer } from '../timeAndLeaveManagement/actions';
import * as Sentry from '@sentry/react';
import HR from './HR';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100vh',
  },
}));

export const Prio: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useKeyboardListener({
    'Control+k': (event) => {
      event.preventDefault();
      event.stopPropagation();
      dispatch(closeNotificationsDrawer());
      dispatch(closeTimeAndLeaveManagementDrawer());
      dispatch(openZendeskDrawer(false));
      dispatch(openContactsDrawer());
    },
    'Control+h': (event) => {
      event.preventDefault();
      event.stopPropagation();
      dispatch(closeTimeAndLeaveManagementDrawer());
      dispatch(closeNotificationsDrawer());
      dispatch(closeContactsDrawer());
      dispatch(openZendeskDrawer(true));
    },
  });

  return (
    <div
      className={classes.root}
      onDragOver={(e) => {
        e.preventDefault();
      }}
    >
      <Routes>
        <Route
          path="*"
          element={<Navigate to="/module/prio/projects/me/dashboard" />}
        />
        <Route path="projects/*" element={<Projects />} />
        <Route path="contacts/*" element={<Contacts />} />
        <Route path="controlling/*" element={<Accounting />} />
        <Route path="hr/*" element={<HR />} />

        <Route path="settings/*" element={<Settings />} />
        <Route path="profile/*" element={<Profile />} />
      </Routes>
    </div>
  );
};

export default Prio;
