import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Select } from 'antd';
import { Button, TextArea } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import {
  JiraUser,
  JiraIssueType,
  JiraFormModel,
  JiraProjectSelection,
} from '../../../models/Task';
import { rowGutter, colon } from '../../../util/forms';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { apiFetchJiraUsers, apiFetchJiraProjectById } from '../api';
import classNames from 'classnames';
import { ProjectExtensionMe } from '../../../models/ProjectExtension';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: theme.old.spacing.unit(2),
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(2),
    marginBottom: theme.old.spacing.unit(2),
    gap: theme.old.spacing.unit(2),
  },
  datePicker: {
    width: '100%',
  },
  timePicker: {
    width: `calc(75% - ${16 + theme.old.spacing.unit(1)}px)`,
    marginRight: theme.old.spacing.unit(1),
  },
  duration: {
    width: `calc(25% - ${theme.old.spacing.unit(1)}px)`,
    marginRight: theme.old.spacing.unit(1),
  },
  space: {
    display: 'flex',
  },
  periodSum: {
    textAlign: 'right',
  },
  mutedColor: {
    color: theme.old.typography.colors.muted,
  },
  deleteButton: {
    float: 'left',
  },
  scrollableFormModal: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 48px)',
  },
  scrollableFormWidget: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 48px - 32px)',
  },
}));

declare type InitialValues = {
  summary: string;
  description: string;
  jiraProjectId: string;
};

interface JiraFormProps {
  className?: string;
  initialValues?: InitialValues;
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  cancelLabel?: string;
  onFinish: (value: JiraFormModel) => void;
  onCancel?: () => void;
  resetOnFinish?: boolean;
  isDrawer?: boolean;
  projectExtensionsMe: ProjectExtensionMe[];
}

export const JiraTask: React.FC<JiraFormProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    initialValues,
    actionLabel,
    cancelLabel,
    onFinish,
    onCancel,
    disableActionButton,
    disableForm,
    resetOnFinish,
    isDrawer,
    projectExtensionsMe,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [userList, setUserList] = useState<JiraUser[]>([]);
  const [issueTypeList, setIssueTypeList] = useState<JiraIssueType[]>([]);
  const [jiraProjects] = useState<JiraProjectSelection[]>(
    projectExtensionsMe?.map((extension) => ({
      key: extension.key,
      displayName: extension.displayName,
    }))
  );
  const [selectedJiraProject, setSelectedJiraProject] =
    useState<JiraProjectSelection>(jiraProjects ? jiraProjects[0] : null);

  const cancel = () => {
    form.resetFields();
    if (onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const [userData, jiraProjectData] = await Promise.all([
        apiFetchJiraUsers(selectedJiraProject?.key),
        selectedJiraProject?.key &&
          apiFetchJiraProjectById(selectedJiraProject?.key),
      ]);

      if (userData?.data) {
        setUserList(userData?.data);
      }

      if (jiraProjectData?.data) {
        setIssueTypeList([]);
        form?.setFieldsValue({
          issueType: null,
        });
        setIssueTypeList(jiraProjectData?.data?.issueTypes);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJiraProject]);

  const handleFinish: (value: JiraFormModel) => void = (
    value: JiraFormModel
  ) => {
    onFinish({
      ...value,
    });
    if (resetOnFinish) {
      setTimeout(() => {
        form.resetFields();
        form.setFieldsValue({});
      }, 2000);
    }
  };

  const handleOnJiraProjectChange = (value) => {
    const selectedProject = jiraProjects.find(
      (project) => project.key === value
    );
    setSelectedJiraProject(selectedProject);
  };

  return (
    <Form
      className={classNames(classes.root, className)}
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
      layout="vertical"
    >
      <div
        className={
          isDrawer ? classes.scrollableFormModal : classes.scrollableFormWidget
        }
      >
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={24}>
                <Form.Item
                  name="jiraProjectId"
                  label={t('tasks:jiraTask.jiraName')}
                  rules={[
                    {
                      required: true,
                      message: t('tasks:form.validation.missing.jiraProject'),
                    },
                  ]}
                >
                  <Select
                    onChange={handleOnJiraProjectChange}
                    value={initialValues?.jiraProjectId}
                  >
                    {jiraProjects?.length > 0 &&
                      jiraProjects?.map((jiraProject) => (
                        <Select.Option
                          key={jiraProject.key}
                          value={jiraProject.key}
                        >
                          {jiraProject.displayName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={24}>
                <Form.Item
                  name="assignee"
                  label={t('tasks:jiraTask.internalProjectContactId')}
                >
                  <Select showSearch optionFilterProp="children">
                    {userList?.map((user) => (
                      <Select.Option
                        key={user.accountId}
                        value={user.accountId}
                      >
                        {user.displayName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={24}>
                <Form.Item
                  name="summary"
                  colon={colon}
                  label={t('tasks:jiraTask.title')}
                  rules={[
                    {
                      required: true,
                      message: t('tasks:form.validation.missing.title'),
                    },
                  ]}
                >
                  <Input disabled={disableForm} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  colon={colon}
                  label={t('tasks:jiraTask.description')}
                >
                  <TextArea disabled={disableForm} rows={8} charLimit={4000} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={24}>
                <Form.Item
                  name="issueType"
                  colon={colon}
                  label={t('tasks:jiraTask.type')}
                  rules={[
                    {
                      required: true,
                      message: t('tasks:form.validation.missing.issueType'),
                    },
                  ]}
                >
                  <Select>
                    {jiraProjects?.length > 0 &&
                      Array.from(
                        new Set(issueTypeList?.map((issueType) => issueType.id))
                      ).map((id) => {
                        const issueType = issueTypeList.find(
                          (type) => type.id === id
                        );
                        return (
                          <Select.Option key={id} value={id}>
                            {issueType.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* The following code is commented out because the endpoints are not finished yet 
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={12}>
                <Form.Item
                  name="editor"
                  label={t('tasks:jiraTask.editor')}
                  colon={colon}
                >
                  <Input disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="dueDate"
                  label={t('tasks:jiraTask.dueDate')}
                  colon={colon}
                >
                  <DatePicker
                    disabled={disableForm}
                    locale={datePickerLocale}
                    format="DD.MM.YYYY"
                    className={classes.datePicker}
                    suffixIcon={
                      <FontAwesomeIcon icon={['fal', 'chevron-down']} />
                    }
                    disabledDate={(date) => date.isBefore(now)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={12}>
                <Form.Item
                  name="editor"
                  label={t('tasks:jiraTask.sprint')}
                  colon={colon}
                >
                  <Input disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="dueDate"
                  label={t('tasks:jiraTask.solutionVersion')}
                  colon={colon}
                >
                  <Input disabled={disableForm} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={12}>
                <Form.Item
                  name="editor"
                  label={t('tasks:jiraTask.parentEpic')}
                  colon={colon}
                >
                  <Input disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="dueDate"
                  label={t('tasks:jiraTask.priority')}
                  colon={colon}
                >
                  <Input disabled={disableForm} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="components" label={t('tasks:jiraTask.components')}>
              <Input disabled={disableForm} />
            </Form.Item>
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={12}>
                <Form.Item
                  name="storyPoints"
                  label={t('tasks:jiraTask.storyPoints')}
                  colon={colon}
                >
                  <Input disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="author"
                  label={t('tasks:jiraTask.author')}
                  colon={colon}
                >
                  <Input disabled={disableForm} />
                </Form.Item>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </div>
      <Row justify="end" className={classes.actionButtonsRow}>
        <Col span={24}>
          <Form.Item className={classes.submitButtonFormItem}>
            <Button type="default" onClick={cancel}>
              {cancelLabel}
            </Button>

            <Button
              htmlType="submit"
              disabled={disableActionButton}
              style={{ marginInline: '8px' }}
            >
              {actionLabel}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default JiraTask;
