import React, { forwardRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, notification } from 'antd';
import { Drawer } from '@prio365/prio365-react-library';
import useFilterContext, {
  useFilterContextDataConvertion,
} from '../../../../components/Filter/hooks/useFilterContext';
import { ProjectExtension } from '../../../../models/ProjectExtension';
import FilterResultNoItemsScreen from '../../../../components/Filter/FilterResultNoItemsScreen';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import CopyableTextTile from '../../../../components/CopyableTextTile';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import { Contact } from '../../../../models/Contact';
import { apiTriggerUpdateProjectExtensionAccess } from '../../../projects/api';
import { makePrioStyles } from '../../../../theme/utils';
import FilterContextVirtualTable from '../../../../components/Filter/FilterContextVirtualTable';

const useStyles = makePrioStyles((theme) => ({
  ellipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  cell: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface ProjectAdminExtensionSettingsPageTableRef {
  fetchHolidays: () => void;
}

interface ProjectAdminExtensionSettingsPageTableProps {
  className?: string;
  tableId: string;
}

export const ProjectAdminExtensionSettingsPageTable = forwardRef(
  (
    props: ProjectAdminExtensionSettingsPageTableProps,
    ref: React.Ref<ProjectAdminExtensionSettingsPageTableRef>
  ) => {
    //#region ------------------------------ Defaults
    const { tableId } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const { data, isLoading } = useFilterContext<ProjectExtension>();
    const projects = useFilterContextDataConvertion(data, true);
    const { getContactById } = useContactsContext();

    const [open, setOpen] = React.useState(false);
    const [selectedProjectExtension, setSelectedProjectExtension] =
      React.useState<ProjectExtension | null>(null);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleOnRowClick: (
      item: ProjectExtension
    ) => VirtualListItemOnRowProps = useCallback((item) => {
      return {
        onClick: (e) => {
          setSelectedProjectExtension(item);
          setOpen(true);
        },
        //className: getClassNameTableRow(item),
      };
    }, []);

    const resetProjectExtensionAccess = async (
      projectExtensionAccessId: string
    ) => {
      return await apiTriggerUpdateProjectExtensionAccess(
        projectExtensionAccessId
      );
    };
    //#endregion

    //#region ------------------------------ Effects
    //#endregion

    //use effect data change
    useEffect(() => {
      console.log(projects);
    }, [projects]);

    const columns: Column<ProjectExtension>[] = [
      {
        //projectExtensionType

        sortingFn: (a, b) =>
          a.projectExtensionType.localeCompare(b.projectExtensionType),
        title: t('settings:projectExtensionTable.projectExtensionType'),
        id: 'projectExtensionType',
        accessor: 'projectExtensionType',
        Cell: (cellProps) => (
          <div className={classes.ellipsis} title={cellProps.value}>
            {cellProps.value}
          </div>
        ),
        width: 180,
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) =>
          a.projectExtensionProcess?.extensionState.localeCompare(
            b.projectExtensionProcess?.extensionState
          ),
        title: t('settings:projectExtensionTable.extensionState'),
        id: 'extensionState',
        accessor: 'projectExtensionProcess.extensionState',
        Cell: (cellProps) => (
          <div className={classes.ellipsis} title={cellProps.value}>
            {cellProps.value}
          </div>
        ),
        width: 180,
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) => a.displayName.localeCompare(b.displayName),
        title: t('settings:projectExtensionTable.displayName'),
        id: 'name',
        accessor: 'displayName',
        Cell: (cellProps) => (
          <div className={classes.ellipsis} title={cellProps.value}>
            {cellProps.value}
          </div>
        ),
        width: 180,
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) => a.key.localeCompare(b.key),
        title: t('settings:projectExtensionTable.key'),
        id: 'key',
        accessor: 'key',
        Cell: (cellProps) => (
          <div className={classes.ellipsis} title={cellProps.value}>
            <CopyableTextTile value={cellProps.value} />
          </div>
        ),
        width: 180,
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) => a.value.localeCompare(b.value),
        title: t('settings:projectExtensionTable.value'),
        id: 'value',
        accessor: 'value',
        Cell: (cellProps) => (
          <div className={classes.ellipsis} title={cellProps.value}>
            <CopyableTextTile value={cellProps.value} />
          </div>
        ),
        width: 250,
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) =>
          a.projectExtensionId.localeCompare(b.projectExtensionId),
        title: t('settings:projectExtensionTable.projectExtensionId'),
        id: 'projectExtensionId',
        accessor: 'projectExtensionId',
        Cell: (cellProps) => (
          <div className={classes.ellipsis} title={cellProps.value}>
            <CopyableTextTile value={cellProps.value} />
          </div>
        ),
        width: 350,
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) =>
          a.hasDefaultAccess ? 1 : b.hasDefaultAccess ? -1 : 0,
        title: t('settings:projectExtensionTable.hasDefaultAccess'),
        id: 'hasDefaultAccess',
        accessor: 'hasDefaultAccess',
        Cell: (cellProps) => (
          <div className={classes.ellipsis} title={cellProps.value}>
            {cellProps.value ? 'Yes' : 'No'}
          </div>
        ),
        width: 180,
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) =>
          a.projectExtensionProcess.isBackgroundWorkerWorking
            ? 1
            : b.projectExtensionProcess.isBackgroundWorkerWorking
            ? -1
            : 0,
        title: t('settings:projectExtensionTable.isBackgroundWorkerWorking'),
        id: 'isBackgroundWorkerWorking',
        accessor: 'projectExtensionProcess.isBackgroundWorkerWorking',
        Cell: (cellProps) => (
          <div className={classes.ellipsis} title={cellProps.value}>
            {cellProps.value ? 'Yes' : 'No'}
          </div>
        ),
        width: 180,
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) =>
          a.projectExtensionProcess.errorState.localeCompare(
            b.projectExtensionProcess.errorState
          ),
        title: t('settings:projectExtensionTable.errorState'),
        id: 'errorState',
        accessor: 'projectExtensionProcess.errorState',
        Cell: (cellProps) => (
          <div title={cellProps.value}>
            <CopyableTextTile value={cellProps.value} />
          </div>
        ),
        width: 350,
        className: classes.cell,
        alignSelf: true,
      },
    ];

    return (
      <>
        <FilterContextVirtualTable<ProjectExtension>
          id={tableId}
          columns={columns}
          data={projects}
          resizable="absolute"
          onRow={handleOnRowClick}
          onCheckEquality={(a, b) =>
            a.projectExtensionId === b.projectExtensionId
          }
          rowsAreSelectable
          noItemsScreen={<FilterResultNoItemsScreen />}
          loading={
            isLoading && {
              type: 'noItems',
            }
          }
          checkBoxContainerWidth={50}
        />
        <Drawer
          visible={open}
          onClose={() => setOpen(false)}
          title={
            t('settings:projectExtensionTable.projectExtensionDetails') +
            ' ' +
            selectedProjectExtension?.displayName
          }
          tabs={[
            {
              key: 'addin',
              label: t(
                'settings:projectExtensionTable.projectExtensionDetails'
              ),
              content: (
                <>
                  {' '}
                  <div>
                    <p>
                      <b>
                        {t(
                          'settings:projectExtensionTable.projectExtensionType'
                        )}
                        :
                      </b>
                    </p>
                    <p>{selectedProjectExtension?.projectExtensionType}</p>
                  </div>
                  <div>
                    <p>
                      <b>
                        {t('settings:projectExtensionTable.extensionState')}:
                      </b>
                    </p>
                    <p>
                      {
                        selectedProjectExtension?.projectExtensionProcess
                          .extensionState
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>{t('settings:projectExtensionTable.displayName')}:</b>
                    </p>
                    <p>{selectedProjectExtension?.displayName}</p>
                  </div>
                  <div>
                    <p>
                      <b>{t('settings:projectExtensionTable.key')}:</b>
                    </p>
                    <p>{selectedProjectExtension?.key}</p>
                  </div>
                  <div>
                    <p>
                      <b>{t('settings:projectExtensionTable.value')}:</b>
                    </p>
                    <p>{selectedProjectExtension?.value}</p>
                  </div>
                  <div>
                    <p>
                      <b>
                        {t('settings:projectExtensionTable.projectExtensionId')}
                        :
                      </b>
                    </p>
                    <p>{selectedProjectExtension?.projectExtensionId}</p>
                  </div>
                  <div>
                    <p>
                      <b>
                        {t('settings:projectExtensionTable.hasDefaultAccess')}:
                      </b>
                    </p>
                    <p>
                      {selectedProjectExtension?.hasDefaultAccess
                        ? 'Yes'
                        : 'No'}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>
                        {t(
                          'settings:projectExtensionTable.isBackgroundWorkerWorking'
                        )}
                        :
                      </b>
                    </p>
                    <p>
                      {selectedProjectExtension?.projectExtensionProcess
                        .isBackgroundWorkerWorking
                        ? 'Yes'
                        : 'No'}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>{t('settings:projectExtensionTable.errorState')}:</b>
                    </p>
                    <p>
                      {
                        selectedProjectExtension?.projectExtensionProcess
                          .errorState
                      }
                    </p>
                  </div>
                </>
              ),
            },
            {
              key: 'accesses',
              label:
                t('settings:projectExtensionTable.accesses') +
                ' (' +
                selectedProjectExtension?.accesses?.length +
                ')',
              content: (
                <div>
                  {// foreach access show a list of accesses with contact name and a button to renew the access
                  selectedProjectExtension?.accesses?.map((access) => {
                    let contact: Contact = getContactById(access?.contactId);
                    if (!contact?.isArchived) {
                      return (
                        <div>
                          {contact?.firstName} {contact?.lastName} |{' '}
                          {access?.extensionAccessState} |{' '}
                          {access.shouldHaveAccess ? 'Yes' : 'No'}
                          <Button
                            onClick={() => {
                              resetProjectExtensionAccess(
                                access.projectExtensionAccessId
                              ).then((response) => {
                                response.result.status === 200
                                  ? notification.success({
                                      message:
                                        'Zugriff wurde erfolgreich zurückgesetzt',
                                      duration: 2,
                                    })
                                  : notification.error({
                                      message:
                                        'Zugriff zurücksetzten ist fehlgeschlagen',
                                      duration: 2,
                                    });
                              });
                            }}
                          >
                            Reset
                          </Button>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              ),
            },
          ]}
        ></Drawer>
      </>
    );
  }
);

export default ProjectAdminExtensionSettingsPageTable;
