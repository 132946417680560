import React, {
  Reducer,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Moment } from 'moment';
import {
  InteractJSEvent,
  RowResolutionType,
  TimelineGroup,
  TimelineItem,
} from './types';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import TimelineRow from './TimelineRow';
import { distinct } from '../../util';
import {
  calculateItemsRowOffset,
  calculateSections,
  TimelineBodySection,
} from './utils';
import moment from 'moment';
import equals from 'deep-equal';
import { v4 as uuid } from 'uuid';
import useInteract from './useInteract';

const DEFAULT_ITEM_HEIGHT = 50;
const CLASS_NAME = 'prio-timeline-body';

const createTempId = () => {
  return `temp-${uuid()}`;
};

const isTemporaryId = (id: string) => {
  return id ? id.startsWith('temp-') : false;
};

interface ItemsByGroupIdState {
  [goupId: string]: TimelineItem[];
}

export interface ItemsByGroupIdAction {
  type: 'RESET' | 'UPDATE' | 'ADD' | 'REMOVE' | 'REPLACE';
  actionId: string;
  itemId?: string;
  newItemId?: string;
  groupId?: string;
  groups?: TimelineGroup[];
  group?: TimelineGroup;
  item?: TimelineItem;
  items?: TimelineItem[];
  deltaXInS?: number;
  deltaWInS?: number;
  isKeyDownEvent?: boolean;
}

interface HeightByGroupIdState {
  [goupId: string]: number;
}

export interface TimelineBodyRef {
  replaceItem: (
    groupId: string,
    item: TimelineItem,
    previousId: string
  ) => void;
  forceUpdate: VoidFunction;
  getItems: () => TimelineItem[];
  getIsFetching: () => boolean;
  setIsFetching: (value: boolean) => void;
}

interface TimelineBodyProps {
  className?: string;
  timelineId: string;
  visibleTimeStart: Moment;
  visibleTimeEnd: Moment;
  startTime: Moment;
  endTime: Moment;
  items: TimelineItem[];
  groups: TimelineGroup[];
  resolution: RowResolutionType;
  itemHeight?: number;
  rowHeight?: number;
  timelineWidth: number;
  setTimelineWidth: (value: number) => void;
  prefixWidth: number;
  suffixWidth: number;
  gridSnapInMilliseconds?: number;
  disableResize?: boolean;
  disableDrag?: boolean;
  disableAdd?: boolean;
  disableRemove?: boolean;
  onItemChange?: (groupId: string, item: TimelineItem) => Promise<TimelineItem>;
  onItemCreate?: (groupId: string, item: TimelineItem) => Promise<TimelineItem>;
  onItemClick?: (item: TimelineItem, group: TimelineGroup) => void;
  onItemRemove?: (item: TimelineItem, group: TimelineGroup) => void;
  getItemMinWidth?: (
    groupId: string,
    item: TimelineItem,
    pixelsPerMs: number,
    event: InteractJSEvent
  ) => string;
  bodySectionClassName?: (section: TimelineBodySection) => string;
}

export const TimelineBody = forwardRef<TimelineBodyRef, TimelineBodyProps>(
  (props, ref) => {
    //#region ------------------------------ Defaults
    const {
      className,
      timelineId,
      visibleTimeStart,
      visibleTimeEnd,
      startTime,
      endTime,
      items,
      groups,
      resolution,
      rowHeight = DEFAULT_ITEM_HEIGHT,
      itemHeight,
      prefixWidth,
      suffixWidth,
      disableResize,
      disableDrag,
      disableAdd,
      disableRemove,
      gridSnapInMilliseconds,
      timelineWidth,
      setTimelineWidth,
      onItemChange,
      onItemCreate,
      onItemClick,
      onItemRemove,
      getItemMinWidth,
      bodySectionClassName,
    } = props;
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const sections = calculateSections(
      resolution,
      visibleTimeStart,
      visibleTimeEnd,
      timelineWidth
    );

    const startEndMs = useMemo(
      () => visibleTimeEnd.diff(visibleTimeStart, 'milliseconds'),
      [visibleTimeEnd, visibleTimeStart]
    );
    const pixelsPerMs = useMemo(
      () => timelineWidth / startEndMs,
      [timelineWidth, startEndMs]
    );

    const listRef = useRef<List>(null);

    const selectedItemRef = useRef<{ itemId: string; groupId: string }>(null);

    const [isCreating, setIsCreating] = useState<boolean>(false);

    const [heightByGroupId, setHeightByGroupId] =
      useState<HeightByGroupIdState>({});
    const heightByGroupIdRef = useRef<HeightByGroupIdState>(heightByGroupId);

    const isFetchingRef = useRef<boolean>(false);
    //#endregion

    //#region ------------------------------ Methods
    const dispatchHeightByGroupId = useCallback(
      (itemsByGroupId: ItemsByGroupIdState) => {
        const newHeightByGroupId: HeightByGroupIdState = Object.keys(
          itemsByGroupId
        ).reduce(
          (map, groupId) => ({
            ...map,
            [groupId]: Math.max(
              0,
              ...calculateItemsRowOffset(
                (itemsByGroupId[groupId] ?? []).filter(
                  ({ groupId: _groupId }) => _groupId === groupId
                ),
                startTime,
                endTime
              ).map(({ rowOffset }) => rowOffset)
            ),
          }),
          {}
        );
        if (!equals(newHeightByGroupId, heightByGroupIdRef.current)) {
          setHeightByGroupId(newHeightByGroupId);
        }
      },
      [startTime, endTime]
    );
    //#endregion

    //#region ------------------------------ Reducers
    const itemsByGroupIdRef = useRef<ItemsByGroupIdState>({});
    const [itemsByGroupId, dispatchItemsByGroupId] = useReducer<
      Reducer<ItemsByGroupIdState, ItemsByGroupIdAction>
    >((state: ItemsByGroupIdState, action: ItemsByGroupIdAction) => {
      const { type, ...rest } = action;
      switch (type) {
        case 'ADD': {
          const { deltaXInS, groupId } = rest;
          const startDateTime = moment
            .unix(startTime.unix() + deltaXInS)
            .toISOString(true)
            .split('.')[0];
          const item: TimelineItem = {
            id: createTempId(),
            groupId,
            title: '',
            startDateTime,
            endDateTime: startDateTime,
          };

          return {
            ...state,
            [groupId]: [...(state[groupId] ?? []), item],
          };
        }
        case 'REMOVE': {
          const { itemId, groupId } = rest;
          return {
            ...state,
            [groupId]: (state[groupId] ?? []).filter(
              (item) => item.id !== itemId
            ),
          };
        }
        case 'UPDATE': {
          const { item, groupId, itemId } = rest;

          return {
            ...state,
            [groupId]: distinct([
              ...(state[groupId] ?? []).filter(
                ({ id }) => !(id === itemId || id === item?.id)
              ),
              ...(item ? [item] : []),
            ]),
          };
        }
        case 'REPLACE': {
          const { item, groupId, itemId: previousId } = rest;
          if (isTemporaryId(previousId)) {
            setIsCreating(false);
          }
          return {
            ...state,
            [groupId]: distinct([
              ...(state[groupId] ?? []).filter(
                ({ id }) => !(id === previousId || id === item?.id)
              ),
              ...(item ? [item] : []),
            ]),
          };
        }
        case 'RESET': {
          const { items, groups } = rest;
          const newState = distinct(groups).reduce(
            (map, group) => ({
              ...map,
              [group.id]: items.filter(({ groupId }) => groupId === group.id),
            }),
            {}
          );
          return newState;
        }
        default: {
          return state;
        }
      }
    }, {});

    itemsByGroupIdRef.current = itemsByGroupId;
    //#endregion

    //#region ------------------------------ Handlers
    const dispatchUpdate = useCallback(
      async (action: ItemsByGroupIdAction) => {
        const { itemId, groupId, deltaXInS, deltaWInS, newItemId } = action;
        const item = (itemsByGroupIdRef.current[groupId] ?? []).find(
          ({ id }) => id === itemId
        );
        if (item) {
          isFetchingRef.current = true;
          const { startDateTime, endDateTime } = item;
          const newStartTime = moment(startDateTime).unix() + deltaXInS;
          const diffStartEndTime =
            moment(endDateTime).unix() - moment(startDateTime).unix();
          const newEndTime = newStartTime + diffStartEndTime + deltaWInS;

          let newItem: TimelineItem = {
            ...item,
            startDateTime: moment
              .unix(newStartTime)
              .toISOString(true)
              .split('.')[0],
            endDateTime: moment
              .unix(newEndTime)
              .toISOString(true)
              .split('.')[0],
          };

          if (!equals(item, newItem)) {
            if (isTemporaryId(itemId)) {
              if (onItemCreate) {
                newItem = await onItemCreate(groupId, newItem);
              } else {
                newItem = {
                  ...newItem,
                  id: newItemId,
                };
              }
              if (newItem) {
                setIsCreating(isTemporaryId(newItem.id));
                dispatchItemsByGroupId({
                  type: 'UPDATE',
                  item: newItem,
                  groupId,
                  itemId,
                  actionId: action.actionId,
                });
                selectedItemRef.current = {
                  groupId,
                  itemId: newItem.id,
                };
              } else {
                dispatchItemsByGroupId({
                  type: 'REPLACE',
                  item: null,
                  itemId,
                  groupId,
                  actionId: action.actionId,
                });
                selectedItemRef.current = null;
              }
            } else {
              if (onItemChange) {
                newItem = await onItemChange(groupId, newItem);
                if (!newItem) {
                  newItem = item;
                }
                try {
                  const element: HTMLElement = document
                    .querySelectorAll(
                      `.prio-timeline-item[data-item-id="${newItem.id}"`
                    )
                    ?.item(0) as HTMLElement;
                  if (element) {
                    const _diffStartEndTime =
                      moment(newItem.endDateTime).unix() -
                      moment(newItem.startDateTime).unix();
                    const _diffStartStartTime =
                      moment(newItem.startDateTime).unix() - startTime.unix();
                    const newWidth = _diffStartEndTime * 1000 * pixelsPerMs;
                    const newLeft = _diffStartStartTime * 1000 * pixelsPerMs;
                    element.style.width = `${newWidth}px`;
                    element.style.left = `${newLeft}px`;
                  }
                } catch (error) {}

                if (selectedItemRef.current?.itemId === itemId && newItem) {
                  selectedItemRef.current = {
                    groupId,
                    itemId: newItem.id,
                  };
                }
              }

              dispatchItemsByGroupId({
                type: 'UPDATE',
                item: newItem,
                groupId,
                itemId,
                actionId: action.actionId,
              });
            }
          } else if (isTemporaryId(itemId)) {
            dispatchItemsByGroupId({
              type: 'REPLACE',
              item: null,
              itemId,
              groupId,
              actionId: action.actionId,
            });
            selectedItemRef.current = null;
          }
          isFetchingRef.current = false;
        }
      },
      [
        dispatchItemsByGroupId,
        onItemChange,
        onItemCreate,
        pixelsPerMs,
        startTime,
      ]
    );

    const dispatchRemove = useCallback(
      (action: ItemsByGroupIdAction) => {
        const { itemId, groupId } = action;
        const item = (itemsByGroupId[groupId] ?? []).find(
          ({ id }) => id === itemId
        );
        const group = groups.find(({ id }) => id === groupId);
        if (item && group && !item.disabled) {
          if (onItemRemove) {
            onItemRemove(item, group);
          }

          if (isTemporaryId(itemId)) {
            setIsCreating(false);
          }
          dispatchItemsByGroupId(action);
        }
      },
      [itemsByGroupId, groups, dispatchItemsByGroupId, onItemRemove]
    );

    const handleRemove = () => {
      if (selectedItemRef.current) {
        const { groupId, itemId } = selectedItemRef.current;
        dispatchRemove({
          type: 'REMOVE',
          groupId,
          itemId,
          actionId: uuid(),
        });
      }
    };

    const handleOnKeydown: React.KeyboardEventHandler<HTMLDivElement> = (
      event
    ) => {
      switch (event.key) {
        case 'Delete': {
          if (!disableRemove) {
            event.stopPropagation();
            event.preventDefault();
            handleRemove();
          }
          break;
        }
        default: {
          break;
        }
      }
    };

    const handleOnItemClick: (
      item: TimelineItem,
      group: TimelineGroup
    ) => void = (item, group) => {
      selectedItemRef.current = {
        itemId: item.id,
        groupId: group.id,
      };
      if (onItemClick) {
        onItemClick(item, group);
      }
    };

    const replaceItem = (
      groupId: string,
      item: TimelineItem,
      previousId: string
    ) => {
      dispatchItemsByGroupId({
        type: 'REPLACE',
        groupId,
        item,
        itemId: previousId,
        actionId: uuid(),
      });
      listRef.current?.forceUpdate();
    };

    const forceUpdate = () => {
      listRef.current?.forceUpdate();
      listRef.current?.recomputeRowHeights();
    };

    const getItems = () => {
      return Object.values(itemsByGroupIdRef.current ?? {}).reduce<
        TimelineItem[]
      >((acc, items) => [...acc, ...items], []);
    };

    const getIsFetching = () => {
      return isFetchingRef.current;
    };

    const setIsFetching = (value: boolean) => {
      isFetchingRef.current = value;
    };

    const getItem = (groupId: string, itemId: string) => {
      return (itemsByGroupIdRef.current[groupId] ?? []).find(
        ({ id }) => id === itemId
      );
    };
    //#endregion

    //#region ------------------------------ Effects
    useInteract(
      timelineId,
      startTime,
      endTime,
      pixelsPerMs,
      gridSnapInMilliseconds,
      prefixWidth,
      dispatchUpdate,
      dispatchItemsByGroupId,
      isCreating,
      disableResize,
      disableDrag,
      disableAdd,
      getItemMinWidth
        ? (groupId, itemId, pixelsPerMs, event) =>
            getItemMinWidth(
              groupId,
              getItem(groupId, itemId),
              pixelsPerMs,
              event
            )
        : undefined
    );

    useImperativeHandle(ref, () => ({
      replaceItem,
      forceUpdate,
      getItems,
      getIsFetching,
      setIsFetching,
    }));

    useEffect(() => {
      dispatchItemsByGroupId({
        type: 'RESET',
        items,
        groups,
        actionId: uuid(),
      });
    }, [groups, items]);

    useEffect(() => {
      dispatchHeightByGroupId(itemsByGroupId);
    }, [itemsByGroupId, dispatchHeightByGroupId]);

    useEffect(() => {
      listRef.current?.recomputeRowHeights();
    }, [heightByGroupId, listRef]);
    //#endregion

    return (
      <>
        <div
          className={classNames(
            CLASS_NAME,
            {
              [`${CLASS_NAME}-disabled`]: disableAdd || disableDrag,
            },
            className,
            'prio-timeline-body'
          )}
          tabIndex={0}
          onKeyDown={handleOnKeydown}
          onBlur={() => {
            selectedItemRef.current = null;
          }}
          onClick={(event) => {
            try {
              if (
                (event.target as HTMLDivElement)?.className?.includes(
                  'prio-timeline-item-row'
                )
              ) {
                selectedItemRef.current = null;
              }
            } catch (e) {}
          }}
        >
          <div
            className={classNames(`${CLASS_NAME}-grid`)}
            style={{
              left: prefixWidth,
            }}
          >
            {sections.map((section, index, array) => {
              const { width, isStartOfHigherResolution } = section;
              return (
                <div
                  className={classNames(
                    `${CLASS_NAME}-gridItem`,
                    {
                      [`${CLASS_NAME}-gridItemStartOfHigherResolution`]:
                        isStartOfHigherResolution,
                    },
                    bodySectionClassName?.(section)
                  )}
                  style={{
                    width: array.length - 1 === index ? width + 1 : width,
                  }}
                />
              );
            })}
          </div>
          <AutoSizer
            onResize={({ width }) => {
              setTimelineWidth(width - prefixWidth - suffixWidth);
            }}
          >
            {({ width, height }) => (
              <List
                className={`${CLASS_NAME}-list`}
                height={height}
                width={width}
                rowCount={groups.length}
                rowHeight={({ index }) => {
                  const rowOffset = heightByGroupId[groups[index]?.id];
                  if (rowOffset) {
                    return (rowOffset + 1) * rowHeight;
                  }
                  return rowHeight;
                }}
                ref={listRef}
                containerStyle={{
                  overflow: 'visible !important',
                }}
                rowRenderer={(props: ListRowProps) => {
                  const { index, style } = props;
                  const group = groups[index];
                  const rowOffset = heightByGroupId[groups[index]?.id];
                  const maxRowOffset = rowOffset ?? 1;
                  return (
                    <TimelineRow
                      style={style}
                      group={group}
                      items={itemsByGroupId[group.id] ?? []}
                      pixelsPerMs={pixelsPerMs}
                      rowHeight={rowHeight}
                      itemHeight={itemHeight}
                      onItemClick={handleOnItemClick}
                      gridSnapInMilliseconds={gridSnapInMilliseconds}
                      maxRowOffset={maxRowOffset}
                    />
                  );
                }}
              />
            )}
          </AutoSizer>
        </div>
      </>
    );
  }
);

export default TimelineBody;
