import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { OfficeHoliday } from '../../../models/AbsenceProposal';
import Flex from '../../../components/Flex';
import { compactDateWithWeekDayFormatString } from '../../../util';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../../apps/main/rootReducer';
import moment, { Moment } from 'moment';
import { fetchOfficeHolidays } from '../actions';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  publicHolidaysCount: {
    fontWeight: theme.old.typography.fontWeight.bold,
  },
  publicHolidays: {
    height: 'fit-content',
    maxHeight: 150,
    overflowY: 'auto',
  },
  publicHolidayDate: {
    width: 125,
  },
}));

export const useOfficeHolidayDataWithDispatch = (
  officeId: string,
  period: Moment[]
) => {
  const dispatch = useDispatch();

  const data = useSelector<RootReducerState, OfficeHoliday[]>((state) =>
    state.absences.officeHolidays.ids
      .map((id) => state.absences.officeHolidays.byId[id])
      .filter((holiday) => {
        const holidayDate = moment(holiday.date);
        return holidayDate.isBetween(period[0], period[1], 'days', '[]');
      })
  );

  const hasDispatched = useRef(false);

  if (!data || (data.length === 0 && !hasDispatched.current)) {
    hasDispatched.current = true;
    dispatch(fetchOfficeHolidays(officeId));
  }

  return data;
};

interface AbsenceProposalDetailsHolidaysProps {
  className?: string;
  absentOfficeHolidays: OfficeHoliday[];
}

export const AbsenceProposalDetailsHolidays: React.FC<
  AbsenceProposalDetailsHolidaysProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, absentOfficeHolidays } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      {absentOfficeHolidays?.length > 0 && (
        <>
          <div className={classes.publicHolidaysCount}>
            {absentOfficeHolidays.length > 1
              ? t('absences:form.publicHolidays_plural', {
                  count:
                    absentOfficeHolidays.length > 0
                      ? absentOfficeHolidays?.reduce((acc, holiday) => {
                          if (holiday.isHalfDay) {
                            return acc + 0.5;
                          }
                          return acc + 1;
                        }, 0)
                      : 0,
                })
              : t('absences:form.publicHolidays', {
                  count:
                    absentOfficeHolidays.length > 0
                      ? absentOfficeHolidays?.reduce((acc, holiday) => {
                          if (holiday.isHalfDay) {
                            return acc + 0.5;
                          }
                          return acc + 1;
                        }, 0)
                      : 0,
                })}
          </div>
          <Flex.Column className={classes.publicHolidays}>
            {absentOfficeHolidays
              .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
              .map((publicHoliday) => (
                <Flex.Row key={publicHoliday.date}>
                  <Flex.Item className={classes.publicHolidayDate}>
                    {compactDateWithWeekDayFormatString(publicHoliday.date)}
                  </Flex.Item>
                  <Flex.Item flex={1}>
                    {publicHoliday.name}{' '}
                    {(publicHoliday.isHalfDay &&
                      `(${t('absences:form.halfDay')})`) ??
                      ''}
                  </Flex.Item>
                </Flex.Row>
              ))}
          </Flex.Column>
        </>
      )}
    </div>
  );
};

export default AbsenceProposalDetailsHolidays;
