import React from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { Project } from '../../../models/Project';
import { VirtualTable, useTheme } from '@prio365/prio365-react-library';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import useCompaniesContext from '../../companies/hooks/useCompaniesContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/Flex';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    '& .prio-vt-body-inner-container': {
      overflowX: 'hidden',
    },
  },
  tableCell: {
    userSelect: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface ProjectMembershipTableProps {
  isFetching: boolean;
  projects: Project[];
}

export const ProjectMembershipTable: React.FC<ProjectMembershipTableProps> = (
  props
) => {
  const classes = useStyles();
  const { projects, isFetching } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#region ------------------------------ Defaults
  const { getCompanyById } = useCompaniesContext();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const columns: Column<Project>[] = [
    {
      id: 'number',
      alignSelf: true,
      title: t('contacts:contactDetails.projectMember.name'),
      width: 34,
      accessor: 'number',
      Cell: (cellprops) => {
        return (
          <Flex.Row childrenGap={theme.spacing.small}>
            <div
              className={classes.tableCell}
              title={`${cellprops?.value ?? ''} ${
                cellprops?.originalData?.shortName ?? ''
              }`}
            >
              {`${cellprops?.value ?? ''} ${
                cellprops?.originalData?.shortName ?? ''
              }`}
            </div>
            {!cellprops.originalData.isVisible && (
              <div
                title={t('contacts:contactDetails.projectMember.hiddenProject')}
              >
                <FontAwesomeIcon
                  color={theme.colors.application.typography.muted}
                  icon={['fal', 'eye-slash']}
                />
              </div>
            )}
          </Flex.Row>
        );
      },
      sortingFn: (a, b) => {
        const numberCompare = a?.number.localeCompare(b?.number);
        if (numberCompare !== 0) {
          return numberCompare;
        }
        return a?.shortName.localeCompare(b?.shortName);
      },
    },
    {
      id: 'companyId',
      alignSelf: true,
      title: t('contacts:contactDetails.projectMember.projectClientCompany'),
      width: 33,
      accessor: 'companyId',
      Cell: (cellprops) => (
        <div
          className={classes.tableCell}
          title={getCompanyById(cellprops?.value)?.fullName}
        >
          {getCompanyById(cellprops?.value)?.fullName}
        </div>
      ),
      sortingFn: (a, b) => {
        return getCompanyById(a.companyId)?.fullName.localeCompare(
          getCompanyById(b.companyId)?.fullName
        );
      },
    },
    {
      id: 'subsidiaryId',
      alignSelf: true,
      title: t('contacts:contactDetails.projectMember.contractorCompany'),
      width: 33,
      accessor: 'subsidiaryId',
      Cell: (cellprops) => (
        <div
          className={classes.tableCell}
          title={getCompanyById(cellprops?.value)?.fullName}
        >
          {getCompanyById(cellprops?.value)?.fullName}
        </div>
      ),
      sortingFn: (a, b) => {
        return getCompanyById(a.subsidiaryId)?.fullName.localeCompare(
          getCompanyById(b.subsidiaryId)?.fullName
        );
      },
    },
  ];

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region -------------------------------- Effects

  //#endregion

  return (
    <div className={classes.root}>
      <VirtualTable<Project>
        id="projectMembershipTable"
        columns={columns}
        data={projects}
        loading={
          isFetching && {
            type: 'noItems',
          }
        }
        overscanRowCount={10}
        classNameTableRow={classes.root}
        resizable="relative"
        checkBoxContainerWidth={50}
      />
    </div>
  );
};
