import {
  DriveItem,
  DriveItemFolderDto,
  FileConvertFormat,
  UpdateDriveItemField,
  UpdateDriveItemFieldError,
  UploadSession,
} from '../../../models/Drive';
import { ApiResult, apiUrl } from '../../../api';
import { getAccessToken } from '../../../store/authEffect';
import {
  DocumentTemplateId,
  DriveItemId,
  GroupId,
  OfficeId,
  ProjectId,
} from '../../../models/Types';
import {
  CreateDocumentReportRequest,
  CreateDocumentRequest,
  CreateNewDocumentRequest,
  DocumentPrefix,
  DocumentTag,
  DocumentTagSet,
  DriveItemVersion,
} from '../../../models/Document';
import {
  DocumentTemplate,
  DocumentTemplateCategory,
  DocumentTemplatePlaceholder,
  DocumentTemplateTag,
  TableProperties,
} from '../../../models/DocumentTemplate';
import { FolderPermission, FolderPermissionV2 } from '../../../models/Settings';
import fetchWithRetry from '../../../util/fetchWithRetry';
import { buildQueryString } from '../../../util/api';
import moment from 'moment';
import { CreateFolderRequest } from '../../settings/components/NewFolderForm';
import { notification } from 'antd';
import i18next from 'i18next';
import { selectValuesToApiData } from '../util';

export const apiFetchDocumentSearchResults: (
  groupId: GroupId,
  searchTerm: string
) => Promise<ApiResult<DriveItemFolderDto>> = async (groupId, searchTerm) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}/search(q='${encodeURI(searchTerm)}')`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDocumentTemplateSuggestionPath: (
  documentTemplateId: DocumentTemplateId,
  projectId: ProjectId
) => Promise<ApiResult<DriveItem[]>> = async (
  documentTemplateId,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/DocumentTemplate/${documentTemplateId}/project/${projectId}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDocumentPrefix: (
  projectId: ProjectId
) => Promise<ApiResult<DocumentPrefix>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/projectDocumentPrefix/generate`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteDriveItem: (
  groupId: string,
  driveItemId: DriveItemId
) => Promise<ApiResult<void>> = async (groupId, driveItemId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}/${driveItemId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'DELETE',
    }
  );
  return {
    result,
  };
};

export const apiDeleteDriveItems: (
  groupId: string,
  driveItemIds: DriveItemId[]
) => Promise<ApiResult<void>> = async (groupId, driveItemIds) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}/deleteDriveItems`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(driveItemIds),
    }
  );
  return {
    result,
  };
};

export const apiUpdateDriveItem: (
  groupId: string,
  driveItemId: DriveItemId,
  patchValue: { name: string }
) => Promise<ApiResult<DriveItem>> = async (
  groupId,
  driveItemId,
  patchValue
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}/${driveItemId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/merge-patch+json',
      },
      method: 'PATCH',
      body: JSON.stringify(patchValue),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiCreateDriveFolder: (
  groupId: string,
  parentItemId: DriveItemId,
  folderName: string,
  permissions?: FolderPermission[]
) => Promise<ApiResult<DriveItem>> = async (
  groupId,
  parentItemId,
  folderName,
  permissions
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}${
      parentItemId ? `?parentItemId=${parentItemId}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        name: folderName,
        folder: {},
        ...(permissions ? { folderPermissions: permissions } : {}),
      }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchGlobalDocumentTags: (
  signal?: AbortSignal,
  projectId?: ProjectId
) => Promise<ApiResult<DocumentTag[]>> = async (signal, projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/${
      projectId ? `project/${projectId}/` : ''
    }DocumentTag/all`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchMyDocumentTags: (
  signal?: AbortSignal
) => Promise<ApiResult<DocumentTag[]>> = async (signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/DocumentTag/projects/me`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectDocumentTags: (
  projectId: ProjectId,
  signal?: AbortSignal
) => Promise<ApiResult<DocumentTag[]>> = async (projectId, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/project/${projectId}/DocumentTag`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateGlobalDocumentTag: (
  name: string,
  projectId?: ProjectId
) => Promise<ApiResult<DocumentTag>> = async (name, projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/${projectId ? `project/${projectId}/` : ''}DocumentTag`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ name }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteGlobalDocumentTag: (
  documentTagId: string,
  projectId?: ProjectId
) => Promise<ApiResult<void>> = async (documentTagId, projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/${
      projectId ? `project/${projectId}/` : ''
    }DocumentTag/${documentTagId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'DELETE',
    }
  );

  return {
    result,
  };
};

export const apiFetchDocumentTagSets: (
  includeArchived?: boolean,
  projectId?: ProjectId
) => Promise<ApiResult<DocumentTagSet[]>> = async (
  includeArchived,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/DocumentTagSet${
      includeArchived || projectId ? `?` : ''
    }${includeArchived ? `includeArchived=${includeArchived}` : ''}${
      includeArchived && projectId ? '&' : ''
    }${projectId ? `projectId=${projectId}` : ''}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiCreateDocumentTagSet: (
  newDocumentTagSet: {
    name: string;
    description: string;
    containedTags: string[];
  },
  projectId?: ProjectId
) => Promise<ApiResult<DocumentTagSet>> = async (
  newDocumentTagSet,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document${projectId ? `/${projectId}` : ''}/DocumentTagSet`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(newDocumentTagSet),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiUpdateDocumentTagSet: (
  documentTagSetId: string,
  updatedDocumentTagSet: {
    name: string;
    description: string;
    containedTags: string[];
  },
  projectId?: ProjectId
) => Promise<ApiResult<DocumentTagSet>> = async (
  documentTagSetId,
  updatedDocumentTagSet,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document${
      projectId ? `/${projectId}` : ''
    }/DocumentTagSet/${documentTagSetId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updatedDocumentTagSet),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiArchiveDocumentTagSet: (
  documentTagSetId: string,
  projectId?: ProjectId
) => Promise<ApiResult<DocumentTagSet>> = async (
  documentTagSetId,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document${
      projectId ? `/${projectId}` : ''
    }/DocumentTagSet/${documentTagSetId}/archive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiUnarchiveDocumentTagSet: (
  documentTagSetId: string,
  projectId?: ProjectId
) => Promise<ApiResult<DocumentTagSet>> = async (
  documentTagSetId,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document${
      projectId ? `/${projectId}` : ''
    }/DocumentTagSet/${documentTagSetId}/unarchive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiAddGlobalDocumentTagSet: (
  projectId: ProjectId,
  documentTagSetId: string
) => Promise<ApiResult<void>> = async (projectId, documentTagSetId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/project/${projectId}/DocumentTagSet/addSet/${documentTagSetId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  return {
    result,
  };
};

export const apiRemoveGlobalDocumentTagSet: (
  projectId: ProjectId,
  documentTagSetId: string
) => Promise<ApiResult<void>> = async (projectId, documentTagSetId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/project/${projectId}/DocumentTagSet/removeSet/${documentTagSetId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  return {
    result,
  };
};

export const apiUpdateProjectDocumentTagSet: (
  projectId: ProjectId,
  documentTagSetId: string,
  updatedDocumentTagSet: {
    name: string;
    description: string;
    containedTags: string[];
  }
) => Promise<ApiResult<DocumentTagSet>> = async (
  projectId,
  documentTagSetId,
  updatedDocumentTagSet
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/project/${projectId}/DocumentTagSet/${documentTagSetId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updatedDocumentTagSet),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiCreateProjectDocumentTagSet: (
  projectId: ProjectId,
  newDocumentTagSet: {
    name: string;
    description: string;
    containedTags: string[];
  }
) => Promise<ApiResult<DocumentTagSet>> = async (
  projectId,
  newDocumentTagSet
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/project/${projectId}/DocumentTagSet`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(newDocumentTagSet),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiFetchDocumentTemplateTags: (
  signal?: AbortSignal
) => Promise<ApiResult<DocumentTemplateTag[]>> = async (signal) => {
  const result = await fetchWithRetry(`${apiUrl}/document/template/tag`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
    signal,
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateDocumentTemplateTag: (
  name: string,
  officeId?: OfficeId
) => Promise<ApiResult<DocumentTemplateTag>> = async (name, officeId) => {
  const url = officeId
    ? `${apiUrl}/document/template/tag/office/${officeId}`
    : `${apiUrl}/document/template/tag`;
  const result = await fetchWithRetry(url, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ name }),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDocumentTemplateCategories: (
  signal?: AbortSignal
) => Promise<ApiResult<DocumentTemplateCategory[]>> = async (signal) => {
  const result = await fetchWithRetry(`${apiUrl}/document/template/category`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
    signal,
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateTemplateCategory: (
  name: string
) => Promise<ApiResult<DocumentTemplateCategory>> = async (name) => {
  const result = await fetchWithRetry(`${apiUrl}/document/template/category`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ name }),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDocumentPlaceholders: (
  officeId: OfficeId,
  signal?: AbortSignal
) => Promise<ApiResult<DocumentTemplatePlaceholder[]>> = async (
  officeId,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/${getDocumentTemplatePath(officeId)}/placeholder`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchTableProperties: (
  scope: string
) => Promise<ApiResult<TableProperties[]>> = async (scope) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/DocumentTemplate/tableProperties/${scope}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export function getDocumentTemplateUploadUrl(officeId) {
  return `${apiUrl}/${getDocumentTemplatePath(officeId)}/upload`;
}

export const apiCreateDocumentTemplate: (
  documentTemplate: DocumentTemplate,
  officeId?: OfficeId
) => Promise<ApiResult<DocumentTemplate>> = async (
  documentTemplate,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/${getDocumentTemplatePath(officeId)}/create`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(documentTemplate),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateDocumentTemplateOffice: (
  documentTemplate: DocumentTemplate,
  officeId: OfficeId
) => Promise<ApiResult<DocumentTemplate>> = async (
  documentTemplate,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/${getDocumentTemplatePath(officeId)}/create`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(documentTemplate),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateDocument: (
  createDocumentRequest: CreateDocumentRequest
) => Promise<ApiResult<DriveItem>> = async (createDocumentRequest) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/CreateDocumentFromTemplate`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createDocumentRequest),
    }
  );
  return {
    result,
  };
};

export const apiCreateNewDocument: (
  createNewDocumentRequest: CreateNewDocumentRequest
) => Promise<ApiResult<DriveItem>> = async (createNewDocumentRequest) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Document/createNewDocument`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createNewDocumentRequest),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateDocumentReport: (
  createReportDocumentRequest: CreateDocumentReportRequest
) => Promise<ApiResult<void>> = async (createDocumentRequest) => {
  let _createDocumentRequest = createDocumentRequest;
  if (
    createDocumentRequest?.reportFilterDto?.from === null &&
    createDocumentRequest?.reportFilterDto?.to === null
  ) {
    _createDocumentRequest = {
      ...createDocumentRequest,
      reportFilterDto: {
        from: moment('2000-01-01'),
        to: moment('2100-01-01'),
      },
    };
  }
  const result = await fetchWithRetry(
    `${apiUrl}/document/Document/createReport`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(_createDocumentRequest),
    }
  );
  return {
    result,
  };
};

//create a mehtod to return the URL dependent on OfficeId or noc´t

export const apiUpdateDocumentTemplate: (
  documentTemplate: DocumentTemplate,
  officeId: OfficeId
) => Promise<ApiResult<DocumentTemplate>> = async (
  documentTemplate,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/${getDocumentTemplatePath(officeId)}/${
      documentTemplate.documentTemplateId
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(documentTemplate),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiArchiveDocumentTemplate: (
  documentTemplate: DocumentTemplate,
  officeId: OfficeId
) => Promise<ApiResult<DocumentTemplate>> = async (
  documentTemplate,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/${getDocumentTemplatePath(officeId)}/${
      documentTemplate.documentTemplateId
    }/archive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(documentTemplate),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDownloadDocumentTemplate: (
  documentTemplate: DocumentTemplate,
  officeId: OfficeId
) => Promise<boolean> = async (documentTemplate, officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/${getDocumentTemplatePath(officeId)}/${
      documentTemplate.documentTemplateId
    }/download`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  if (!(result.status >= 200 && result.status < 300)) {
    return false;
  }
  let blob = await result.blob();
  if (blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.download = documentTemplate.driveItemId + documentTemplate.fileExtension;

    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    return true;
  }
  return false;
};

export const apiCopyDocumentTemplate: (
  documentTemplate: DocumentTemplate,
  officeId: OfficeId
) => Promise<ApiResult<DocumentTemplate>> = async (
  documentTemplate,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/${getDocumentTemplatePath(officeId)}/${
      documentTemplate.documentTemplateId
    }/copy`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUnarchiveDocumentTemplate: (
  documentTemplate: DocumentTemplate,
  officeId: OfficeId
) => Promise<ApiResult<DocumentTemplate>> = async (
  documentTemplate,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/${getDocumentTemplatePath(officeId)}/${
      documentTemplate.documentTemplateId
    }/unarchive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(documentTemplate),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDocumentUploadUrl: (
  projectId: ProjectId,
  group: GroupId,
  driveItemId: DriveItemId
) => string = (projectId, groupId, driveItemId) =>
  `${apiUrl}/document/Drive/${projectId}/group/${groupId}/driveItem/${driveItemId}/upload`;

export const apiFetchFolderPermissions: (
  groupId: GroupId,
  driveItemId: DriveItemId
) => Promise<ApiResult<FolderPermission[]>> = async (groupId, driveItemId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}/item/${driveItemId}/accessRights`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDocument: (
  grouId: GroupId,
  driveItemId: DriveItemId
) => Promise<ApiResult<DriveItem>> = async (groupId, driveItemId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}/${driveItemId}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDocumentWithCaching: (
  projectId: ProjectId,
  driveItemId: DriveItemId
) => Promise<ApiResult<DriveItem>> = async (projectId, driveItemId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/ProjectDrive/${projectId}/${driveItemId}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUnzipArchive: (
  grouId: GroupId,
  driveItem: DriveItem,
  parentItemId: string
) => Promise<ApiResult<void>> = async (groupId, driveItem, parentItemId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}/${parentItemId}/driveItem/${driveItem.id}/unzip`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );

  return {
    result,
  };
};

const getDriveItemDownloadUrl = async (
  groupId: GroupId,
  driveItem: DriveItem
): Promise<string | null> => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}/${driveItem.id}/contentDownloadUrl`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );

  if (!(result.status >= 200 && result.status < 300)) {
    return null;
  }

  return result.json();
};

const downloadAndOpenUrlFile = async (fileUrl: string): Promise<boolean> => {
  const response = await fetch(fileUrl);
  if (!response.ok) return false;

  const text = await response.text();
  const match = text.match(/URL=(.+)/);
  let extractedUrl = match ? match[1].trim() : null;

  if (extractedUrl) {
    if (
      !extractedUrl.startsWith('http://') &&
      !extractedUrl.startsWith('https://')
    ) {
      extractedUrl = 'https://' + extractedUrl;
    }
    window.open(extractedUrl, '_blank');
    return true;
  }
  return false;
};

export const apiOpenDriveItemUrlFile = async (
  groupId: GroupId,
  driveItem: DriveItem
): Promise<boolean> => {
  const fileUrl = await getDriveItemDownloadUrl(groupId, driveItem);
  return fileUrl ? downloadAndOpenUrlFile(fileUrl) : false;
};

export const apiDownloadDriveItem = async (
  groupId: GroupId,
  driveItem: DriveItem
): Promise<boolean> => {
  const fileUrl = await getDriveItemDownloadUrl(groupId, driveItem);
  if (fileUrl) {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return true;
  }
  return false;
};

export const apiGetBlobOfDriveItem = async (
  groupId: GroupId,
  driveItem: DriveItem
): Promise<Blob | null> => {
  const fileUrl = await getDriveItemDownloadUrl(groupId, driveItem);
  if (!fileUrl) return null;

  const response = await fetch(fileUrl);
  return response.ok ? response.blob() : null;
};

export const apiDownloadDriveItems = async (
  groupId: GroupId,
  driveItems: DriveItem[]
): Promise<boolean> => {
  const downloadPromises = driveItems.map(async (driveItem) =>
    apiDownloadDriveItem(groupId, driveItem)
  );
  const results = await Promise.all(downloadPromises);
  return results.every((result) => result);
};

export const apiDownloadDriveItemThumbnails: (
  driveItemId: string,
  grouId: GroupId
) => Promise<any> = async (driveItemId, groupId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}/${driveItemId}/thumbnails`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDownloadDocumentTemplatePreview: (
  documentTemplateId: string
) => Promise<any> = async (documentTemplateId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/DocumentTemplate/${documentTemplateId}/preview`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDownloadDocumentPreview: (
  itemId: string,
  groupId: string
) => Promise<any> = async (itemId, groupId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}/item/${itemId}/preview`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDownloadProjectMailAttachmentPreview: (
  projectId: string,
  messageId: string,
  attachmentId: string
) => Promise<any> = async (projectId, messageId, attachmentId) => {
  let result = null;
  if (projectId !== 'me') {
    result = await fetchWithRetry(
      `${apiUrl}/email/Email/${projectId}/message/${messageId}/attachment/${attachmentId}/preview`,
      {
        headers: { Authorization: `Bearer ${await getAccessToken()}` },
        method: 'POST',
      }
    );
  } else {
    result = await fetchWithRetry(
      `${apiUrl}/email/EmailMe/message/${messageId}/attachment/${attachmentId}/preview`,
      {
        headers: { Authorization: `Bearer ${await getAccessToken()}` },
        method: 'POST',
      }
    );
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchChildrenDriveItems: (
  itemId: string,
  groupId: string
) => Promise<any> = async (itemId, groupId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${groupId}/${itemId}/children`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchChildrenDriveItemsWithCaching: (
  itemId: string,
  projectId: string
) => Promise<any> = async (itemId, projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${projectId}/${itemId}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCopyAs: (
  projectId: string,
  driveItemId: string,
  fileConvertFormat: FileConvertFormat
) => Promise<any> = async (
  projectId: string,
  driveItemId: string,
  fileConvertFormat: FileConvertFormat
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/drive/${projectId}/${driveItemId}/copyAs?format=${fileConvertFormat}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'POST',
    }
  );

  return {
    result,
  };
};

export const apiCreateUploadSession: (
  projectId: ProjectId,
  groupId: GroupId,
  parentDriveItemId: DriveItemId,
  fileName: string
) => Promise<ApiResult<UploadSession>> = async (
  projectId: ProjectId,
  groupId: GroupId,
  parentDriveItemId: DriveItemId,
  fileName: string
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${projectId}/group/${groupId}/driveItem/${parentDriveItemId}/uploadSession/${fileName}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ fileName: fileName }),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiUpdateDocumentMetaData: (
  values: UpdateDriveItemField
) => Promise<ApiResult<UpdateDriveItemFieldError>> = async (values) => {
  const result = await fetchWithRetry(`${apiUrl}/document/Drive/fields`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(values),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiGetDriveItemsByTags: (
  grouId: GroupId,
  queryString: string
) => Promise<ApiResult<DriveItem[]>> = async (grouId, queryString) => {
  const result = await fetch(
    `${apiUrl}/document/Drive/${grouId}/searchByTags/${queryString}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiGetDriveItemTags: (
  projectId?: ProjectId,
  includeArchived?: boolean
) => Promise<ApiResult<DocumentTag[]>> = async (projectId, includeArchived) => {
  const baseEndpoint = `${apiUrl}/document${
    projectId ? `/project/${projectId}` : ''
  }/DocumentTag`;
  const queryString = buildQueryString({ includeArchived });
  const endpoint = `${baseEndpoint}${queryString}`;

  const accessToken = await getAccessToken();

  const result = await fetch(endpoint, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiGetDriveItemVersions: (
  groupId: GroupId,
  driveItemId: DriveItemId,
  signal?: AbortSignal
) => Promise<ApiResult<DriveItemVersion[]>> = async (
  groupId,
  driveItemId,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/driveItemVersions?groupId=${groupId}&driveItemId=${driveItemId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'GET',
      signal,
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiAddDriveItemPermissions: (
  projectId: ProjectId,
  driveItemId: DriveItemId,
  groupId: GroupId,
  permissionAssignments: FolderPermissionV2[]
) => Promise<ApiResult<unknown>> = async (
  projectId,
  groupId,
  driveItemId,
  permissionAssignments
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${projectId}/driveItemPermissions`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        driveItemId: driveItemId,
        groupId: groupId,
        permissionAssignments: permissionAssignments,
      }),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const addDriveItemPermissions = async (
  newDriveItemId: DriveItemId,
  value: CreateFolderRequest,
  projectId: ProjectId,
  groupId: GroupId
) => {
  const permissions: FolderPermissionV2[] = [
    selectValuesToApiData('admin', value.permissions?.[0] ?? 'none'),
    selectValuesToApiData('assistance', value.permissions?.[1] ?? 'none'),
    selectValuesToApiData('controller', value.permissions?.[2] ?? 'none'),
    selectValuesToApiData('member', value.permissions?.[3] ?? 'none'),
    selectValuesToApiData('guest', value.permissions?.[4] ?? 'none'),
  ];

  const { result } = await apiAddDriveItemPermissions(
    projectId,
    groupId,
    newDriveItemId,
    permissions
  );

  if (result.status >= 400) {
    notification.open({
      message: i18next.t('common:error'),
      description: i18next.t('documents:errorMessages.updatePermissionsError'),
    });
  }
};

export const apiRemoveNonPrioDriveItemPermissions: (
  projectId: ProjectId,
  driveItemId: DriveItemId,
  groupId: GroupId
) => Promise<ApiResult<unknown>> = async (projectId, driveItemId, groupId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/document/Drive/${projectId}/driveItemPermissions/nonPrio?groupId=${groupId}&driveItemId=${driveItemId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );

  return {
    result,
  };
};

export const apiGenerateXInvoicePreview: (
  file: FormData
) => Promise<Blob> = async (file) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/XRechnungVisualize`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'POST',
      body: file,
    }
  );

  return await result.blob();
};

function getDocumentTemplatePath(officeId: any) {
  return officeId
    ? `document/office/${officeId}/DocumentTemplate`
    : 'document/DocumentTemplate';
}
