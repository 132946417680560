import { combineReducers } from 'redux';
import { MailFolder } from '../../../models/MailFolder';
import { Message } from '../../../models/Message';
import { AdvancedMailSearchDto } from '../../../models/MailSearch';
import {
  MailCategoryColorName,
  MailFolderId,
  MessageId,
  ProjectId,
} from '../../../models/Types';
import {
  MessageIdsByMailFolderId,
  ByMessageId,
  SpecialMailFolders,
  MailListNavigationState,
  MailFolderByMailFolderId,
} from '../actions/types';
import me, * as fromMe from './me';
import projects, * as fromProjects from './projects';
import favorites, * as fromFavorites from './favorites';
import mailMeta, * as fromMailMeta from './mailMeta';
import { ByProjectId, NextLinkState } from './projects/messages';
import { SpecialMailFolderByProjectId } from './projects/specialMailFolder';

export interface MailState {
  me: fromMe.MailMeState;
  projects: fromProjects.ProjectMailState;
  favorites: fromFavorites.FavoritesMailState;
  mailMeta: fromMailMeta.MailMetaState;
}

export default combineReducers<MailState>({
  me,
  projects,
  favorites,
  mailMeta,
});

//#region ------------------------------ mailFolders
export const getAllMailFolders: (
  state: MailState,
  projectId: string
) => Array<MailFolder> = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getAllMailFoldersMe(state.me);
  }
  return fromProjects.getAllProjectMailFolders(state.projects, projectId);
};

export const getMailFoldersByIdState: (
  state: MailState,
  projectId: string
) => MailFolderByMailFolderId = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMeMailFoldersById(state.me);
  }
  return fromProjects.getProjectMailFoldersById(state.projects);
};

export const getMailFolder: (
  state: MailState,
  mailFolderId: string,
  projectId: string
) => MailFolder = (state, mailFolderId, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMailFolderMe(state.me, mailFolderId);
  }
  return fromProjects.getProjectMailFolder(state.projects, mailFolderId);
};

export const getMailFoldersIsFetching: (
  state: MailState,
  projectId: string
) => boolean = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMailFoldersMeIsFetching(state.me);
  }
  return fromProjects.getProjectMailFoldersIsFetching(
    state.projects,
    projectId
  );
};
export const getMailFoldersHasError: (
  state: MailState,
  projectId: string
) => boolean = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMailFoldersMeHasError(state.me);
  }
  return fromProjects.getProjectMailFoldersHasError(state.projects);
};
export const getMailFoldersErrorMessage: (
  state: MailState,
  projectId: string
) => string = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMailFoldersMeErrorMessage(state.me);
  }
  return fromProjects.getProjectMailFoldersErrorMessage(state.projects);
};

export const getActiveMailFolderId: (
  state: MailState,
  projectId: string
) => string = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getActiveMailFolderIdMe(state.me);
  }
  return fromProjects.getActiveProjectMailFolderId(state.projects, projectId);
};

export const getOpenMailFolderKeys: (
  state: MailState,
  projectId: ProjectId
) => MailFolderId[] = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getOpenMailFolderKeysMe(state.me);
  }
  return fromProjects.getOpenMailFolderKeysProjects(state.projects, projectId);
};
//#endregion

//#region ------------------------------ messages
export const getAllMessages: (
  state: MailState,
  projectId: string,
  mailFolderId: string
) => Array<Message> = (state, projectId, mailFolderId) => {
  if (projectId === 'me') {
    return fromMe.getAllMessagesMe(state.me, mailFolderId);
  }
  return fromProjects.getAllProjectMessages(
    state.projects,
    projectId,
    mailFolderId
  );
};

export const getAllProjectUnreadAssignmentMessagesIds: (
  state: MailState,
  projectId: string,
  userId: string
) => Array<MessageId> = (state, projectId, userId) => {
  return fromProjects.getAllProjectUnreadAssignmentMessagesIds(
    state.projects,
    projectId,
    userId
  );
};

export const getMessageIdsByProjecId: (state: MailState) => ByProjectId = (
  state
) => {
  return fromProjects.getMessageIdsByProjecId(state.projects);
};

export const getMessage: (
  state: MailState,
  projectId: string,
  messageId: string
) => Message = (state, projectId, messageId) => {
  if (projectId === 'me') {
    return fromMe.getMessageMe(state.me, messageId);
  }
  return fromProjects.getProjectMessage(state.projects, messageId);
};

export const getMessagesById: (
  state: MailState,
  projectId: string
) => {
  [messageId: string]: Message;
} = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMessagesMeById(state.me);
  }
  return fromProjects.getProjectMessagesById(state.projects);
};

export const getMessagesIsFetching: (
  state: MailState,
  projectId: string,
  mailFolderId?: MailFolderId
) => boolean = (state, projectId, mailFolderId) => {
  if (projectId === 'me') {
    return fromMe.getMessagesMeIsFetching(state.me, mailFolderId);
  }
  return fromProjects.getProjectMessagesIsFetching(
    state.projects,
    projectId,
    mailFolderId
  );
};

export const getMessagesHasError: (
  state: MailState,
  projectId: string
) => boolean = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMessagesMeHasError(state.me);
  }
  return fromProjects.getProjectMessagesHasError(state.projects);
};

export const getIsSingleMessageFetching: (
  state: MailState,
  projectId: string
) => boolean = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getIsSingleMessageMeFetching(state.me);
  }
  return fromProjects.getIsSingleProjectMessageFetching(state.projects);
};

export const getMessagesNextLink: (
  state: MailState,
  projectId: string,
  mailFolderId: string
) => string = (state, projectId, mailFolderId) => {
  if (projectId === 'me') {
    return fromMe.getMessagesMeNextLink(state.me, mailFolderId);
  }
  //return getMailCountByProjectIdAndFolderId(state, projectId, mailFolderId);
  return fromProjects.getProjectMessagesNextLink(
    state.projects,
    projectId,
    mailFolderId
  );
};

export const getProjectsNextLinkState: (state: MailState) => NextLinkState = (
  state
) => fromProjects.getProjectsNextLinkState(state.projects);

export const getMessagesErrorMessage: (
  state: MailState,
  projectId: string
) => string = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMessagesMeErrorMessage(state.me);
  }
  return fromProjects.getProjectMessagesErrorMessage(state.projects);
};

export const getMessagesByIdState: (
  state: MailState,
  projectId: string
) => ByMessageId = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMessagesMeByIdState(state.me);
  }
  return fromProjects.getProjectMessagesByIdState(state.projects);
};

export const getMessagesIdsState: (
  state: MailState,
  projectId: string
) => MessageIdsByMailFolderId = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMessagesMeIdsState(state.me);
  }
  return fromProjects.getProjectMessagesIdsState(state.projects, projectId);
};
//#endregion

//#region ------------------------------ meta
export const getDraftMessages: (
  state: MailState,
  projectId: string
) => Message[] = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getDraftMessagesMe(state.me);
  }
  return fromProjects.getDraftMessagesProject(state.projects, projectId);
};

export const getDraftMessage: (
  state: MailState,
  projectId: string,
  messageId: MessageId
) => Message = (state, projectId, messageId) => {
  if (projectId === 'me') {
    return fromMe.getDraftMessageMe(state.me, messageId);
  }
  return fromProjects.getDraftMessageProject(
    state.projects,
    projectId,
    messageId
  );
};

export const getDraftMessageIsInitial: (
  state: MailState,
  projectId: string,
  messageId: MessageId
) => boolean = (state, projectId, messageId) => {
  if (projectId === 'me') {
    return fromMe.getDraftMessageIsInitialMe(state.me, messageId);
  }
  return fromProjects.getDraftMessageIsInitialProject(
    state.projects,
    projectId,
    messageId
  );
};

export const getActiveDraftMessageId: (
  state: MailState,
  projectId: string
) => MessageId = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getActiveDraftMessageIdMe(state.me);
  }
  return fromProjects.getActiveDraftMessageIdProject(state.projects, projectId);
};

export const getSendingMessageIdsProjects: (state: MailState) => MessageId[] = (
  state
) => {
  return fromProjects.getSendingMessageIds(state.projects);
};

export const getSendingMessageIdsMe: (state: MailState) => MessageId[] = (
  state
) => {
  return fromMe.getSendingMessageIdsMe(state.me);
};
//#endregion

//#region ------------------------------ categories
export const getColorForMessageCategory: (
  state: MailState,
  projectId: string,
  displayName: string
) => string = (state, projectId, displayName) => {
  if (projectId === 'me') {
    return fromMe.getColorForMessageCategoryMe(state.me, displayName);
  }
  return fromProjects.getColorForProjectMessageCategory(
    state.projects,
    projectId,
    displayName
  );
};

export const getMessageCategoryColorMap: (
  state: MailState,
  projectId: string
) => { [displayName: string]: MailCategoryColorName } = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMessageCategoryColorMapMe(state.me);
  }
  return fromProjects.getMessageCategoryColorMapForProject(
    state.projects,
    projectId
  );
};
//#endregion

//#region ------------------------------ search
export const getMailSearchIsFetching: (
  state: MailState,
  projectId: ProjectId,
  mailFolderId?: MailFolderId
) => boolean = (state, projectId, mailFolderId) => {
  if (projectId === 'me') {
    return fromMe.getSearchMeIsFetching(state.me, mailFolderId);
  }
  return fromProjects.getSearchProjectIsFetching(
    state.projects,
    projectId,
    mailFolderId
  );
};
export const getMailSearchHasError: (
  state: MailState,
  projectId: ProjectId
) => boolean = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getSearchMeHasError(state.me);
  }
  return fromProjects.getSearchProjectHasError(state.projects);
};
export const getMailSearchErrorMessage: (
  state: MailState,
  projectId: ProjectId
) => string = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getSearchMeErrorMessage(state.me);
  }
  return fromProjects.getSearchProjectErrorMessage(state.projects);
};

export const getSearchMessageById: (
  state: MailState,
  projectId: string
) => ByMessageId = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getSearchMeByIdState(state.me);
  }
  return fromProjects.getSearchProjectByIdState(state.projects);
};
export const getSearchMessageIds: (
  state: MailState,
  projectId: string
) => string[] = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getSearchMeIds(state.me);
  }
  return fromProjects.getSearchProjectIds(state.projects, projectId);
};

export const getSearchMessageIdsState: (
  state: MailState,
  projectId: string
) => { [projectId: ProjectId]: string[] } = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getSearchMeIdsState(state.me);
  }
  return fromProjects.getSearchProjectIdsState(state.projects);
};

export const getSearchMessage: (
  state: MailState,
  projectId: string,
  id: MessageId
) => Message = (state, projectId, id) => {
  if (projectId === 'me') {
    return fromMe.getSearchMessageMe(state.me, id);
  }
  return fromProjects.getSearchProjectMessage(state.projects, id);
};

export const getSearchMessages: (
  state: MailState,
  projectId: string
) => Message[] = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getSearchMessagesMe(state.me);
  }
  return fromProjects.getSearchProjectMessages(state.projects, projectId);
};

export const getMailSearchNextLink: (
  state: MailState,
  projectId: string,
  mailFolderId: MailFolderId
) => string = (state, projectId, mailFolderId) => {
  if (projectId === 'me') {
    return fromMe.getSearchMeNextLink(state.me, mailFolderId);
  }
  return fromProjects.getSearchProjectNextLink(
    state.projects,
    projectId,
    mailFolderId
  );
};

export const getlastSearchTermMail: (
  state: MailState,
  projectId: string
) => string | AdvancedMailSearchDto = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getlastSearchTermMailMe(state.me);
  }
  return fromProjects.getlastSearchTermMailProject(state.projects, projectId);
};

export const getCurrentSearchTerm: (
  state: MailState,
  projectId: string
) => string | AdvancedMailSearchDto = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getCurrentSearchTermMe(state.me);
  }
  return fromProjects.getCurrentSearchTermProject(state.projects, projectId);
};

export const getCurrentSearchTermState = (state: MailState) =>
  fromProjects.getCurrentSearchTermState(state.projects);

export const getCurrentSearchMailFolderId: (
  state: MailState,
  projectId: string
) => MailFolderId = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getCurrentSearchMailFolderIdMe(state.me);
  }
  return fromProjects.getCurrentSearchMailFolderIdProject(
    state.projects,
    projectId
  );
};

export const getCurrentSearchMailFolderIdState = (state: MailState) =>
  fromProjects.getCurrentSearchMailFolderIdState(state.projects);

export const getCurrentSearchKeywords: (
  state: MailState,
  projectId: string
) => string = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getCurrentSearchKeywordsMe(state.me);
  }
  return fromProjects.getCurrentSearchKeywordsProject(
    state.projects,
    projectId
  );
};
//#endregion

//#region ------------------------------ specialMailFolders
export const getProjectsSpecialMailFoldersState: (
  state: MailState
) => SpecialMailFolderByProjectId = (state) =>
  fromProjects.getSpecialMailFoldersState(state.projects);

export const getSpecialMailFolders: (
  state: MailState,
  projectId: string
) => SpecialMailFolders = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getSpecialMailFoldersMe(state.me);
  }
  return fromProjects.getSpecialProjectMailFolders(state.projects, projectId);
};

export const isDeletedFolder: (
  state: MailState,
  projectId: string,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) => {
  if (projectId === 'me') {
    return fromMe.isDeletedFolderMe(state.me, mailFolderId);
  }
  return fromProjects.isDeletedProjectFolder(
    state.projects,
    projectId,
    mailFolderId
  );
};

export const isDraftFolder: (
  state: MailState,
  projectId: string,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) => {
  if (projectId === 'me') {
    return fromMe.isDraftFolderMe(state.me, mailFolderId);
  }
  return fromProjects.isDraftProjectFolder(
    state.projects,
    projectId,
    mailFolderId
  );
};

export const isSendFolder: (
  state: MailState,
  projectId: string,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) => {
  if (projectId === 'me') {
    return fromMe.isSendFolderMe(state.me, mailFolderId);
  }
  return fromProjects.isSendProjectFolder(
    state.projects,
    projectId,
    mailFolderId
  );
};
//#endregion

//#region ------------------------------ mailNavigation
export const getMailListNavigationState: (
  state: MailState,
  projectId: string
) => MailListNavigationState = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMailMeListNavigationState(state.me);
  }
  return fromProjects.getProjectMailListNavigationState(state.projects);
};

export const isActiveMailEntry: (
  state: MailState,
  projectId: string,
  messageId: MessageId
) => boolean = (state, projectId, messageId) => {
  if (projectId === 'me') {
    return fromMe.isActiveMailMeEntry(state.me, messageId);
  }
  return fromProjects.isActiveProjectMailEntry(state.projects, messageId);
};

export const isActiveScrollableMailEntry: (
  state: MailState,
  projectId: string,
  messageId: MessageId
) => boolean = (state, projectId, messageId) => {
  if (projectId === 'me') {
    return fromMe.isActiveScrollableMailMeEntry(state.me, messageId);
  }
  return fromProjects.isActiveScrollableProjectMailEntry(
    state.projects,
    messageId
  );
};

export const getActiveMessageId: (
  state: MailState,
  projectId: string
) => MessageId = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getActiveMessageMeId(state.me);
  }
  return fromProjects.getActiveProjectMessageId(state.projects);
};

export const getMailMenuOpen: (
  state: MailState,
  projectId: string
) => boolean = (state, projectId) => {
  if (projectId === 'me') {
    return fromMe.getMailMeMenuOpen(state.me);
  }
  return fromProjects.getProjectMailMenuOpen(state.projects);
};
//#endregion

//#region ------------------------------ favorites
export const getActiveProjectInMessageCenter: (
  state: MailState
) => ProjectId = (state) =>
  fromFavorites.getActiveProjectInMessageCenter(state.favorites);
//#endregion

export const getMailToEmlIsCreating: (
  state: MailState,
  messageId: MessageId
) => boolean = (state, messageId) =>
  fromMailMeta.getMailToEmlIsCreating(state.mailMeta, messageId);

export const getMailsToEmlAreCreating: (state: MailState) => MessageId[] = (
  state
) => fromMailMeta.getMailsToEmlAreCreating(state.mailMeta);

export const getMessageIsMoving: (
  state: MailState,
  messageId: MessageId
) => boolean = (state, messageId) =>
  fromMailMeta.getMessageIsMoving(state.mailMeta, messageId);

export const getMovingMessages: (state: MailState) => MessageId[] = (state) =>
  fromMailMeta.getMovingMessages(state.mailMeta);

export const getMailCountByProjectIdAndFolderId: (
  state: MailState,
  projectId: string,
  mailFolderId: string
) => string = (state, projectId, mailFolderId) => {
  let messagesByProjectId = fromProjects.getMessageIdsByProjecId(
    state.projects
  );
  return (
    messagesByProjectId[projectId]?.[mailFolderId]?.length?.toString() ?? ''
  );
};

//#region ------------------------------ comments
export const getCommentsByConversationId = (
  state: MailState,
  conversationId: string
) => fromProjects.getCommentsByConversationId(state.projects, conversationId);

export const getCommentById = (state: MailState, commentId: string) =>
  fromProjects.getCommentById(state.projects, commentId);

export const getIsFetchingComments = (state: MailState) =>
  fromProjects.getIsFetchingComments(state.projects);

export const getCommentsError = (state: MailState) =>
  fromProjects.getCommentsError(state.projects);
//#endregion
