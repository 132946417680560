import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { Message, MessageCenterMessage } from '../../../../models/Message';
import {
  ContactId,
  DriveItemId,
  GroupId,
  MailCategoryColorName,
  MailFolderId,
  MailSelectionListSpacing,
  MessageId,
  ProjectId,
} from '../../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { createSelector } from 'reselect';
import {
  RootReducerState,
  getActiveMessageId,
  getMailsToEmlAreCreating,
  getMessageCategoryColorMap,
  getMovingMessages,
  getProjectNewsByProjectId,
  getUserMeContactId,
  isMailDraftFolder,
  isMailSendFolder,
} from '../../../../apps/main/rootReducer';
import { VirtualList } from '@prio365/prio365-react-library';
import { ArrowKeyListRef } from '@prio365/prio365-react-library/lib/VirtualList/components/ArrowKeyList';
import { ListRowProps } from 'react-virtualized';
import { fetchMessagesSagaAction } from '../../actions/sagas';
import { addMailListSeparators } from '../../util';
import moment from 'moment';
import { useMailListCategoryListStyles } from './MailListItemCategoryList';
import MailListItem, { useListItemStyles } from './MailListItem';
import { useNavigate } from 'react-router-dom';
import { addEmlToDriveFolder } from '../../../documents/actions';
import { setMailListNavigationState } from '../../actions/actionControllers/mailNavigationActionController';
import {
  copyMessageToProject,
  markAsRead,
} from '../../actions/actionControllers/messageActionController';
import { debounceFunction } from '../../../../util';
import { Dispatch } from 'redux';
import { setActiveProjectInMessageCenter } from '../../actions/favorites/meta';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import { DND_TYPE_EMAIL } from '../../../../dnd/types';
import { ProjectNewsCategoryKey } from '../../../../models/Project';
import { fetchProjectNewsInSaga } from '../../../projects/actions';
const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    '& .prio-virtual-list-item-active': {
      '& .prio-mail-list-item-is-flagged': {
        backgroundColor: theme.old.palette.backgroundPalette.active.content,
      },
    },
    '& .prio-virtual-list-item-selected': {
      '& .prio-mail-list-item-is-flagged': {
        backgroundColor: theme.old.palette.backgroundPalette.active.content,
      },
    },
    '& .prio-virtual-list-item-active.prio-virtual-list-item-selected': {
      '& .prio-mail-list-item-is-flagged': {
        backgroundColor: theme.old.palette.backgroundPalette.active.sub,
      },
    },
    '& .prio-virtual-list-item:hover .prio-mail-list-item-is-flagged': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
  },
  separator: {
    borderBottom: theme.old.borders.content,
    paddingLeft: 24 + 2 * theme.old.components.mailListItem.spacing,
    fontSize: theme.old.components.mailListItem.fontSize,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    fontWeight: 500,
  },
  listItemContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  projectNewsDotVertical: {
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 8,
      left: 8,
      width: 8,
      height: 8,
      backgroundColor: theme.colors.base.primary.default,
      borderRadius: '50%',
      display: 'none',
    },
    '&:not(:has([class^="separator"]))::before': {
      display: 'block',
    },
  },
  projectNewsDotHorizontal: {
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 4,
      left: 4,
      width: 8,
      height: 8,
      backgroundColor: theme.colors.base.primary.default,
      borderRadius: '50%',
      display: 'none',
    },
    '&:not(:has([class^="separator"]))::before': {
      display: 'block',
    },
  },
}));

const isSendOrDraftFolderSelector = (
  projectId: ProjectId,
  mailFolderId: MailFolderId
) =>
  createSelector<
    [
      (state: RootReducerState) => boolean,
      (state: RootReducerState) => boolean,
    ],
    boolean
  >(
    (state) =>
      projectId === 'favorites'
        ? false
        : isMailSendFolder(state, projectId, mailFolderId),
    (state) =>
      projectId === 'favorites'
        ? false
        : isMailDraftFolder(state, projectId, mailFolderId),
    (isSendFolder, isDraftFolder) => isSendFolder || isDraftFolder
  );

const useShouldFetchIndex = (items: Message[], isFavoritesView: boolean) => {
  const index = !isFavoritesView
    ? items.length - 10
    : (items as MessageCenterMessage[]).findIndex((item) => item.shouldFetch);
  return useMemo(() => index, [index]);
};

const useActiveMessageIndex = (projectId: ProjectId, items: Message[]) => {
  const activeMessageId = useSelector<RootReducerState, MessageId>((state) =>
    getActiveMessageId(state, projectId)
  );

  const index = items.findIndex((item) => item.id === activeMessageId);

  return useMemo(() => index, [index]);
};

const debouncedMarkAsRead = debounceFunction(
  (
    message: Message,
    projectId: ProjectId,
    mailFolderId: MailFolderId,
    getInboxFolderId: (value: ProjectId) => MailFolderId,
    dispatch: Dispatch<any>
  ) => {
    const _projectId =
      projectId !== 'favorites'
        ? projectId
        : (message as MessageCenterMessage).projectId;
    dispatch(
      markAsRead(
        _projectId,
        [message.id],
        !message.isRead,
        mailFolderId === 'inbox' ? getInboxFolderId(_projectId) : mailFolderId,
        1
      )
    );
  },
  500
);

interface DragObject {
  message: Message;
  selectedMessages: Message[];
  mailFolderId: MailFolderId;
  isPersonal: boolean;
}

interface DropResult {
  showModal: boolean;
  projectId: ProjectId;
  message: Message;
  driveItemId: DriveItemId;
  groupId: GroupId;
  destinationMailFolder: MailFolderId;
  targetProjectId: ProjectId;
  droppedInWidgetDriveFolder: boolean;
  isFavoritesView: boolean;
}

interface MailListProps {
  className?: string;
  items: Message[];
  selection: Message[];
  mailFolderId?: MailFolderId;
  projectId: ProjectId;
  itemLayout: 'vertical' | 'horizontal';
  mailListSpacing: MailSelectionListSpacing;
  mailListNavigationWidth: number;
  hiddenLayout?: boolean;
  prefix?: string;
  automaticRead?: boolean;
  moveToFolder: (
    message: Message,
    selectedMessages: Message[],
    projectId: ProjectId
  ) => void;
  deleteMovedMessageMe?: boolean;
  onSelectionChange: (selectedIds: Message[]) => void;
  getInboxFolderId: (projectId: ProjectId) => MailFolderId;
}

export const MailList: React.FC<MailListProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    items,
    selection,
    mailFolderId,
    projectId,
    itemLayout,
    mailListSpacing,
    hiddenLayout,
    automaticRead,
    moveToFolder,
    deleteMovedMessageMe,
    onSelectionChange,
    getInboxFolderId,
    mailListNavigationWidth,
  } = props;
  const classes = useStyles();
  const classesOfListItem = useListItemStyles({ itemLayout });
  const classesOfCategoryList = useMailListCategoryListStyles();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const listRef = useRef<ArrowKeyListRef>(null);

  const isSendOrDraftFolder = useSelector(
    isSendOrDraftFolderSelector(projectId, mailFolderId)
  );

  const colorMap = useSelector<
    RootReducerState,
    { [displayName: string]: MailCategoryColorName }
  >((state) =>
    projectId === 'favorites'
      ? null
      : getMessageCategoryColorMap(state, projectId)
  );

  const activeMessageIndex = useActiveMessageIndex(projectId, items);

  const rowHeight = useCallback(
    ({ index }: { index: number }) => {
      const message = items[index];
      const previousMessage = index === 0 ? null : items[index - 1];
      const separator = addMailListSeparators(
        !!previousMessage
          ? moment(previousMessage?.receivedDateTime)
          : undefined,
        moment(message?.receivedDateTime)
      );

      const seperatorHeight = separator
        ? theme.old.spacing.unit(2) + theme.old.components.mailListItem.spacing
        : 0;
      return (
        (itemLayout === 'vertical'
          ? mailListSpacing === 'full'
            ? 81
            : mailListSpacing === 'middle'
            ? 73
            : 63
          : mailListSpacing === 'full'
          ? 37
          : mailListSpacing === 'middle'
          ? 33
          : 27) +
        (projectId === 'favorites' ? 24 : 0) +
        seperatorHeight
      );
    },
    [projectId, mailListSpacing, itemLayout, items, theme]
  );

  const indexToFetch = useShouldFetchIndex(items, projectId === 'favorites');

  const creatingEmlMessageIds = useSelector(getMailsToEmlAreCreating);
  const movingMessageIds = useSelector(getMovingMessages);

  const projectNews = useSelector<RootReducerState, ProjectNewsCategoryKey>(
    (state) => {
      return getProjectNewsByProjectId(state, projectId);
    }
  );

  const currentUserId = useSelector<RootReducerState, ContactId>(
    getUserMeContactId
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const isRowLoaded = useCallback(
    ({ index }) => {
      return index < indexToFetch;
    },
    [indexToFetch]
  );

  const loadMoreRows = async ({ stopIndex }) => {
    if (items.length > 0) {
      if (projectId !== 'favorites') {
        dispatch(fetchMessagesSagaAction(projectId, mailFolderId));
      } else {
        (items as MessageCenterMessage[])
          .filter((item, index) => item.shouldFetch && index < stopIndex)
          .forEach((item) => {
            dispatch(
              fetchMessagesSagaAction(item.projectId, item.mailFolderId)
            );
          });
      }
    }
  };

  const handleOnDeselectMessage = useCallback(
    (message: Message) => {
      if (selection.map((selected) => selected.id).includes(message.id)) {
        onSelectionChange(
          selection.filter((selected) => selected.id !== message.id)
        );
      }
    },
    [selection, onSelectionChange]
  );

  const handleOnItemClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
      const message = items[index];
      const { ctrlKey, shiftKey } = event;
      if (message && hiddenLayout && !ctrlKey && !shiftKey) {
        navigate(`../${mailFolderId ?? 'inbox'}/message/${message.id}/details`);
      }
      if (automaticRead && !message.isRead) {
        debouncedMarkAsRead(
          message,
          projectId,
          mailFolderId,
          getInboxFolderId,
          dispatch
        );
      }
    },
    [
      projectId,
      items,
      hiddenLayout,
      mailFolderId,
      automaticRead,
      dispatch,
      navigate,
      getInboxFolderId,
    ]
  );

  const handleOnItemDoubleClick = useCallback(
    (message: Message) => {
      let _projectId = projectId;
      if (projectId === 'favorites') {
        _projectId = (message as MessageCenterMessage).projectId;
      }
      const width = window.screen.availWidth / 2;
      const height = window.screen.availHeight / 2;
      window.open(
        `/view/${_projectId}/message/${message.id}/details`,
        '_blank',
        `width=${width},height=${height},noopener,noreferrer`
      );
    },
    [projectId]
  );

  const handleOnActiveChange = useCallback(
    (index: number) => {
      const message = items[index];
      if (message) {
        if (projectId === 'favorites') {
          dispatch(
            setActiveProjectInMessageCenter(
              (message as MessageCenterMessage).projectId
            )
          );
        }
        if (!hiddenLayout) {
          navigate(
            `../${mailFolderId ?? 'inbox'}/message/${message.id}/details`
          );
        }
        if (automaticRead && !message.isRead && !hiddenLayout) {
          debouncedMarkAsRead(
            message,
            projectId,
            mailFolderId,
            getInboxFolderId,
            dispatch
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      items,
      automaticRead,
      mailFolderId,
      projectId,
      hiddenLayout,
      getInboxFolderId,
      navigate,
      dispatch,
    ]
  );

  const uploadMessagesToDriveFolder = useCallback(
    (
      projectId: ProjectId,
      message: Message,
      parentDriveItemId: DriveItemId,
      destinationGroupId: GroupId,
      selectedMessages: Message[]
    ) => {
      if (
        selectedMessages.find(
          (selectedMessage) => selectedMessage.id === message.id
        )
      ) {
        selectedMessages.forEach((selectedMessage) => {
          dispatch(
            addEmlToDriveFolder(
              (selectedMessage as MessageCenterMessage).projectId ?? projectId,
              selectedMessage.id,
              parentDriveItemId,
              destinationGroupId
            )
          );
        });
      } else {
        dispatch(
          addEmlToDriveFolder(
            (message as MessageCenterMessage).projectId ?? projectId,
            message.id,
            parentDriveItemId,
            destinationGroupId
          )
        );
      }
    },
    [dispatch]
  );

  const handleCheckEquality = useCallback((a: Message, b: Message) => {
    return a.id === b.id;
  }, []);

  const handleOnMailDropped: (
    message: Message,
    projectId: ProjectId,
    mailFolderId: MailFolderId,
    item: DropResult
  ) => void = useCallback(
    (message, projectId, mailFolderId, item) => {
      if (
        !item.showModal &&
        onSelectionChange &&
        selection.find(
          (selectedMessage) => selectedMessage?.id === item.message?.id
        )
      ) {
        listRef.current?.setSelectedIndexes([]);
        onSelectionChange([]);
      }
      if (item.droppedInWidgetDriveFolder) {
        uploadMessagesToDriveFolder(
          projectId,
          message,
          item.driveItemId,
          item.groupId,
          selection
        );
      } else if (item.isFavoritesView) {
        const messagesToCopy = (
          selection.find(
            (selectedMessage) => selectedMessage.id === item.message.id
          )
            ? selection
            : [item.message]
        ) as MessageCenterMessage[];
        const messagesByProjectId: { [projectId: ProjectId]: Message[] } =
          messagesToCopy.reduce(
            (map, message) => ({
              ...map,
              [message.projectId ?? projectId]: [
                ...(map[message.projectId ?? projectId] ?? []),
                message,
              ],
            }),
            {}
          );
        Object.keys(messagesByProjectId).forEach((projectId) =>
          dispatch(
            copyMessageToProject(
              messagesByProjectId[projectId],
              projectId,
              mailFolderId ?? 'inbox',
              item.destinationMailFolder,
              item.targetProjectId,
              deleteMovedMessageMe
            )
          )
        );
        if (deleteMovedMessageMe) {
          const urlId = !!window.location.href.match(/message\/(.*)\/details?$/)
            ? window.location.href.match(/message\/(.*)\/details?$/)[1]
            : null;
          window.history.replaceState(
            {},
            '',
            urlId && messagesToCopy.find((message) => message.id === urlId)
              ? `../../../${mailFolderId}`
              : ''
          );
          if (messagesToCopy.find((message) => message.id === urlId)) {
            dispatch(setMailListNavigationState(null, projectId));
          }
        }
      } else if (item.showModal) {
        moveToFolder(item.message, selection, item.projectId);
      }
    },
    [
      selection,
      deleteMovedMessageMe,
      onSelectionChange,
      uploadMessagesToDriveFolder,
      moveToFolder,
      dispatch,
    ]
  );

  const handleOnRow: (
    message: Message,
    index: number
  ) => VirtualListItemOnRowProps<Message, DragObject, DropResult> = useCallback(
    (message, index) => {
      const svp = message?.customSingleValueExtendedProperties;

      const isMessageAssignmentUnread =
        svp?.messageAssignmentCreatedDateTime !== null &&
        svp?.messageAssignmentReadDateTime === null &&
        svp?.messageAssignmentId === currentUserId;

      return {
        className: classNames(classesOfListItem.root, {
          [classesOfListItem.isUnread]: !message.isRead,
          [classes.projectNewsDotVertical]:
            itemLayout === 'vertical' && isMessageAssignmentUnread,
          [classes.projectNewsDotHorizontal]:
            itemLayout === 'horizontal' && isMessageAssignmentUnread,
          'prio-mail-list-item-is-flagged':
            message.flag.flagStatus === 'Flagged',
        }),
        onClick: (event) => {
          handleOnItemClick(event, index);
        },
        onDoubleClick: () => {
          handleOnItemDoubleClick(message);
        },
        loading:
          movingMessageIds.includes(message.id) ||
          creatingEmlMessageIds.includes(message.id),
        draggable: {
          dragType: DND_TYPE_EMAIL,
          disableDefaultPreviewImage: false,
          dragItem: {
            message,
            selectedMessages: selection,
            mailFolderId,
            isPersonal: projectId === 'me',
          },
          collect: (monitor) => ({
            isDragging: monitor.isDragging(),
          }),
          canDrag: () => !movingMessageIds.includes(message.id),
          onDragEnd: async (_, monitor) => {
            if (monitor.didDrop()) {
              const item = monitor.getDropResult<DropResult>();
              let _projectId = projectId;
              if (_projectId === 'favorites') {
                _projectId = (message as MessageCenterMessage).projectId;
              }
              handleOnMailDropped(message, projectId, mailFolderId, item);
            }
          },
        },
      };
    },
    [
      classesOfListItem,
      projectId,
      mailFolderId,
      selection,
      movingMessageIds,
      creatingEmlMessageIds,
      handleOnItemClick,
      handleOnItemDoubleClick,
      handleOnMailDropped,
      itemLayout,
      classes,
      currentUserId,
    ]
  );
  //#endregion

  //#region ------------------------------ Components
  const rowRenderer = useCallback(
    ({ index, isScrolling, isVisible }: ListRowProps) => {
      const message = items[index];

      const selected = selection.some(
        (selectedMessage) => selectedMessage.id === message.id
      );

      return (
        <MailListItem
          classes={classesOfListItem}
          classesOfCategoryList={classesOfCategoryList}
          message={message}
          isSendOrDraftFolder={isSendOrDraftFolder}
          projectId={
            projectId === 'favorites'
              ? (message as MessageCenterMessage).projectId
              : projectId
          }
          colorMap={colorMap}
          isFavoritesView={projectId === 'favorites'}
          itemLayout={itemLayout}
          mailListSpacing={mailListSpacing}
          mailListNavigationWidth={mailListNavigationWidth}
          mailFolderId={mailFolderId}
          selected={selected}
          selectedMessages={selection}
          loading={isScrolling || !isVisible}
          getInboxFolderId={getInboxFolderId}
          onDeselectMessage={handleOnDeselectMessage}
          projectNews={projectNews}
        />
      );
    },
    [
      items,
      itemLayout,
      classesOfListItem,
      classesOfCategoryList,
      isSendOrDraftFolder,
      projectId,
      mailFolderId,
      colorMap,
      selection,
      mailListSpacing,
      getInboxFolderId,
      handleOnDeselectMessage,
      mailListNavigationWidth,
      projectNews,
    ]
  );

  const separatorRenderer = useCallback(
    (index: number) => {
      const message = items[index];
      const previousMessage = index === 0 ? null : items[index - 1];
      const separator = addMailListSeparators(
        !!previousMessage
          ? moment(previousMessage?.receivedDateTime)
          : undefined,
        moment(message?.receivedDateTime)
      );

      if (separator) {
        return (
          <div
            style={{
              height:
                theme.old.spacing.unit(2) +
                theme.old.components.mailListItem.spacing,
            }}
            className={classes.separator}
          >
            {separator}
          </div>
        );
      }
      return null;
    },
    [theme, classes, items]
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (listRef.current) {
      listRef.current.recomputeRowHeights();
      listRef.current?.forceUpdate();
    }
  }, [
    selection,
    mailListSpacing,
    itemLayout,
    movingMessageIds,
    creatingEmlMessageIds,
  ]);

  useEffect(() => {
    if (listRef.current && !hiddenLayout) {
      listRef.current.setActiveIndex(activeMessageIndex);
    }
  }, [activeMessageIndex, hiddenLayout]);

  useEffect(() => {
    dispatch(fetchProjectNewsInSaga());
  }, [dispatch]);

  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <VirtualList.ArrowKeyList<Message, DragObject, DropResult>
        id="mailListVList"
        enableContainer={false}
        ref={listRef}
        items={items}
        onSelectionChange={onSelectionChange}
        selectedItems={selection}
        onActiveChange={handleOnActiveChange}
        rowHeight={rowHeight}
        rowRenderer={rowRenderer}
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        threshold={40}
        overscanRowCount={60}
        onCheckEquality={handleCheckEquality}
        onRow={handleOnRow}
        separatorRenderer={separatorRenderer}
        rowsAreSelectable
        isInfiniteList
      />
    </div>
  );
};

export default MailList;
