import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@prio365/prio365-react-library';
import UserAvatar from './UserAvatar';
import Flex from './Flex';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMe } from '../apps/main/rootReducer';
import useDebounce from '../hooks/useDebounce';
import classNames from 'classnames';
import { clearPrioCache } from '../actions';
import { loggedOut } from '../modules/auth/actions';
import { logoutWithAccount } from '../store/authEffect';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../theme/types';
import { makePrioStyles } from '../theme/utils';
import LanguageSelector from '../modules/profile/components/LanguageSelector';
import { Tooltip } from 'antd';

const useStyles = makePrioStyles((theme) => ({
  root: {
    zIndex: 500,
    right: 0,
    top: 48,
    width: 320,
    position: 'absolute',
    borderRadius: 2,
    backgroundColor: theme.colors.application.background.default,
    boxShadow: '0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%)',
    marginLeft: '0!important',
    transition: 'all 0.2s',
    opacity: 0,
    color: theme.colors.application.typography.default,
    padding: theme.spacing.regular,
    visibility: 'hidden',
  },
  rootOpen: {
    opacity: 1,
    zIndex: 999,
    visibility: 'visible',
  },
  info: {
    overflow: 'hidden',
  },
  avatar: {
    cursor: 'pointer',
    marginRight: theme.spacing.small,
  },
  headLine: {
    color: theme.colors.application.typography.default,
    fontSize: '18px',
    fontWeight: theme.font.fontWeight.bold,
  },
  email: {
    fontSize: theme.font.fontSize.small,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface AccountInformationPanelProps {}

export const AccountInformationPanel: React.FC<AccountInformationPanelProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { t } = useTranslation();
  const me = useSelector(getUserMe);
  const ref = useRef(null);
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();

  const [visible, setVisible] = useState<boolean>();
  const debouncedVisible = useDebounce(visible, 200);
  //#endregion

  //#region -------------------------------- Methods

  const signOut = () => {
    dispatch(clearPrioCache());
    dispatch(loggedOut());
    logoutWithAccount();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (visible) {
      ref.current?.focus();
    }
  }, [visible, ref]);
  //#endregion

  return (
    <div
      tabIndex={-1}
      onBlur={(event) => {
        if (
          event.relatedTarget !== buttonRef.current &&
          event.relatedTarget?.id !== 'languageSelector'
        ) {
          setVisible(false);
        }
      }}
    >
      <div onClick={() => setVisible(!visible)} className={classes.avatar}>
        <UserAvatar size="medium" />
      </div>
      <div
        className={classNames(classes.root, {
          [classes.rootOpen]: visible,
        })}
        ref={ref}
      >
        {(visible || debouncedVisible) && (
          <Flex.Column childrenGap={theme.spacing.regular}>
            <Flex.Row
              justifyContent="space-between"
              childrenGap={theme.spacing.regular}
            >
              <Flex.Item>
                <UserAvatar size="large" />
              </Flex.Item>
              <Flex.Column
                className={classes.info}
                flex={1}
                childrenGap={theme.spacing.regular}
              >
                <Flex.Column>
                  <Flex.Item>
                    <div className={classes.headLine}>
                      {me?.displayName ?? ''}
                    </div>
                  </Flex.Item>
                  <Flex.Item>
                    <Tooltip zIndex={999999} title={me?.mail ?? ''}>
                      <div className={classes.email}>{me?.mail ?? ''}</div>
                    </Tooltip>
                  </Flex.Item>
                </Flex.Column>
              </Flex.Column>
            </Flex.Row>

            <Flex.Item>
              <div
                style={{
                  fontSize: theme.font.fontSize.extraSmall,
                  color: theme.colors.application.typography.muted,
                }}
              >
                {t('common:navigationBar.language')}
              </div>
              <LanguageSelector />
            </Flex.Item>
            <Flex.Row justifyContent="flex-end">
              <Flex.Item>
                <Tooltip
                  title={t('common:navigationBar.signOut')}
                  placement="bottom"
                >
                  <div>
                    <Button
                      onClick={signOut}
                      type="primary"
                      ref={buttonRef}
                      suffixIconProp={['fal', 'sign-out']}
                    >
                      <>{t('common:navigationBar.signOut')}</>
                    </Button>
                  </div>
                </Tooltip>
              </Flex.Item>
            </Flex.Row>
          </Flex.Column>
        )}
      </div>
    </div>
  );
};

export default AccountInformationPanel;
