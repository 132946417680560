import React, { useEffect, useState } from 'react';
import MailTaskItem from './MailTaskItem';
import { TaskDetail } from '../../../models/Task';
import { apiFetchTasksForAMessage } from '../../tasks/api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNewTaskCreated,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { setNewTaskCreated } from '../actions';
import Flex from '../../../components/Flex';
import { PrioSpinner } from '@prio365/prio365-react-library';

interface MailTaskItemListProps {
  projectId: string;
  messageId: string;
}

const MailTaskItemList: React.FC<MailTaskItemListProps> = ({
  projectId,
  messageId,
}) => {
  const [taskDetails, setTaskDetails] = useState<TaskDetail[]>(null);
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const newCreatedTask = useSelector<RootReducerState, boolean>((state) =>
    getNewTaskCreated(state)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let timeoutId;

    if (messageId || newCreatedTask) {
      timeoutId = setTimeout(() => {
        apiFetchTasksForAMessage(projectId, messageId, true)
          .then((result) => {
            if (result?.data?.length > 0) {
              const taskDetails = result.data.map((messageTask) => {
                const details: TaskDetail =
                  messageTask.taskDetails || ({} as TaskDetail);
                return {
                  taskId: messageTask.taskId,
                  taskType: messageTask.taskType,
                  taskTitle: details.taskTitle || '',
                  startDate: details.startDate || '',
                  dueDate: details.dueDate || '',
                  assignees: details.assignees || [],
                  status: details.status || 'NotStarted',
                  url: details.url || '',
                };
              });
              setTaskDetails(taskDetails);
            } else {
              setTaskDetails([]);
            }
            if (newCreatedTask) {
              dispatch(setNewTaskCreated(false));
            }
          })
          .catch((error) => {
            console.error('Error fetching tasks or task details:', error);
          });
      }, 500);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [messageId, projectId, newCreatedTask, dispatch]);

  if (taskDetails === null || taskDetails?.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing.regular,
          width: '100%',
          height: '100%',
          color: theme.colors.application.typography.default,
        }}
      >
        <div
          style={{
            fontWeight: theme.font.fontWeight.bold,
            fontSize: theme.font.fontSize.large,
          }}
        >
          {t('common:widgetArea.task')}
        </div>

        <Flex.Item
          flex={1}
          alignItems="center"
          justifyContent="center"
          height="100%"
          textAlign="center"
          display="flex"
        >
          {taskDetails === null ? (
            <PrioSpinner size="small" />
          ) : (
            t('common:widgetArea.noTasks')
          )}
        </Flex.Item>
      </div>
    );
  }

  return (
    <div
      style={{
        padding: theme.spacing.regular,
        gap: theme.spacing.small,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          color: theme.colors.application.typography.default,
          fontWeight: theme.font.fontWeight.bold,
          fontSize: theme.font.fontSize.large,
        }}
      >
        {t('common:widgetArea.task')}
      </div>
      {taskDetails?.map((taskDetail) => (
        <MailTaskItem key={taskDetail.taskId} taskDetail={taskDetail} />
      ))}
    </div>
  );
};

export default MailTaskItemList;
