import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import {
  Button,
  HistoryItem,
  Input,
  Modal,
  PrioSpinner,
  useKeyboardListener,
  useTheme,
} from '@prio365/prio365-react-library';
import FilterContextProvider from '../../../../components/Filter/FilterContextProvider';
import Flex from '../../../../components/Flex';
import {
  FilterBar,
  FilterBarRef,
} from '../../../../components/Filter/FilterBar';
import { PrioTheme } from '../../../../theme/types';
import { useDispatch, useSelector } from 'react-redux';
import { getIsGlobalDocumentsSearchModalOpen } from '../../../../apps/main/rootReducer';
import { closeGlobalDocumentsSearchModal } from '../../actions/globalDocumentsSearchModal';
import { useTranslation } from 'react-i18next';
import { DriveItem } from '../../../../models/Drive';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import GlobalDocumentsSearchBookmarksAndHistory from './GlobalDocumentsSearchBookmarksAndHistory';
import GlobalDocumentsSearchTable from './GlobalDocumentsSearchTable';
import { GenericSearchResult } from '../../../../components/Filter/types';
import { apiPutSearchHistory } from '../../../../components/Filter/api';
import { useQueryClient } from '@tanstack/react-query';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  modal: {
    width: '1290px!important',
    height: '640px!important',
    margin: '70px auto',
    '& > div:nth-child(2)': {
      height: '100%',
    },
    '& .prio-modal-footer': {
      display: 'none',
    },
    '& .prio-modal-close': {
      display: 'none',
    },
    '& .prio-modal-content': {
      height: '100%',
    },
    '& .prio-modal-body': {
      height: '100%',
    },
  },
  filterbar: {
    '& .prio-filter-bar-actions': {
      display: 'none',
    },
    '& .prio-filter-bar': {
      padding: 0,
      marginBottom: theme.spacing.regular,
    },
  },
  searchInput: {
    width: '100%',
    paddingRight: '6px',
    '& .prio-input-suffix': {
      display: 'flex',
    },
  },
  searchParameterIcon: {
    cursor: 'pointer',
    padding: '6px',
    '&:hover': {
      background: theme.colors.base.blue[20],
    },
  },
  dropdownOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '180px',
    gap: theme.spacing.regular,
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.default,
    padding: '6px',
    paddingLeft: '12px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.colors.application.background.hover,
    },
  },
  dropdownOptionSelected: {
    position: 'relative',
    backgroundColor: theme.colors.application.background.selected,
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 3,
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  dropdownOptionCloseIcon: {
    padding: '4px',
    '&:hover': {
      color: theme.old.palette.primaryColor,
      cursor: 'pointer',
      scale: 1.2,
    },
  },
  overlay: {
    padding: 0,
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  editModal: {
    margin: '120px auto',
    '& .prio-modal-body': {
      ' & > div': {
        width: '100%!important',
      },
    },
  },
  modalInnerContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    overflow: 'hidden',
  },
  errorPage: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    gap: theme.spacing.small,
    padding: theme.spacing.large,
  },
}));

interface GlobalDocumentsSearchModalProps {
  className?: string;
}

export const GlobalDocumentsSearchModal: React.FC<
  GlobalDocumentsSearchModalProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const modalOpen = useSelector(getIsGlobalDocumentsSearchModalOpen);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleModalClose = () => {
    dispatch(closeGlobalDocumentsSearchModal());
  };
  //#endregion

  //#region ------------------------------ Effects
  useKeyboardListener({
    Escape: () => {
      handleModalClose();
    },
  });
  //#endregion

  return (
    <Modal
      className={classNames(classes.modal, classes.filterbar, className)}
      visible={modalOpen}
      onClose={handleModalClose}
      maskClosable
      destroyOnClose
    >
      <FilterContextProvider
        searchType="documents"
        disableURLQuery={true}
        fetchSearchOnMount={false}
        customDefaultSearchParameters={[
          {
            parameterName: 'Transformed.ExcludeFolders',
            defaultValue: 'true',
            defaultMethod: 'eq',
          },
        ]}
      >
        <ModalContent />
      </FilterContextProvider>
    </Modal>
  );
};

export default GlobalDocumentsSearchModal;

interface ModalContentProps {}

const ModalContent: React.FC<ModalContentProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const inputRef = useRef(null);
  const filterBarRef = useRef<FilterBarRef>(null);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const modalOpen = useSelector(getIsGlobalDocumentsSearchModalOpen);

  const [isError, setIsError] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const {
    data,
    isLoading,
    searchString,
    fetchSearch,
    isError: _isError,
    clearSearch,
  } = useFilterContext<DriveItem, {}>();

  useEffect(() => {
    setIsError(_isError);
  }, [_isError]);

  const [searchStringFromFilterBar, setSearchStringFromFilterBar] =
    useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [combinedSearchString, setCombinedSearchString] = useState<string>('');
  const [lastCombinedSearchString, setLastCombinedSearchString] =
    useState<string>('');

  const parsedLastCombinedSearchString = useMemo(() => {
    return lastCombinedSearchString.replace(/(?<=\S)&|&(?=\S)/g, ' & ');
  }, [lastCombinedSearchString]);

  const isSearchActionDisabled = useMemo(
    () =>
      combinedSearchString === '' ||
      isLoading ||
      parsedLastCombinedSearchString === combinedSearchString,
    [combinedSearchString, isLoading, parsedLastCombinedSearchString]
  );
  const [historyItemInModal, setHistoryItemInModal] = useState<
    HistoryItem | undefined
  >(undefined);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleFetchSearch = () => {
    fetchSearch(combinedSearchString);
    setLastCombinedSearchString(combinedSearchString);
  };

  const onResetSearch = useCallback(() => {
    setSearchTerm('');
    clearSearch();
    filterBarRef.current?.handleResetFilterParameterFromOutside();
    setTimeout(() => inputRef.current?.focus(), 100);
    setIsError(false);
  }, [clearSearch]);

  const editBookmark = async (historyItem: HistoryItem) => {
    await apiPutSearchHistory(historyItem.searchHistoryId, {
      description: historyItem?.description,
      isBookmarked: historyItem?.isBookmarked,
      sortKey: historyItem?.sortKey,
    });
    queryClient.invalidateQueries({
      queryKey: ['searchAPI', 'documents'],
    });
  };

  const handleEditDescriptionModalOk = (historyItem: HistoryItem) => {
    editBookmark(historyItem);
    queryClient.invalidateQueries({
      queryKey: ['searchAPI', 'documents'],
    });

    setHistoryItemInModal(null);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const regex = /(?:&\s*)?Transformed\.FileName like '[^']*'/g;

    const reducedSearchString =
      searchStringFromFilterBar
        ?.trim()
        ?.replace(regex, '')
        ?.replace(/^&\s*|&\s*$/g, '') ?? '';
    setCombinedSearchString(
      `${reducedSearchString && `${reducedSearchString} & `}${
        searchTerm ? `Transformed.FileName like '${searchTerm.trim()}'` : ''
      }`
    );
  }, [searchStringFromFilterBar, searchTerm]);

  useEffect(() => {
    const _searchTerm = searchString
      ?.match(/Transformed\.FileName like '[^']*'/g)?.[0]
      .split(' ')?.[2]
      ?.replace(/'/g, '');

    setSearchTerm(_searchTerm);
  }, [searchString]);

  useKeyboardListener({
    Enter: () => {
      if (!!!historyItemInModal) {
        if (!!combinedSearchString) {
          handleFetchSearch();
          inputRef.current?.focus();
          inputRef.current?.blur();
        }
      } else handleEditDescriptionModalOk(historyItemInModal);
    },
  });

  useEffect(() => {
    if (modalOpen) {
      if (inputRef.current) {
        setTimeout(() => inputRef.current?.focus(), 100);
      }
    } else {
      setTimeout(() => onResetSearch(), 250);
    }
  }, [modalOpen, onResetSearch]);
  //#endregion

  return (
    <div style={{ height: '100%' }}>
      <Flex.Column
        childrenGap={theme.spacing.small}
        className={classes.content}
      >
        <Flex.Row childrenGap={theme.spacing.small}>
          {combinedSearchString === '' && data && (
            <Button
              type={'default'}
              iconProp={['fal', 'arrow-left']}
              size="large"
              onClick={clearSearch}
            />
          )}
          <Flex.Item flex={1}>
            <div
              style={{
                overflow: 'hidden',
              }}
            >
              <Input
                key={modalOpen + 'input'}
                ref={inputRef}
                full={true}
                className={classes.searchInput}
                placeholder={t(
                  'components:filter.globalDocumentsSearch.inputPlaceholder'
                )}
                value={searchTerm}
                onChange={(value) => setSearchTerm(value)}
              />
            </div>
          </Flex.Item>
          <Button
            type={'default'}
            iconProp={['fal', 'trash']}
            size="large"
            disabled={combinedSearchString === '' || isLoading}
            onClick={onResetSearch}
          />
          <Button
            iconProp={['fal', 'magnifying-glass']}
            size="large"
            disabled={isSearchActionDisabled}
            onClick={handleFetchSearch}
          />
        </Flex.Row>
        <Flex.Row>
          <FilterBar
            ref={filterBarRef}
            onChange={(searchString) =>
              setSearchStringFromFilterBar(searchString)
            }
            hiddenPickers={[
              'Transformed.FileName',
              'Transformed.ExcludeFolders',
            ]}
          />
        </Flex.Row>
        <div className={classes.modalInnerContent}>
          <ModalInnerContent
            data={data}
            isLoading={isLoading}
            isError={isError}
            setHistoryItemInModal={setHistoryItemInModal}
            onResetSearch={onResetSearch}
            setLastCombinedSearchString={setLastCombinedSearchString}
          />
        </div>
      </Flex.Column>
      <Modal
        style={{ zIndex: 9999998 }}
        title={t('components:filter.historyDrawer.editName')}
        visible={!!historyItemInModal}
        onOk={() => handleEditDescriptionModalOk(historyItemInModal)}
        onClose={() => setHistoryItemInModal(null)}
        okText={t('common:actions.save')}
        cancelText={t('common:actions.cancel')}
        className={classes.editModal}
        destroyOnClose
      >
        <Input
          style={{ width: '100%!important' }}
          type="text"
          value={historyItemInModal?.description}
          placeholder="Name eingeben"
          onChange={(value) =>
            setHistoryItemInModal((historyItemInModal) => ({
              ...historyItemInModal,
              description: value,
            }))
          }
        />
      </Modal>
    </div>
  );
};

interface ModalInnerContentProps {
  data: GenericSearchResult<DriveItem, {}>;
  isLoading: boolean;
  isError: boolean;
  setHistoryItemInModal: (historyItem: HistoryItem) => void;
  onResetSearch: () => void;
  setLastCombinedSearchString: (searchString: string) => void;
}

const ModalInnerContent: React.FC<ModalInnerContentProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    data,
    isLoading = false,
    isError = false,
    setHistoryItemInModal,
    setLastCombinedSearchString,
    onResetSearch,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  if (isLoading) {
    return (
      <div className={classes.loadingSpinner}>
        <PrioSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={classes.errorPage}>
        <div>
          {t('components:filter.globalDocumentsSearch.errorMessagePt1')}
        </div>
        <div style={{ paddingBottom: theme.spacing.regular }}>
          {t('components:filter.globalDocumentsSearch.errorMessagePt2')}
        </div>
        <Button onClick={onResetSearch} type="link">
          {t('common:back')}
        </Button>
      </div>
    );
  }
  return (
    <div style={{ width: '100%' }}>
      {data ? (
        <GlobalDocumentsSearchTable />
      ) : (
        <GlobalDocumentsSearchBookmarksAndHistory
          setHistoryItemInModal={setHistoryItemInModal}
          setLastCombinedSearchString={setLastCombinedSearchString}
        />
      )}
    </div>
  );
};
