import React, { CSSProperties, useMemo } from 'react';
import { MailListItemProps } from './MailListItem';
import classNames from 'classnames';
import { ShortDateTimeFormatString } from '../../../../util';
import i18n from '../../../../i18n';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import MailListItemCategoryList from './MailListItemCategoryList';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getMessageCategoryColorMap,
  getUserMeContactId,
} from '../../../../apps/main/rootReducer';
import { ContactId, MailCategoryColorName } from '../../../../models/Types';
import QuickActions from './QuickActions';
import AssignMailButton from './AssignMailButton';
import { useTranslation } from 'react-i18next';
import { MessageCenterMessage } from '../../../../models/Message';

const mainRowStyle: (theme: PrioTheme) => CSSProperties = (theme) => ({
  width: '100%',
  minHeight: '1.5715em',
  paddingRight: theme.old.components.mailListItem.spacing,
  overflow: 'visible',
  willChange: 'transform',
});

export const MailListItemVertical: React.FC<MailListItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    classes,
    classesOfCategoryList,
    message,
    projectId,
    isSendOrDraftFolder,
    isFavoritesView,
    colorMap: itemColorMap,
    mailFolderId,
    loading,
    getInboxFolderId,
    onDeselectMessage,
    mailListNavigationWidth,
    mailListSpacing,
  } = props;
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const colorMap = useSelector<
    RootReducerState,
    { [displayName: string]: MailCategoryColorName }
  >((state) =>
    isFavoritesView
      ? getMessageCategoryColorMap(state, projectId)
      : itemColorMap
  );

  const currentUserId = useSelector<RootReducerState, ContactId>(
    getUserMeContactId
  );

  const visibleTagCount = useMemo<number>(() => {
    const spacing = {
      full: 220,
      middle: 196,
      tight: 180,
    };
    var visibleTags = Math.floor(
      (mailListNavigationWidth - (spacing?.[mailListSpacing] || 220)) / 14
    ); // 180 && 220
    return visibleTags;
  }, [mailListNavigationWidth, mailListSpacing]);

  const isMessageAssignmentUnread = useMemo(() => {
    const svp = message?.customSingleValueExtendedProperties;
    return (
      svp?.messageAssignmentCreatedDateTime !== null &&
      svp?.messageAssignmentReadDateTime === null &&
      svp?.messageAssignmentId === currentUserId
    );
  }, [message, currentUserId]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classNames(
        classes.flexColumn,
        'mail-list-item-data-container'
      )}
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      {isFavoritesView && (
        <div className={classes.favoritesViewProjectName}>
          {(message as MessageCenterMessage).projectName}
        </div>
      )}
      <div className={classes.flexRow} style={mainRowStyle(theme)}>
        <div className="prio-mail-list-item-mail-address" style={{ flex: 1 }}>
          {isSendOrDraftFolder
            ? message.toRecipients
                ?.concat(message.ccRecipients)
                .map((recipient) => recipient?.emailAddress.name)
                .join('; ')
            : message.from?.emailAddress?.name}
        </div>
        <QuickActions
          className={classes.quickActionsRoot}
          style={{ marginRight: 0 }}
          classes={classes}
          projectId={projectId}
          mailFolderId={mailFolderId}
          message={message}
          getInboxFolderId={getInboxFolderId}
          onDeselect={onDeselectMessage}
          loading={loading}
        />
      </div>
      <div className={classes.flexRow} style={mainRowStyle(theme)}>
        <div className="prio-mail-list-item-subject" style={{ flex: 1 }}>
          {(message?.subject ?? '')?.trim().length > 0
            ? (message?.subject ?? '')?.trim() ?? ''
            : t('mail:messageDisplay.noSubject')}
        </div>
        {projectId !== 'me' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              width: 26,
              height: 26,
              ...(isMessageAssignmentUnread && {
                background: `radial-gradient(circle at 50% 50%, ${theme.colors.base.primary.default} 20%, transparent 70%)`,
                borderRadius: '50%',
                backgroundSize: 'contain',
              }),
            }}
          >
            <AssignMailButton
              classes={classes}
              projectId={projectId}
              message={message}
              loading={loading}
            />
          </div>
        )}
      </div>
      <div
        className={classNames(classes.flexRow, classes.categoryAndDateRow)}
        style={mainRowStyle(theme)}
      >
        <MailListItemCategoryList
          classes={classesOfCategoryList}
          className={classes.categoryList}
          projectId={projectId}
          values={message?.categories ?? []}
          colorMap={colorMap}
          messageId={message?.id}
          loading={loading}
          disableText
          visibleTagCount={visibleTagCount}
        />
        <div className="prio-mail-list-item-receivedDateTime">
          {ShortDateTimeFormatString(message.receivedDateTime, i18n.language)}
        </div>
      </div>
    </div>
  );
};

export default MailListItemVertical;
