import React, { useState, useEffect, useCallback, useRef } from 'react';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { ProjectId, GroupId, DriveItemId } from '../../../models/Types';
import { Result, notification, Input, Typography, Popover } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  DEFAULT_PRIO_COLUMN_PREFIX,
  DriveItem,
  DriveItemDropTarget,
  FolderDriveItem,
} from '../../../models/Drive';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'antd';
import {
  apiCopyAs,
  apiFetchDocumentSearchResults,
  apiGetDriveItemsByTags,
} from '../api';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentFolderChildren,
  getCurrentFolderItem,
  getProject,
  RootReducerState,
  getDocumentsMetaState,
  getCurrentRemoteItem,
  getDriveItemIsFetching,
} from '../../../apps/main/rootReducer';
import {
  abortDriveItemFetch,
  DriveItemsFetchContext,
  fetchDriveItemsSagaAction,
  setDriveItemId,
} from '../actions';
import { setDocumentsMetaState } from '../actions/meta';
import DriveItemBreadcrumb from './DriveItemBreadcrumb/DriveItemBreadcrumb';
import { Project } from '../../../models/Project';
import DeleteSelectionModal from './Modals/DeleteSelectionModal';
import CreateRemoteFolderModal from './Modals/CreateRemoteFolderModal';
import { DropTargetMonitor } from 'react-dnd';
import {
  DndDriveItemDto,
  DND_TYPE_DRIVE_ITEM_FILE,
  DND_TYPE_EMAIL,
} from '../../../dnd/types';
import { deleteRemoteFolder } from '../actions/remoteFolders';
import {
  AdvancedDocumentSearchDto,
  DriveUserRemoteItem,
} from '../../../models/Document';
import DocumentsTable from './DocumentsTable';
import DroppableElement from '../../../dnd/components/DroppableElement';
import CopyDocumentsDrawer from './CopyDocumentsDrawer';
import RenameModal from './Modals/RenameModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  sagaStartUploadFiles,
  sagaUploadFiles,
} from '../sagas/watchUploadFiles';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import {
  CreateDriveFavorite,
  DriveFavorite,
} from '../../../models/DriveFavorite';
import {
  createDriveFavorite,
  deleteDriveFavorite,
} from '../actions/driveFavorites';
import UploadField, { PrioFile } from '../../../components/Upload/UploadField';
import { addPrioFilesToUploadList } from '../actions/uploadLists';
import { updateDriveItemPath } from '../actions/lastOpenDriveItemFolder';
import DocumentSearch, {
  DocumentSearchRef,
} from './DocumentSearch/DocumentSearch';

import { useQuery } from '@tanstack/react-query';
import { isDriveItemFolder } from '../util';
import FolderAccessRightsModal from './FolderAccessRightsModal';
import { useDownloadXInvoice } from '../hooks/useDownloadXInvoice';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  content: {
    flex: 1,
    padding: theme.old.components.documentsTable.padding,
    height: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  breadCrumb: {
    paddingBottom: theme.old.spacing.unit(0.5),
    paddingTop: theme.old.spacing.unit(0.5),
    maxWidth: '100%',
  },
  searchInput: {
    display: 'flex',
    flex: 1,
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
  },
  pointerOnHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  searchBar: {
    marginBottom: theme.old.spacing.unit(2),
    height: 33,
    alignItems: 'flex-end',
    flexWrap: 'nowrap',
  },
  infoIcon: {
    color: theme.old.typography.colors.muted,
  },
}));

export interface DocumentPageRef {
  refetchDocuments: () => void;
}

interface DocumentsPageProps {
  className?: string;
  projectId: ProjectId;
  groupId: GroupId;
  pathPrefix: string;
  selectedDriveItems?: DriveItem[];
  setSelectedDriveItems?: (driveItems: DriveItem[]) => void;
}

export const DocumentsPage = (props: DocumentsPageProps) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    className,
    projectId,
    groupId,
    pathPrefix,
    selectedDriveItems,
    setSelectedDriveItems,
  } = props;
  const { driveItemId: currentFolderDriveItemId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme<PrioTheme>();

  //#endregion

  //#region ------------------------------ Defaults
  const [breadcrumbDriveItem, setBreadcrumbDriveItem] = useState<DriveItem>();

  const documentSearchRef = useRef<DocumentSearchRef>(null);

  const [renameModalDriveItem, setRenameModalDriveItem] =
    useState<DriveItem>(null);
  const [
    createDriveUserRemoteItemModalDriveItem,
    setCreateDriveUserRemoteItemModalDriveItem,
  ] = useState<DriveItem>(null);

  const activeProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );

  const rootDriveItemFolderId = `root-group-${groupId}`;
  const currentFolderDriveItem = useSelector<RootReducerState, DriveItem>(
    (state) =>
      getCurrentFolderItem(
        state,
        currentFolderDriveItemId ?? rootDriveItemFolderId
      )
  );

  const currentFolderChildren = useSelector<RootReducerState, DriveItem[]>(
    (state) =>
      getCurrentFolderChildren(
        state,
        currentFolderDriveItemId ?? rootDriveItemFolderId
      )
  );

  const [searchInput, setSearchInput] = React.useState<string>('');

  const [isReloadingDocuments, setIsReloadingDocuments] =
    useState<boolean>(false);

  const {
    showDeleteDocumentsModal,
    showCreateRemoteFolderModal,
    copyDocumentsDrawerState,
  } = useSelector(getDocumentsMetaState);

  const [copyDrawerDriveItems, setCopyDrawerDriveItems] = useState<DriveItem[]>(
    []
  );

  const currentRemoteItem = useSelector(getCurrentRemoteItem);

  const isRoot = !currentFolderDriveItemId;

  const { isFetching } = useSelector<RootReducerState, { isFetching: boolean }>(
    (state) =>
      getDriveItemIsFetching(
        state,
        isRoot ? rootDriveItemFolderId : currentFolderDriveItemId
      )
  );

  const rootFolderDriveItemId = useSelector<RootReducerState, DriveItem>(
    (state) => getCurrentFolderItem(state, rootDriveItemFolderId)
  )?.id;
  //#endregion

  //#region -------------------------------- States / Attributes / Selectors / Refs

  //#region -------------------------------- States
  const [isFocused, setIsFocused] = useState<boolean>(false);
  useState<DriveItem>(null);

  const [isFolderAccessRightModalVisible, setIsFolderAccessRightModalVisible] =
    useState<boolean>(false);

  const [accessRightModalDriveItem, setAccessRightModalDriveItem] =
    useState<DriveItem>(null);

  const [dropDestinationProjectId, setDropDestinationProjectId] =
    useState<ProjectId>(null);
  //#endregion

  //#region -------------------------------- Refs
  const searchInputRef = useRef<Input>(null);
  //#endregion
  //#endregion

  //#region ------------------------------ Methods / Handlers

  //#region -------------------------------- Methods
  const setSearchInputValue = (value: string) => {
    setSearchInput(value);
    if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.setValue(value);
    }
  };

  const closeRenameModal = () => {
    setRenameModalDriveItem(null);
  };

  const searchOnline = useCallback(() => {
    return { searchTerm: searchInput };
  }, [searchInput]);

  //#endregion

  //#region -------------------------------- Handlers
  const { onDownloadXInvoice } = useDownloadXInvoice(groupId);

  const handleReloadDocuments = async () => {
    if (driveItemsFromSearch && documentSearchRef?.current?.hasValue) {
      refetchSearch?.();
    } else {
      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          currentFolderDriveItemId,
          isRoot,
          DriveItemsFetchContext.DocumentsPage
        )
      );
      setTimeout(() => {
        setIsReloadingDocuments(false);
      }, 5000);
    }
    if (!searchInput) {
      setIsFocused(false);
    }
  };

  const onMoveItemsIntoProject: (
    driveItems: DriveItem[],
    destinationProject: Project,
    dropEffect: 'copy' | 'move'
  ) => void = (driveItems, destinationProject, dropEffect) => {
    dispatch(
      setDocumentsMetaState({
        copyDocumentsDrawerState: dropEffect,
      })
    );
    setCopyDrawerDriveItems(driveItems);
    setDropDestinationProjectId(destinationProject.projectId);
  };

  const onCloseCopyDriveItemDrawer = (deselect?: boolean) => {
    dispatch(
      setDocumentsMetaState({
        copyDocumentsDrawerState: 'none',
      })
    );
    setDropDestinationProjectId(null);
    setCopyDrawerDriveItems(null);
    if (deselect) {
      setSelectedDriveItems?.([]);
    }
  };

  const onMoveItemsIntoRemote = () => {
    setSelectedDriveItems?.([]);
  };

  const onCreateDriveFavorite = (driveItem: DriveItem) => {
    const createDriveFavoriteRequest: CreateDriveFavorite = {
      displayName: driveItem.name,
      driveItemId: driveItem.id,
      projectId: projectId,
      groupId: groupId,
      sharepointListId: driveItem.sharepointIds?.listId,
      drivePlatform: driveItem.sharepointIds?.siteId
        ? 'sharepoint'
        : 'oneDrive',
      mimeType: isDriveItemFolder(driveItem)
        ? undefined
        : driveItem?.file?.mimeType,
    };
    dispatch(createDriveFavorite(createDriveFavoriteRequest));
  };

  const onDeleteDriveFavorite = (driveFavorite: DriveFavorite) => {
    dispatch(deleteDriveFavorite(driveFavorite));
  };

  const onDriveItemClick = (driveItem: DriveItem) => {
    const isOneNoteFile = driveItem?.package?.type === 'oneNote';
    const isFolder = isDriveItemFolder(driveItem);
    if (isFolder && !isOneNoteFile) {
      setSearchInputValue('');
      setSelectedDriveItems?.([]);
    }
  };

  const onDeleteRemoteItem = (remoteItem: DriveUserRemoteItem) => {
    // open Modal and delete
    Modal.confirm({
      icon: null,
      title: t('documents:remoteItems.confirmation.delete.title'),
      content: (
        <Flex.Column>
          <Typography.Text>
            {t('documents:remoteItems.confirmation.delete.content')}
          </Typography.Text>
          <Typography.Text>{remoteItem.name}</Typography.Text>
        </Flex.Column>
      ),
      okText: t('documents:remoteItems.confirmation.delete.okText'),
      cancelText: t('documents:remoteItems.confirmation.delete.cancelText'),
      onOk() {
        const promise = new Promise<void>(async (resolve) => {
          dispatch(
            deleteRemoteFolder(remoteItem.driveUserRemoteItemId, remoteItem)
          );

          resolve();
        });
        return promise;
      },
    });
  };

  const onCreateRemoteItem = (driveItem: DriveItem) => {
    setCreateDriveUserRemoteItemModalDriveItem(driveItem);
    dispatch(
      setDocumentsMetaState({
        showCreateRemoteFolderModal: true,
      })
    );
  };

  const onCopyAsPdf = async (driveItem: DriveItem) => {
    notification.open({
      message: t('common:loading'),
      description: t('documents:inProgressMessage.creatingPDFIsInProgress'),
    });
    await apiCopyAs(projectId, driveItem.id, 'pdf');
  };

  const handleOnBeforeUpload = (originFiles: PrioFile[], sessionId: string) => {
    dispatch(
      sagaStartUploadFiles(
        groupId,
        sessionId,
        currentFolderDriveItemId ?? rootDriveItemFolderId,
        projectId
      )
    );
  };

  const handleOnAfterUpload = async (
    fileList: PrioFile[],
    sessionId: string
  ) => {
    dispatch(
      addPrioFilesToUploadList(
        groupId,
        fileList.map(
          ({ fileId, name, webkitRelativePath, size, type, sessionId }) => ({
            fileId,
            sessionId,
            parentDriveItemId:
              currentFolderDriveItemId ?? rootDriveItemFolderId,
            name,
            path: webkitRelativePath,
            size,
            isFolder: false,
            mimeType: type,
            driveItemId: null,
          })
        )
      )
    );
    dispatch(
      sagaUploadFiles(
        groupId,
        sessionId,
        currentFolderDriveItemId ?? rootDriveItemFolderId,
        projectId,
        fileList
      )
    );
  };

  const onMenuRename = (driveItem: DriveItem) => {
    setRenameModalDriveItem(driveItem);
  };

  const onMenuDelete = (driveItem: DriveItem) => {};

  const onMenuDownload = async (driveItem: DriveItem) => {};

  const onMenuMove = (driveItem: DriveItem) => {
    setCopyDrawerDriveItems([driveItem]);
  };

  const onMenuCopy = (driveItem: DriveItem) => {
    setCopyDrawerDriveItems([driveItem]);
  };

  const onChangeFolderAccessRights = (driveItem: DriveItem) => {
    setIsFolderAccessRightModalVisible(true);
    setAccessRightModalDriveItem(driveItem);
  };

  const {
    driveItemsFromSearch,
    queryWithText,
    queryWithAdvancedTerm,
    loading,
    refetchSearch,
  } = useDocumentQuery(
    rootDriveItemFolderId,
    pathPrefix,
    projectId,
    groupId,
    () => {
      documentSearchRef?.current?.resetSearch();
    }
  );

  const onTextSearchSubmit = (value: string) => {
    queryWithText(value);
  };

  const onAdvancedSearchSubmit = (value: AdvancedDocumentSearchDto) => {
    queryWithAdvancedTerm(value);
  };

  const onDriveItemSelect = (driveItems: DriveItem[]) => {
    dispatch(
      setDocumentsMetaState({
        areMultipleSelected: driveItems.length > 0,
      })
    );
    setSelectedDriveItems?.(driveItems);
  };

  const onDeleteSelectedDriveItems = () => {
    setSelectedDriveItems?.([]);
    dispatch(
      setDocumentsMetaState({
        areMultipleSelected: false,
      })
    );
  };

  const onCancelDeleteSelectedDriveItems = () => {};

  const onDropIntoDocumentsPage = (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>,
    target: FolderDriveItem
  ) => {
    const itemType = monitor.getItemType();

    switch (itemType) {
      case DND_TYPE_DRIVE_ITEM_FILE: {
        return {
          type: 'driveItem',
          object: target,
        } as DriveItemDropTarget;
      }
      case DND_TYPE_EMAIL: {
        return {
          droppedInDocumentsPage: true,
          driveItemId: target.driveItem?.id,
          groupId,
        };
      }
    }
    return undefined;
  };

  const onDndCopy = (driveItem: DriveItem) => {
    setSelectedDriveItems?.([]);
  };

  const onDndMove = () => {
    setSelectedDriveItems?.([]);
  };

  const disableDrag: (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>,
    object: FolderDriveItem,
    isOverCurrent: boolean
  ) => boolean = (item, monitor, targetDropItem, isOverCurrent) => {
    if (isFetching) {
      return true;
    }
    const itemType = monitor.getItemType();

    switch (itemType) {
      case DND_TYPE_DRIVE_ITEM_FILE: {
        const draggedElement = monitor.getItem() as DndDriveItemDto;
        const draggedIsFolder = !!draggedElement?.driveItem?.folder;
        const targetIsRoot = !targetDropItem?.driveItem?.parentReference?.path;

        if (draggedIsFolder && targetIsRoot) return true;
        if (
          !targetDropItem?.driveItem?.id ||
          draggedElement.driveItems.some(
            (x) => x.id === targetDropItem.driveItem.id
          ) ||
          monitor.isOver({ shallow: true }) === false
        ) {
          return true;
        }
        return false;
      }
      case DND_TYPE_EMAIL: {
        return false;
      }
    }
    return true;
  };

  const onSecondRowBreadCrumbClick = (driveItemId: DriveItemId) => {
    setSearchInputValue('');
    setIsFocused(false);
    navigate(`${pathPrefix}folder/${driveItemId}`);
  };

  const onOpenParentFolder = (driveItem: DriveItem) => {
    setSearchInputValue('');
    setIsFocused(false);

    if (driveItem?.parentReference?.id !== currentFolderDriveItemId) {
      if (driveItem?.parentReference?.id === rootFolderDriveItemId) {
        navigate(`${pathPrefix}all`);
      } else {
        navigate(`${pathPrefix}folder/${driveItem.parentReference.id}`);
      }
    }
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (currentFolderDriveItem) {
      setBreadcrumbDriveItem(currentFolderDriveItem);
    }
  }, [currentFolderDriveItem]);

  useEffect(() => {
    if (!!groupId && !searchInput) {
      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          currentFolderDriveItemId,
          isRoot,
          DriveItemsFetchContext.DocumentsPage
        )
      );
      dispatch(
        setDriveItemId(currentFolderDriveItemId ?? rootDriveItemFolderId)
      );
      dispatch(
        setDocumentsMetaState({
          areMultipleSelected: false,
          showDeleteDocumentsModal: false,
          copyDocumentsDrawerState: 'none',
          showCreateRemoteFolderModal: false,
        })
      );
      setSelectedDriveItems?.([]);
    }
  }, [
    groupId,
    currentFolderDriveItemId,
    searchInput,
    rootDriveItemFolderId,
    isRoot,
    dispatch,
    projectId,
    setSelectedDriveItems,
  ]);

  useEffect(() => {
    setSelectedDriveItems?.([]);
  }, [currentFolderDriveItemId, setSelectedDriveItems]);

  useEffect(() => {
    dispatch(
      updateDriveItemPath(activeProject.projectId, {
        documentsPage: currentFolderDriveItemId,
      })
    );
  }, [
    activeProject.projectId,
    currentFolderDriveItemId,
    dispatch,
    setSelectedDriveItems,
  ]);

  useEffect(() => {
    return () => {
      dispatch(
        abortDriveItemFetch(
          DriveItemsFetchContext.DocumentsPage,
          undefined,
          true
        )
      );
    };
  }, [dispatch]);
  //#endregion

  if (!projectId || !groupId)
    return (
      <Result
        status="500"
        title={`${t('common:error')} (GroupId ist leer)`}
        subTitle={t('common:somethingWentWrong')}
        extra={<Button>Back Home</Button>}
      />
    );

  return (
    <>
      <UploadField
        className={classNames(classes.root, className)}
        onBeforeUpload={handleOnBeforeUpload}
        onAfterUpload={handleOnAfterUpload}
        multiple
        directory
      >
        <DroppableElement
          activeOverlay
          accept={[DND_TYPE_DRIVE_ITEM_FILE, DND_TYPE_EMAIL]}
          onDrop={onDropIntoDocumentsPage}
          object={
            {
              projectId: activeProject.projectId,
              groupId: groupId,
              driveItem: currentFolderDriveItem,
            } as FolderDriveItem
          }
          disable={disableDrag}
        >
          <Flex.Row
            className={classNames(classes.root, className)}
            id={'prio-project-documents-page'}
          >
            <div className={classes.content}>
              <Flex.Row
                childrenGap={theme.old.spacing.unit(1)}
                className={classes.searchBar}
              >
                {!isFocused && (
                  <div
                    style={{
                      width: 'calc(100% - 260px)',
                    }}
                  >
                    <DriveItemBreadcrumb
                      driveItem={breadcrumbDriveItem}
                      project={activeProject}
                      pathPrefix={pathPrefix}
                      className={classes.breadCrumb}
                      remoteItem={currentRemoteItem}
                      enableCopyLink
                      handleReloadDocuments={handleReloadDocuments}
                      isReloadingDocuments={isReloadingDocuments}
                    />
                  </div>
                )}
                <Flex.Item flex={1}>
                  <Flex.Row>
                    <Flex.Row
                      alignItems="center"
                      padding={theme.old.spacing.baseSpacing}
                    >
                      <Popover
                        trigger="hover"
                        content={
                          <>
                            <h3>{t('documents:search.help.exmamples')}: </h3>
                            <p>{t('documents:search.help.firstExample')}</p>
                            <p>{t('documents:search.help.secondExample')}</p>
                            <p style={{ fontStyle: 'italic' }}>
                              {t('documents:search.help.secondExampleResult')}
                            </p>
                          </>
                        }
                      >
                        <FontAwesomeIcon
                          icon={['fal', 'circle-info']}
                          className={classes.infoIcon}
                        />
                      </Popover>
                    </Flex.Row>
                    <DocumentSearch
                      projectId={projectId}
                      ref={documentSearchRef}
                      searchOptions={{
                        searchTextInput: {
                          onSubmit: onTextSearchSubmit,
                        },
                        advancedSearch: {
                          onSubmit: onAdvancedSearchSubmit,
                        },
                      }}
                      groupId={groupId}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() =>
                        !documentSearchRef?.current?.hasValue &&
                        setIsFocused(false)
                      }
                      iconWhenValue={
                        <FontAwesomeIcon
                          icon={['fal', 'redo']}
                          onClick={handleReloadDocuments}
                          className={classes.pointerOnHover}
                        />
                      }
                    />
                  </Flex.Row>
                </Flex.Item>
              </Flex.Row>
              <Flex.Row>
                {isFocused && (
                  <div
                    style={{
                      marginTop: theme.old.components.documentsTable.spacing,
                      width: '100%',
                    }}
                  >
                    {!documentSearchRef?.current?.hasValue && (
                      <DriveItemBreadcrumb
                        driveItem={currentFolderDriveItem}
                        project={activeProject}
                        pathPrefix={pathPrefix}
                        className={classes.breadCrumb}
                        remoteItem={currentRemoteItem}
                        enableCopyLink
                        handleReloadDocuments={handleReloadDocuments}
                        isReloadingDocuments={isReloadingDocuments}
                        onItemClick={onSecondRowBreadCrumbClick}
                      />
                    )}
                  </div>
                )}
              </Flex.Row>
              <DocumentsTable
                id={'documentsModule'}
                mode="full"
                groupId={groupId}
                isKeywordSearch={!!driveItemsFromSearch}
                activeProject={activeProject}
                pathPrefix={pathPrefix}
                searchOnline={searchOnline}
                selectedDriveItemIds={
                  selectedDriveItems?.map((x) => x.id) ?? []
                }
                onDriveItemClick={onDriveItemClick}
                onMenuDelete={onMenuDelete}
                onMenuDownload={onMenuDownload}
                onMenuRename={onMenuRename}
                onMenuMove={onMenuMove}
                onMenuCopy={onMenuCopy}
                onDndCopy={onDndCopy}
                onDndMove={onDndMove}
                onCreateRemoteItem={onCreateRemoteItem}
                onDeleteRemoteItem={onDeleteRemoteItem}
                onCopyAsPdf={onCopyAsPdf}
                onChange={onDriveItemSelect}
                onMoveItemsIntoProject={onMoveItemsIntoProject}
                onMoveItemsIntoRemote={onMoveItemsIntoRemote}
                onCreateDriveFavorite={onCreateDriveFavorite}
                onDeleteDriveFavorite={onDeleteDriveFavorite}
                driveItemsFromSearch={
                  documentSearchRef?.current?.hasValue
                    ? driveItemsFromSearch
                    : null
                }
                setIsFocused={setIsFocused}
                onOpenParentFolder={onOpenParentFolder}
                projectId={activeProject.projectId}
                selectedDriveItemId={currentFolderDriveItemId}
                searchIsFetching={loading}
                onEditFolderAccessRights={onChangeFolderAccessRights}
                onDownloadXInvoice={onDownloadXInvoice}
              />
            </div>
          </Flex.Row>
        </DroppableElement>
      </UploadField>

      <RenameModal
        renameDriveItem={renameModalDriveItem}
        folderDriveItemId={currentFolderDriveItemId}
        groupId={groupId}
        onClose={closeRenameModal}
      />
      <DeleteSelectionModal
        visible={showDeleteDocumentsModal}
        onOk={onDeleteSelectedDriveItems}
        onCancel={onCancelDeleteSelectedDriveItems}
        groupId={groupId}
        selectedDriveItemIds={selectedDriveItems?.map((x) => x.id) ?? []}
        parentDriveItemId={currentFolderDriveItemId ?? rootDriveItemFolderId}
        driveItems={driveItemsFromSearch ?? currentFolderChildren ?? []}
      />
      <CreateRemoteFolderModal
        visible={showCreateRemoteFolderModal}
        onOk={() => {}}
        onCancel={() => {}}
        driveItem={createDriveUserRemoteItemModalDriveItem}
        project={activeProject}
      />
      {copyDocumentsDrawerState !== 'none' && (
        <CopyDocumentsDrawer
          drawerState={copyDocumentsDrawerState}
          sourceFolderId={currentFolderDriveItemId}
          sourceProjectId={projectId}
          selectedDriveItems={
            copyDrawerDriveItems?.length > 0
              ? copyDrawerDriveItems
              : selectedDriveItems
          }
          destinationProjectId={dropDestinationProjectId}
          onClose={onCloseCopyDriveItemDrawer}
        />
      )}
      <FolderAccessRightsModal
        projectId={projectId}
        groupId={groupId}
        driveItem={accessRightModalDriveItem}
        visible={isFolderAccessRightModalVisible}
        setVisible={setIsFolderAccessRightModalVisible}
      />
    </>
  );
};

const useDocumentQuery = (
  rootDriveItemFolderId: string,
  pathPrefix: string,
  projectId: ProjectId,
  groupId: GroupId,
  pathChangedCallback: () => void
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [textSearchTerm, setTextSearchTerm] = useState<string>('');

  const [advancedQueryString, setAdvancedQueryString] = useState<string>('');

  const [currentPath, setCurrentPath] = useState<string>(null);

  const {
    data: textQueryResult,
    isFetching: textSearchLoading,
    refetch: startTextQuery,
  } = useQuery({
    queryKey: ['documentsTextQuery', groupId, textSearchTerm],
    queryFn: ({ queryKey }) => {
      const [, groupId, textSearchTerm] = queryKey;
      return apiFetchDocumentSearchResults(groupId, textSearchTerm.trim());
    },
    enabled: false,
  });

  const {
    data: advancedQueryResult,
    isFetching: advancedSearchLoading,
    refetch: startAdvancedQuery,
  } = useQuery({
    queryKey: ['documentsAdvancedQuery', groupId, advancedQueryString],
    queryFn: ({ queryKey }) => {
      const [, groupId, advancedQueryString] = queryKey;
      return apiGetDriveItemsByTags(groupId, advancedQueryString);
    },
    enabled: false,
  });

  function countPropertiesWithValues(obj) {
    if (obj === undefined) return 0;
    const keys = Object.keys(obj);
    let count = 0;

    for (let i = 0; i < keys.length; i++) {
      const value = obj[keys[i]];
      if (
        value !== null &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        count++;
      }
    }

    return count;
  }

  useEffect(() => {
    const searchWithPrioLink = (
      projectIdFromSearch: string,
      driveItemsFolderIdFromSearch: string
    ) => {
      const projectIdDoesNotMathCurrenProject =
        projectIdFromSearch && projectId !== projectIdFromSearch;

      if (projectIdDoesNotMathCurrenProject) {
        notification.open({
          message: t('common:error'),
          description: t('documents:search.error.wrongProject'),
        });
      } else {
        navigate(
          `${pathPrefix}folder/${
            driveItemsFolderIdFromSearch ?? rootDriveItemFolderId
          }`
        );
      }
    };

    const projectIdFromSearch = textSearchTerm.match(
      /(?<=projects\/)(.*)(?=\/documents)/
    )?.[0];

    const driveItemsFolderIdFromSearch = textSearchTerm.match(
      /(?<=\/documents\/folder\/)(.*)(?=)/
    )?.[0];

    const isPrioLink = !!projectIdFromSearch;

    if (isPrioLink) {
      searchWithPrioLink(projectIdFromSearch, driveItemsFolderIdFromSearch);
    } else if (textSearchTerm.trim()) {
      startTextQuery();
    }
  }, [
    navigate,
    pathPrefix,
    projectId,
    rootDriveItemFolderId,
    startTextQuery,
    t,
    textSearchTerm,
  ]);

  useEffect(() => {
    if (advancedQueryString?.trim()) {
      startAdvancedQuery();
    }
  }, [advancedQueryString, startAdvancedQuery]);

  useEffect(() => {
    if (location.pathname === currentPath) {
    } else {
      pathChangedCallback?.();
    }
  }, [currentPath, location.pathname, pathChangedCallback]);

  const formatQueryString = (searchDto: AdvancedDocumentSearchDto): string => {
    const prefixMap = {
      alternativeName: 'AlternativeName',
      keyWords: 'DriveItemTags',
      companyIds: 'CompanyIds',
      contactIds: 'ContactIds',
    };

    let queryParams: string[] = [];

    for (const key in searchDto) {
      const value = searchDto[key];

      if (
        value === null ||
        value === undefined ||
        value === '' ||
        (Array.isArray(value) && value.length === 0)
      ) {
        continue;
      }

      let formattedValue: string;

      if (Array.isArray(value)) {
        formattedValue = value.join(',');
      } else {
        formattedValue = `${value}`;
      }

      queryParams.push(
        `${DEFAULT_PRIO_COLUMN_PREFIX}${prefixMap[key]}=${formattedValue}`
      );
    }

    return queryParams.join('&');
  };

  const queryWithText = (value: string) => {
    setCurrentPath(location.pathname);
    setTextSearchTerm(value);
  };

  const queryWithAdvancedTerm = (value: AdvancedDocumentSearchDto) => {
    setCurrentPath(location.pathname);
    const hasValues = countPropertiesWithValues(value) > 0;
    if (hasValues) {
      setAdvancedQueryString(formatQueryString(value));
    } else {
      setAdvancedQueryString('');
      queryWithText('');
    }
  };

  return {
    queryWithText,
    queryWithAdvancedTerm,
    refetchSearch: advancedQueryResult?.data
      ? startAdvancedQuery
      : textQueryResult?.data?.children
      ? startTextQuery
      : null,
    loading: textSearchLoading || advancedSearchLoading,
    textQueryResult,
    driveItemsFromSearch:
      advancedQueryResult?.data ?? textQueryResult?.data?.children,
  };
};

export default DocumentsPage;
