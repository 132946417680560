import { PRIO } from '../../../constants';
import { apiUrl } from '../../../api';
import React from 'react';
import {
  DriveItemId,
  GroupId,
  MessageId,
  ProjectId,
} from '../../../models/Types';
import { CopyDriveItem, DriveItem } from '../../../models/Drive';
import { createTemporaryId } from '../../../util';
import { DispatchAction } from '../../../models/Redux';

const messageControllerGenerator: (projectId: string) => string = (
  projectId
) => {
  return projectId === 'me' ? '/email/EmailMe' : `/email/Email/${projectId}`;
};

/** Purging old entries to decrease space usage in indexeddb */
export const PURGE_DRIVE_ITEMS = PRIO + 'PURGE_DRIVE_ITEMS';

export const purgeDriveItems = () => ({
  type: PURGE_DRIVE_ITEMS,
});

export const SET_CURRENT_DRIVE_ITEM_ID = PRIO + 'SET_CURRENT_DRIVE_ITEM_ID';

export const setDriveItemId = (driveItemId: DriveItemId) => ({
  type: SET_CURRENT_DRIVE_ITEM_ID,
  driveItemId,
});

/** Drive item fetching */
export const FETCH_DRIVE_ITEMS_REQUEST = PRIO + 'FETCH_DRIVE_ITEMS_REQUEST';
export const FETCH_DRIVE_ITEMS_COMMIT = PRIO + 'FETCH_DRIVE_ITEMS_COMMIT';
export const FETCH_DRIVE_ITEMS_ROLLBACK = PRIO + 'FETCH_DRIVE_ITEMS_ROLLBACK';

interface QueryParameterKeyValuePair {
  key: React.Key;
  value: any;
}

const applyParams = (...params: QueryParameterKeyValuePair[]) => {
  var queryString = '';

  params.forEach((p, index) => {
    if (index === 0 && params.length !== 0) {
      queryString = '?';
    }

    if (p.value && p.value !== '') {
      queryString = queryString.concat(`${p.key}=${p.value}`);
      if (index !== params.length - 1) {
        queryString = queryString.concat('&');
      }
    }
  });
  return queryString;
};

export const fetchDriveItems = (
  groupId: GroupId,
  driveItemId?: DriveItemId,
  nextLink?: string,
  isRoot?: boolean
) => {
  let parsedDriveItemId = driveItemId;
  if (parsedDriveItemId?.includes('root-group-')) {
    parsedDriveItemId = null;
  }
  return {
    type: FETCH_DRIVE_ITEMS_REQUEST,
    requiresAuth: true,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: parsedDriveItemId
            ? `${apiUrl}/document/Drive/${groupId}/${parsedDriveItemId}/children${applyParams(
                {
                  key: 'nextLink',
                  value: nextLink,
                },
                {
                  key: 'size',
                  value: 250,
                }
              )}`
            : `${apiUrl}/document/Drive/${groupId}${applyParams(
                {
                  key: 'nextLink',
                  value: nextLink,
                },
                {
                  key: 'size',
                  value: 250,
                }
              )}`,
          method: 'GET',
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: FETCH_DRIVE_ITEMS_COMMIT,
          meta: {
            driveItemId:
              isRoot || !parsedDriveItemId
                ? `root-group-${groupId}`
                : parsedDriveItemId,
            groupId,
            nextLink,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: FETCH_DRIVE_ITEMS_ROLLBACK,
          meta: {
            driveItemId:
              isRoot || !parsedDriveItemId
                ? `root-group-${groupId}`
                : parsedDriveItemId,
            groupId,
          },
        },
      },
      driveItemId:
        isRoot || !parsedDriveItemId
          ? `root-group-${groupId}`
          : parsedDriveItemId,
      groupId,
      nextLink,
    },
  };
};

export const fetchDriveItemsWithCaching = (
  projectId: ProjectId,
  groupId: GroupId,
  driveItemId?: DriveItemId,
  isRoot?: boolean
) => {
  let parsedDriveItemId = driveItemId;
  if (parsedDriveItemId?.includes('root-group-')) {
    parsedDriveItemId = null;
  }
  return {
    type: FETCH_DRIVE_ITEMS_REQUEST,
    requiresAuth: true,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: parsedDriveItemId
            ? `${apiUrl}/document/ProjectDrive/${projectId}/${parsedDriveItemId}`
            : `${apiUrl}/document/ProjectDrive/${projectId}`,
          method: 'GET',
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: FETCH_DRIVE_ITEMS_COMMIT,
          meta: {
            driveItemId:
              isRoot || !parsedDriveItemId
                ? `root-group-${groupId}`
                : parsedDriveItemId,
            projectId,
            groupId,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: FETCH_DRIVE_ITEMS_ROLLBACK,
          meta: {
            driveItemId:
              isRoot || !parsedDriveItemId
                ? `root-group-${groupId}`
                : parsedDriveItemId,
            projectId,
            groupId,
          },
        },
      },
      driveItemId:
        isRoot || !parsedDriveItemId
          ? `root-group-${groupId}`
          : parsedDriveItemId,
      projectId,
      groupId,
    },
  };
};

export const DRIVE_ITEM_RENAMED = PRIO + 'DRIVE_ITEM_RENAMED';

export const driveItemRenamed = (
  driveItem: DriveItem,
  parentDriveItemId: DriveItemId | null,
  groupId: GroupId
) => ({
  type: DRIVE_ITEM_RENAMED,
  driveItem,
  meta: { driveItemId: parentDriveItemId, groupId },
});

export const DRIVE_ITEM_CREATED = PRIO + 'DRIVE_ITEM_CREATED';

export const driveItemCreated = (
  driveItem: DriveItem,
  parentDriveItemId: DriveItemId | null,
  groupId: GroupId
) => ({
  type: DRIVE_ITEM_CREATED,
  payload: driveItem,
  meta: { driveItemId: parentDriveItemId, groupId },
});

export const DRIVE_ITEM_DELETED = PRIO + 'DRIVE_ITEM_DELETED';

export const driveItemDeleted = (
  driveItemId: DriveItemId,
  parentDriveItemId: DriveItemId | null,
  groupId: GroupId
) => ({
  type: DRIVE_ITEM_DELETED,
  driveItemId,
  meta: { driveItemId: parentDriveItemId, groupId },
});

export interface IAddEmlToDriveFolderMeta {
  messageId: MessageId;
  destinationGroupId: GroupId;
}

export const ADD_EML_TO_DRIVE_ITEM_REQUEST =
  PRIO + 'ADD_EML_TO_DRIVE_ITEM_REQUEST';
export const ADD_EML_TO_DRIVE_ITEM_COMMIT =
  PRIO + 'ADD_EML_TO_DRIVE_ITEM_COMMIT';
export const ADD_EML_TO_DRIVE_ITEM_ROLLBACK =
  PRIO + 'ADD_EML_TO_DRIVE_ITEM_ROLLBACK';

export const addEmlToDriveFolder: (
  projectId: ProjectId,
  messageId: MessageId,
  parentDriveItemId: DriveItemId,
  destinationGroupId: GroupId
) => DispatchAction<IAddEmlToDriveFolderMeta, DriveItem> = (
  projectId,
  messageId,
  parentDriveItemId,
  destinationGroupId
) => ({
  type: ADD_EML_TO_DRIVE_ITEM_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}${messageControllerGenerator(projectId)}/createEml`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        json: {
          messageId: messageId,
          parentDriveItemId,
          destinationGroupId,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ADD_EML_TO_DRIVE_ITEM_COMMIT,
        meta: {
          messageId,
          destinationGroupId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: ADD_EML_TO_DRIVE_ITEM_ROLLBACK,
        snackbarErrorMessage: {
          label: 'documents:errorMessages.emlUploadError',
          timeout: 6,
        },
        meta: { messageId, destinationGroupId },
      },
    },
    messageId,
    destinationGroupId,
  },
});

export const DRIVE_ITEMS_DELETED = PRIO + 'DRIVE_ITEMS_DELETED';

export const driveItemsDeleted = (
  driveItemIds: DriveItemId[],
  parentDriveItemId: DriveItemId | null,
  groupId: GroupId
) => ({
  type: DRIVE_ITEMS_DELETED,
  driveItemIds,
  meta: { driveItemId: parentDriveItemId, groupId },
});

export const DRIVE_ITEM_NEW_FOLDER_CREATED =
  PRIO + 'DRIVE_ITEM_NEW_FOLDER_CREATED';

export const driveItemNewFolderCreated = (
  driveItem: DriveItem,
  parentDriveItemId: DriveItemId | null,
  groupId: GroupId
) => ({
  type: DRIVE_ITEM_NEW_FOLDER_CREATED,
  driveItem,
  meta: { driveItemId: parentDriveItemId, groupId },
});

export const COPY_ITEMS_INTO_FOLDER_REQUEST =
  PRIO + 'COPY_ITEMS_INTO_FOLDER_REQUEST';
export const COPY_ITEMS_INTO_FOLDER_COMMIT =
  PRIO + 'COPY_ITEMS_INTO_FOLDER_COMMIT';
export const COPY_ITEMS_INTO_FOLDER_ROLLBACK =
  PRIO + 'COPY_ITEMS_INTO_FOLDER_ROLLBACK';

export const copyItemsIntoFolder = (
  projectId: ProjectId,
  sourceGroupId: GroupId,
  destinationGroupId: GroupId,
  driveItemsToMove: CopyDriveItem[],
  originalDriveItems: DriveItem[],
  sourceDriveItemId: DriveItemId,
  destinationDriveItemId: DriveItemId,
  sourceIsRoot: boolean,
  destinationIsRoot: boolean
) => {
  const temporaryDriveItemsToMove: { [key: DriveItemId]: string } =
    driveItemsToMove.reduce((map, item) => {
      if (item.deleteSourceDriveItem) {
        return map;
      }
      return { ...map, [item.driveItemId]: createTemporaryId() };
    }, {});
  return {
    type: COPY_ITEMS_INTO_FOLDER_REQUEST,
    requiresAuth: true,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${apiUrl}/document/project/${projectId}/Drive/copy`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          json: {
            destinationGroupId,
            destinationDriveItemId,
            driveItemsToMove,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: COPY_ITEMS_INTO_FOLDER_COMMIT,
          meta: {
            originalDriveItems,
            driveItemsToMove,
            sourceDriveItemId,
            sourceIsRoot,
            sourceGroupId,
            destinationDriveItemId,
            destinationIsRoot,
            destinationGroupId,
            temporaryDriveItemsToMove,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: COPY_ITEMS_INTO_FOLDER_ROLLBACK,
          meta: {
            originalDriveItems,
            driveItemsToMove,
            sourceDriveItemId,
            sourceIsRoot,
            sourceGroupId,
            destinationDriveItemId,
            destinationIsRoot,
            destinationGroupId,
            temporaryDriveItemsToMove,
          },
          snackbarErrorMessage: {
            label: driveItemsToMove[0]?.deleteSourceDriveItem
              ? 'documents:errorMessages.moveItemsError'
              : 'documents:errorMessages.copyItemsError',
            timeout: 6,
          },
        },
      },
      originalDriveItems,
      driveItemsToMove,
      sourceDriveItemId,
      sourceIsRoot,
      sourceGroupId,
      destinationDriveItemId,
      destinationIsRoot,
      destinationGroupId,
      temporaryDriveItemsToMove,
    },
  };
};

export const UPDATE_DRIVE_ITEM = PRIO + 'UPDATE_DRIVE_ITEM';

export const updateDriveItem = (
  driveItem: DriveItem,
  groupId: GroupId,
  isParentRoot: boolean
) => {
  return {
    type: UPDATE_DRIVE_ITEM,
    driveItem,
    meta: {
      groupId,
      isParentRoot,
    },
  };
};

export const START_FETCH_DRIVE_ITEMS_SAGA =
  PRIO + 'START_FETCH_DRIVE_ITEMS_SAGA';

export const DriveItemsFetchContext = {
  DocumentsPage: 'DocumentsPage',
  WidgetArea: 'WidgetArea',
  Other: 'Other',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DriveItemsFetchContext =
  (typeof DriveItemsFetchContext)[keyof typeof DriveItemsFetchContext];
export interface FetchDriveItemsSagaAction {
  type: string;
  groupId: GroupId;
  driveItemFolderId: DriveItemId;
  isRoot: boolean;
  projectId: ProjectId;
  ignoreShouldNotFetch?: boolean;
  context: DriveItemsFetchContext;
}

export const fetchDriveItemsSagaAction: (
  projectId: ProjectId,
  groupId: GroupId,
  driveItemFolderId: DriveItemId,
  isRoot: boolean,
  context: DriveItemsFetchContext
) => FetchDriveItemsSagaAction = (
  projectId,
  groupId,
  driveItemFolderId,
  isRoot,
  context
) => ({
  type: START_FETCH_DRIVE_ITEMS_SAGA,
  projectId,
  groupId,
  driveItemFolderId,
  isRoot,
  context,
});

export const DRIVE_ITEM_ABORT = PRIO + 'DRIVE_ITEM_ABORT';

/**
 * Aborts the drive item fetch in saga
 * @param context The context where the fetch was initiated
 * @param driveItemId The drive item id to abort fetching. Should not be null. If is root, use `root-group-${groupId}`
 * @param resetContextInManager If true, the context will be reset in the drive item manager
 * @returns The action
 */
export const abortDriveItemFetch = (
  context: DriveItemsFetchContext,
  driveItemId?: DriveItemId,
  resetContextInManager?: boolean
) => ({
  type: DRIVE_ITEM_ABORT,
  context,
  driveItemId,
  resetContextInManager,
});

export const FETCH_DRIVE_ITEM_REQUEST = PRIO + 'FETCH_DRIVE_ITEM_REQUEST';
export const FETCH_DRIVE_ITEM_COMMIT = PRIO + 'FETCH_DRIVE_ITEM_COMMIT';
export const FETCH_DRIVE_ITEM_ROLLBACK = PRIO + 'FETCH_DRIVE_ITEM_ROLLBACK';

export const fetchDriveItem = (
  groupId: GroupId,
  driveItemId: DriveItemId,
  parentDriveItemId?: DriveItemId
) => {
  return {
    type: FETCH_DRIVE_ITEM_REQUEST,
    requiresAuth: true,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${apiUrl}/document/Drive/${groupId}/${driveItemId}`,
          method: 'GET',
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: FETCH_DRIVE_ITEM_COMMIT,
          meta: {
            driveItemId,
            groupId,
            parentDriveItemId,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: FETCH_DRIVE_ITEM_ROLLBACK,
          meta: {
            driveItemId,
            groupId,
            parentDriveItemId,
          },
        },
      },
      driveItemId,
      groupId,
      parentDriveItemId,
    },
  };
};
